// libraries.
import { Outlet } from "react-router-dom";

// components.
import { SidebarPublicationPrice } from "./components/SidebarPublicationPrice/SidebarPublicationPrice";
import { DetailNavbar } from "@shared/DetailNavbar";

export const PublicationPricePage = () => {
  return (
    <div className="d-flex">
      <div style={{ width: "70px" }} />
      <SidebarPublicationPrice />
      <div className="d-flex flex-column w-100" style={{ height: "100vh" }}>
        <DetailNavbar />
        <Outlet />
      </div>
    </div>
  );
};
