// components

import { OriginalMinimumPrice } from "./components/OriginalMinimumPrice";

export const ApprovalPriceInputs = () => {
  return (
    <>
      <div className="d-flex gap-1 flex-column mt-2">
        <div className="d-flex flex-column gap-2">
          <div className="d-flex flex-column gap-2 mb-3">
            <OriginalMinimumPrice />
          </div>
        </div>
      </div>
    </>
  );
};
