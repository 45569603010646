import { sidebarActionsTypes } from "./sidebar.types";

export type SidebarAction =
  | {
      payload: string;
      type: typeof sidebarActionsTypes.SIDEBAR_SELECTED_BUTTON;
    }
  | { payload: boolean; type: typeof sidebarActionsTypes.SIDEBAR_IS_HOVERED }
  | { type: typeof sidebarActionsTypes.SIDEBAR_RESET }
  | { type: typeof sidebarActionsTypes.DEFAULT };

export const doSidebarReset = (): SidebarAction => ({
  type: sidebarActionsTypes.SIDEBAR_RESET,
});
export const doSidebarSelectedButton = (value: string): SidebarAction => ({
  payload: value,
  type: sidebarActionsTypes.SIDEBAR_SELECTED_BUTTON,
});
export const doSidebarIsHovered = (value: boolean): SidebarAction => ({
  payload: value,
  type: sidebarActionsTypes.SIDEBAR_IS_HOVERED,
});
