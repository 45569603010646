import { IconCards, IconTableList } from "@assets";
import { electricPurple000, electricPurple600 } from "@habitech/shared";
import { usePageModuleFeedbackLook } from "@pages/InventoryPage/modules/FeedbackLook/slice/pageModuleFeedbackLook.hook";
import { ViewCommentsType } from "@pages/InventoryPage/modules/FeedbackLook/slice/pageModuleFeedbackLook.slice";
import { Button } from "react-bootstrap";

export const SwitchButton = () => {
  const { viewCommentsType, setViewToTableComments, setViewToCardComments } =
    usePageModuleFeedbackLook();

  const table = viewCommentsType === ViewCommentsType.TABLE_COMMENTS;
  const cards = viewCommentsType === ViewCommentsType.CARD_COMMENTS;

  const tableButtonStyles = {
    background: table ? `${electricPurple600}` : "none",
    border: "none",
    borderRadius: "6px 0px 0px 6px",
  };

  const cardButtonStyles = {
    background: cards ? `${electricPurple600}` : "none",
    border: "none",
    borderRadius: "0px 6px 6px 0px",
  };

  return (
    <div className=" ms-auto border  border-subtle rounded">
      <>
        <Button style={tableButtonStyles} onClick={setViewToTableComments}>
          <div>
            <IconTableList
              fill={table ? `${electricPurple000}` : `${electricPurple600}`}
            />
          </div>
        </Button>

        <Button style={cardButtonStyles} onClick={setViewToCardComments}>
          <IconCards
            fill={cards ? `${electricPurple000}` : `${electricPurple600}`}
          />
        </Button>
      </>
    </div>
  );
};
