// hooks/useUser.ts
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@store";
import {
  setUserProfile,
  clearUserProfile,
  UserProfileState,
} from "./userSlice";
import { useNavigate } from "react-router-dom";
import { pathLogin } from "@routes/routes.types";

export const useUserProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.userReducer);
  const country = user?.country;
  const countries = user?.countries || [];
  const name = user?.name || "";
  const imageUrl = user?.imageUrl || "";
  const email = user?.email || "";

  const updateUserProfile = (userData: UserProfileState) => {
    dispatch(setUserProfile(userData));
  };

  const clearUser = () => {
    dispatch(clearUserProfile());
    navigate(pathLogin);
  };

  return {
    clearUser,
    countries,
    country,
    email,
    imageUrl,
    name,
    updateUserProfile,
    user,
  };
};
