import { electricPurple300, white } from "@habitech/shared";
import styled from "styled-components";

interface PropsTab {
  active?: boolean;
  icon?: React.ReactNode;
  onClick: () => void;
  title: string | React.ReactNode;
}

export const WrapperTab = styled.div`
  border-radius: 8px 8px 0 0;
  &.active {
    background-color: ${white};
    border-bottom: 4px solid ${electricPurple300};
  }
`;

export const Tab = ({ active, icon, onClick, title }: PropsTab) => {
  return (
    <WrapperTab
      className={`pointer px-3 py-2 d-flex align-items-center gap-1  ${
        active && "active fw-bold"
      }`}
      onClick={onClick}
    >
      {icon}
      <div>{title}</div>
    </WrapperTab>
  );
};
