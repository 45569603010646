// libraries.
import { Button } from "react-bootstrap";
import { useModuleHomeBulkUpload } from "../bulkUpload-slice/bulkUpload.hook";

export const DeleteFile = () => {
  const { dataError, clearState, nidsNotUpdated } = useModuleHomeBulkUpload();

  if (!dataError.length && nidsNotUpdated.length === 0) {
    return null;
  }

  return (
    <div className="d-flex justify-content-end">
      <Button
        className="fw-bold rounded-2 py-2 px-4 btn-secundary-habi"
        onClick={clearState}
      >
        <span>Eliminar</span>
      </Button>
    </div>
  );
};
