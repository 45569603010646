// libraries.
import { useFormContext } from "react-hook-form";

// hooks.
import { useGetLeadDetailInfo } from "@store/apiSlices/leadDetail/leadDetail.hooks";
import { useGetPublicationPriceInfo } from "@store/apiSlices/pricing/pricing.hooks";

// utils.
import { formatPrice } from "@utils/formatPrice.utils";

// styles.
import { WrapperSummary } from "./styles";
import { useEffect, useState } from "react";
import { useDebounce } from "@uidotdev/usehooks";
import { useUserProfile } from "@shared/GoogleLoginButton/user-slice/useUserProfile.hook";
import {
  PriceSummaryActions,
  usePriceSummaryContext,
} from "../../../priceSummary-reducer/priceSummary.context";
import { SavePricingButtonCO } from "./components/SavePricingButtonCO";
import { useGetHistoryLeadInfo } from "@store/apiSlices/historyLeadInfo/historyLeadInfo.hooks";
import { Form } from "react-bootstrap";
import { useLazyGetPercentileCalculatorQuery } from "@store/apiSlices/percentileCalculator/percentileCalculator.endpoints";
import {
  calculatePercentageInPrice,
  percentageToApplyInRelatedPricesCO,
} from "@shared/DashboardComponentsSummary/components/utils";
import { ValidateTopeCO } from "../components/ValidateTopeCO";

export const PriceSummaryCO = () => {
  const { user } = useUserProfile();
  const [, dispatch] = usePriceSummaryContext();

  const [applyPercentageToPrices, setApplyPercentageToPrices] = useState(true);

  const {
    getLeadDetailInfoQuery: {
      data: leadDetail,
      isLoading: isLoadingLeadDetail,
    },
  } = useGetLeadDetailInfo();

  const {
    getPublicationPriceInfoQuery: { data: publicationPriceInfo },
  } = useGetPublicationPriceInfo();

  const [
    mutatePercentileCalculator,
    {
      data: percentileCalculatorValuesCO,
      isLoading: isLoadingPercentileCalculator,
    },
  ] = useLazyGetPercentileCalculatorQuery();

  const {
    getHistoryLeadInfoQuery: { data: historyLeadInfo },
  } = useGetHistoryLeadInfo();
  const comparablesInfoUsed = historyLeadInfo?.used_comparables.map(
    ({ price_per_m2, last_ask_price }) => ({
      price_m2: price_per_m2,
      sale_value: last_ask_price,
    }),
  );

  const { register, watch, setValue } = useFormContext();

  const pricePublicationInput = watch("publicationPrice");
  const minPublicationPriceInput = watch("minPublicationPrice");

  const deferredPricePublicationValue = useDebounce(
    pricePublicationInput,
    1000,
  );
  const deferredMinPublicationPriceValue = useDebounce(
    minPublicationPriceInput,
    1000,
  );

  const leadArea = leadDetail?.property?.area ?? 0;

  const publicationPriceM2 =
    Number(pricePublicationInput ?? publicationPriceInfo?.publicationPrice) /
    leadArea;

  const minPublicationPriceM2 =
    Number(
      minPublicationPriceInput ?? publicationPriceInfo?.minPublicationPrice,
    ) / leadArea;

  const comparablesToJsonString = JSON.stringify(comparablesInfoUsed);
  const comparablesToEncodedString = encodeURIComponent(
    comparablesToJsonString,
  );

  const shouldFetchPercentileCalculator =
    user.country != null &&
    comparablesToEncodedString != null &&
    publicationPriceInfo?.minPublicationPrice != null &&
    publicationPriceInfo?.publicationPrice != null &&
    minPublicationPriceM2 != null &&
    publicationPriceM2 != null;

  useEffect(() => {
    setValue(
      "minPublicationPrice",
      publicationPriceInfo?.minPublicationPrice || 0,
    );
    setValue("publicationPrice", publicationPriceInfo?.publicationPrice || 0);
  }, [publicationPriceInfo]);

  useEffect(() => {
    if (shouldFetchPercentileCalculator) {
      mutatePercentileCalculator({
        comparables: comparablesToEncodedString,
        country: user.country,
        minPublicationPriceAbs: deferredMinPublicationPriceValue,
        minPublicationPriceM2: minPublicationPriceM2,
        publicationPriceAbs: deferredPricePublicationValue,
        publicationPriceM2: publicationPriceM2,
      });
    }
  }, [
    shouldFetchPercentileCalculator,
    deferredPricePublicationValue,
    deferredMinPublicationPriceValue,
  ]);

  useEffect(() => {
    if (percentileCalculatorValuesCO) {
      dispatch(
        PriceSummaryActions.setPercentileCalculatorValues({
          priceABSMin:
            percentileCalculatorValuesCO.minPublicationPrice?.abs || 0,
          priceABSPublication:
            percentileCalculatorValuesCO.publicationPrice?.abs || 0,
          priceM2Publication:
            percentileCalculatorValuesCO.publicationPrice?.m2 || 0,
          priceMinM2: percentileCalculatorValuesCO.minPublicationPrice?.m2 || 0,
        }),
      );
    }
  }, [percentileCalculatorValuesCO]);

  useEffect(() => {
    setValue("isLoading", isLoadingLeadDetail || isLoadingPercentileCalculator);
  }, [isLoadingLeadDetail, isLoadingPercentileCalculator]);

  return (
    <div className="h-100 d-flex flex-column justify-content-between ">
      <WrapperSummary>
        <div className="d-flex gap-4 flex-column ">
          <div>
            <div className="title">Resumen del ejercicio</div>
            <p>Rectifica los valores.</p>
          </div>
          <div className="line" />
          <div className="d-flex gap-1 flex-column ">
            <div className="d-flex align-items-center justify-content-between ">
              <div>Precio publicado.</div>
              <input
                data-testid="publicationPriceInput"
                {...register("publicationPrice", {
                  onChange: (e) => {
                    if (applyPercentageToPrices) {
                      const priceMin = calculatePercentageInPrice(
                        e.target.value,
                        false,
                        percentageToApplyInRelatedPricesCO,
                      );

                      setValue("minPublicationPrice", priceMin);
                    }
                  },
                })}
                type="text"
                className="rounded-3 p-2"
              />
            </div>
            <div className="d-flex justify-content-end w-100">
              <small className="text-white">
                Valor por m²: {formatPrice(publicationPriceM2) || 0}
              </small>
            </div>
          </div>
          <div className="d-flex flex-column gap-1">
            <div className="d-flex justify-content-between align-items-center">
              <div>Precio mínimo publicado</div>
              <input
                data-testid="minPublicationPriceInput"
                {...register("minPublicationPrice", {
                  onChange: (e) => {
                    if (applyPercentageToPrices) {
                      const price = calculatePercentageInPrice(
                        e.target.value,
                        true,
                        percentageToApplyInRelatedPricesCO,
                      );

                      setValue("publicationPrice", price);
                    }
                  },
                })}
                type="text"
                className="rounded-3 p-2"
              />
            </div>
            <div className="d-flex justify-content-end w-100">
              <small className="text-white">
                Valor por m²: {formatPrice(minPublicationPriceM2) || 0}
              </small>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <Form.Check
            type="checkbox"
            label="Calculo automático al 5%"
            className="py-2"
            defaultChecked={applyPercentageToPrices}
            onChange={() =>
              setApplyPercentageToPrices(!applyPercentageToPrices)
            }
            data-testid="applyPercentageToPrices"
          />
        </div>
        <ValidateTopeCO />
      </WrapperSummary>
      <div className="d-flex flex-column gap-4">
        <div className="line" />
        <textarea
          className="w-100 rounded-3 p-2"
          placeholder="Agregar comentario"
          style={{ height: "150px" }}
        />
        <SavePricingButtonCO />
      </div>
    </div>
  );
};
