// libraries.
import { useFormContext } from "react-hook-form";

// hooks.
import { useGetInventoryPropertyInfo } from "@store/apiSlices/inventoryPropertyInfo/inventoryPropertyInfo.hook";
import { useGetLeadDetailInfo } from "@store/apiSlices/leadDetail/leadDetail.hooks";

// utils.
import { formatPrice } from "@utils/formatPrice.utils";
import { useEffect } from "react";

export const MinPublicationPriceMX = () => {
  const { register, watch, setValue } = useFormContext();
  const minPublicationPriceInput = watch("minPublicationPrice");

  const { area } = useGetLeadDetailInfo();

  const { minPublicationPrice: defaultMinPublicationPrice } =
    useGetInventoryPropertyInfo();

  const minPublicationPriceM2 =
    Number(minPublicationPriceInput ?? defaultMinPublicationPrice) / area;

  useEffect(() => {
    setValue("minPublicationPrice", defaultMinPublicationPrice);
  }, [defaultMinPublicationPrice]);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <div>Precio mínimo publicado</div>
        <input
          data-testid="minPublicationPriceInputMX"
          {...register("minPublicationPrice")}
          // se comentan estas líneas debido a adaptación del check acprdado con Juan David
          //, {
          // onChange: (e) => {
          //   if (applyPercentageToPrices) {
          //     const price = calculatePercentageInPrice(
          //       e.target.value,
          //       true,
          //       3,
          //     );
          // setValue("publicationPrice", price);
          // }
          // },
          //  })}
          type="text"
          className="rounded-3 p-2"
        />
      </div>
      <div className="d-flex justify-content-end w-100">
        <small className="text-white">
          Valor por m²: {formatPrice(minPublicationPriceM2) || 0}
        </small>
      </div>
    </>
  );
};
