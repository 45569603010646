import {
  electricPurple600,
  monserratRegular,
  robotoRegular,
} from "@habitech/shared";
import styled from "styled-components";

export const WrapperNidModal = styled.div`
  .modal-title {
    font-family: ${monserratRegular};
    font-size: 24px;
    font-weight: 800;
  }
  .wrapper-nid {
    display: flex;
    align-items: center;
    gap: 8px;
    .nid-title {
      font-size: 16px !important;
      font-weight: 800;
      font-family: ${robotoRegular};
      color: ${electricPurple600} !important;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .modal-body {
    border-top: 1px solid var(--habi-background-anti-flash-white, #f3f3f3);
  }
  .modal-header {
    border-bottom: none;
  }
  .modal-footer {
    border-top: none;
  }
  .btn-primary {
    background-color: #7c01ff;
    border: none;
    border-radius: 100px;
    color: #ffffff;
    padding: 8px 24px;
    font-size: 14px;
  }
  .badge {
    font-size: 12px;
  }
  .date-create {
    font-family: ${robotoRegular};
    font-size: 14px;
    color: var(--font-habi-pcom-secundary-aa, #6d6970);
    font-weight: 400;
  }
`;
