// libraries.
import { useState } from "react";

// components.
import { Tab } from "@shared/Tabs";
import { ArchivedCommentsTable, CommentsTable } from "./TableToRender";
import { TitleArchivedComments, TitleComments } from "./TitleCommentsTabs";

// assets.
import { IconFeedchat, IconHide } from "@assets";

// styles.
import { electricPurple600, scarpaGray500 } from "@habitech/shared";

enum TabsFeedbackLoop {
  Comments = "comments",
  ArchivedComments = "archived-comments",
}

export const FeedbackTables = () => {
  const tabsComments = [
    {
      Component: CommentsTable,
      Icon: IconFeedchat,
      Title: TitleComments,
      tab: TabsFeedbackLoop.Comments,
    },
    {
      Component: ArchivedCommentsTable,
      Icon: IconHide,
      Title: TitleArchivedComments,
      tab: TabsFeedbackLoop.ArchivedComments,
    },
  ];

  const initialTabComments = tabsComments[0].tab;

  const [tableComments, setTableComments] =
    useState<TabsFeedbackLoop>(initialTabComments);

  return (
    <>
      <div className="d-flex">
        {tabsComments.map(({ tab, Icon, Title }) => (
          <Tab
            key={tab}
            active={tableComments === tab}
            icon={
              <Icon
                fill={tableComments === tab ? electricPurple600 : scarpaGray500}
              />
            }
            onClick={() => setTableComments(tab)}
            title={<Title active={tableComments === tab} />}
            aria-label={`Tab para ${tab}`}
          />
        ))}
      </div>

      {tabsComments.map(({ tab, Component }) => (
        <div style={{ marginRight: "5px" }} key={tab}>
          {tableComments === tab && <Component />}
        </div>
      ))}
    </>
  );
};
