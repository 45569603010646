// libraries.
import {
  electricPurple500,
  monserratSemiBold,
  monserratRegular,
} from "@habitech/shared";
import styled from "styled-components";

export const WrapperHasOtherLeadInProgress = styled.div`
  font-size: 12px;
  font-family: ${monserratRegular};
  .other-nid {
    color: ${electricPurple500};
  }
`;

export const WrapperProgress = styled.div`
  .progress-nid {
    font-size: 13px;
    font-family: ${monserratSemiBold};
    font-weight: 800;
  }
`;
