import {
  robotoRegular,
  scarpaGray50,
  scarpaGray500,
  scarpaGray800,
} from "@habitech/shared";
import styled from "styled-components";

export const WrapperFilterText = styled.div`
  display: flex;
  justify-content: space-between;
  width: -webkit-fill-available;
  p {
    font-family: ${robotoRegular};
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: ${scarpaGray800};
  }
  span {
    font-family: ${robotoRegular};
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 18px;
    color: ${scarpaGray500};
  }
`;

export const WrapperUsedFilters = styled.div`
  background-color: ${scarpaGray50};
  border: 1px solid rgb(211, 210, 212);
  width: inherit;
  height: 48px;
  cursor: not-allowed;
  padding: 12px;
  border-radius: 6px;
  .placeholder-filter {
    color: ${scarpaGray500};
    font-family: ${robotoRegular};
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
  }
`;
