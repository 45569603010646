// libraries.
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

// slices.
import { apiPortalPricing } from "../apiSlices";

// interfaces.
import { ModelError } from "../interfaces/interfaces";

export interface LeadInProgressModel {
  agent: string;
  country: string;
  id: string;
  price_type: string;
  status: string;
}

interface LeadInProgressResponse {
  message: string;
  nid_progress: number;
  status: string;
  success: boolean;
}

export const leadInProgress = apiPortalPricing.injectEndpoints({
  endpoints: (builder) => ({
    leadInProgress: builder.mutation({
      query: (newData: LeadInProgressModel) => ({
        body: newData,
        method: "POST",
        url: "/post_status_pricing",
      }),
      transformErrorResponse: (error: FetchBaseQueryError): ModelError => {
        const modelError: ModelError = {
          errorStatus: error.status,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          messageFromServer: (error.data as any)?.message,
          serviceErrorName: "post_status_pricing in progress",
        };
        return modelError;
      },
      transformResponse: (
        response: LeadInProgressResponse,
      ): LeadInProgressResponse => {
        return response;
      },
    }),
  }),
});

export const { useLeadInProgressMutation } = leadInProgress;
