// libraries.
import { Outlet } from "react-router-dom";

// components
import { SidebarHome } from "./components/SidebarHome/SidebarHome";
import { HomeProvider } from "./context/home.context";
import { LeadDetailCard as ModalLeadDetailCard } from "./components/NavBarUser/ViewLeadDetail/LeadDetailCard";
import { useRoleByUser } from "@store/apiSlices/roles/role.hook";
import { useClearState } from "./reduxClearState";

export const HomePage = () => {
  useRoleByUser();
  useClearState();
  return (
    <HomeProvider>
      <div className="d-flex ">
        <div>
          <SidebarHome />
        </div>
        <div className="d-flex flex-column w-100">
          <Outlet />
        </div>
      </div>
      <ModalLeadDetailCard />
    </HomeProvider>
  );
};

export { useNid } from "./components/NavBarUser/SearchByNid/nid-slice/useNid.hook";
