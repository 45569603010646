import { electricPurple500 } from "@habitech/shared";
import { PolygonF } from "@react-google-maps/api";
import { useGetHistoryLeadInfo } from "@store/apiSlices/historyLeadInfo/historyLeadInfo.hooks";

export const LeadPolygon = () => {
  const { getHistoryLeadInfoQuery } = useGetHistoryLeadInfo();

  const polygon = getHistoryLeadInfoQuery.data?.polygon;

  const options = {
    fillColor: electricPurple500,
    fillOpacity: 0.1,
    strokeColor: electricPurple500,
    strokeOpacity: 0.8,
    strokeWeight: 2,
  };

  return <PolygonF path={polygon} options={options} />;
};
