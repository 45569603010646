// libraries.
import { Modal } from "react-bootstrap";
import { useFormContext } from "react-hook-form";

// hooks.
import { modalOptions, useModalOptions } from "@store/slices/modals";
import { useGetLeadDetailInfo } from "@store/apiSlices/leadDetail/leadDetail.hooks";

// components.
import GoogleMap from "@shared/GoogleMap/GoogleMap";
import { MarkerZone } from "./components/MarkerZone";
import { ActionsModalZone } from "./components/ActionsModalZone/ActionsModalZone";
import { Hexagons } from "./components/Hexagons";

// styles.
import { WrapperZoneModal } from "./styles";
import { useEffect, useState } from "react";

export const ModalZone = () => {
  const { watch } = useFormContext();
  const commentId = watch("commentId");
  const hasCommentId =
    commentId !== undefined && commentId !== null && commentId !== "";

  const { leadLatLng } = useGetLeadDetailInfo();

  const [mapCenter, setMapCenter] = useState(leadLatLng);

  const { isModalOpen, closeModal } = useModalOptions();

  const show = isModalOpen(modalOptions.modalInventoryPageMapZone);

  const handleClose = () => {
    closeModal(modalOptions.modalInventoryPageMapZone);
  };

  useEffect(() => {
    if (!mapCenter) {
      setMapCenter(leadLatLng);
    }
  }, [leadLatLng, mapCenter]);

  return (
    <Modal show={show} size="xl" onHide={handleClose}>
      <WrapperZoneModal>
        <Modal.Header closeButton>
          <div className="d-flex flex-column">
            <Modal.Title>
              {hasCommentId ? "Editar Zona" : "Crear Zona"}
            </Modal.Title>
            <Modal.Title className="description">
              Selecciona los hexágonos de la zona que deseas
            </Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body className="p-0" style={{ height: "500px" }}>
          <GoogleMap
            defaultCenter={mapCenter}
            containerStyle={{
              height: "100%",
              width: "100%",
            }}
            defaultZoom={17}
          >
            <Hexagons />
            <MarkerZone />
          </GoogleMap>
        </Modal.Body>
        <ActionsModalZone />
      </WrapperZoneModal>
    </Modal>
  );
};
