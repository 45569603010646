import { tomatoCoral500, turquoise700 } from "@habitech/shared";
import { CommentStatus } from "@store/apiSlices/feedbackLoop/comments/comments.service";

const calculateValuesForStatus = (status?: string) => {
  if (status === CommentStatus.ACTIVE) {
    return { color: turquoise700, text: "Activo" };
  }
  return { color: tomatoCoral500, text: "Archivado" };
};

export const BadgeCommentStatus = ({ status }: { status?: string }) => {
  const statusValues = calculateValuesForStatus(status);
  return (
    <small
      className="rounded fw-bold py-1 px-2 text-white simple-text"
      style={{ background: statusValues.color }}
    >
      {statusValues.text}
    </small>
  );
};
