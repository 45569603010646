// shared.
import { InfoTagHover } from "@shared";

// assets
import { IconWarning } from "@assets";

interface CardTooltipProps {
  message: string;
  warningFlag?: boolean;
}

export const CardTooltipMessage = ({
  warningFlag,
  message,
}: CardTooltipProps) => {
  return (
    warningFlag && (
      <InfoTagHover
        style={{ left: "-45px", top: "26px" }}
        icon={<IconWarning />}
      >
        <div className="flag-message">{message}</div>
      </InfoTagHover>
    )
  );
};
