// slices.
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { apiInventoryPortal } from "../apiSlices";
import { ModelError } from "../interfaces/interfaces";

interface InventoryPropertyInfo {
  alert?: string;
  click_num?: number;
  commercial_days?: number;
  commission?: number;
  day_withdrawal?: number;
  days_from_alert?: number;
  download_num?: number;
  financial_days?: number;
  gross_margin?: number;
  initial_utility?: number;
  last_commercial_cycle?: number;
  min_publication_price?: number;
  offer_num?: number;
  percentil: number;
  pre_sale: number;
  publication_price?: number;
  total_budget?: number;
  utility?: number;
  visit_num?: number;
}

interface InventoryInfoResponse {
  body: InventoryPropertyInfo;
  code: number;
  success: boolean;
}

interface InventoryInfoParams {
  country?: string;
  nid?: string;
}

export const inventoryPropertyInfo = apiInventoryPortal.injectEndpoints({
  endpoints: (builder) => ({
    getInventoryPropertyInfo: builder.query({
      query: ({ country, nid }: InventoryInfoParams) => {
        return `/property_diagnosis?country=${country}&nid=${nid}`;
      },
      transformErrorResponse: (error: FetchBaseQueryError) => {
        const modelError: ModelError = {
          errorStatus: error.status,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          messageFromServer: (error.data as any)?.message,
          serviceErrorName: "getInventoryPropertyInfo",
        };
        return modelError;
      },
      transformResponse: (response: InventoryInfoResponse) => {
        return response.body;
      },
    }),
  }),
});

export const { useGetInventoryPropertyInfoQuery } = inventoryPropertyInfo;
