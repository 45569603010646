// hooks.
import { useComments } from "@store/apiSlices/feedbackLoop/comments/comments.hook";
import { usePageModuleFeedbackLook } from "../../slice/pageModuleFeedbackLook.hook";

// slice.
import { ViewCommentsType } from "../../slice/pageModuleFeedbackLook.slice";

// components.
import { FeedbackTables } from "./components/TableStatus/components/FeedbackTables";
import { TableStatus } from "./components/TableStatus/TableStatus";
import { CardsComments } from "./components/CardsComments";

export const TableFeedbackLook = () => {
  const { comments } = useComments();
  const { viewCommentsType } = usePageModuleFeedbackLook();

  if (comments.length === 0) return <TableStatus />;

  if (viewCommentsType === ViewCommentsType.CARD_COMMENTS) {
    return <CardsComments commentsNoArchived={comments} />;
  }

  return (
    <div
      data-testid="TableFeedbackLook"
      style={{
        height: "calc(100vh - 195px)",
        overflowY: "auto",
      }}
    >
      <FeedbackTables />
    </div>
  );
};
