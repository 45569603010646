// components
import { InventoryPercentileCalculator } from "../../shared/InventoryPercentileCalculator";
import { DiagnosisCards } from "./components/DiagnosisCards";
import { PropertyStatistics } from "./components/PropertyStatistics";
import { PropertyUnitCO } from "./components/PropertyUnit/PropertyUnitCO";

export const DiagnosisCardsCO = () => {
  return (
    <div>
      <div
        className="d-flex flex-column gap-4"
        id="diagnosis-cards"
        data-testid="diagnosis-cardsCO"
      >
        <DiagnosisCards />
        <PropertyUnitCO />
        <PropertyStatistics />
        <InventoryPercentileCalculator />
      </div>
    </div>
  );
};
