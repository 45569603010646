// libraries.
import { useState } from "react";
import { electricPurple600, scarpaGray500 } from "@habitech/shared";

// shared.
import { Tab } from "@shared/Tabs";
import { IconGrayWork, IconRemoActions, IconRemoBrokers } from "@assets";

// components
import { TableRemo } from "./TableRemo/TableRemo";
import { TableActionsRemo } from "./TableActionsRemo/TableActionsRemo";
import { TableBrokers } from "./TableBrokers/TableBrokers";
import {
  TitleFeedbackActions,
  TitleFeedbackBrokers,
  TitleFeedbackRemo,
} from "./TitleTabsRemo";

// hooks.
import { useUserProfile } from "@shared/GoogleLoginButton/user-slice/useUserProfile.hook";
import { useSearchParams } from "react-router-dom";

enum TabRemo {
  Feedback = "remo-feedback",
  Actions = "remo-action",
  Brokers = "remo-brokers",
}

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer: any[];
  }
}

export const RemoTabs = () => {
  const { country } = useUserProfile();

  const location = useSearchParams();
  const [searchParams] = location;
  const tabByParam = Object.fromEntries(searchParams).tab;

  const getTabByParam = (tab: string | undefined): TabRemo => {
    switch (tab) {
      case "remo":
        return TabRemo.Feedback;
      case "price":
      case "comission":
        return TabRemo.Actions;
      case "broker":
        return TabRemo.Brokers;
      default:
        return TabRemo.Actions;
    }
  };

  const showDefaultTab = getTabByParam(tabByParam);

  const tabsCO = [
    {
      Component: TableActionsRemo,
      Icon: IconRemoActions,
      Title: TitleFeedbackActions,
      id: "co-tab-actions",
      tab: TabRemo.Actions,
    },
    {
      Component: TableBrokers,
      Icon: IconRemoBrokers,
      Title: TitleFeedbackBrokers,
      id: "co-tab-brokers",
      tab: TabRemo.Brokers,
    },
    {
      Component: TableRemo,
      Icon: IconGrayWork,
      Title: TitleFeedbackRemo,
      id: "co-tab-remo",
      tab: TabRemo.Feedback,
    },
  ];

  const tabsMX = [
    {
      Component: TableActionsRemo,
      Icon: IconRemoActions,
      Title: TitleFeedbackActions,
      id: "mx-tab-actions",
      tab: TabRemo.Actions,
    },
    {
      Component: TableBrokers,
      Icon: IconRemoBrokers,
      Title: TitleFeedbackBrokers,
      id: "mx-tab-brokers",
      tab: TabRemo.Brokers,
    },
  ];

  const tabsToRender = country === "MX" ? tabsMX : tabsCO;

  const [tabRemo, setTabRemo] = useState<TabRemo>(showDefaultTab);

  const setTabAndSendEvent = (newTab: TabRemo, id: string) => {
    setTabRemo(newTab);

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "tab_change",
      tab: id,
    });
  };

  return (
    <>
      <div className="d-flex">
        {tabsToRender.map(({ id, tab, Icon, Title }) => (
          <Tab
            key={tab}
            active={tabRemo === tab}
            icon={
              <Icon
                fill={tabRemo === tab ? electricPurple600 : scarpaGray500}
              />
            }
            onClick={() => setTabAndSendEvent(tab, id)}
            title={<Title active={tabRemo === tab} />}
            aria-label={`Tab para ${tab}`}
          />
        ))}
      </div>

      {tabsToRender.map(({ tab, Component }) => (
        <div key={tab}>{tabRemo === tab && <Component />}</div>
      ))}
    </>
  );
};
