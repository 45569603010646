import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { apiInventoryPortal } from "../apiSlices";
import { ModelError } from "../interfaces/interfaces";
import { FeedbackBrokersTransformed } from "./utils";

interface FeedbackBrokersParams {
  country?: string;
  nid: number;
}

interface FeedbackBrokers {
  broker_ticket: {
    date_create: string;
    broker: {
      name: string;
      phone: string;
      email: string;
    };
    category: string;
    comment: string;
  }[];
  category_metrics: {
    acceso_al_conjunto: string;
    acceso_al_inmueble: string;
    estado_del_inmueble: string;
    informacion_del_inmueble: string;
  };
}
interface FeedbackBrokersResponse {
  body: FeedbackBrokers;
  code: number;
  success: boolean;
}

export const feedbackBrokers = apiInventoryPortal.injectEndpoints({
  endpoints: (builder) => ({
    getFeedbackBrokers: builder.query({
      query: ({ country, nid }: FeedbackBrokersParams) => {
        return `/get_feedback_brokers?country=${country}&nid=${nid}`;
      },
      transformErrorResponse: (error: FetchBaseQueryError) => {
        const modelError: ModelError = {
          errorStatus: error.status,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          messageFromServer: (error.data as any)?.message,
          serviceErrorName: "getFeedbackBrokers",
        };
        return modelError;
      },
      transformResponse: (response: FeedbackBrokersResponse) => {
        const brokersData = response.body;
        const feedbackBrokers: FeedbackBrokersTransformed = {
          brokerTicket: brokersData.broker_ticket.map((ticket) => ({
            broker: {
              brokerEmail: ticket.broker.email,
              brokerName: ticket.broker.name,
              brokerPhone: ticket.broker.phone,
            },
            category: ticket.category,
            comment: ticket.comment,
            dateCreate: ticket.date_create,
          })),
          categoryMetrics: {
            complexAccess: brokersData.category_metrics.acceso_al_conjunto,
            propertyAccess: brokersData.category_metrics.acceso_al_inmueble,
            propertyInfo: brokersData.category_metrics.informacion_del_inmueble,
            propertyStatus: brokersData.category_metrics.estado_del_inmueble,
          },
        };
        return feedbackBrokers;
      },
    }),
  }),
});

export const { useGetFeedbackBrokersQuery } = feedbackBrokers;
