// libraries.
import { Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";

// hooks.
import { useInventoryPricingMutation } from "@store/apiSlices/inventoryPricing/inventoryPricing.endpoints";
import { useUserProfile } from "@shared/GoogleLoginButton/user-slice/useUserProfile.hook";
import { useNid } from "@pages/HomePage";

// components.
import { PriceInventorySummaryCO } from "./PriceInventorySummaryCO/PriceInventorySummaryCO";
import { DiagnosisCardsCO } from "./CardsSummaryInfoCO/DiagnosisCardsCO";
import { pathHome } from "@routes/routes.types";
import { pathHomeInventoryList } from "@pages/HomePage/routes/routes.types";
import { inventoryPriceType } from "../type";

interface InventorySummaryForm {
  applyPercentageToPrices: boolean;
  commentInput: string;
  commissionPercentage: number;
  isLoading: boolean;
  leader: string;
  minPublicationPrice: number;
  publicationPrice: number;
  utilityCurrent: number | null;
  utilityInitial: number | null;
}

export const InventorySummaryCO = () => {
  const navigate = useNavigate();
  const paths = useParams();
  const { user } = useUserProfile();
  const { nid } = useNid();

  const nidSelected = paths.nid ? paths.nid : nid;

  const [handlePostInventoryPricing, { isSuccess, isError }] =
    useInventoryPricingMutation();

  const methods = useForm<InventorySummaryForm>({
    defaultValues: {
      applyPercentageToPrices: true,
      commentInput: "",
      commissionPercentage: 0,
      isLoading: false,
      leader: "",
      minPublicationPrice: 0,
      publicationPrice: 0,
      utilityCurrent: null,
      utilityInitial: null,
    },
    mode: "all",
  });

  useEffect(() => {
    if (isSuccess) {
      navigate(`${pathHome}/${pathHomeInventoryList}`);
      toast.success("Precios guardados correctamente");
    }
    if (isError) {
      toast.error(
        <small>
          <strong>No se pudo actualizar el precio.</strong>{" "}
          <small>
            Verifica el estado del inmueble o comunícate con soporte.
          </small>
        </small>,
      );
    }
  }, [isSuccess]);

  const onSubmit = (data: InventorySummaryForm) => {
    handlePostInventoryPricing({
      agent: user.email,
      comment: data.commentInput,
      commission: data.commissionPercentage.toString(),
      country: user.country,
      current_utility: data.utilityCurrent || 0,
      initial_utility: data.utilityInitial || 0,
      leader_approval: data.leader,
      min_publication_price: Number(data.minPublicationPrice) || 0,
      nid: Number(nidSelected),
      price_type: inventoryPriceType,
      publication_price: Number(data.publicationPrice) || 0,
      status: "completed",
    });
  };

  return (
    <>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          data-testid="inventory-form"
          className="h-100 d-flex p-0"
        >
          <Col
            md={9}
            className="p-4 price-info  d-flex flex-column gap-4 overflow-y-auto"
            data-testid="inventory-info"
            style={{ height: "calc(100vh - 50px)" }}
          >
            <DiagnosisCardsCO />
          </Col>
          <Col
            md={3}
            className="p-4 price-summary text-white overflow-y-auto"
            data-testid="inventory-summary"
            style={{ height: "calc(100vh - 50px)" }}
          >
            <PriceInventorySummaryCO />
          </Col>
        </form>
      </FormProvider>
    </>
  );
};
