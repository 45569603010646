// Libraries.
import {
  robotoRegular,
  scarpaGray50,
  scarpaGray500,
  scarpaGray800,
} from "@habitech/shared";
import styled from "styled-components";

export const WrapperScroll = styled.div`
  width: calc(100vw - 74px);
  overflow: hidden;

  font-family: ${robotoRegular};
  font-size: 12px;
  line-height: 20px;
  color: ${scarpaGray800};
  height: 50px;
  box-shadow:
    0px 0px 1px rgba(48, 49, 51, 0.05),
    0px 1px 3px rgba(48, 49, 51, 0.1);
  .title {
    color: ${scarpaGray500};
    text-align: center;
  }
`;

export const WrapperButtonScroll = styled.div`
  background: ${scarpaGray50};
  position: absolute;
  height: 50px;
  right: 0;
  top: 0;
`;
