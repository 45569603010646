// hooks.
import { useGetPublicationPriceInfo } from "@store/apiSlices/pricing/pricing.hooks";

// utils.
import { formatPrice } from "@utils/formatPrice.utils";

interface IncreaseValue {
  minPubIncrease?: boolean;
  publicationIncrease?: boolean;
}

export const IncreaseDiscountPrice = ({
  publicationIncrease,
  minPubIncrease,
}: IncreaseValue) => {
  const {
    getPublicationPriceInfoQuery: { data },
  } = useGetPublicationPriceInfo();

  if (!data) {
    return null;
  }

  const publicationPrice = data.publicationPrice;
  const minPublicationPrice = data.minPublicationPrice;

  const alertPrice = 1500000;

  let discountPrice;

  const increasePublicationPrice = publicationPrice * (1 + 2 / 100);
  const increaseMinPublicationPrice = minPublicationPrice * (1 + 2 / 100);

  if (publicationIncrease && publicationPrice < alertPrice) {
    discountPrice = `2% extra: ${formatPrice(increasePublicationPrice) || 0}`;
  }

  if (minPubIncrease && publicationPrice < alertPrice) {
    discountPrice = `2% extra: ${
      formatPrice(increaseMinPublicationPrice) || 0
    }`;
  }

  return <small className="text-white">{discountPrice}</small>;
};
