// libraries.
import { scarpaGray500 } from "@habitech/shared";

// assets.
import { IconI, IconSuccess } from "@assets";

type Notification = {
  ticketType: string;
  comment: string;
};

interface NotificationIconProps {
  notification: Notification;
}

export const NotificationIcon = ({ notification }: NotificationIconProps) => {
  const renderIcon = () => {
    if (notification.ticketType === "price") {
      return <IconSuccess />;
    } else {
      return <IconI width="20" height="20" fill={scarpaGray500} />;
    }
  };

  return <div className="d-flex align-items-center">{renderIcon()}</div>;
};
