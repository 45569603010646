// components.
import { ComparablesTabs } from "../ComparablesTab/ComparablesTab";
import { UsedComparablesCard } from "./UsedComparablesCard";

// hooks.
import { useUserProfile } from "@shared/GoogleLoginButton/user-slice/useUserProfile.hook";
import { useGetHistoryLeadInfo } from "@store/apiSlices/historyLeadInfo/historyLeadInfo.hooks";

// styles.
import { WrapperComparablesCard } from "./styles";
import { formatPrice } from "@utils/formatPrice.utils";

export const UsedComparablesList = () => {
  const {
    getHistoryLeadInfoQuery: { data },
  } = useGetHistoryLeadInfo();
  const { user } = useUserProfile();

  const renderList = data?.used_comparables;
  if (renderList?.length === 0) return null;

  return (
    <div className="d-flex flex-column bg-light">
      <ComparablesTabs />
      <WrapperComparablesCard>
        {renderList?.map((comparable, key) => {
          return (
            <UsedComparablesCard
              key={key}
              address={comparable?.address}
              color={comparable?.source_id}
              country={user?.country}
              elevator={comparable?.elevator}
              features={comparable?.features}
              nid={comparable?.nid}
              price={
                comparable?.last_ask_price &&
                formatPrice(comparable?.last_ask_price)
              }
              pricem2={
                comparable?.price_per_m2 &&
                formatPrice(comparable?.price_per_m2)
              }
              source={comparable?.source_id}
            />
          );
        })}
      </WrapperComparablesCard>
    </div>
  );
};
