// hooks.
import { Tab } from "@shared/Tabs";
import { useModuleHomeBulkUpload } from "../bulkUpload-slice/bulkUpload.hook";
import { useEffect, useState } from "react";
import { WrapperTable } from "@shared/Tables";
import { Table } from "react-bootstrap";
import { IconCheck, IconI } from "@assets";
import {
  electricPurple600,
  robotoRegular,
  scarpaGray50,
  scarpaGray500,
} from "@habitech/shared";
import { FileTypeData } from "../bulkUpload-slice/bulkUpload.slice";
import { WrapperDataTabs } from "../styles";

enum TabType {
  Errors = "Errores",
  Success = "Exitosos",
}

export const getColorIcon = (isActive: boolean) =>
  isActive ? electricPurple600 : scarpaGray500;

const HeadersTable = () => {
  return (
    <thead>
      <tr>
        <th></th>
        <th>NID</th>
        <th>Precio de publicación</th>
        <th>Precio mínimo de publicación</th>
        <th>Utilidad esperada</th>
        <th>Comentario</th>
      </tr>
    </thead>
  );
};

const TableToShowFileData = ({
  data,
  dataTestId,
}: {
  data: FileTypeData[];
  dataTestId: string;
}) => {
  return (
    <WrapperTable data-testid={dataTestId}>
      <div style={{ height: "calc(100vh - 140px)", overflowY: "auto" }}>
        <Table bordered hover responsive className="rounded-5 mb-0" style={{}}>
          <HeadersTable />
          <tbody>
            {data.map((item, index) => {
              return (
                <tr key={item.nid}>
                  <td
                    className="d-flex justify-content-center"
                    style={{
                      backgroundColor: scarpaGray50,
                    }}
                  >
                    {index + 1}
                  </td>
                  <td>{item.nid}</td>
                  <td>{item.publication_price}</td>
                  <td>{item.min_publication_price}</td>
                  <td>{item.initial_utility}</td>
                  <td>{item.comment}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </WrapperTable>
  );
};

export const TableFileData = () => {
  const { hasFile, dataError, dataSuccess, nidsNotUpdated } =
    useModuleHomeBulkUpload();

  const hasErrors = dataError.length > 0;
  const selectedTab = hasErrors ? TabType.Errors : TabType.Success;

  const [activeTab, setActiveTab] = useState(selectedTab);
  const isActiveTabSuccess = activeTab === TabType.Success;
  const isActiveTabErrors = activeTab === TabType.Errors;

  useEffect(() => {
    setActiveTab(selectedTab);
  }, [selectedTab]);

  if (!hasFile) return null;

  if (nidsNotUpdated.length > 0) {
    return (
      <div className="w-100 h-100 px-4" data-testid="data-file">
        <h3
          className="text-danger fw-bold text-center pb-3"
          style={{ fontFamily: robotoRegular }}
        >
          Estos nid&apos;s no pudieron ser actualizados
        </h3>
        <TableToShowFileData
          data={nidsNotUpdated}
          dataTestId="table-nids-not-updated"
        />
      </div>
    );
  }

  return (
    <div className="w-100 h-100 px-4" data-testid="data-file">
      <WrapperDataTabs>
        <div className="d-flex ">
          {hasErrors && (
            <Tab
              icon={
                <IconI
                  width="20"
                  height="20"
                  fill={getColorIcon(isActiveTabErrors)}
                />
              }
              title={`Errores (${dataError.length})`}
              active={isActiveTabErrors}
              onClick={() => {
                setActiveTab(TabType.Errors);
              }}
            />
          )}
          <Tab
            icon={
              <IconCheck
                width="20"
                height="20"
                fill={getColorIcon(isActiveTabSuccess)}
              />
            }
            title={`Validados (${dataSuccess.length})`}
            active={isActiveTabSuccess}
            onClick={() => {
              setActiveTab(TabType.Success);
            }}
          />
        </div>
      </WrapperDataTabs>
      {isActiveTabErrors && hasErrors && (
        <TableToShowFileData data={dataError} dataTestId="table-errors" />
      )}
      {isActiveTabSuccess && (
        <TableToShowFileData data={dataSuccess} dataTestId="table-success" />
      )}
    </div>
  );
};
