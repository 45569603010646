import { useUserLeadDetail } from "@shared/hooks/userLeadDetail.hook";

export const leadersCO = [
  {
    email: "juancamachoe@habi.co",
    id: 1,
    name: "Juan Camacho",
  },
  {
    email: "leonardoavila@habi.co",
    id: 2,
    name: "Leonardo Avila",
  },
  {
    email: "isidoroesquenazi@habi.co",
    id: 3,
    name: "Isidoro Esquenazi",
  },
];

export const LeadersMX = [
  {
    email: "juancamachoe@habi.co",
    id: 1,
    name: "Juan Camacho",
  },
  {
    email: "leonardoavila@habi.co",
    id: 2,
    name: "Leonardo Avila",
  },
  {
    email: "paulbecker@tuhabi.mx",
    id: 3,
    name: "Paul Becker",
  },
];

export const useLeaders = () => {
  const { country } = useUserLeadDetail();

  const leaders = country === "CO" ? leadersCO : LeadersMX;

  return { leaders };
};
