import { InventoryPortalRoute } from "@interfaces";
import {
  pathFeedbackLoop,
  pathFeedbackRemo,
  pathInventorySummary,
} from "./routes.types";

// components.
import { InventorySummary } from "../modules/InventorySummary/InventorySummary";
import { FeedbackLook } from "../modules/FeedbackLook/FeedbackLook";
import { FeedbackRemo } from "../modules/FeedbackRemo/FeedbackRemo";

export const routesInventory: InventoryPortalRoute[] = [
  {
    element: <InventorySummary />,
    id: "InventorySummary",
    path: `${pathInventorySummary}/:nid`,
  },
  {
    element: <FeedbackLook />,
    id: "FeedbackLoop",
    path: `${pathFeedbackLoop}/:nid`,
  },
  {
    element: <FeedbackRemo />,
    id: "FeedbackRemo",
    path: `${pathFeedbackRemo}/:nid`,
  },
];
