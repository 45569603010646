// libraries.
import { useNavigate } from "react-router-dom";

// components
import { SidebarButton } from "./SidebarButton/SidebarButton";

import { pathHome } from "@routes/routes.types";
import { useSidebarContext } from "./Sidebar/Sidebar";
import { SIDEBAR_BUTTON_HOME } from "../sidebar-reducer/sidebar.types";
import { LogoCountryWhite } from "@shared/LogoWhiteByCountry";
import { pathHomeReleaseNotes } from "@pages/HomePage/routes/routes.types";

interface SidebarButtonHomeProps {
  hoverText?: string;
  onClick?: () => void;
}

export const SidebarButtonHome = ({
  hoverText = "Home",
  onClick,
}: SidebarButtonHomeProps) => {
  const navigate = useNavigate();

  const {
    state: { selectedSidebarButton },
  } = useSidebarContext();

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else {
      navigate(`${pathHome}/${pathHomeReleaseNotes}`);
    }
  };

  return (
    <SidebarButton
      hoverText={hoverText}
      onClick={handleClick}
      selected={selectedSidebarButton === SIDEBAR_BUTTON_HOME}
    >
      <LogoCountryWhite />
    </SidebarButton>
  );
};
