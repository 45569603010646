// libraries.
import { scarpaGray500 } from "@habitech/shared";
import styled from "styled-components";

interface WrapperColorPriceProps {
  pricecolor?: string;
}

export const WrapperNormalization = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const WrapperColorPrice = styled.div<WrapperColorPriceProps>`
  color: ${(props) => props.pricecolor || scarpaGray500};
`;
