import {
  cobalBlue300,
  electricPurple300,
  electricPurple50,
  maximumYellow50,
  maximumYellow700,
  skyBlue50,
  tomatoCoral25,
  tomatoCoral500,
  turquoise100,
  turquoise700,
} from "@habitech/shared";

export const formatDateTime = (datetime: string) => {
  if (!datetime) {
    return "Sin fecha";
  }
  const dateOnly = datetime.slice(0, 10);
  return dateOnly;
};

export const actionLabelStyles = (labelType: string) => {
  const styles = {
    background: "",
    color: "",
  };

  if (labelType === "Remodelaciones") {
    styles.background = turquoise100;
    styles.color = turquoise700;
  }
  if (labelType === "Inventory") {
    styles.background = electricPurple50;
    styles.color = electricPurple300;
  }
  if (labelType === "Broker") {
    styles.background = skyBlue50;
    styles.color = cobalBlue300;
  }
  if (labelType === "Comercial") {
    styles.background = tomatoCoral25;
    styles.color = tomatoCoral500;
  }
  if (labelType === "Trámites") {
    styles.background = maximumYellow50;
    styles.color = maximumYellow700;
  }
  return styles;
};

export const transformCategory = (category: string) => {
  const transformText = category.replace(/_/g, " ");
  return transformText;
};
