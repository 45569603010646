// libraries.
import styled from "styled-components";
import {
  electricPurple100,
  electricPurple700,
  robotoRegular,
  white,
} from "@habitech/shared";

// assets.
import { IconWarning } from "@assets";

export const WrapperCopy = styled.div`
  background-color: ${electricPurple700};
  border-radius: 8px;
  font-size: 12px;
  font-family: ${robotoRegular};
  .message {
    color: ${electricPurple100};
  }
  .copy {
    color: ${white};
    font-weight: 900;
  }
`;

export const CopyCountdownPrice = ({
  priceCountdown,
}: {
  priceCountdown: number;
}) => {
  const copy =
    priceCountdown > 1
      ? `Faltan ${priceCountdown} días`
      : `Falta ${priceCountdown} día`;

  return (
    <WrapperCopy className="d-flex flex-column gap-1 p-3">
      <div className="d-flex gap-1 fw-bold align-content-center">
        <IconWarning fill={white} />
        <div className="d-flex align-items-center">Nuevo cambio de precio</div>
      </div>
      <div className="message">
        El precio de este inmueble se modificó hace menos de 15 días.{" "}
        <span className="copy">{copy}</span> para que puedas realizar un nuevo
        cambio o podrías solicitar la aprobación de un líder.
      </div>
    </WrapperCopy>
  );
};
