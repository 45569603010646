// libraries.
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

// slices.
import { apiInventoryPortal } from "@store/apiSlices/apiSlices";

// interfaces.
import { ModelError } from "@store/apiSlices/interfaces/interfaces";
import { CommentStatus } from "./comments.service";

export interface CreateCommentModel {
  agent: string;
  category: string;
  comment: string;
  country: string;
  experience: string;
  hash_list: string[];
  nid: number;
  radius: number;
  status: CommentStatus;
  type: string;
}

interface CreateCommentResponse {
  message: string;
  nid_progress: number;
  success: boolean;
}

export const createComment = apiInventoryPortal.injectEndpoints({
  endpoints: (builder) => ({
    createComment: builder.mutation({
      query: (newData: CreateCommentModel) => ({
        body: newData,
        method: "POST",
        url: "/post_feedback_loop",
      }),
      transformErrorResponse: (error: FetchBaseQueryError): ModelError => {
        const modelError: ModelError = {
          errorStatus: error.status,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          messageFromServer: (error.data as any)?.message,
          serviceErrorName: "createCommentError",
        };
        return modelError;
      },
      transformResponse: (
        response: CreateCommentResponse,
      ): CreateCommentResponse => {
        return response;
      },
    }),
  }),
});

export const { useCreateCommentMutation } = createComment;
