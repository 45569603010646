// libraries.
import { useContext } from "react";
import { Tab, Tabs } from "react-bootstrap";

// hooks.
import { useGetHistoryLeadInfo } from "@store/apiSlices/historyLeadInfo/historyLeadInfo.hooks";

// components.
import { TableCO } from "./TableCO";
import { WrapperTabs } from "../CalculationsTableMX/styles";
import { DownloadButton } from "../DownloadButton/DownloadButton";
import { CalculationsTableContextCO } from "@pages/PublicationPricePage/modules/PublicationPriceCalculations/PublicationPriceCalculations";

export const CalculationsTabsCO = () => {
  const { categoryZone } = useContext(CalculationsTableContextCO);
  const {
    getHistoryLeadInfoQuery: { data },
  } = useGetHistoryLeadInfo();

  const categoryZoneValue = categoryZone?.value;
  const showZone = categoryZoneValue === "Zona";
  const showComplex = categoryZoneValue === "Conjunto";

  const showComparablesTab =
    data?.used_comparables && data.used_comparables.length > 0;
  const showDuplicatedTab =
    data?.possible_duplicated_comps &&
    data?.possible_duplicated_comps.length > 0;
  const showDeletedTab =
    data?.feature_delete_comps && data.feature_delete_comps.length > 0;

  return (
    <WrapperTabs>
      <DownloadButton data={data} />
      <Tabs defaultActiveKey="comparable" id="tabs">
        {showComparablesTab && (
          <Tab
            eventKey="comparable"
            title={`Comparables (${
              showZone
                ? data.used_comparables.length
                : data.set_comps_complex.comps_complex.used_comps.length
            })`}
          >
            {showZone && <TableCO comparablesList={data.used_comparables} />}
            {showComplex && (
              <TableCO
                comparablesList={
                  data.set_comps_complex.comps_complex.used_comps
                }
              />
            )}
          </Tab>
        )}
        {showDuplicatedTab && (
          <Tab eventKey="duplicates" title="Duplicados">
            {showZone && (
              <TableCO
                comparablesList={data.possible_duplicated_comps.flat()}
              />
            )}
          </Tab>
        )}
        {showDeletedTab && (
          <Tab eventKey="deleted" title="Eliminados">
            {showZone && (
              <TableCO comparablesList={data.feature_delete_comps} />
            )}
            {showComplex && (
              <TableCO
                comparablesList={
                  data.set_comps_complex.comps_complex.deleted_comparables
                }
              />
            )}
          </Tab>
        )}
      </Tabs>
    </WrapperTabs>
  );
};
