import { Card } from "react-bootstrap";
import { WrapperPublicationPriceInfo } from "./styles";
import { useGetPublicationPriceInfo } from "@store/apiSlices/pricing/pricing.hooks";
import { formatPrice } from "@utils/formatPrice.utils";
import { VariationPrice } from "./components/VariationPrice";
import { CardCurrentRevenueCategory } from "./components/CardCurrentRevenueCategory";
import { CardUtilityOptions } from "./components/CardUtilityOptions";
import { CardUtility } from "./components/CardUtility";
import { PercentileCalculatorCO } from "./components/PercentileCalculatorCO";
import { robotoRegular } from "@habitech/shared";

interface CardPriceInfoProps {
  children: React.ReactNode;
  classNameData?: string;
  style?: React.CSSProperties;
}

export const CardPriceInfo = ({
  children,
  classNameData,
}: CardPriceInfoProps) => {
  const classData =
    classNameData || "rounded-3 card-price-info p-3 d-flex flex-column gap-1";
  return (
    <Card className={classData} style={{ fontFamily: robotoRegular }}>
      {children}
    </Card>
  );
};

export const PriceInfoCO = () => {
  const {
    getPublicationPriceInfoQuery: { data },
  } = useGetPublicationPriceInfo();

  const publicationPrice =
    data?.askPricePublication && formatPrice(data.askPricePublication);

  const purchasePrice = data?.purchasePrice && formatPrice(data.purchasePrice);

  const paintingPrice = data?.paintingPrice && formatPrice(data.paintingPrice);

  const improvementsPrice =
    data?.improvementsPrice && formatPrice(data.improvementsPrice);

  const repairsPrice = data?.repairsPrice && formatPrice(data.repairsPrice);

  const remodelingBudget =
    data?.remodelingBudget && formatPrice(data.remodelingBudget);

  const isHabiIsHabi = data?.isHabiIsHabi ? "Tiene" : "No tiene";

  const percentileHesh = data?.percentileHesh || 0;

  return (
    <WrapperPublicationPriceInfo className="price-info-grid">
      <CardPriceInfo>
        <small className="title-card">Precio de publicación</small>
        <div data-testId="publication-card" className="subtitle-card fw-bold">
          {publicationPrice}
        </div>
      </CardPriceInfo>
      <CardPriceInfo>
        <small className="title-card">Precio de compra</small>
        <div className="subtitle-card fw-bold">{purchasePrice}</div>
      </CardPriceInfo>
      <CardPriceInfo>
        <small className="title-card">Perc. precio de public...</small>
        <div className="subtitle-card fw-bold">
          {data?.percentilePublication}
        </div>
      </CardPriceInfo>
      <CardPriceInfo>
        <small className="title-card">Pintura remo</small>
        <div className="subtitle-card fw-bold">{paintingPrice}</div>
      </CardPriceInfo>
      <CardPriceInfo>
        <small className="title-card">Mejora remo</small>
        <div className="subtitle-card fw-bold">{improvementsPrice}</div>
      </CardPriceInfo>
      <CardPriceInfo>
        <small className="title-card">Reparación remo</small>
        <div className="subtitle-card fw-bold">{repairsPrice}</div>
      </CardPriceInfo>
      <CardPriceInfo>
        <small className="title-card">Presupuesto remo</small>
        <div className="subtitle-card fw-bold">{remodelingBudget}</div>
      </CardPriceInfo>
      <CardPriceInfo>
        <small className="title-card">Habi es Habi</small>
        <div className="subtitle-card fw-bold">{isHabiIsHabi}</div>
      </CardPriceInfo>
      <CardPriceInfo>
        <small className="title-card">Categoría comité</small>
        <div className="subtitle-card fw-bold">
          {data?.categoryPriceName || "No tiene"}
        </div>
      </CardPriceInfo>
      <CardCurrentRevenueCategory />
      <CardUtility />
      <CardUtilityOptions />
      <VariationPrice />
      <CardPriceInfo>
        <small className="title-card">Percentil HESH</small>
        <div className="subtitle-card fw-bold">{percentileHesh}</div>
      </CardPriceInfo>
      <PercentileCalculatorCO />
    </WrapperPublicationPriceInfo>
  );
};
