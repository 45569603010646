// libraries.
import {
  electricPurple50,
  electricPurple500,
  robotoRegular,
  scarpaGray500,
  white,
  warning,
  turquoise600,
  error,
  turquoise100,
  tomatoCoral50,
  maximumYellow50,
} from "@habitech/shared";
import styled from "styled-components";

export const WrapperCardItems = styled.div`
  &.price-info-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 1rem;
  }

  .default-shadow {
    border: 1px solid ${electricPurple50};
    background: ${white};
    box-shadow: 0px 0px 16px 0px rgba(124, 1, 255, 0.08);
  }

  .warning-shadow {
    border: 1px solid ${maximumYellow50};
    box-shadow: 0px 0px 16px 0px rgba(252, 247, 236, 1);

    .warning {
      color: ${warning};
      font-weight: 600;
      font-family: ${robotoRegular};
      font-size: 16px;
      line-height: 24px;
    }
  }

  .downtrend-shadow {
    border: 1px solid ${tomatoCoral50};
    background: ${white};
    box-shadow: 0px 0px 16px 0 rgba(255, 238, 238, 1);
    .downtrend {
      color: ${error};
      font-family: ${robotoRegular};
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }
    .statistics-percentage {
      color: ${scarpaGray500};
      font-family: ${robotoRegular};
      font-weight: 400;
      font-size: 10px;
      line-height: 18px;
    }
  }

  .uptrend-shadow {
    border: 1px solid ${turquoise100};
    background: ${white};
    box-shadow: 0px 0px 16px 0px rgba(204, 252, 243, 1);
    .uptrend {
      color: ${turquoise600};
      font-family: ${robotoRegular};
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }
    .statistics-percentage {
      color: ${scarpaGray500};
      font-family: ${robotoRegular};
      font-weight: 400;
      font-size: 10px;
      line-height: 18px;
    }
  }

  .card-price-info {
    .title-card {
      color: ${scarpaGray500};
      font-family: ${robotoRegular};
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
    }

    .subtitle-card {
      color: ${electricPurple500};
      font-family: ${robotoRegular};
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }

    .danger {
      color: ${error};
      font-weight: 600;
      font-family: ${robotoRegular};
      font-size: 20px;
      line-height: 24px;
    }
  }
`;
