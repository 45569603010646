// assets.
import { ImageHero, IconHabi } from "@assets";
import GoogleLoginButton from "@shared/GoogleLoginButton/GoogleLoginButton";

// styles
import { BodyWrapper } from "./styles";

export const LoginPage = () => {
  return (
    <>
      <div className="d-flex">
        <ImageHero style={{ height: "100vh" }} />
        <div className="d-flex align-items-center justify-content-center w-100">
          <div>
            <div className="d-flex justify-content-center mb-5">
              <IconHabi />
            </div>

            <BodyWrapper className="d-flex flex-column gap-2 align-items-center justify-content-center">
              <div className="first-subtitle">
                Aprobologos y aprobación de publicación
              </div>
              <h1 className="second-subtitle text-center">
                Inicia sesión con tu <br /> cuenta Google
              </h1>
              {process.env.ENV_AUTH_GOOGLE_API_KEY && (
                <GoogleLoginButton
                  clientId={process.env.ENV_AUTH_GOOGLE_API_KEY}
                />
              )}
            </BodyWrapper>
          </div>
        </div>
      </div>
    </>
  );
};
