import { electricPurple000, robotoRegular } from "@habitech/shared";
import styled from "styled-components";

export const IconInfoHover = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  .message {
    display: none;
  }
  &:hover {
    cursor: pointer;
    .message {
      display: block;
      position: absolute;
      padding: 10px 10px 8px 12px;
      width: auto;
      background-color: rgba(0, 0, 0, 1);
      color: ${electricPurple000};
      border-radius: 5px;
      opacity: 0.7;
      text-align: center;
      font-family: ${robotoRegular};
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      z-index: 1;
    }
    .flag-message:after {
      content: "";
      width: 15px;
      height: 15px;
      background-color: rgba(0, 0, 0, 1);
      opacity: 0.9;
      transform: rotate(45deg);
      position: absolute;
      top: -6px;
      left: 49px;
      z-index: 1;
    }
  }
`;
