// libraries.
import { ListGroup } from "react-bootstrap";
import { electricPurple600 } from "@habitech/shared";

// hooks.
import { useGetHistoryLeadInfo } from "@store/apiSlices/historyLeadInfo/historyLeadInfo.hooks";

// styles.
import { WrapperInfoContainer } from "./styles";

// utils.
import { formatPrice } from "@utils/formatPrice.utils";
import { CalculationsTableContextCO } from "../../../PublicationPriceCalculations";
import { useContext } from "react";

export const CalculationsInfoCO = () => {
  const { categoryZone } = useContext(CalculationsTableContextCO);
  const {
    getHistoryLeadInfoQuery: { data },
  } = useGetHistoryLeadInfo();

  const categoryZoneValue = categoryZone?.value;
  const showZone = categoryZoneValue === "Zona";

  return (
    <WrapperInfoContainer>
      <ListGroup horizontal className="list">
        <ListGroup.Item>
          <div data-id="calculation-properties">
            <span>Mediana</span>
            <br />
            <strong>
              {showZone
                ? data?.median_price_m2 && formatPrice(data?.median_price_m2)
                : data?.median_price_m2_conj &&
                  formatPrice(data?.median_price_m2_conj)}
            </strong>
          </div>
        </ListGroup.Item>
        <ListGroup.Item>
          <div data-id="calculation-properties">
            <span>Coeficiente</span>
            <br />
            <strong>
              {showZone
                ? `${data?.coefficient_variation.toFixed(2)} %`
                : `${data?.coefficient_of_variation_conj.toFixed(2)} %`}
            </strong>
          </div>
        </ListGroup.Item>
        <ListGroup.Item>
          <div data-id="calculation-properties">
            <span>Área</span>
            <br />
            <strong>{`${data?.m2} mt²`}</strong>
          </div>
        </ListGroup.Item>
        <ListGroup.Item>
          <div data-id="calculation-properties">
            <span>Percentil</span>
            <br />
            <strong>{data?.percentil}</strong>
          </div>
        </ListGroup.Item>
        <ListGroup.Item>
          <div data-id="calculation-properties">
            <span>Ventas en conjunto</span>
            <br />
            <strong>{`${data?.percentage_calculation.toFixed(2)} %`}</strong>
          </div>
        </ListGroup.Item>
        <ListGroup.Item>
          <div data-id="calculation-properties">
            <span>Valor de compra</span>
            <br />
            <strong>
              {data?.discount_price && formatPrice(data?.discount_price)}
            </strong>
          </div>
        </ListGroup.Item>
        <ListGroup.Item>
          <div data-id="calculation-properties">
            <span>Valor comercial</span>
            <br />
            <strong style={{ color: `${electricPurple600}` }}>
              {data?.starting_price && formatPrice(data?.starting_price)}
            </strong>
          </div>
        </ListGroup.Item>
      </ListGroup>
    </WrapperInfoContainer>
  );
};
