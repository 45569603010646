// libraries.
import { tomatoCoral600, turquoise800 } from "@habitech/shared";

// hooks.
import { HistoryLeadInfo } from "@store/apiSlices/historyLeadInfo/historyLeadInfo.endpoints";

// assets.
import { IconTooltip } from "@assets";

// styles.
import { WrapperNorm } from "./styles";
import { DropdownUsedComparables } from "./DropdownUsedComparables";

interface NormProps {
  data?: HistoryLeadInfo;
}

export const DownloadButton = ({ data }: NormProps) => {
  return (
    <WrapperNorm>
      <div className="d-flex gap-2 align-items-center">
        <IconTooltip />
        <span>Normalización: </span>
        {data?.used_normalizations && data?.used_normalizations.length > 0 ? (
          <p style={{ color: turquoise800 }}>Encendida</p>
        ) : (
          <p style={{ color: tomatoCoral600 }}>Apagada</p>
        )}
      </div>
      <DropdownUsedComparables />
    </WrapperNorm>
  );
};
