// Components.
import { InfoTagHover } from "@shared/InfoTagHover/InfoTagHover";

// Hooks.
import { useGetPublicationPriceInfo } from "@store/apiSlices/pricing/pricing.hooks";

export const ApprovalDiscountAlert = () => {
  const {
    getPublicationPriceInfoQuery: { data },
  } = useGetPublicationPriceInfo();

  if (!data) {
    return null;
  }

  const publicationPrice = data.publicationPrice;
  const alertPrice = 1500000;

  let increaseMessage;
  let increaseAlert;

  if (publicationPrice && publicationPrice < alertPrice) {
    increaseMessage = "Aumento inteligencia de mercados";
    increaseAlert = "2% extra en ambos precios";
  }

  return (
    increaseMessage && (
      <div className="d-flex gap-2 justify-content-end mt-3 align-items-center">
        <InfoTagHover style={{ left: "-45px", top: "26px" }}>
          {increaseMessage}
        </InfoTagHover>
        <small className="text-white">{increaseAlert}</small>
      </div>
    )
  );
};
