import { useContext } from "react";
import DropdownBootstrap from "react-bootstrap/Dropdown";
import { DropdownContext } from "./Dropdown";
import { OptionValue } from "../useSelectValue.hook";

interface DropdownItemProps {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  value: OptionValue;
}

export const DropdownItem = ({
  style,
  className,
  children,
  value,
}: DropdownItemProps) => {
  const { handleChange } = useContext(DropdownContext);

  if (!handleChange) return null;

  return (
    <DropdownBootstrap.Item
      onClick={() => handleChange(value)}
      className={className}
      style={style}
    >
      {children}
    </DropdownBootstrap.Item>
  );
};
