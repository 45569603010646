import { pathPublicationPrice } from "@routes/routes.types";

interface OpportunityProps {
  id?: string;
  labelDealBusiness?: string;
  navigate?: string;
}

export const businessOpportunity: OpportunityProps[] = [
  {
    id: "initial_pricing",
    labelDealBusiness: "Pricing inicial",
  },
  {
    id: "initial_repricing",
    labelDealBusiness: "Repricing inicial",
  },
  {
    id: "committee_pricing",
    labelDealBusiness: "Pricing Comité",
  },
  {
    id: "committee_repricing",
    labelDealBusiness: "Repricing Comité",
  },
  {
    id: "publication_price",
    labelDealBusiness: "Precio de publicación",
    navigate: pathPublicationPrice,
  },
  {
    id: "publication_approval",
    labelDealBusiness: "Aprobación de publicación",
    navigate: pathPublicationPrice,
  },
];

export const getCurrentDealBusiness = (dealId?: string) => {
  if (!dealId) return undefined;

  return businessOpportunity.find((item) => item.id === dealId);
};
