// libraries.
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

// components
import { IncreaseDiscountPrice } from "../../IncreaseDiscountPrice";
import { ApprovalDiscountAlert } from "../../ApprovalDiscountAlert";

// hooks.
import { useGetLeadDetailInfo } from "@store/apiSlices/leadDetail/leadDetail.hooks";
import { useGetPublicationPriceInfo } from "@store/apiSlices/pricing/pricing.hooks";

// utils.
import { formatPrice } from "@utils";

export const OriginalMinimumPrice = () => {
  const { register, watch, setValue } = useFormContext();
  const { area } = useGetLeadDetailInfo();

  const originalMinimumPriceInput = watch("originalMinimumPrice");

  const {
    getPublicationPriceInfoQuery: { data: publicationPriceInfo },
  } = useGetPublicationPriceInfo();

  const originalMinimumPriceM2 =
    Number(
      originalMinimumPriceInput ?? publicationPriceInfo?.originalMinimumPrice,
    ) / area;

  useEffect(() => {
    setValue(
      "originalMinimumPrice",
      publicationPriceInfo?.originalMinimumPrice,
    );
  }, [publicationPriceInfo?.originalMinimumPrice]);

  return (
    <div className="d-flex flex-column gap-1">
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <div>Precio mínimo original</div>
          <IncreaseDiscountPrice minPubIncrease />
        </div>
        <input
          data-testid="originalMinimumPriceInput"
          {...register("originalMinimumPrice")}
          type="number"
          className="rounded-3 p-2"
        />
      </div>
      <div className="d-flex justify-content-end w-100">
        <small className="text-white">
          Valor por m²: {formatPrice(originalMinimumPriceM2) || 0}
        </small>
      </div>
      <ApprovalDiscountAlert />
    </div>
  );
};
