// libraries.
import { useEffect, useState } from "react";

// hooks.
import { useModuleNotifications } from "../../notifications-slice/notifications.hook";

// components.
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
} from "@shared/Inputs/Dropdown";
import { CheckboxItem } from "@shared/Inputs/Dropdown/components/CheckboxItem";

// utils.
import { notificationOption } from "./utils";
import { WrapperInventoryFilters } from "@pages/HomePage/modules/Inventory/components/InventoryBar/components/styles";

export const NotificationsDropdown = () => {
  const { updateNotificationFilter } = useModuleNotifications();

  const [optionSelected, setOptionSelected] = useState<string[]>([
    "remo",
    "brokers",
    "budget",
    "comission",
    "price",
  ]);

  const handleChange = (value: string) => {
    setOptionSelected((prevSelectedOpts) => {
      const isAllSelected = prevSelectedOpts.includes("all");
      const isSelected = prevSelectedOpts.includes(value);

      if (value === "all") {
        return isAllSelected
          ? []
          : notificationOption.map((option) => option.value);
      }
      if (isSelected && prevSelectedOpts.length === notificationOption.length) {
        return prevSelectedOpts.filter(
          (option) => option !== value && option !== "all",
        );
      }

      const updatedOptions = isSelected
        ? prevSelectedOpts.filter((option) => option !== value)
        : [...prevSelectedOpts, value];

      const optionsSelected = notificationOption
        .filter((option) => option.value !== "all")
        .every((option) => updatedOptions.includes(option.value));

      return optionsSelected ? [...updatedOptions, "all"] : updatedOptions;
    });
  };

  useEffect(() => {
    const hasAllSelected = optionSelected.includes("all");
    if (hasAllSelected) {
      const updatedOptions = optionSelected.filter(
        (option) => option !== "all",
      );
      updateNotificationFilter(updatedOptions.join(","));
    } else {
      updateNotificationFilter(optionSelected.join(","));
    }
  }, [optionSelected, updateNotificationFilter]);

  const defaultOption = {
    label: "Notificaciones",
    value: "notificationsFilter",
  };
  return (
    <WrapperInventoryFilters className="d-flex align-self-center w-100">
      <div className="w-100">
        <Dropdown value={defaultOption} className="w-100">
          <DropdownToggle
            className="filter-toggle w-100"
            id="dropdownBucketFilter"
            showArrow
            style={{
              background: "white",
              border: "1px solid #949494",
              color: "black",
            }}
          />
          <DropdownMenu className="w-100">
            {notificationOption.map((option) => (
              <CheckboxItem
                className="filter-check w-100"
                key={option.value}
                id={option.label}
                label={option.label}
                checked={optionSelected.includes(option.value)}
                onChange={() => handleChange(option.value)}
              />
            ))}
          </DropdownMenu>
        </Dropdown>
      </div>
    </WrapperInventoryFilters>
  );
};
