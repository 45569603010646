// libraries.
import { Card, Container } from "react-bootstrap";

// component.
import { NewComment } from "@shared/NewComment";

// styles
import { scarpaGray50 } from "@habitech/shared";

export const TableEmpty = () => {
  return (
    <Container fluid className=" d-flex justify-content-center h-100">
      <Card
        className="d-flex justify-content-center py-5 border-0"
        style={{
          boxShadow: `0px 0px 16px 0px ${scarpaGray50}`,
          height: "calc(100vh - 166px)",
          position: "absolute",
          width: "calc(100% - 73px)",
        }}
      >
        <div className="text-center">
          <div data-testid="TableFeedbackLook" className="fw-bold mb-2">
            No hay comentarios
          </div>
          <div className="fw-bold text-body-secondary mb-4">
            Agrega nuevos comentarios de la zona o NID.
          </div>
          <NewComment />
        </div>
      </Card>
    </Container>
  );
};
