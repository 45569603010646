// libraries.
import { useNavigate } from "react-router-dom";

// assets.
import { IconMap } from "@assets";

// hooks.
import { useNid } from "@pages";

// shared components.
import { SidebarButton, useSidebarContext } from "@shared/Sidebar";

// types.
import { SIDEBAR_PUBLICATION_PRICE_MAP } from "../sidebarPublicationPrice.types";
import { pathPublicationPrice } from "@routes/routes.types";
import { pathPublicationPriceMap } from "@pages/PublicationPricePage/routes/routes.types";

export const ButtonMap = () => {
  const navigate = useNavigate();

  const { nid } = useNid();

  const {
    setSelectedButton,
    state: { selectedSidebarButton },
  } = useSidebarContext();

  const isSelected = selectedSidebarButton === SIDEBAR_PUBLICATION_PRICE_MAP;

  const handleSelect = () => {
    navigate(`${pathPublicationPrice}/${pathPublicationPriceMap}/${nid}`);
    setSelectedButton(SIDEBAR_PUBLICATION_PRICE_MAP);
  };
  return (
    <SidebarButton
      hoverText="Mapa"
      selected={isSelected}
      onClick={() => handleSelect()}
    >
      <IconMap fill={"white"} />
    </SidebarButton>
  );
};
