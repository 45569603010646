// components.
import { CardPriceInfo } from "../PriceInfoCO";
import { InfoTagHover } from "@shared/InfoTagHover/InfoTagHover";

// hooks.
import {
  useGetPublicationPriceInfo,
  useVariationFromPublicationPrice,
} from "@store/apiSlices/pricing/pricing.hooks";
import { getLabelVariation } from "./utils";

export const VariationPrice = () => {
  const {
    getPublicationPriceInfoQuery: { data },
  } = useGetPublicationPriceInfo();

  const { isInVariationRange } = useVariationFromPublicationPrice();

  const isWarningColor = !isInVariationRange
    ? "warning"
    : "subtitle-card fw-bold";
  const showTooltip = !isInVariationRange && data?.discardReasonVariation;

  return (
    <CardPriceInfo>
      <small className="title-card">Variación precio publi...</small>
      <div className="d-flex justify-content-between">
        <div className={isWarningColor}>{`${data?.variationPrice || 0}%`}</div>
        {showTooltip && (
          <InfoTagHover style={{ left: "-45px", top: "26px" }}>
            {getLabelVariation(data?.discardReasonVariation)}
          </InfoTagHover>
        )}
      </div>
    </CardPriceInfo>
  );
};
