// libraries.
import { Button } from "react-bootstrap";
import { useEffect } from "react";
// hooks.
import { useModuleHomeBulkUpload } from "../bulkUpload-slice/bulkUpload.hook";
import { useUserProfile } from "@shared/GoogleLoginButton/user-slice/useUserProfile.hook";
import { useUpdatePrices } from "@store/apiSlices/massiveLoad/massiveLoad.hook";

export const SaveData = () => {
  const {
    dataError,
    dataSuccess,
    clearState,
    hasFile,
    setNidsNotUpdated,
    nidsNotUpdated,
  } = useModuleHomeBulkUpload();
  const { email } = useUserProfile();

  const {
    updatePrices,
    mutateUpdatePricesQuery: { data, isSuccess },
  } = useUpdatePrices();
  const listNidErrors = data ? data : [];
  const lenNidErrors = listNidErrors.length;

  const sendData = () => {
    const dataFromUser = dataSuccess.map((data) => {
      return {
        ...data,
        agent: email,
      };
    });
    updatePrices(dataFromUser);
  };

  useEffect(() => {
    if (lenNidErrors === 0 && isSuccess) {
      clearState();
    }

    if (lenNidErrors > 0) {
      setNidsNotUpdated(listNidErrors);
    }
  }, [lenNidErrors, isSuccess]);

  if (dataError.length > 0) {
    return null;
  }

  if (!hasFile) {
    return null;
  }

  if (nidsNotUpdated.length > 0) return null;

  return (
    <div className="d-flex align-items-center gap-2">
      <Button
        className="fw-bold btn-secundary-habi rounded-2 py-2 px-4"
        onClick={clearState}
      >
        <span>Cancelar</span>
      </Button>
      <Button
        className="btn-habi rounded-2 fw-bold py-2 px-4"
        onClick={sendData}
      >
        <span>Guardar</span>
      </Button>
    </div>
  );
};
