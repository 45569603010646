// libraries.
import styled from "styled-components";
import {
  electricPurple000,
  monserratSemiBold,
  robotoRegular,
  tomatoCoral500,
  warning,
} from "@habitech/shared";

export const WrapperInventoryTitle = styled.div`
  p {
    font-family: ${robotoRegular};
    font-size: 14px;
  }
  h4 {
    background: var(
      --habi-Gradient-Primary-variant,
      linear-gradient(90deg, #4a0199 0%, #9634ff 100%)
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: ${monserratSemiBold};
  }
  .badge-alert {
    background-color: ${warning};
    color: ${electricPurple000};
    font-family: ${robotoRegular};
    font-size: 14px;
    padding: 0px 8px;
    border-radius: 6px;
  }
  .badge-inventory {
    background-color: ${tomatoCoral500};
    color: ${electricPurple000};
    font-family: ${robotoRegular};
    font-size: 14px;
    padding: 0px 8px;
    border-radius: 6px;
  }
`;
