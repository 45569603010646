import { useUserProfile } from "@shared/GoogleLoginButton/user-slice/useUserProfile.hook";

export const headersMX = [
  "#",
  "ID",
  "NID",
  "Fecha",
  "Fecha Avalúo",
  "Fuente",
  "Precio cliente",
  "Precio cliente normalizado",
  "Precio por m²",
  "Terreno",
  "Área",
  "Habit.",
  "Baños",
  "Estac.",
  "Elev.",
  "Antigüedad",
  "Piso",
  "Dirección",
  "URL",
  "Tipo de Inmueble",
  "Obra gris",
  "Descripción",
  "Contacto",
  "Teléfono",
];

export const headersCO = [
  "#",
  "ID",
  "Fecha",
  "Fuente",
  "Dirección",
  "Conjunto",
  "Área",
  "Piso",
  "Habit.",
  "Baños",
  "Parqu.",
  "Ascen.",
  "Antigüedad",
  "Precio Cliente",
  "Precio cliente normalizado",
  "Precio por m²",
  "Obra gris",
  "Contacto",
  "Teléfono",
  "Tipo de Inmueble",
];

export const headersByCountry = () => {
  const { user } = useUserProfile();
  if (user?.country === "CO") {
    return headersCO;
  } else {
    return headersMX;
  }
};

export const tableHeaders = () => {
  const allHeaders = headersByCountry();
  return (
    <thead>
      <tr>
        {allHeaders.map((header, index) => (
          <th key={index}>{header}</th>
        ))}
      </tr>
    </thead>
  );
};
