import { robotoRegular } from "@habitech/shared";

export const TableHeaders = () => {
  const styleHeaders = { fontFamily: `${robotoRegular}` };
  return (
    <thead>
      <tr>
        <th className="fw-bold"></th>
        <th className="fw-bold" style={styleHeaders}>
          Fecha
        </th>
        <th className="fw-bold" style={styleHeaders}>
          Modo
        </th>
        <th className="fw-bold" style={styleHeaders}>
          Comentario
        </th>
        <th className="fw-bold" style={styleHeaders}>
          Creador
        </th>
        <th className="fw-bold" style={styleHeaders}>
          Categoría
        </th>
        <th className="fw-bold" style={styleHeaders}>
          Experiencia
        </th>
        <th className="fw-bold" style={styleHeaders}>
          Estado
        </th>
      </tr>
    </thead>
  );
};
