// hooks.
import { useGetHistoryLeadInfo } from "@store/apiSlices/historyLeadInfo/historyLeadInfo.hooks";

// components.
import { UsedFiltersCard } from "@shared/UsedFiltersCard/UsedFilters";

// styles.
import { WrapperFilters } from "./styles";
import { WrapperFilterText } from "@shared/UsedFiltersCard/styles";

// utils.
import {
  getAntiquityRange,
  getAreaRange,
  getLabelUsedFilters,
} from "../../utils";

export const UsedFiltersCO = () => {
  const {
    getHistoryLeadInfoQuery: { data },
  } = useGetHistoryLeadInfo();

  const usedFilters = data?.used_filters;

  const hasElevator = usedFilters?.elevator[1].selected;
  const hasNoElevator = usedFilters?.elevator[0].selected;

  return (
    <WrapperFilters>
      <div
        className="d-flex flex-column p-4 bg-light"
        style={{ width: "270px" }}
      >
        <div className="filters-title">Filtros</div>
      </div>
      <div className="overflow-y-scroll">
        <div className="p-4 d-flex flex-column gap-2">
          <UsedFiltersCard
            title="Antigüedad"
            subTitle="años"
            minVal={usedFilters?.years_old_min}
            maxVal={usedFilters?.years_old_max}
            filters={getAntiquityRange(usedFilters)}
          />
          <UsedFiltersCard
            title="Área"
            subTitle="m²"
            minVal={usedFilters?.area_min}
            maxVal={usedFilters?.area_max}
            filters={getAreaRange(usedFilters)}
          />
          <UsedFiltersCard
            title="Fecha del comparable"
            filters={getLabelUsedFilters(usedFilters?.from_date_create)}
          />
          <UsedFiltersCard
            title="Tipo de inmueble"
            filters={getLabelUsedFilters(usedFilters?.property_type_id)}
          />
          <UsedFiltersCard
            title="Baños"
            filters={getLabelUsedFilters(usedFilters?.baths)}
          />
          <UsedFiltersCard
            title="Parqueadero"
            filters={getLabelUsedFilters(usedFilters?.garage)}
          />
          <WrapperFilterText>
            <p>Ascensor</p>
          </WrapperFilterText>

          <div className="d-flex border rounded" style={{ height: "50px" }}>
            <div
              className={`d-flex justify-content-center align-items-center w-100 h-100 border-end rounded-start ${
                hasElevator && "bg-habi"
              }`}
            >
              <div className={`${hasElevator && "text-white"}`}>Sí</div>
            </div>
            <div
              className={`d-flex justify-content-center align-items-center w-100 h-100 rounded-end ${
                hasNoElevator && "bg-habi"
              }`}
            >
              <div className={`${hasNoElevator && "text-white"}`}>No</div>
            </div>
          </div>
        </div>
      </div>
    </WrapperFilters>
  );
};
