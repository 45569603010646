// components.
import { InventorySummaryCO } from "./InventorySummaryCO/InventorySummaryCO";
import { InventorySummaryMX } from "./InventorySummaryMX/InventorySummaryMX";

// hooks.
import { useUserProfile } from "@shared/GoogleLoginButton/user-slice/useUserProfile.hook";

interface InventorySummaryByCountryComponent {
  component: React.ReactNode;
  country: string;
}

export const InventorySummariesByCountry: InventorySummaryByCountryComponent[] =
  [
    {
      component: <InventorySummaryMX />,
      country: "MX",
    },
    { component: <InventorySummaryCO />, country: "CO" },
  ];

export const InventorySummaryByCountry = () => {
  const { user } = useUserProfile();
  const inventorySummaryComponent = InventorySummariesByCountry.find(
    (filter) => filter.country === user.country,
  );
  return inventorySummaryComponent && inventorySummaryComponent.component;
};
