export const headersRemo = [
  "",
  "Fecha entrada",
  "Clasificación",
  "Comentario",
  "Estado",
  "Estado vivienda",
  "Encargado",
  "Total",
  "Creación solici...",
  "Motivo",
  "Etapa solicitud",
  "Prioridad",
  "Creador",
  "Fecha contrati...",
  "Fecha Inicio",
  "Tipo de actividad",
  "Ultima Actuli...",
];

export const headersActions = [
  "",
  "Creación solici...",
  "Encargado",
  "Días de holding co...",
  "Accionable y costo",
  "Comentario",
];

export const headersBrokers = [
  "",
  "Fecha",
  "Nombre del Broker",
  "Contacto del Broker",
  "Categoría",
  "¿Que sucedió?",
];

export const widths = {
  Comentario: "400px",
  "¿Que sucedió?": "500px",
};
