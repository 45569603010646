// libraries.
import { Modal } from "react-bootstrap";

export const LoadingNotifications = () => {
  return (
    <Modal.Body>
      <div className="d-flex flex-column justify-content-center align-items-center gap-4 w-100 h-100">
        <div className="d-flex flex-column align-items-center gap-3">
          <div className="loading-spinner" />
          <span className="titles">Cargando notificaciones</span>
        </div>
      </div>
    </Modal.Body>
  );
};
