import {
  electricPurple000,
  monserratSemiBold,
  robotoRegular,
} from "@habitech/shared";
import styled from "styled-components";

export const WrapperBulkUpload = styled.div`
  width: 100%;
  height: 100%;
  .title {
    font-size: 24px;
    background: rgba(50, 0, 102, 1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: ${monserratSemiBold};
  }
  .empty-load {
    font-size: 14px;
    font-family: ${robotoRegular};
    width: 100%;
    height: 100%;
    border-radius: 8px;
    border: 1px dotted rgba(229, 204, 255, 1);
    background: ${electricPurple000};
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    align-items: center;
  }
`;

export const WrapperDataTabs = styled.div`
  font-family: ${robotoRegular};
  font-size: 15px;
`;
