import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { apiInventoryPortal } from "../apiSlices";
import { ModelError } from "../interfaces/interfaces";
import { FeedbackActionsTransformed } from "./utils";

interface FeedbackActionsParams {
  country?: string;
  nid: number;
}

interface FeedbackActions {
  actionable: string;
  comment: string;
  commercial_holding: string;
  created_date: string;
  diff_price: number;
  label: string;
  manager: string;
  nid: string;
}

interface FeedbackActionsResponse {
  data: FeedbackActions[];
  code: number;
  success: boolean;
}

export const feedbackActions = apiInventoryPortal.injectEndpoints({
  endpoints: (builder) => ({
    getFeedbackActions: builder.query({
      query: ({ country, nid }: FeedbackActionsParams) => {
        return `/get_list_actionables?country=${country}&nid=${nid}`;
      },
      transformErrorResponse: (error: FetchBaseQueryError) => {
        const modelError: ModelError = {
          errorStatus: error.status,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          messageFromServer: (error.data as any)?.message,
          serviceErrorName: "getFeedbackActions",
        };
        return modelError;
      },
      transformResponse: (response: FeedbackActionsResponse) => {
        const feedbackActions: FeedbackActionsTransformed[] = response.data.map(
          (feedback) => {
            return {
              actionableType: feedback.actionable,
              comment: feedback.comment,
              commercialHolding: feedback.commercial_holding,
              createdDate: feedback.created_date,
              diffPrice: feedback.diff_price,
              labelType: feedback.label,
              manager: feedback.manager,
              nid: feedback.nid,
            };
          },
        );
        return { feedbackActions };
      },
    }),
  }),
});

export const { useGetFeedbackActionsQuery } = feedbackActions;
