// libraries.
import { Container } from "react-bootstrap";

// shared components.
import { EmbeddedBoard } from "@shared/EmbeddedBoards";

export const ReleaseNotes = () => {
  const url =
    "https://docs.google.com/presentation/d/1jGhdJYQbZhCvE1fOdPjCmypofWQz7vc3EArFS3etfDk/embed?start=false&loop=false&delayms=3000";
  return (
    <div className="d-flex h-100">
      <Container fluid className="d-flex p-3">
        <EmbeddedBoard url={url} className="w-100 h-100 rounded" />
      </Container>
    </div>
  );
};
