// libraries.
import { useNavigate } from "react-router-dom";

// assets
import { IconSummary } from "@assets";

// hooks.
import { useNid } from "@pages";

// components.
import { SidebarButton, useSidebarContext } from "@shared/Sidebar";

// types.
import { SIDEBAR_SEARCH_LEAD } from "@pages/HomePage/components/SidebarHome/sidebarHome.types";
import { pathPublicationPriceSummary } from "@pages/PublicationPricePage/routes/routes.types";
import { pathPublicationPrice } from "@routes/routes.types";

export const ButtonSummary = () => {
  const navigate = useNavigate();

  const { nid } = useNid();

  const {
    setSelectedButton,
    state: { selectedSidebarButton },
  } = useSidebarContext();

  const isSelected = selectedSidebarButton === SIDEBAR_SEARCH_LEAD;

  const handleSelect = () => {
    navigate(`${pathPublicationPrice}/${pathPublicationPriceSummary}/${nid}`);
    setSelectedButton(SIDEBAR_SEARCH_LEAD);
  };
  return (
    <SidebarButton
      hoverText="Precio"
      selected={isSelected}
      onClick={() => handleSelect()}
    >
      <IconSummary fill={"white"} />
    </SidebarButton>
  );
};
