// slices.
import { apiInventoryPortal } from "@store/apiSlices/apiSlices";

export const notificationsUpdate = apiInventoryPortal.injectEndpoints({
  endpoints: (builder) => ({
    getNotificationsUpdate: builder.mutation({
      query: ({
        notificationIds,
        country,
      }: {
        country?: string;
        notificationIds: number[];
      }) => ({
        body: { country, notification_ids: notificationIds },
        method: "PUT",
        url: "/put_notifications",
      }),
    }),
  }),
});

export const { useGetNotificationsUpdateMutation } = notificationsUpdate;
