// hooks.
import { useGetPublicationPriceInfo } from "@store/apiSlices/pricing/pricing.hooks";

// components.
import { ValidateTope } from "../../shared/ValidateTope";

export const ValidateTopeCO = () => {
  const {
    getPublicationPriceInfoQuery: { data },
  } = useGetPublicationPriceInfo();

  const watchPriceName = data?.isHabiIsHabi
    ? "minPublicationPrice"
    : "publicationPrice";

  return (
    <div className="d-flex justify-content-end">
      <ValidateTope watchPriceName={watchPriceName} />
    </div>
  );
};
