// libraries.
import { modalOptions, useModalOptions } from "@store/slices/modals";
import { Button } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { ModalZone } from "../../../../ModalZone/ModalZone";
import { skyBlue100, skyBlue700 } from "@habitech/shared";

export const CreateZone = () => {
  const { watch } = useFormContext();

  const hashList = watch("hashList");
  const hasHashList = hashList?.length > 0;

  const { openModal, modals } = useModalOptions();

  return (
    <div className="d-flex justify-content-end">
      {!hasHashList && (
        <Button
          variant="dark"
          size="sm"
          className="rounded fw-bold simple-text"
          style={{ backgroundColor: skyBlue100, color: skyBlue700 }}
          onClick={() => {
            const modalsAux = modals.concat([
              modalOptions.modalInventoryPageMapZone,
            ]);
            openModal(modalsAux);
          }}
        >
          <span>+ </span>
          <span className="simple-text"> Crear zona</span>
        </Button>
      )}
      {hasHashList && (
        <Button
          data-testid="zone-button"
          variant="dark"
          size="sm"
          className="rounded simple-text"
          style={{ backgroundColor: skyBlue100, color: skyBlue700 }}
          onClick={() => {
            const modalsAux = modals.concat([
              modalOptions.modalInventoryPageMapZone,
            ]);
            openModal(modalsAux);
          }}
        >
          Zona
        </Button>
      )}
      <ModalZone />
    </div>
  );
};
