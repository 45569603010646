// libraries.
import { Col, Container, Row } from "react-bootstrap";
import { scarpaGray100, scarpaGray900, white } from "@habitech/shared";

// hooks.
import { modalOptions, useModalOptions } from "@store/slices/modals";

// components.
import { CardComment } from "../../../shared/CardComment";
import { IComment } from "@store/apiSlices/feedbackLoop/comments/comments.service";

export const CardsComments = ({
  commentsNoArchived,
}: {
  commentsNoArchived: IComment[];
}) => {
  const { openModal } = useModalOptions();
  return (
    <Container fluid data-testid="cards-comments">
      <Row className="px-3">
        {commentsNoArchived.map((propsComment) => {
          return (
            <Col
              lg={3}
              key={propsComment.commentId}
              className="mb-3 pointer"
              onClick={() =>
                openModal(modalOptions.modalInventoryPageCreateComment, {
                  commentId: propsComment.commentId,
                })
              }
            >
              <CardComment
                key={propsComment.commentId}
                commentCategory={propsComment.commentCategory}
                commentExperience={propsComment.commentExperience}
                commentText={propsComment.commentText}
                commentType={propsComment.commentType}
                date={propsComment.dateUpdated}
                user={propsComment.user}
                style={{
                  backgroundColor: white,
                  borderColor: white,
                  boxShadow: `0px 1px 16px 0px ${scarpaGray100}`,
                  color: scarpaGray900,
                  padding: "22px 16px",
                }}
                className="text-dark h-100"
              />
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};
