// hooks.
import { useListInventory } from "@store/apiSlices/listInventory/listInventory.hooks";

// styles.
import { WrapperInventoryTitle } from "../styles";

export const InventoryTitle = () => {
  const { inventoryLen } = useListInventory();

  return (
    <WrapperInventoryTitle
      className="d-flex flex-column gap-2 w-100"
      data-testid="title-id"
    >
      <h4>Inventario alertado</h4>
      <div className="d-flex justify-content-between">
        <p className="fw-bold">{inventoryLen} inmuebles</p>
        <div className="d-flex gap-2">
          <div className="badge-alert">Alerta temprana</div>
          <div className="badge-inventory">Inventario rojo</div>
        </div>
      </div>
    </WrapperInventoryTitle>
  );
};
