import { monserratSemiBold, robotoRegular } from "@habitech/shared";
import styled from "styled-components";

export const WrapperPropertyLayout = styled.div`
  div {
    font-family: ${robotoRegular};
    font-size: 14px;
  }
  .value-property {
    font-weight: 800;
  }
  h4 {
    font-family: ${monserratSemiBold};
    font-weight: 800;
  }
`;

export const WrapperPropertyInfo = styled.div`
  div {
    font-family: ${robotoRegular};
    font-size: 14px;
    font-weight: 900;
  }
  .value-property {
    color: var(--font-habi-pcom-secundary-aa, #6d6970);
    font-weight: 400;
  }
`;
