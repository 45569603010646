// libraries
import { Button } from "react-bootstrap";
import { useEffect } from "react";
import { useDebounce } from "@uidotdev/usehooks";
import { useFormContext } from "react-hook-form";

// hooks.
import { usePriceUtility } from "@store/apiSlices/utilityService/priceUtility.hook";
import { usePriceCountdown } from "@store/apiSlices/priceCountdown/priceCountdown.hook";
import { useGetInventoryPropertyInfo } from "@store/apiSlices/inventoryPropertyInfo/inventoryPropertyInfo.hook";

// styles.
import { WrapperButton } from "./styles";
import { CopyToDisablePrice } from "./CopyToDisablePrice";
import { CopyCountdownPrice } from "./CopyCountdownPrice";

export const SaveInventoryPricingButton = () => {
  const {
    watch,
    formState: { errors, isSubmitted },
    reset,
  } = useFormContext();

  const leader = watch("leader");
  const minPublicationPriceInput = watch("minPublicationPrice");
  const deferredMinPublicationPriceValue = useDebounce(
    minPublicationPriceInput,
    1000,
  );

  const { isPreSale } = useGetInventoryPropertyInfo();
  const { isAbleToPrice, isLoading, priceCountdown } = usePriceCountdown();

  const {
    getMinimumPriceUtilityQuery: { status },
    isUtilityInitialNull,
  } = usePriceUtility({
    price: deferredMinPublicationPriceValue,
  });

  const hasErrors = Object.keys(errors).length > 0;

  useEffect(() => {
    if (hasErrors) {
      reset(undefined, {
        keepDirtyValues: true,
        keepErrors: true,
        keepValues: true,
      });
    }
  }, [hasErrors]);

  if (isPreSale) {
    return <CopyToDisablePrice />;
  }

  if (!isAbleToPrice && !leader && !isLoading && priceCountdown > 0) {
    return <CopyCountdownPrice priceCountdown={priceCountdown} />;
  }

  if (status === "uninitialized") {
    return (
      <WrapperButton>
        <Button type="submit" className="w-100 rounded-5 p-3" disabled>
          Guardar
        </Button>
      </WrapperButton>
    );
  }

  if (status !== "fulfilled") {
    return (
      <WrapperButton>
        <Button type="submit" className="w-100 rounded-5 p-3" disabled>
          Validando utilidad...
        </Button>
      </WrapperButton>
    );
  }

  if (isUtilityInitialNull) {
    return (
      <WrapperButton>
        <Button type="submit" className="w-100 rounded-5 p-3" disabled>
          Utilidad no calculada
        </Button>
      </WrapperButton>
    );
  }

  return (
    <WrapperButton>
      <Button
        type="submit"
        className="w-100 rounded-5 p-3"
        disabled={isSubmitted || hasErrors}
      >
        {isSubmitted ? "Guardando" : "Guardar"}
      </Button>
    </WrapperButton>
  );
};
