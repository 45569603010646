// Libraries.
import styled from "styled-components";

export const WrapperSearchInput = styled.div`
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const Line = styled.img`
  position: absolute;
  top: 6px;
  left: 40px;
`;
