import { WrapperFilterText, WrapperUsedFilters } from "./styles";

interface UsedFiltersCardProps {
  filters?: string;
  maxVal?: number;
  minVal?: number;
  subTitle?: string;
  title: string;
}

export const UsedFiltersCard = ({
  title,
  subTitle,
  filters,
  minVal,
  maxVal,
}: UsedFiltersCardProps) => {
  return (
    <div className="d-flex flex-column gap-1">
      <WrapperFilterText>
        <p>{title}</p>
        <span>
          {minVal} {subTitle} {subTitle && "/"} {maxVal} {subTitle}
        </span>
      </WrapperFilterText>
      <WrapperUsedFilters>
        <p className="placeholder-filter">{filters}</p>
      </WrapperUsedFilters>
    </div>
  );
};
