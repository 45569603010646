import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  OptionValue,
} from "@shared/Inputs/Dropdown";
import { useGetLeadDetailInfo } from "@store/apiSlices/leadDetail/leadDetail.hooks";
import { Form } from "react-bootstrap";
import { useNid } from "../../SearchByNid/nid-slice/useNid.hook";

export const DropdownBusinessOpportunities = () => {
  const {
    setBusinessOpportunity,
    businessOpportunity,
    setBusinessOpportunityIsConfirmed,
    businessOpportunityIsConfirmed,
  } = useNid();

  const {
    getLeadDetailInfoQuery: { data },
  } = useGetLeadDetailInfo();

  const defaultOption = businessOpportunity || {
    label: "Oportunidad de negocio",
    value: "businessOpportunity",
  };

  const options = data?.logic_doors;

  const handleChange = ({ value }: { value?: OptionValue }) => {
    if (!value) return;
    setBusinessOpportunity(value);
  };

  if (options?.length === 0)
    return (
      <h5 className="fw-bold">
        No hay opciones disponibles para precios de aprobación
      </h5>
    );

  return (
    <div className="d-flex gap-3 align-items-center">
      <div>
        <Dropdown value={defaultOption} onChange={handleChange}>
          <DropdownToggle
            style={{
              background: "white",
              border: "1px solid #949494",
              color: "black",
              fontWeight: "600",
              height: "42px",
            }}
            className="d-flex gap-2 align-items-center rounded-2 px-3"
            id="dropdownBusinessOpportunity"
            label="Oportunidad de negocio"
            showArrow
          />
          <DropdownMenu>
            {options?.map((option) => (
              <DropdownItem
                key={option.value}
                value={{
                  label: option.label,
                  value: option.value,
                }}
              >
                {option.label}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      </div>
      <Form.Group controlId="formBasicCheckbox" className="habi-checkbox">
        <Form.Check
          type="checkbox"
          label="haré esta oportunidad de negocio"
          style={{
            fontSize: "14px",
          }}
          onChange={() =>
            setBusinessOpportunityIsConfirmed(!businessOpportunityIsConfirmed)
          }
          checked={businessOpportunityIsConfirmed}
        />
      </Form.Group>
    </div>
  );
};
