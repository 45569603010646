import imagePin from "@assets/images/pin.png";
import { MarkerF } from "@react-google-maps/api";
import { useGetLeadDetailInfo } from "@store/apiSlices/leadDetail/leadDetail.hooks";

export const LeadLocation = () => {
  const { getLeadDetailInfoQuery } = useGetLeadDetailInfo();

  const coordinates = getLeadDetailInfoQuery.data?.location.coordinates;

  if (!coordinates) {
    return null;
  }

  const position = {
    lat: coordinates?.[0],
    lng: coordinates?.[1],
  };

  return <MarkerF icon={imagePin} position={position} />;
};
