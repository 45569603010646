// libraries.
import {
  markerHighConfidenceIcon,
  markerLowConfidenceIcon,
  markerMediumConfidenceIcon,
  markerNeverUseIcon,
} from "@assets";
import { MarkerF } from "@react-google-maps/api";

// hooks.
import { useGetHistoryLeadInfo } from "@store/apiSlices/historyLeadInfo/historyLeadInfo.hooks";

export const LeadComparables = () => {
  const { getHistoryLeadInfoQuery } = useGetHistoryLeadInfo();

  const comprables = getHistoryLeadInfoQuery.data?.used_comparables;

  if (!comprables?.length) {
    return null;
  }

  const comparableConfidence = (confidenceLevel: string) => {
    switch (confidenceLevel) {
      case "alta_confianza":
        return markerHighConfidenceIcon;
      case "media_confianza":
        return markerMediumConfidenceIcon;
      case "baja_confianza":
        return markerLowConfidenceIcon;
      case "nunca_usarla":
        return markerNeverUseIcon;
      default:
        return markerNeverUseIcon;
    }
  };

  return (
    <>
      {comprables.map((comprable) => {
        const position = {
          lat: comprable.latitude,
          lng: comprable.longitude,
        };

        const icon = comparableConfidence(comprable.confidence_levels);

        return (
          <MarkerF
            key={comprable.comparable_id}
            position={position}
            icon={icon}
          />
        );
      })}
    </>
  );
};
