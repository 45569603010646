// libraries.
import Card from "react-bootstrap/Card";

// context.
import { usePriceSummaryContext } from "@pages/PublicationPricePage/modules/PublicationPriceSummary/priceSummary-reducer/priceSummary.context";

// styles.
import { PercentileCard } from "@pages/PublicationPricePage/modules/PublicationPriceSummary/styles";
import {
  GridHeader,
  GridItem,
} from "../../../PublicationPriceSummaryMX/PriceInfoMX/components/styles";
import { GridContainerCO } from "../styles";

export const PercentileCalculatorCO = () => {
  const [{ percentileCalculatorValues }] = usePriceSummaryContext();

  return (
    <>
      <PercentileCard>
        <Card>
          <div className="p-3 border-bottom">
            <div className="title">Calculadora de Percentiles</div>
          </div>
          <GridContainerCO
            className="p-3 fw-bold"
            data-testId="grid-container-CO"
          >
            <div id="just-for-create-a-space" />
            <GridHeader className="text-muted row1">Precio mínimo</GridHeader>
            <GridHeader className="text-muted row2">
              Precio publicación
            </GridHeader>
            <GridHeader className="text-muted col1">
              Sobre precios absolutos
            </GridHeader>
            <GridItem data-testId="priceABSMin-CO">
              {percentileCalculatorValues?.priceABSMin}
            </GridItem>
            <GridItem data-testId="priceABSPublication-CO">
              {percentileCalculatorValues?.priceABSPublication}
            </GridItem>
            <GridHeader className="text-muted col2">
              Sobre valores de m²
            </GridHeader>
            <GridItem data-testId="priceMinM2-CO">
              {percentileCalculatorValues?.priceMinM2}
            </GridItem>
            <GridItem
              className="active-number"
              data-testId="priceM2Publication-CO"
            >
              {percentileCalculatorValues?.priceM2Publication}
            </GridItem>
          </GridContainerCO>
          <small className="px-3 pb-3 text-muted" data-testId="caption-CO">
            Se actualiza de acuerdo a los valores ingresados en precio de
            publicación y precio mínimo publicado
          </small>
        </Card>
      </PercentileCard>
    </>
  );
};
