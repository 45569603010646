// libraries.
import { Modal } from "react-bootstrap";

// slices.
import { modalOptions, useModalOptions } from "@store/slices/modals";

// components.
import { NotificationsHeader } from "./NotificationsHeader/NotificationsHeader";
import { NotificationsList } from "./NotificationsList/NotificationsList";

export const Notifications = () => {
  const { isModalOpen } = useModalOptions();

  const show = isModalOpen(modalOptions.modalInventoryPageNotifications);

  return (
    <Modal
      data-testid="ModalNotifications"
      show={show}
      centered={true}
      contentClassName="modal-content-left"
      dialogClassName="modal-dialog-left"
      className="mobile-modal modal-from-left p-0"
      style={{ left: "calc(74px)", width: "380px" }}
    >
      <div style={{ position: "sticky", top: 0, zIndex: 1000 }}>
        <NotificationsHeader />
      </div>
      <NotificationsList />
    </Modal>
  );
};
