// components.
import { CalculationsTabsCO } from "./CalculationsTabsCO";

// styles.
import { WrapperCalculation } from "../CalculationsTableMX/styles";

export const CalculationsTableCO = () => {
  return (
    <WrapperCalculation>
      <div className="w-100 py-4">
        <CalculationsTabsCO />
      </div>
    </WrapperCalculation>
  );
};
