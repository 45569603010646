import styled from "styled-components";
import { useGetHistoryLeadInfo } from "@store/apiSlices/historyLeadInfo/historyLeadInfo.hooks";
import { UsedComparables } from "@store/apiSlices/historyLeadInfo/historyLeadInfo.endpoints";
import { Badge } from "react-bootstrap";

const LeadConfidenceLevelsWrapper = styled.div`
  position: absolute;
  top: 18px;
  left: 200px;
`;

export const LeadConfidenceLevels = () => {
  const { getHistoryLeadInfoQuery } = useGetHistoryLeadInfo();

  const comprables = getHistoryLeadInfoQuery.data?.used_comparables;

  if (!comprables?.length) {
    return null;
  }

  function countConfidenceLevels(items: UsedComparables[]) {
    const confidenceCounts = {
      alta_confianza: 0,
      baja_confianza: 0,
      media_confianza: 0,
      nunca_usarla: 0,
    };

    if (!items?.length) {
      return confidenceCounts;
    }

    items.forEach((item) => {
      confidenceCounts[item.confidence_levels] += 1;
    });

    return confidenceCounts;
  }

  const counts = countConfidenceLevels(comprables);

  return (
    <LeadConfidenceLevelsWrapper className="d-flex gap-4">
      <Badge pill className="d-flex gap-2" bg="success">
        <span>{counts.alta_confianza}</span>
        <span>alta</span>
      </Badge>
      <Badge pill className="d-flex gap-2" bg="primary">
        <span>{counts.media_confianza}</span>
        <span>media</span>
      </Badge>
      <Badge pill className="d-flex gap-2" bg="warning">
        <span>{counts.baja_confianza}</span>
        <span>baja</span>
      </Badge>
      <Badge pill className="d-flex gap-2" bg="secondary">
        <span>{counts.nunca_usarla}</span>
        <span>no usar</span>
      </Badge>
    </LeadConfidenceLevelsWrapper>
  );
};
