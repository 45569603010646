// libraries.
import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

// store.
import { RootState } from "@store";

// actions.
import {
  closeModalOptions,
  closeThisModal,
  setModalOptions,
  modalOptions,
} from "./modals";

export const useModalOptions = <TArgs = unknown>() => {
  const dispatch = useDispatch();
  const modals = useSelector(
    (state: RootState) => state.modalOptionsReducer.modalOptions,
  );
  const args = useSelector(
    (state: RootState) => state.modalOptionsReducer.args as TArgs,
  );

  const openModal = useCallback(
    (option: modalOptions | modalOptions[], args?: TArgs) => {
      const modalOptionsWithArgs = { args, modalOptions: option };
      dispatch(setModalOptions(modalOptionsWithArgs));
    },
    [dispatch],
  );

  const closeModal = useCallback(
    (option: modalOptions) => {
      dispatch(closeThisModal(option));
    },
    [dispatch],
  );

  const closeAllModals = useCallback(() => {
    dispatch(closeModalOptions());
  }, [dispatch]);

  const isModalOpen = useCallback(
    (option: modalOptions) => {
      return modals.includes(option);
    },
    [modals],
  );

  return {
    args,
    closeAllModals,
    closeModal,
    isModalOpen,
    modals,
    openModal,
  };
};
