// libraries.
import { useNavigate } from "react-router-dom";

// components.
import { useSidebarContext } from "@shared/Sidebar";
import { SidebarButton } from "@shared/Sidebar/components/SidebarButton/SidebarButton";

// assets.
import { IconKanban } from "@assets";
import { pathInventory } from "@routes/routes.types";
import { ButtonSidebarInventory } from "../sidebar-inventory.utils";
import { pathInventorySummary } from "@pages/InventoryPage/routes/routes.types";
import { useGetLeadDetailInfo } from "@store/apiSlices/leadDetail/leadDetail.hooks";

export const ButtonDiagnosis = () => {
  const navigate = useNavigate();
  const { nidSelected } = useGetLeadDetailInfo();

  const {
    setSelectedButton,
    state: { selectedSidebarButton },
  } = useSidebarContext();

  const handleOnClick = () => {
    setSelectedButton(ButtonSidebarInventory.DIAGNOSIS);
    navigate(`${pathInventory}/${pathInventorySummary}/${nidSelected}`);
  };
  return (
    <SidebarButton
      hoverText="Diagnóstico"
      onClick={handleOnClick}
      selected={selectedSidebarButton === ButtonSidebarInventory.DIAGNOSIS}
    >
      <IconKanban fill="white" />
    </SidebarButton>
  );
};
