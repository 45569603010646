// slices.
import { apiInventoryPortal } from "../apiSlices";
import { ModelError } from "../interfaces/interfaces";

interface LimitPriceInfo {
  body: {
    is_valid: boolean;
    price: number;
  };
}

export const limitPrice = apiInventoryPortal.injectEndpoints({
  endpoints: (builder) => ({
    getValidateTope: builder.query({
      query: ({
        country,
        comparables,
        category,
        price,
        area,
        medianZone,
      }: {
        area?: number;
        category?: string;
        comparables?: string;
        country?: string;
        medianZone?: string | number;
        price?: number;
      }) => {
        return `/validate_tope?country=${country}&comparables=${comparables}&category=${category}&price=${price}&area=${area}&median_zone=${medianZone}`;
      },
      transformErrorResponse: (error: {
        data?: { error: string };
        status: number;
      }) => {
        const modelError: ModelError = {
          errorStatus: error.status,
          messageFromServer: error.data?.error,
          serviceErrorName: "getValidateTope",
        };
        return modelError;
      },

      transformResponse: (response: LimitPriceInfo) => {
        const { is_valid: isValidTope, price: priceTope } = response.body;
        return { isValidTope, priceTope };
      },
    }),
  }),
});

export const { useGetValidateTopeQuery, useLazyGetValidateTopeQuery } =
  limitPrice;
