export const commentExperienceOptions = [
  {
    id: "positive",
    label: "Positiva",
  },
  {
    id: "negative",
    label: "Negativa",
  },
  {
    id: "analytic",
    label: "Análisis",
  },
  {
    id: "visit",
    label: "Visita",
  },
];
