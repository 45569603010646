// components.
import { MinPublicationPriceMX } from "./components/MinPublicationPriceMX";
import { PercentageInPricesMX } from "./components/PercentageInPricesMX";
import { PublicationPriceMX } from "./components/PublicationPriceMX";

export const PublicationPriceInputsMX = () => {
  return (
    <>
      <div className="d-flex gap-1 flex-column mt-2">
        <div className="d-flex flex-column gap-2">
          <div className="d-flex flex-column gap-2 mb-3">
            <PublicationPriceMX />
          </div>
          <div className="d-flex flex-column gap-2 mb-1">
            <MinPublicationPriceMX />
          </div>
        </div>
      </div>
      <PercentageInPricesMX />
    </>
  );
};
