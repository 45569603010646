// components
import {
  TitleSummary,
  WrapperSummary,
} from "@shared/DashboardComponentsSummary";
import { PublicationPriceInputsMX } from "./components/PublicationPriceInputsMX/PublicationPriceInputsMX";

// shared
import {
  CommentInput,
  CommissionPercentageInput,
  SaveInventoryPricingButton,
} from "../../shared";
import { InputSelectLeader } from "../../shared/InputSelectLeader/InputSelectLeader";

export const PriceInventorySummaryMX = () => {
  return (
    <div className="d-flex flex-column h-100 justify-content-between">
      <WrapperSummary>
        <TitleSummary
          title="Precio y comisión"
          subtitle="Puedes editar los valores y guardar los cambios"
        />
        <InputSelectLeader />

        <PublicationPriceInputsMX />
        <CommissionPercentageInput />
      </WrapperSummary>
      <div className="d-flex flex-column gap-4">
        <div className="line" />
        <CommentInput />
        <SaveInventoryPricingButton />
      </div>
    </div>
  );
};
