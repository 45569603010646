// libraries.
import { useNavigate } from "react-router-dom";

// hooks.
import { useGetLeadDetailInfo } from "@store/apiSlices/leadDetail/leadDetail.hooks";
import { useSidebarContext } from "@shared/Sidebar";

// components.
import { SidebarButton } from "@shared/Sidebar/components/SidebarButton/SidebarButton";

// utils.
import { pathInventory } from "@routes/routes.types";
import { pathFeedbackLoop } from "@pages/InventoryPage/routes/routes.types";
import { ButtonSidebarInventory } from "../sidebar-inventory.utils";

// assets.
import { IconFeedchat } from "@assets";

export const ButtonFeedbackLoop = () => {
  const navigate = useNavigate();
  const { nidSelected } = useGetLeadDetailInfo();
  const {
    setSelectedButton,
    state: { selectedSidebarButton },
  } = useSidebarContext();

  const handleOnClick = () => {
    setSelectedButton(ButtonSidebarInventory.FEEDBACK_LOOP);
    navigate(`${pathInventory}/${pathFeedbackLoop}/${nidSelected}`);
  };
  return (
    <SidebarButton
      hoverText="Retroalimentación"
      onClick={handleOnClick}
      selected={selectedSidebarButton === ButtonSidebarInventory.FEEDBACK_LOOP}
    >
      <IconFeedchat fill="white" />
    </SidebarButton>
  );
};
