// libraries.
import { Outlet } from "react-router-dom";

// components.
import { SidebarInventory } from "./components/SidebarInventory/SideBarInventory";
import { DetailNavbar } from "@shared/DetailNavbar";

export const InventoryPage = () => {
  return (
    <div className="d-flex overflow-hidden h-100">
      <div style={{ width: "73px" }} />
      <SidebarInventory />
      <div
        className="d-flex flex-column w-100 h-100"
        data-testid="inventory-id"
      >
        <DetailNavbar data-testid="scroll-ref" />
        <Outlet />
      </div>
    </div>
  );
};
