// shared.
import {
  CardItem,
  Title,
  WrapperCardItems,
} from "@shared/DashboardComponentsSummary";

// hooks.
import { useGetInventoryPropertyInfo } from "@store/apiSlices/inventoryPropertyInfo/inventoryPropertyInfo.hook";

// utils.
import { formatPrice } from "@utils";

export const DiagnosisCards = () => {
  const {
    getInventoryPropertyInfoQuery: { data },
    dayWithdrawal,
    lastCommercialCycle,
  } = useGetInventoryPropertyInfo();

  const cardStyle = "rounded-3 card-price-info p-3 d-flex flex-column gap-2";

  const alertData = data?.alert ? data.alert : "No hay data";
  const daysFromAlert = data?.days_from_alert || 0;
  const financialDays = data?.financial_days || 0;
  const commercialDays = data?.commercial_days || 0;
  const totalBudget = data?.total_budget || 0;

  return (
    <div className="d-flex flex-column gap-3">
      <Title>Diagnóstico del inmueble</Title>
      <WrapperCardItems className="price-info-grid">
        <CardItem classNameData={`warning-shadow ${cardStyle}`}>
          <small className="title-card">Tipo de alerta</small>
          <div className="warning">{alertData}</div>
        </CardItem>
        <CardItem classNameData={`default-shadow ${cardStyle}`}>
          <small className="title-card">Días trans. desde la alerta </small>
          <div className="subtitle-card">{`${daysFromAlert} días`}</div>
        </CardItem>
        <CardItem classNameData={`default-shadow ${cardStyle}`}>
          <small className="title-card">Tiempo financiero</small>
          <div className="subtitle-card">{`${financialDays} días`}</div>
        </CardItem>
        <CardItem classNameData={`default-shadow ${cardStyle}`}>
          <small className="title-card">Tiempo Comercial</small>
          <div className="subtitle-card">{`${commercialDays} días`}</div>
        </CardItem>
        <CardItem classNameData={`default-shadow ${cardStyle}`}>
          <small className="title-card">Total presupuesto</small>
          <div className="subtitle-card">
            {totalBudget && formatPrice(totalBudget)}
          </div>
        </CardItem>
        <CardItem classNameData={`default-shadow ${cardStyle}`}>
          <small className="title-card">
            Holding comercial desde desistimiento
          </small>
          <div className="subtitle-card">{`${dayWithdrawal} días`}</div>
        </CardItem>
        <CardItem classNameData={`default-shadow ${cardStyle}`}>
          <small className="title-card">Ultimo ciclo comercial</small>
          <div className="subtitle-card">{`${lastCommercialCycle} días`}</div>
        </CardItem>
      </WrapperCardItems>
    </div>
  );
};
