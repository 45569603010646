import {
  Sidebar,
  SidebarButtonHome,
  SidebarProvider,
  useSidebar,
} from "@shared/Sidebar";
import { CountryDropdown } from "./components/CountryDropdown";
import { UserInfo } from "./components/UserInfo";
import { SearchLead } from "./components/SearchLead";
import { CloseSession } from "./components/CloseSession";
import { useEffect } from "react";
import { SIDEBAR_SEARCH_LEAD } from "./sidebarHome.types";
import { ButtonInventory } from "./components/ButtonInventory";
import { ButtonBulkUpload } from "./components/ButtonBulkUpload";
import { ButtonNotifications } from "./components/ButtonNotifications";

export const SidebarHome = () => {
  const sidebar = useSidebar();

  useEffect(() => {
    sidebar.setSelectedButton(SIDEBAR_SEARCH_LEAD);
  }, []);

  return (
    <SidebarProvider value={sidebar}>
      <Sidebar>
        <div className="d-flex flex-column h-100 justify-content-between">
          <div className="d-flex flex-column gap-3">
            <SidebarButtonHome />
            <SearchLead />
            <ButtonInventory />
            <ButtonBulkUpload />
          </div>
          <div className="d-flex flex-column gap-3">
            <ButtonNotifications />
            <CountryDropdown />
            <UserInfo />
            <CloseSession />
          </div>
        </div>
      </Sidebar>
    </SidebarProvider>
  );
};
