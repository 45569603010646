import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { apiInventoryPortal } from "../apiSlices";
import { ModelError } from "../interfaces/interfaces";

interface ListInventoryParams {
  alert: string;
  bucket: string;
  email: string;
  country?: string;
}

interface ItemInventoryResponse {
  address: string;
  alert: string;
  area: number;
  baths: number;
  bucket: number;
  complex: string;
  garage: number;
  last_publication_date: string;
  nid: number;
  postal_code: string;
  pre_sale: number;
  price: number;
  red_inventory: number;
  rooms: number;
}

interface ItemInventoryResponseTransformed {
  address: string;
  alert: string;
  area: number;
  baths: number;
  bucket: number;
  complex: string;
  garage: number;
  idKey: number;
  lastPublicationDate: string;
  nid: number;
  postalCode: string;
  preSale: number;
  price: number;
  redInventory: number;
  rooms: number;
}

interface ListInventoryResponse {
  body: ItemInventoryResponse[];
  code: number;
  success: boolean;
}

export const listInventory = apiInventoryPortal.injectEndpoints({
  endpoints: (builder) => ({
    getListInventory: builder.query({
      query: ({ country, alert, email, bucket }: ListInventoryParams) => {
        return `/get_list_inventory?country=${country}&alert=${alert}&bucket=${bucket}&email=${email}`;
      },

      transformErrorResponse: (error: FetchBaseQueryError) => {
        const modelError: ModelError = {
          errorStatus: error.status,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          messageFromServer: (error.data as any)?.message,
          serviceErrorName: "getListInventory",
        };
        return modelError;
      },

      transformResponse: (
        response: ListInventoryResponse,
      ): ItemInventoryResponseTransformed[] => {
        const { body } = response;

        const listInventoryInfo: ItemInventoryResponseTransformed[] = body.map(
          (item, i) => ({
            address: item.address,
            alert: item.alert,
            area: item.area,
            baths: item.baths,
            bucket: item.bucket,
            complex: item.complex,
            garage: item.garage,
            idKey: i + 1,
            lastPublicationDate: item.last_publication_date,
            nid: item.nid,
            postalCode: item.postal_code,
            preSale: item.pre_sale,
            price: item.price,
            redInventory: item.red_inventory,
            rooms: item.rooms,
          }),
        );

        return listInventoryInfo;
      },
    }),
  }),
});

export const { useGetListInventoryQuery } = listInventory;
