import { InventoryPortalRoute } from "@interfaces";
import { LeadDetail } from "../modules/LeadDetail";
import { ReleaseNotes } from "../modules/ReleaseNotes";
import { InventoryList } from "../modules/Inventory/InventoryList";
import { BulkUpload } from "../modules/BulkUpload/BulkUpload";
import {
  pathHomeBulkUpload,
  pathHomeInventoryList,
  pathHomeLeadDetail,
  pathHomeReleaseNotes,
} from "./routes.types";

export const routesHome: InventoryPortalRoute[] = [
  {
    element: <LeadDetail />,
    id: "leadDetail",
    path: pathHomeLeadDetail,
  },
  {
    element: <ReleaseNotes />,
    id: "releaseNotes",
    path: pathHomeReleaseNotes,
  },
  {
    element: <InventoryList />,
    id: "inventoryList",
    path: pathHomeInventoryList,
  },
  {
    element: <BulkUpload />,
    id: "bulkUpload",
    path: pathHomeBulkUpload,
  },
];
