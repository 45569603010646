// libraries.
import { useNavigate } from "react-router-dom";

// components.
import { SidebarButton } from "@shared/Sidebar/components/SidebarButton/SidebarButton";
import { useSidebarContext } from "@shared/Sidebar";

// hooks.
import {
  WrapperByUserRole,
  UserRoles,
} from "@shared/WrapperByUserRole/WrapperByUserRole";
import { modalOptions, useModalOptions } from "@store/slices/modals";

// types.
import { SIDEBAR_BUTTON_NOTIFICATIONS } from "../sidebarHome.types";

// assets.
import { pathHome } from "@routes/routes.types";
import { pathHomeInventoryList } from "@pages/HomePage/routes/routes.types";
import { IconNotification } from "@assets";
import { useNotificationsList } from "@store/apiSlices/notificationsInfo/notifications.hooks";

export const ButtonNotifications = () => {
  const navigate = useNavigate();

  const { notificationsLen } = useNotificationsList();

  const { openModal } = useModalOptions();

  const {
    setSelectedButton,
    state: { selectedSidebarButton },
  } = useSidebarContext();

  const handleOnClick = () => {
    setSelectedButton(SIDEBAR_BUTTON_NOTIFICATIONS);
    navigate(`${pathHome}/${pathHomeInventoryList}`);
    openModal(modalOptions.modalInventoryPageNotifications);
  };

  return (
    <WrapperByUserRole role={[UserRoles.INVENTORY, UserRoles.LEADER]}>
      <SidebarButton
        hoverText="Notificaciones"
        onClick={handleOnClick}
        selected={selectedSidebarButton === SIDEBAR_BUTTON_NOTIFICATIONS}
      >
        <span className="notification-button">
          <IconNotification fill="white" />
          {notificationsLen > 0 && (
            <span
              className="notification-count"
              data-testid="counter"
              style={{ fontSize: notificationsLen > 99 ? "10px" : "12px" }}
            >
              {notificationsLen}
            </span>
          )}
        </span>
      </SidebarButton>
    </WrapperByUserRole>
  );
};
