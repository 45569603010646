// hooks.
import { useComments } from "@store/apiSlices/feedbackLoop/comments/comments.hook";

export const AnalyticsComments = () => {
  const { comments } = useComments();

  const positiveComments = comments?.filter(
    (comment) => comment.commentExperience === "positive",
  );
  const negativeComments = comments?.filter(
    (comment) => comment.commentExperience === "negative",
  );
  const analyticComments = comments?.filter(
    (comment) => comment.commentExperience === "analytic",
  );

  const visitComments = comments?.filter(
    (comment) => comment.commentExperience === "visit",
  );

  const totalComments = comments?.length;
  const positivePercentage =
    (positiveComments?.length / totalComments) * 100 || 0;
  const negativePercentage =
    (negativeComments?.length / totalComments) * 100 || 0;
  const analyticPercentage =
    (analyticComments?.length / totalComments) * 100 || 0;
  const visitPercentage = (visitComments?.length / totalComments) * 100 || 0;

  return (
    <>
      <small
        className={`label-red simple-text d-flex gap-1 px-2 py-1 rounded-3`}
      >
        <div>Negativos: </div>
        <div>
          {negativePercentage.toFixed()}% ({negativeComments?.length})
        </div>
      </small>
      <small
        className={`label-blue simple-text d-flex gap-1 px-2 py-1 rounded-3`}
      >
        <div>Análisis: </div>
        <div>
          {analyticPercentage.toFixed()}% ({analyticComments?.length})
        </div>
      </small>
      <small
        className={`label-purple simple-text d-flex gap-1 px-2 py-1 rounded-3`}
      >
        <div>Visita: </div>
        <div>
          {visitPercentage.toFixed()}% ({visitComments?.length})
        </div>
      </small>
      <small
        className={`label-green simple-text d-flex gap-1 px-2 py-1 rounded-3`}
      >
        <div>Positivos: </div>
        <div>
          {positivePercentage.toFixed()}% ({positiveComments?.length})
        </div>
      </small>
    </>
  );
};
