// Endpoints.
import { LeadDetailInfo } from "@store/apiSlices/leadDetail/leadDetail.endpoints";
import {
  Agent,
  Antiquity,
  GrayWork,
  Location,
  PriceClient,
  PropertyType,
} from "./PropertyInfo";
import { formatPrice } from "@utils/formatPrice.utils";

type PropertyInfoLayoutProps = {
  data: LeadDetailInfo | undefined;
};
export const PropertyInfoLayout = ({ data }: PropertyInfoLayoutProps) => {
  return (
    <div className="d-flex gap-5">
      <div className="d-flex flex-column flex-fill gap-2 w-100">
        <Location location={data?.location} />
        <PropertyType
          typeProperty={data?.property?.property_type}
          houseComplex={data?.location?.house_complex}
          bigZone={data?.location?.big_zone}
        />
        <Antiquity antiquity={data?.property?.antiquity} />
      </div>
      <div className="d-flex flex-column flex-fill gap-2 w-100 mx-5">
        <PriceClient
          price={
            data?.property?.price?.last_price &&
            formatPrice(data?.property?.price?.last_price)
          }
        />
        <Agent name={data?.agent?.email.split("@")[0]} />
        <GrayWork grayWork={data?.property?.gray_work} />
      </div>
    </div>
  );
};
