// hooks.
import { useFeedbackRemo } from "@store/apiSlices/feedbackRemo/feedbackRemo.hook";

// components.
import { TableRows } from "../../../shared/TableRows";
import { TableLoading } from "@pages/InventoryPage/modules/FeedbackLook/components/TableFeedbackLook/components/TableStatus/components/TableLoading";
import { EmptyTickets } from "../../../shared/EmptyTickets";

// utils.
import { formatDateTime } from "../utils";
import { headersRemo, widths } from "../../../shared/utils";

export const RowsRemo = () => {
  const {
    getFeedbackRemoQuery: { data, isLoading },
    itemsFeedbackRemoLen,
  } = useFeedbackRemo();

  if (isLoading) {
    return <TableLoading />;
  }

  if (itemsFeedbackRemoLen === 0) {
    return <EmptyTickets />;
  }

  const remoRows =
    data?.feedbackRemo.map((item, i) => ({
      index: i + 1,
      entryDate: formatDateTime(item.entryDate),
      rating: item.rating,
      comment: item.comment,
      status: item.status,
      apartmentStatus: item.apartmentStatus || "No tiene",
      attendant: item.attendant,
      totalPrice: item.totalPrice,
      requestCreated: formatDateTime(item.requestCreated),
      reason: item.reason,
      requestStage: item.requestStage,
      priority: item.priority,
      creator: item.creator,
      contractorDate: formatDateTime(item.contractorDate),
      initialDate: formatDateTime(item.initialDate),
      activityType: item.activityType,
      lastUpdate: formatDateTime(item.lastUpdate),
    })) || [];

  return (
    <TableRows
      headers={headersRemo}
      rows={remoRows}
      widths={widths}
      style={{ width: "2700px" }}
    />
  );
};
