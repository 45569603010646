import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { apiInventoryPortal } from "../apiSlices";
import { ModelError } from "../interfaces/interfaces";
import { IncreasePricesTransformed } from "./utils";

interface IncreasePriceParams {
  category?: string;
  country?: string;
  price: number;
}

interface IncreasePrices {
  publication_price: number;
  minimum_publication_price: number;
  increase_im_publication_price: number;
  increase_im_min_publication_price: number;
  increase_ranges_publication_price: number;
  increase_revenue_publication_price: number;
}

interface IncreasePricesResponse {
  body: IncreasePrices;
  code: number;
  success: boolean;
}

export const increasePrices = apiInventoryPortal.injectEndpoints({
  endpoints: (builder) => ({
    getIncreasePrices: builder.query({
      query: ({ category, country, price }: IncreasePriceParams) => {
        return `/increase_approval_price?category=${category}&country=${country}&price=${price}`;
      },
      transformErrorResponse: (error: FetchBaseQueryError) => {
        const modelError: ModelError = {
          errorStatus: error.status,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          messageFromServer: (error.data as any)?.message,
          serviceErrorName: "getIncreasePrices",
        };
        return modelError;
      },
      transformResponse: (response: IncreasePricesResponse) => {
        const modalIncreasePrices = response.body;

        const increasePricesInfo: IncreasePricesTransformed = {
          increaseIMPublicationPrice:
            modalIncreasePrices.increase_im_publication_price,
          increaseImMinPublicationPrice:
            modalIncreasePrices.increase_im_min_publication_price,
          increaseRangesPublicationPrice:
            modalIncreasePrices.increase_ranges_publication_price,
          increaseRevenuePublicationPrice:
            modalIncreasePrices.increase_revenue_publication_price,
          minimumPublicationPrice:
            modalIncreasePrices.minimum_publication_price,
          publicationPrice: modalIncreasePrices.publication_price,
        };
        return increasePricesInfo;
      },
    }),
  }),
});

export const { useGetIncreasePricesQuery, useLazyGetIncreasePricesQuery } =
  increasePrices;
