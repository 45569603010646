// hooks.
import { useGetHistoryLeadInfo } from "@store/apiSlices/historyLeadInfo/historyLeadInfo.hooks";

// components.
import { UsedFiltersCard } from "@shared/UsedFiltersCard/UsedFilters";

// styles.
import { WrapperFilters } from "../UsedFiltersCO/styles";
import { ContainerFilters } from "./styles";

// utils.
import { getAreaRange, getLabelUsedFilters } from "../../utils";

export const UsedFiltersMX = () => {
  const {
    getHistoryLeadInfoQuery: { data },
  } = useGetHistoryLeadInfo();

  const usedFilters = data?.used_filters;
  return (
    <WrapperFilters>
      <div
        className="d-flex flex-column p-4 bg-light"
        style={{ width: "270px" }}
      >
        <div className="filters-title">Filtros</div>
      </div>
      <div
        className="overflow-y-scroll"
        style={{ height: "calc(100vh - 150px)" }}
      >
        <div className="p-4 d-flex flex-column gap-2">
          <ContainerFilters>
            <div className="null-filter">
              <p>Antigüedad</p>
              <span>
                Null:{" "}
                {getLabelUsedFilters(usedFilters?.years_old_null) === "Null"
                  ? "Activo"
                  : "Inactivo"}
              </span>
            </div>
            <div className="antiquity">
              <div className="price-filter">{usedFilters?.years_old_min}</div>
              <div className="price-filter">{usedFilters?.years_old_max}</div>
            </div>
          </ContainerFilters>
          <UsedFiltersCard
            title="Área"
            subTitle="m²"
            minVal={usedFilters?.area_min}
            maxVal={usedFilters?.area_max}
            filters={getAreaRange(usedFilters)}
          />
          <UsedFiltersCard
            title="Fecha del comparable"
            filters={getLabelUsedFilters(usedFilters?.from_date_create)}
          />
          <UsedFiltersCard
            title="Tipo de inmueble"
            filters={getLabelUsedFilters(usedFilters?.property_type_id)}
          />
          <UsedFiltersCard
            title="Baños completos"
            filters={getLabelUsedFilters(usedFilters?.baths)}
          />
          <UsedFiltersCard
            title="Habitaciones"
            filters={getLabelUsedFilters(usedFilters?.rooms)}
          />
          <UsedFiltersCard
            title="Estacionamiento"
            filters={getLabelUsedFilters(usedFilters?.garage)}
          />
          <UsedFiltersCard
            title="Elevador"
            filters={getLabelUsedFilters(usedFilters?.elevator)}
          />
        </div>
      </div>
    </WrapperFilters>
  );
};
