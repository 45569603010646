// libraries.
import {
  electricPurple600,
  robotoRegular,
  scarpaGray50,
  tomatoCoral300,
  tomatoCoral50,
} from "@habitech/shared";
import styled from "styled-components";

export const WrapperTable = styled.div`
  font-size: 12px;
  font-family: ${robotoRegular};
  th {
    font-family: ${robotoRegular};
    font-weight: 800;
  }
  .sticky-numb {
    position: sticky;
    left: 0;
    background-color: #fff;
    z-index: 1;
    background: ${scarpaGray50};
    width: 28px;
  }
  .id {
    position: sticky;
    left: 30px;
    background-color: #fff;
    z-index: 1;
    width: 96px;
  }
  .outlier-numb {
    position: sticky;
    left: 0;
    z-index: 1;
    background-color: ${tomatoCoral300};
    width: 28px;
  }
  .outlier-id {
    position: sticky;
    left: 30px;
    z-index: 1;
    background-color: ${tomatoCoral50};
    width: 96px;
  }
  .table-responsive {
    overflow-y: auto;
    max-height: calc(100vh - 220px);
  }
`;

export const WrapperCalculation = styled.div`
  font-size: 14px;
  font-family: ${robotoRegular};
`;

export const WrapperTabs = styled.div`
  .nav-link {
    color: ${electricPurple600};
    font-weight: 700;
    padding: 16px 12px;
  }

  .tab-content {
    width: calc(100vw - 75px);
  }
`;
