// slices.
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { apiInventoryPortal } from "../apiSlices";
import { ModelError } from "../interfaces/interfaces";
interface MinimumPriceUtility {
  country?: string;
  nid?: string;
  price: number;
  category?: string;
}
interface MinimumPriceUtilityResponse {
  body: {
    current_utility: number;
    initial_utility: number;
  };
  code: number;
  success: boolean;
}
export const minimumPriceUtility = apiInventoryPortal.injectEndpoints({
  endpoints: (builder) => ({
    getMinimumPriceUtility: builder.query({
      query: ({ country, nid, price, category }: MinimumPriceUtility) => {
        const paramCategory = category ? `&category=${category}` : "";
        return `/get_utility?country=${country}&nid=${nid}&price=${price}${paramCategory}`;
      },
      transformErrorResponse: (error: FetchBaseQueryError) => {
        const modelError: ModelError = {
          errorStatus: error.status,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          messageFromServer: (error.data as any)?.message,
          serviceErrorName: "getMinimumPriceUtility",
        };
        return modelError;
      },
      transformResponse: (response: MinimumPriceUtilityResponse) => {
        const currentMinimumPriceUtility = response.body;
        return { currentMinimumPriceUtility };
      },
    }),
  }),
});
export const {
  useGetMinimumPriceUtilityQuery,
  useLazyGetMinimumPriceUtilityQuery,
} = minimumPriceUtility;
