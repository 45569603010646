// libraries.
import { toast } from "react-toastify";
import { useEffect } from "react";

// hooks.
import { useUserLeadDetail } from "@shared/hooks/userLeadDetail.hook";
import { useUpdateMassivePricesMutation } from "./massiveLoad.endpoints";
import { FileTypeData } from "@pages/HomePage/modules/BulkUpload/bulkUpload-slice/bulkUpload.slice";

export const useUpdatePrices = () => {
  const { country, email } = useUserLeadDetail();

  const [mutateUpdatePrices, mutateUpdatePricesQuery] =
    useUpdateMassivePricesMutation();

  const updatePrices = (listPrices: FileTypeData[]) => {
    if (listPrices && country) {
      mutateUpdatePrices({
        agent: email,
        country: country,
        list_prices: listPrices,
      });
    }
  };

  const TOAST_MESSAGES = {
    error: "Error al actualizar precios",
    loading: "Actualizando precios masivos...",
    success: "Precios actualizados correctamente",
  };

  const TOAST_IDS = {
    loading: "massivePriceUpdateLoading",
  };

  useEffect(() => {
    if (mutateUpdatePricesQuery.isLoading) {
      toast.loading(TOAST_MESSAGES.loading, {
        autoClose: false,
        toastId: TOAST_IDS.loading,
      });
    } else if (mutateUpdatePricesQuery.isSuccess) {
      toast.dismiss(TOAST_IDS.loading);
      toast.success(TOAST_MESSAGES.success);
    } else if (mutateUpdatePricesQuery.error) {
      toast.error(TOAST_MESSAGES.error);
      toast.dismiss(TOAST_IDS.loading);
    } else {
      toast.dismiss(TOAST_IDS.loading);
    }
  }, [mutateUpdatePricesQuery]);

  return {
    mutateUpdatePricesQuery,
    updatePrices,
  };
};
