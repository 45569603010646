// components.
import { Marker } from "@shared/GoogleMap/components/Marker";

// hooks.
import { useGetLeadDetailInfo } from "@store/apiSlices/leadDetail/leadDetail.hooks";

export const MarkerZone = () => {
  const { leadLatLng } = useGetLeadDetailInfo();

  return <Marker position={leadLatLng} />;
};
