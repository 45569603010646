interface BucketProps {
  bucket: number;
  redInventory: number;
}

export const BucketTag = ({ bucket, redInventory }: BucketProps) => {
  const isRed = redInventory === 1;

  return isRed ? (
    <div className="badge pill badge-bucket mt-1">{`${bucket} días`}</div>
  ) : null;
};
