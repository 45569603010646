// libraries
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface DropdownCategoryState {
  selectedCategoryFromUser: string;
}

export const initialStateDropdownCategory: DropdownCategoryState = {
  selectedCategoryFromUser: "",
};

const sharedDropdownCategorySlice = createSlice({
  initialState: initialStateDropdownCategory,
  name: "dropdownCategory",
  reducers: {
    setCategory: (state, action: PayloadAction<string>) => {
      state.selectedCategoryFromUser = action.payload;
    },
  },
});

export const { setCategory } = sharedDropdownCategorySlice.actions;

export default sharedDropdownCategorySlice.reducer;
