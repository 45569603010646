import { createGlobalStyle } from "styled-components";
import monserratRegular from "./fonts/Montserrat-Regular.ttf";
import monserratBold from "./fonts/Montserrat-Bold.ttf";
import monserratLight from "./fonts/Montserrat-Light.ttf";
import monserratSemiBold from "./fonts/Montserrat-SemiBold.ttf";
import monserratBlack from "./fonts/Montserrat-Black.ttf";
import robotoRegular from "./fonts/Roboto-Regular.ttf";
import {
  electricPurple500,
  electricPurple600,
  white,
  scarpaGray50,
  tomatoCoral500,
  turquoise600,
  electricPurple50,
  skyBlue500,
  electricPurple300,
  scarpaGray500,
} from "@habitech/shared";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Montserrat';
    src: url(${monserratRegular}) format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Montserrat Bold';
    src: url(${monserratBold}) format('truetype');
    font-weight: 700; 
    font-style: normal;
  }

  @font-face {
    font-family: 'Montserrat Light';
    src: url(${monserratLight}) format('truetype');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Montserrat SemiBold';
    src: url(${monserratSemiBold}) format('truetype');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'Montserrat Black';
    src: url(${monserratBlack}) format('truetype');
    font-weight: 900;
    font-style: normal;
  }

  @font-face {
    font-family: 'Roboto';
    src: url(${robotoRegular}) format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  body {
    font-family: 'Montserrat', sans-serif; 
  }

  p, h1, h2, h3, h4, h5 {
    margin: 0;
  }

  .habi-checkbox .form-check-input:checked {
    background-color: ${electricPurple500} !important;
    border-color: ${electricPurple500} !important; 
  }

  .pointer {
    cursor: pointer;
  }

  .loading-spinner {
    height: 30px;
    width: 30px;
    border: 4px solid #ccc;
    border-right-color: #673ab7;
    border-radius: 50%;
    animation: spin 1.5s linear infinite;
    position: relative;
    margin: auto;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Ocultar las flechas para los campos de tipo número en Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .ph-white::placeholder {
    color: white;
    opacity: 0.7;
  }

  // font styles

  .titles{
    font-family: 'Montserrat SemiBold';
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
  }

  .simple-text{
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
  }

  .sub-titles{
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: ${scarpaGray500}
  }
  

  // modals
  .modal {
    overflow: hidden;
  }
  .modal-dialog-right {
    margin-right: initial;
    overflow: auto;
    margin-top: initial;
    height: 100%;
    max-width: 500px!important;
  }
  .modal-content-right {
    border-radius: 0;
    overflow: auto;
    height: 100%;
  }
  .modal-dialog-left {
    margin-left: initial;
    overflow: auto;
    margin-top: initial;
    height: 100%;
    max-width: 500px !important;
    display: flex;
    flex-direction: column;
  }
  
  .modal-content-left {
    border-radius: 0;
    height: 100%;
  }
  
  .modal-from-left.fade .modal-dialog {
    transform: translateX(-500px);
    transition: transform 0.3s ease-in-out;
  }
  
  .modal-from-left.show .modal-dialog {
    transform: translateX(0);
  }
  

  @media (min-width: 575px) { 
  .modal-from-right.fade .modal-dialog{
    transform: translate(500px);
    transition:transform 0.2s ease-in-out;
  }  
  .modal-from-right.show .modal-dialog{
    transform: none;
  }

  // buttons.
  .btn-habi{
    font-size: 15px;
    font-family: 'Roboto';
    src: url(${robotoRegular}) format('truetype');
    background-color: ${electricPurple500};
    border: none;
  }
  .btn-link-habi:hover{
    background-color: ${electricPurple600};
  }
  .btn-habi:hover{
    background-color: ${electricPurple600};
  } 
  .btn-light-habi{
    background-color: ${white};
    border: 1px solid ${scarpaGray50};
    color: ${electricPurple500};
  }
  .btn-light-habi:hover{
    background-color: ${electricPurple500};
    color: ${white};
  }
.btn-secundary-habi{
    font-size: 15px;
    font-family: 'Roboto';
    src: url(${robotoRegular}) format('truetype');
    background-color: ${white};
    border: none;
    color: ${electricPurple500};
}
.btn-secundary-habi:hover {
    background-color: ${electricPurple50};
    border: none;
    color: ${electricPurple500};
}

  // background labels
  .label-red{
    background-color: ${tomatoCoral500};
    color: ${white};
  }
  .label-green{
    background-color: ${turquoise600};
    color: ${white};
  }
  .label-blue {
    background-color: ${skyBlue500};
    color: ${white};
  }
  .label-purple {
    background-color: ${electricPurple300};
    color: ${white};
  }
}
`;

export default GlobalStyle;
