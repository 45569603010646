// libraries.
import { useFormContext } from "react-hook-form";
import { useDebounce } from "@uidotdev/usehooks";

import { WrapperCardItems } from "@shared/DashboardComponentsSummary";
import { PercentileCalculator } from "@shared/PercentileCalculator/PercentileCalculator";

export const InventoryPercentileCalculator = () => {
  const { watch } = useFormContext();

  const publicationPriceInput = watch("publicationPrice");
  const minPublicationPriceInput = watch("minPublicationPrice");

  const deferredPublicationPrice = useDebounce(publicationPriceInput, 1000);
  const deferredMinPublicationPrice = useDebounce(
    minPublicationPriceInput,
    1000,
  );

  return (
    <WrapperCardItems className="price-info-grid">
      <PercentileCalculator
        price={deferredPublicationPrice}
        priceMin={deferredMinPublicationPrice}
      />
    </WrapperCardItems>
  );
};
