// libraries..
import styled from "styled-components";
import { monserratSemiBold, robotoRegular } from "@habitech/shared";

export const WrapperSummary = styled.div`
  font-size: 14px;
  font-family: ${robotoRegular};
  .title {
    font-size: 16px;
    font-weight: 600;
    font-family: ${monserratSemiBold};
  }
  input {
    text-align: end;
    border: none;
    outline: none;
  }
  .subtitle {
    font-size: 12px;
  }
`;
