export const percentageToApplyInRelatedPricesCO = 5;
export const percentageToApplyInRelatedPricesMX = 5;

// Mx. percentage limits
export const percentageToApplyGretter2Million = 4;
export const percentageToApplyLess2Million = 3;

// Mx. percentage applied to inputs
export const percentageUp = 4;
export const percentageDown = 3;

export const calculatePercentageInPrice = (
  price: string,
  add: boolean,
  percentageToApply: number,
) => {
  const priceNumber = Number(price);
  const percentageToAdd = priceNumber * (percentageToApply / 100);
  const newPrice = add
    ? priceNumber + percentageToAdd
    : priceNumber - percentageToAdd;
  return Number(newPrice.toFixed(2));
};
