// libraries.
import { Button, Modal } from "react-bootstrap";
import { useFormContext } from "react-hook-form";

// hooks.
import { modalOptions, useModalOptions } from "@store/slices/modals";
import { IFormCreateComment } from "../ModalCreateComment";
import { ModalArchived } from "../../ModalArchived/ModalArchived";

export const ActionsToCreateComment = () => {
  const { watch, reset } = useFormContext<IFormCreateComment>();

  const commentId = watch("commentId");
  const hasCommentId =
    commentId !== undefined && commentId !== null && commentId !== 0;

  const { closeModal, openModal, modals } = useModalOptions();

  return (
    <Modal.Footer className="d-flex border-0 p-0">
      <div className="d-flex w-100 gap-2">
        {hasCommentId && (
          <Button
            data-testid="archiving-modal-btn"
            className="d-flex w-100 justify-content-center fw-bold btn-secundary-habi"
            variant="primary"
            onClick={() => {
              const modalsAux = modals.concat([
                modalOptions.modalInventoryPageArchivedComment,
              ]);
              openModal(modalsAux);
            }}
          >
            Archivar
          </Button>
        )}
        <ModalArchived />
        <Button
          type="submit"
          className="d-flex w-100 justify-content-center fw-bold border-5 btn-habi"
          variant="primary"
          data-testid="save-formBtn"
        >
          {!hasCommentId && "Agregar"}
          {hasCommentId && "Guardar"}
        </Button>
      </div>

      <div className="d-flex gap-2 w-100">
        <Button
          onClick={() => {
            closeModal(modalOptions.modalInventoryPageCreateComment);
            reset();
          }}
          className="d-flex w-100 text-white justify-content-center fw-bold btn-link-habi"
          variant="link"
          aria-label="Cancelar comentario"
        >
          Cancelar
        </Button>
      </div>
    </Modal.Footer>
  );
};
