// libraries.
import { useEffect } from "react";
import { Container } from "react-bootstrap";

// components.
import { InventoryCards } from "@pages/HomePage/modules/Inventory/components/CardInventoryManagerCO/InventoryCards";
import { InventoryBar } from "./components/InventoryBar/InventoryBar";
import { WrapperInventoryCards } from "./components/CardInventoryManagerCO/style";
import { useModuleInventoryList } from "./inventoryList-slice/inventory.hook";
import { Notifications } from "../Notifications/components/Notifications";

export const InventoryList = () => {
  const { clearState } = useModuleInventoryList();

  useEffect(() => {
    return () => clearState();
  }, []);

  return (
    <div className="d-flex flex-column w-100 h-100">
      <InventoryBar />
      <Notifications />
      <WrapperInventoryCards>
        <Container fluid className="p-4" data-testid="cards">
          <InventoryCards />
        </Container>
      </WrapperInventoryCards>
    </div>
  );
};
