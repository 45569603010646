// components
import { Card } from "react-bootstrap";

interface CardItemProps {
  children: React.ReactNode;
  classNameData?: string;
  style?: React.CSSProperties;
  testId?: string;
}

export const CardItem = ({
  children,
  classNameData,
  testId,
  style,
}: CardItemProps) => {
  const classData =
    classNameData || "rounded-3 card-price-info p-3 d-flex flex-column gap-2";
  return (
    <Card data-testid={testId} className={classData} style={style}>
      {children}
    </Card>
  );
};
