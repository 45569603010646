// libraries.
import { useFormContext } from "react-hook-form";

// components.
import { CardPriceInfo } from "../PriceInfoCO";

// assets.
import { IconWarning } from "@assets";
import { actualWarning } from "./utils";

// hooks.
import { useDebounce } from "@uidotdev/usehooks";
import { useGetHistoryLeadInfo } from "@store/apiSlices/historyLeadInfo/historyLeadInfo.hooks";
import { useGetPublicationPriceInfo } from "@store/apiSlices/pricing/pricing.hooks";
import { usePriceUtility } from "@store/apiSlices/utilityService/priceUtility.hook";
import { useDropdownCategory } from "@shared/DropdownCategories/dropdownCategory-slice/dropdownCategory.hook";
import { CardDropdownCategories } from "@shared/DropdownCategories";

export const CardUtility = () => {
  const { watch } = useFormContext();
  const {
    getPublicationPriceInfoQuery: { data: publicationPriceInfo },
  } = useGetPublicationPriceInfo();

  const minPublicationPriceInput = watch("minPublicationPrice");

  const deferredMinPublicationPriceValue = useDebounce(
    minPublicationPriceInput,
    1000,
  );
  const { selectedCategoryFromUser } = useDropdownCategory();

  const showCategoryFromUserCO = selectedCategoryFromUser ? (
    <span className="fw-bold">({selectedCategoryFromUser})</span>
  ) : null;

  const {
    getMinimumPriceUtilityQuery: { isFetching },
    utilityInitial,
  } = usePriceUtility({
    price: deferredMinPublicationPriceValue,
  });

  const revenueStandard = publicationPriceInfo?.revenueStandard;

  const {
    getHistoryLeadInfoQuery: { data },
  } = useGetHistoryLeadInfo();

  const createdAt = data?.created_at;

  const businessDate = "2023-10-13";

  type RevenueCategory = "Basic" | "Bone" | "Prime" | "Regular" | "Subprime";

  const warningThresholds: Record<
    RevenueCategory,
    { max: number; min: number }
  > = {
    Basic: { max: 5.4, min: 5 },
    Bone: { max: 5, min: -Infinity },
    Prime: { max: Infinity, min: 7.5 },
    Regular: { max: 6.4, min: 5.5 },
    Subprime: { max: 7.4, min: 6.5 },
  };

  const showWarning = () => {
    if (
      utilityInitial === undefined ||
      !revenueStandard ||
      revenueStandard === "No tiene"
    )
      return false;
    const thresholds =
      createdAt && createdAt > businessDate ? actualWarning : warningThresholds;
    const { min, max } = thresholds[revenueStandard as RevenueCategory] || {};

    return utilityInitial > min && utilityInitial <= max;
  };

  const isWarning = showWarning();

  if (isFetching)
    return (
      <CardPriceInfo>
        <small className="title-card">Utilidad {showCategoryFromUserCO}</small>
        <div className="d-flex justify-content-between align-items-center">
          <div className="subtitle-card fw-bold">Cargando...</div>
        </div>
      </CardPriceInfo>
    );

  if (utilityInitial === null)
    return (
      <>
        <CardPriceInfo>
          <small className="title-card">
            Utilidad {showCategoryFromUserCO}
          </small>
          <div className="d-flex justify-content-between align-items-center">
            <div className="subtitle-card fw-bold">No se pudo calcular</div>
          </div>
        </CardPriceInfo>
        <CardDropdownCategories />
      </>
    );

  return (
    <CardPriceInfo>
      <small className="title-card">Utilidad {showCategoryFromUserCO}</small>
      <div className="d-flex justify-content-between align-items-center">
        <div
          className={
            isWarning ? "fw-bold text-warning" : "subtitle-card fw-bold"
          }
        >
          {utilityInitial || 0}%
        </div>
        {isWarning && <IconWarning />}
      </div>
    </CardPriceInfo>
  );
};
