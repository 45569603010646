// slices.
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@store/index";
import {
  clearBulkUploadState,
  setFileData,
  setNidsNotUpdated as setNotUpdatedNids,
} from "./bulkUpload.slice";

export const useModuleHomeBulkUpload = () => {
  const dispatch = useDispatch();

  const { fileName, dataError, dataSuccess, hasFile, nidsNotUpdated } =
    useSelector((state: RootState) => {
      return state.moduleHomeBulkUploadReducer;
    });

  const updateFileData = (data: unknown) => {
    dispatch(setFileData(data));
  };

  const clearState = () => {
    dispatch(clearBulkUploadState());
  };

  const setNidsNotUpdated = (nids: string[]) => {
    dispatch(setNotUpdatedNids(nids));
  };

  return {
    clearState,
    dataError,
    dataSuccess,
    fileName,
    hasFile,
    nidsNotUpdated,
    setNidsNotUpdated,
    updateFileData,
  };
};
