// libraries.
import { Modal } from "react-bootstrap";
import GoogleMap from "@shared/GoogleMap/GoogleMap";
import {
  monserratSemiBold,
  robotoRegular,
  scarpaGray500,
  skyBlue100,
  skyBlue700,
} from "@habitech/shared";

// hooks.
import { MarkerZone } from "../ModalZone/components/MarkerZone";
import { useComments } from "@store/apiSlices/feedbackLoop/comments/comments.hook";
import { useGetLeadDetailInfo } from "@store/apiSlices/leadDetail/leadDetail.hooks";
import { modalOptions, useModalOptions } from "@store/slices/modals";

// components.

import { BadgeCommentCategory } from "../../shared/BadgeCommentCategory";
import { BadgeCommentExperience } from "../../shared/BadgeCommentExperience";
import { HexagonsZone } from "./components/HexagonsZone";

interface IModalZone {
  commentId: number | null;
}

export const ModalGetZone = () => {
  const { comments } = useComments();
  const { isModalOpen, closeModal, args } = useModalOptions<IModalZone>();

  const commentZone = comments.find(
    (comment) => comment.commentId === args?.commentId,
  );
  const zone = commentZone?.commentType === "zone" && "Zona";

  const { leadLatLng } = useGetLeadDetailInfo();

  const show = isModalOpen(modalOptions.modalInventoryPageGetZone);

  const handleClose = () => {
    closeModal(modalOptions.modalInventoryPageGetZone);
  };

  return (
    <Modal show={show} size="xl" onHide={handleClose}>
      <Modal.Header closeButton className="px-4">
        <Modal.Title>
          <div className="d-flex gap-2 align-items-center">
            <div
              style={{
                fontFamily: monserratSemiBold,
                fontSize: "14px",
              }}
              className="fw-bold pb-1"
            >
              {commentZone?.user}
            </div>
            <small className="text-muted simple-text">
              {commentZone?.datePosted}
            </small>
          </div>
          <div className="d-flex gap-2">
            <small
              className="rounded simple-text py-1 px-2"
              style={{ backgroundColor: skyBlue100, color: skyBlue700 }}
            >
              {zone} | {commentZone?.radius}m
            </small>
            <BadgeCommentExperience
              experience={commentZone?.commentExperience}
            />
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0" style={{ height: "500px" }}>
        <GoogleMap
          defaultCenter={leadLatLng}
          containerStyle={{
            height: "100%",
            width: "100%",
          }}
          defaultZoom={17}
        >
          <MarkerZone />

          <HexagonsZone hashList={commentZone?.hashList} />
        </GoogleMap>
      </Modal.Body>
      <Modal.Footer className="d-flex flex-column align-items-start px-4">
        <small
          style={{
            color: `${scarpaGray500}`,
            margin: "0",
          }}
        >
          <BadgeCommentCategory
            commentCategory={commentZone?.commentCategory}
          />
        </small>
        <small
          style={{
            fontFamily: robotoRegular,
          }}
          className="m-0"
        >
          {commentZone?.commentText}
        </small>
      </Modal.Footer>
    </Modal>
  );
};
