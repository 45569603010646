// libraries.
import { scarpaGray25 } from "@habitech/shared";

// components.
import { UploadButton } from "./components/UploadButton";

// styles.
import { WrapperBulkUpload } from "./styles";
import { TableFileData } from "./components/TableFileData";
import { Container } from "react-bootstrap";
import { DeleteFile } from "./components/DeleteFile";
import { SaveData } from "./components/SaveData";

export const BulkUpload = () => {
  return (
    <div
      className="d-flex flex-column w-100 h-100"
      style={{ background: scarpaGray25 }}
    >
      <WrapperBulkUpload>
        <div className="w-100 h-100 d-flex flex-column">
          <Container fluid className="py-4 px-3">
            <div className="d-flex justify-content-between align-items-center">
              <div className="title">Cambios de precios masivos</div>
              <DeleteFile />
              <SaveData />
            </div>
          </Container>
          <UploadButton />
          <TableFileData />
        </div>
      </WrapperBulkUpload>
    </div>
  );
};
