// components.
import { TitleFeedbackLook } from "./components/TittleFeedbackLook/TitleFeedbackLook";
import { TableFeedbackLook } from "./components/TableFeedbackLook/TableFeedbackLook";
import { ModalCreateComment } from "./components/ModalCreateComment/ModalCreateComment";
import { ModalGetZone } from "./components/ModalGetZone/ModalGetZone";
import { scarpaGray50 } from "@habitech/shared";

export const FeedbackLook = () => {
  return (
    <div
      className="m-0 pe-0 ps-4"
      data-testid="feedback-look-page"
      style={{
        backgroundColor: scarpaGray50,
      }}
    >
      <TitleFeedbackLook />
      <TableFeedbackLook />
      <ModalCreateComment />
      <ModalGetZone />
    </div>
  );
};
