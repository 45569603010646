// libraries.
import { Badge } from "react-bootstrap";

// utils.
import { getBadgeColor } from "./utils";

interface SourceBadgeTagsProps {
  children?: React.ReactNode;
  className?: string;
  color?: string;
  style?: React.CSSProperties;
}

export const SourceBadgeTags = ({
  children,
  color,
  className,
  style,
}: SourceBadgeTagsProps) => {
  const badgeColor = getBadgeColor(color);
  return (
    <Badge pill bg={badgeColor} className={className} style={style}>
      {children}
    </Badge>
  );
};
