// libraries.
import { Card, Col, Row } from "react-bootstrap";
import { formatPrice } from "@utils/formatPrice.utils";

// styles.
import { CardManager } from "./style";
import { WrapperLoader } from "@shared/styles";
import { Link } from "react-router-dom";
import { pathInventory } from "@routes/routes.types";
import { pathInventorySummary } from "@pages/InventoryPage/routes/routes.types";

// components.
import { BucketTag } from "./components/BucketTag";
import { PreSaleTag } from "./components/PreSaleTag";

// hooks.
import { useListInventory } from "@store/apiSlices/listInventory/listInventory.hooks";

export const InventoryCards = () => {
  const { listInventoryData, loadingData } = useListInventory();

  const isBathOrBaths = (baths: number) =>
    baths === 1 ? `${baths} Baño` : `${baths} Baños`;

  if (loadingData) {
    return (
      <WrapperLoader data-testid="loader">
        <div className="loading-spinner" />
      </WrapperLoader>
    );
  }

  return (
    <Row>
      {listInventoryData.map((item) => (
        <Col lg={3} key={item.idKey} className="mb-3">
          <Link
            to={`${pathInventory}/${pathInventorySummary}/${item.nid}`}
            className="rounded-3 text-decoration-none "
            role="button"
          >
            <Card
              className="flex-column p-3 rounded-3 gap-2 d-flex align-items-right h-100 border-0"
              style={{
                boxShadow: "0px 0px 8px 0px rgba(51, 51, 51, 0.16)",
              }}
            >
              <CardManager>
                <div className="d-flex gap-5 justify-content-between">
                  <div className="nid-and-info">{item.nid}</div>
                  <div className="date">
                    Publicación: {item.lastPublicationDate}
                  </div>
                </div>
                <div className="flex-column">
                  <div className="card-price">
                    {!!item.price && formatPrice(item.price)}
                  </div>
                  <div className="complex-name mt-1">
                    {item.postalCode ? `CP: ${item.postalCode} - ` : ""}
                    {item.complex || "S/C"}
                  </div>
                  <div className="address">{item.address}</div>
                  <div className="d-flex gap-2 nid-and-info mt-1">
                    <small>{`${item.area} m² •`}</small>
                    <small>{`${item.rooms} Hab. •`}</small>
                    <small>{`${isBathOrBaths(item.baths)} •`}</small>
                    <small>{`${item.garage} Est.`}</small>
                  </div>
                  <div className="d-flex gap-1">
                    <div className="badge pill badge-inventory mt-1">
                      {item.alert}
                    </div>
                    <BucketTag
                      bucket={item.bucket}
                      redInventory={item.redInventory}
                    />
                    <PreSaleTag preSale={item.preSale} />
                  </div>
                </div>
              </CardManager>
            </Card>
          </Link>
        </Col>
      ))}
    </Row>
  );
};
