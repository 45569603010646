import {
  IconAgent,
  IconAntiquity,
  IconGrayWork,
  IconLocation,
  IconPriceClient,
  IconPropertyType,
} from "@assets";

// Hooks.
import { useUserProfile } from "@shared/GoogleLoginButton/user-slice/useUserProfile.hook";
import { WrapperPropertyInfo } from "../PropertyFeatures/styles";

interface PropertyInfoFeatureProps {
  nid?: number;
}

interface LocationProps extends PropertyInfoFeatureProps {
  location:
    | {
        address?: string;
        city?: {
          label?: string;
        };
        delegation?: string;
        exterior_number?: string;
        house_complex?: string;
        zip_code?: string;
      }
    | undefined;
}

interface PropertyTypeProps extends PropertyInfoFeatureProps {
  bigZone: string | undefined;
  houseComplex: string | undefined;
  typeProperty: string | undefined;
}

interface AntiquityProps extends PropertyInfoFeatureProps {
  antiquity: number | undefined;
}

interface PriceClientProps extends PropertyInfoFeatureProps {
  price?: number | string;
}

interface AgentProps extends PropertyInfoFeatureProps {
  name: string | undefined;
}

interface GrayWorkProps extends PropertyInfoFeatureProps {
  grayWork: number | undefined;
}

export const Location = ({ location }: LocationProps) => {
  const { user: country } = useUserProfile();
  const addressProperty =
    country?.country === "MX"
      ? `${location?.address} ${
          location?.delegation === null ? "" : location?.delegation
        } ${location?.exterior_number} ${
          location?.zip_code ? "C.P.:" : ""
        } ${location?.zip_code}`
      : `${location?.address}`;
  return (
    <div className="d-flex align-items-center gap-2">
      <IconLocation />
      <WrapperPropertyInfo>
        <div className="fw-bold">{location?.city?.label}</div>
        <div className="value-property text-nowrap">{addressProperty}</div>
      </WrapperPropertyInfo>
    </div>
  );
};

export const PropertyType = ({
  typeProperty,
  houseComplex,
  bigZone,
}: PropertyTypeProps) => {
  const { user: country } = useUserProfile();
  return (
    <div className="d-flex align-items-center gap-2">
      <IconPropertyType />
      {country?.country === "MX" ? (
        <WrapperPropertyInfo>
          <div>Tipo de vivienda</div>
          <div className="value-property text-nowrap">{typeProperty}</div>
        </WrapperPropertyInfo>
      ) : (
        <WrapperPropertyInfo>
          <div className="fw-bold">{typeProperty}</div>
          <div className="value-property text-nowrap">
            {houseComplex} • {bigZone}
          </div>
        </WrapperPropertyInfo>
      )}
    </div>
  );
};

export const Antiquity = ({ antiquity }: AntiquityProps) => (
  <div className="d-flex align-items-center gap-2">
    <IconAntiquity />
    <WrapperPropertyInfo>
      <div className="fw-bold">Antigüedad</div>
      <div className="value-property">
        {antiquity} {antiquity !== 1 ? "años" : "año"}
      </div>
    </WrapperPropertyInfo>
  </div>
);

export const PriceClient = ({ price }: PriceClientProps) => (
  <div className="d-flex align-items-center gap-2">
    <IconPriceClient />
    <WrapperPropertyInfo>
      <div className=" text-nowrap">Precio del cliente</div>
      <div className="value-property">{price}</div>
    </WrapperPropertyInfo>
  </div>
);

export const Agent = ({ name }: AgentProps) => (
  <div className="d-flex align-items-center gap-2">
    <IconAgent />
    <WrapperPropertyInfo>
      <div>Agente asignado</div>
      <div className="value-property">{name ? name : "Sin asignar"}</div>
    </WrapperPropertyInfo>
  </div>
);

export const GrayWork = ({ grayWork }: GrayWorkProps) => (
  <div className="d-flex align-items-center gap-2">
    <IconGrayWork />
    <WrapperPropertyInfo>
      <div>Obra gris</div>
      <div className="value-property">
        {(grayWork === 0 && "No") ||
          (grayWork === 1 && "Si") ||
          (grayWork === null && "Sin info")}
      </div>
    </WrapperPropertyInfo>
  </div>
);
