import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

// apis.
import {
  apiInventoryPortal,
  apiAuth,
  apiPortalPricing,
} from "./apiSlices/apiSlices";

// slices.
import userSlice from "@shared/GoogleLoginButton/user-slice/userSlice";
import nidSlice from "@pages/HomePage/components/NavBarUser/SearchByNid/nid-slice/nid.slice";
import modalOptionsSlice from "@store/slices/modals/modals";
import commentsSlice from "./apiSlices/feedbackLoop/comments/comments.service";

// modules slices.
import pageModuleFeedbackLookSlice from "@pages/InventoryPage/modules/FeedbackLook/slice/pageModuleFeedbackLook.slice";
import moduleHomeBulkUploadSlice from "@pages/HomePage/modules/BulkUpload/bulkUpload-slice/bulkUpload.slice";
import moduleInventoryListSlice from "@pages/HomePage/modules/Inventory/inventoryList-slice/moduleInventoryList.slice";
import sharedDropdownCategorySlice from "@shared/DropdownCategories/dropdownCategory-slice/dropdownCategory.slice";
import moduleNotificationsSlice from "@pages/HomePage/modules/Notifications/notifications-slice/moduleNotifications.slice";

const persistConfig = {
  key: "store_global",
  storage,
  whitelist: ["userReducer", "nidReducer", "commentsReducer"],
};

const rootReducer = combineReducers({
  // apis.
  [apiInventoryPortal.reducerPath]: apiInventoryPortal.reducer,
  [apiAuth.reducerPath]: apiAuth.reducer,
  [apiPortalPricing.reducerPath]: apiPortalPricing.reducer,
  // slices.
  commentsReducer: commentsSlice,
  modalOptionsReducer: modalOptionsSlice,
  moduleHomeBulkUploadReducer: moduleHomeBulkUploadSlice,
  moduleInventoryListReducer: moduleInventoryListSlice,
  moduleNotificationsReducer: moduleNotificationsSlice,
  nidReducer: nidSlice,
  pageModuleFeedbackLookReducer: pageModuleFeedbackLookSlice,
  sharedDropdownCategoryReducer: sharedDropdownCategorySlice,
  userReducer: userSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export type RootState = ReturnType<typeof rootReducer>;

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        immutableCheck: false,
        serializableCheck: false,
      },
    }).concat(
      apiInventoryPortal.middleware,
      apiAuth.middleware,
      apiPortalPricing.middleware,
    ),
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
