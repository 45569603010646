// libraries.
import styled from "styled-components";
import { robotoRegular } from "@habitech/shared";

export const SpaceButtons = styled.div`
  display: flex;
  width: 100%;
  cursor: pointer;

  &.selected {
    background: linear-gradient(90deg, #4a0199 0%, #9634ff 100%);
    border: 1px solid #7c01ff;
    box-shadow: 0px 4px 24px rgba(51, 51, 51, 0.24);
    border-radius: 8px;
  }
  &:hover {
    background: #4a0199;
    border-radius: 8px;
  }
  .hover-button {
    font-family: ${robotoRegular};
    font-size: 12px;
    transition: max-width 1s ease-in-out;

    div {
      opacity: 0;
      visibility: hidden;
      transition:
        opacity 0.3s ease-in-out,
        visibility 0.3s ease-in-out;
    }
  }

  .notification-button {
    position: relative;
    display: flex;
    align-items: center;
  }

  .notification-count {
    position: absolute;
    top: -11px;
    right: -9px;
    background-color: rgba(255, 89, 88, 1);
    color: white;
    border-radius: 50%;
    padding: 4px;
    line-height: 1;
    height: 23px;
    min-width: 23px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
