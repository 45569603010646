// hooks.
import { useUserProfile } from "@shared/GoogleLoginButton/user-slice/useUserProfile.hook";
import { useGetLeadDetailInfoQuery } from "./leadDetail.endpoints";
import { useNid } from "@pages";
import { useParams } from "react-router-dom";

export const useGetLeadDetailInfo = () => {
  const paths = useParams();
  const { nid } = useNid();
  const { user: country } = useUserProfile();

  const nidSelected = paths.nid ? paths.nid : nid;
  const shouldFetch = nidSelected && country.country;

  const getLeadDetailInfoQuery = useGetLeadDetailInfoQuery(
    {
      country: country.country,
      nid: nidSelected,
    },
    { skip: !shouldFetch },
  );

  const area = getLeadDetailInfoQuery.data?.property?.area ?? 0;
  const location = getLeadDetailInfoQuery.data?.location?.coordinates;
  const medianZone = getLeadDetailInfoQuery.data?.location?.median_zone ?? "";
  const medianZoneId =
    getLeadDetailInfoQuery.data?.location?.median_zone_id ?? 0;
  const latitude = location ? location[0] : 0;
  const longitude = location ? location[1] : 0;

  const leadLatLng = location
    ? {
        lat: location[0],
        lng: location[1],
      }
    : { lat: 0, lng: 0 };

  return {
    area,
    getLeadDetailInfoQuery,
    latitude,
    leadLatLng,
    longitude,
    medianZone,
    medianZoneId,
    nidSelected,
  };
};
