// libraries.
import Card from "react-bootstrap/Card";
import { useEffect } from "react";
import { useDebounce } from "@uidotdev/usehooks";
import { useFormContext } from "react-hook-form";

// hooks.
import { useUserProfile } from "@shared/GoogleLoginButton/user-slice/useUserProfile.hook";
import { useGetLeadDetailInfo } from "@store/apiSlices/leadDetail/leadDetail.hooks";
import { useGetHistoryLeadInfo } from "@store/apiSlices/historyLeadInfo/historyLeadInfo.hooks";
import { useGetPublicationPriceInfo } from "@store/apiSlices/pricing/pricing.hooks";
import { useLazyGetPercentileCalculatorQuery } from "@store/apiSlices/percentileCalculator/percentileCalculator.endpoints";

// styles.
import {
  GridContainer,
  GridHeader,
  GridItem,
} from "../../../../PublicationPriceSummaryMX/PriceInfoMX/components/styles";
import { PercentileCard } from "@pages/PublicationPricePage/modules/PublicationPriceSummary/styles";

export const PercentileCalculatorMX = () => {
  const { country } = useUserProfile();
  const { area } = useGetLeadDetailInfo();
  const { watch } = useFormContext();

  const {
    getPublicationPriceInfoQuery: { data },
  } = useGetPublicationPriceInfo();

  const {
    getHistoryLeadInfoQuery: { data: historyLeadInfo },
  } = useGetHistoryLeadInfo();
  const comparablesInfoUsed = historyLeadInfo?.used_comparables?.map(
    ({ price_per_m2, last_ask_price }) => ({
      price_m2: price_per_m2,
      sale_value: last_ask_price,
    }),
  );

  const [mutatePercentileCalculator, { data: calculatorValues }] =
    useLazyGetPercentileCalculatorQuery();

  const originalMinimumPriceInput = watch("originalMinimumPrice");

  const deferredValue = useDebounce(originalMinimumPriceInput, 1000);

  const originalMinimumPriceM2 =
    Number(originalMinimumPriceInput ?? data?.originalMinimumPrice) / area;

  const comparablesToJsonString = JSON.stringify(comparablesInfoUsed);
  const comparablesToEncodedString = encodeURIComponent(
    comparablesToJsonString,
  );

  useEffect(() => {
    if (deferredValue) {
      mutatePercentileCalculator({
        comparables: comparablesToEncodedString,
        country: country,
        minPublicationPriceAbs: Number(originalMinimumPriceInput),
        minPublicationPriceM2: originalMinimumPriceM2,
        publicationPriceAbs: Number(originalMinimumPriceInput),
        publicationPriceM2: originalMinimumPriceM2,
      });
    }
  }, [deferredValue]);

  return (
    <PercentileCard>
      <Card className="rounded-3 card-price-info d-flex flex-column p-0">
        <div className="p-3 border-bottom">
          <div className="title">Calculadora de percentiles</div>
        </div>
        <GridContainer className="p-3 fw-bold" data-testid="grid-container">
          <div />
          <GridHeader className="text-muted row1">
            Precio mínimo original
          </GridHeader>
          <GridHeader className="text-muted col1">
            Sobre precios absolutos
          </GridHeader>
          <GridItem data-testid="priceABSMin">
            {calculatorValues?.minPublicationPrice.abs || 0}
          </GridItem>
          <GridHeader className="text-muted col1">
            Sobre valores de m²
          </GridHeader>
          <GridItem className="active-number" data-testid="priceMinM2">
            {calculatorValues?.minPublicationPrice.m2 || 0}
          </GridItem>
        </GridContainer>
        <small className="px-3 pb-3 text-muted">
          Se actualiza de acuerdo al valor ingresado en el precio mínimo
          original.
        </small>
      </Card>
    </PercentileCard>
  );
};
