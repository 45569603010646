// utils.
import { useFeedbackBrokers } from "@store/apiSlices/feedbackRemo/feedbackBrokers.hooks";
import { headersBrokers, widths } from "../../../shared/utils";
import { formatDateTime, transformCategory } from "../../TableRemo/utils";
import { UserImage } from "@pages/InventoryPage/modules/FeedbackLook/shared/UserImage";

// components.
import { TableRows } from "../../../shared/TableRows";
import { TableLoading } from "@pages/InventoryPage/modules/FeedbackLook/components/TableFeedbackLook/components/TableStatus/components/TableLoading";
import { EmptyTickets } from "../../../shared/EmptyTickets";

export const RowsBrokers = () => {
  const { brokersTickets, isLoadingBrokers, itemsFeedbackBrokersLen } =
    useFeedbackBrokers();

  if (isLoadingBrokers) {
    return <TableLoading />;
  }

  if (itemsFeedbackBrokersLen === 0) {
    return <EmptyTickets />;
  }

  const brokersRows =
    brokersTickets?.map((item, i) => ({
      index: i + 1,
      dateCreate: formatDateTime(item.dateCreate),
      brokerName: <UserImage nameUser={item.broker.brokerName} />,
      brokerContact: (
        <div>
          <div>{item.broker.brokerPhone || "No tiene"}</div>
          <div>{item.broker.brokerEmail}</div>
        </div>
      ),
      category: transformCategory(item.category) || "No tiene",
      comment: item.comment || "No tiene",
    })) || [];

  return (
    <TableRows headers={headersBrokers} rows={brokersRows} widths={widths} />
  );
};
