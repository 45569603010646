// libraries.
import { createContext, useState } from "react";
import { scarpaGray50 } from "@habitech/shared";
import { OptionValue } from "@shared/Inputs/Dropdown";

// components.
import { ComparablesTableByCountry } from "./components/ComparablesTable";
import { CalculationsInfoByCountry } from "./components/CalculatiosInfo";

export const CalculationsTableContextCO = createContext(
  {} as CalculationsTableProps,
);
const { Provider } = CalculationsTableContextCO;

interface CalculationsTableProps {
  categoryZone: OptionValue;
  setCategoryZone: (value: OptionValue) => void;
}

export const PublicationPriceCalculations = () => {
  const [categoryZone, setCategoryZone] = useState<OptionValue>({
    label: "Zona",
    value: "Zona",
  });
  return (
    <Provider value={{ categoryZone, setCategoryZone }}>
      <div
        className="d-flex flex-column justify-content-between h-100 ps-3"
        style={{ backgroundColor: `${scarpaGray50}` }}
      >
        <div>
          <ComparablesTableByCountry />
        </div>
        <div className="w-100">
          <CalculationsInfoByCountry />
        </div>
      </div>
    </Provider>
  );
};
