// Slices
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { apiPortalPricing } from "../apiSlices";
import { ModelError } from "../interfaces/interfaces";

interface AgentStatusResponse {
  agent_status: string;
  current_deal_business_status: string;
  nid: string;
  status: string;
}

export const statusPricingByAgent = apiPortalPricing.injectEndpoints({
  endpoints: (builder) => ({
    getStatusPricingByAgent: builder.query({
      query: ({ country, email }: { country?: string; email?: string }) => {
        return `/agent_status?country=${country}&agent=${email}`;
      },
      transformErrorResponse: (error: FetchBaseQueryError) => {
        const modelError: ModelError = {
          errorStatus: error?.status,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          messageFromServer: (error?.data as any)?.message,
          serviceErrorName: "getAgentStatus",
        };
        return modelError;
      },
      transformResponse: (response: AgentStatusResponse) => {
        const res = {
          agentStatus: response.agent_status,
          currentDealBusinessStatus: response.current_deal_business_status,
          nid: response.nid,
          status: response.status,
        };
        return res;
      },
    }),
  }),
});

export const { useGetStatusPricingByAgentQuery } = statusPricingByAgent;
