// libraries.
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { OptionValue } from "@shared/Inputs/Dropdown";

interface NidState {
  businessOpportunity?: OptionValue | null;
  businessOpportunityIsConfirmed: boolean;
  nid?: string;
}

export const initialState: NidState = {
  businessOpportunity: null,
  businessOpportunityIsConfirmed: false,
  nid: "",
};

const nidSlice = createSlice({
  initialState,
  name: "nid",
  reducers: {
    clearNid: () => initialState,
    setBusinessOpportunity: (
      state,
      action: PayloadAction<OptionValue | null>,
    ) => {
      state.businessOpportunity = action.payload;
    },
    setBusinessOpportunityIsConfirmed: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.businessOpportunityIsConfirmed = action.payload;
    },
    setNid: (state, action: PayloadAction<string>) => {
      state.nid = action.payload;
    },
  },
});

export const {
  clearNid,
  setNid,
  setBusinessOpportunity,
  setBusinessOpportunityIsConfirmed,
} = nidSlice.actions;

export default nidSlice.reducer;
