// libraries.
import { useEffect } from "react";

import {
  SidebarProvider,
  useSidebar,
  Sidebar,
  SidebarButtonHome,
} from "@shared/Sidebar";
import { ButtonMap } from "./components/ButtonMap";
import { ButtonSummary } from "./components/ButtonSummary";
import { CalculationsViewer } from "./components/ButtonCalculations";
import { SIDEBAR_PUBLICATION_PRICE_MAP } from "./sidebarPublicationPrice.types";
import { useUserProfile } from "@shared/GoogleLoginButton/user-slice/useUserProfile.hook";

export const SidebarPublicationPrice = () => {
  const sidebar = useSidebar();
  const { country } = useUserProfile();

  useEffect(() => {
    sidebar.setSelectedButton(SIDEBAR_PUBLICATION_PRICE_MAP);
  }, []);

  const sideBarStyles = country === "CO" ? "position-fixed z-3" : "";

  return (
    <SidebarProvider value={sidebar}>
      <Sidebar className={sideBarStyles}>
        <div data-testid="sidebar-approval">
          <div className="d-flex flex-column gap-3 h-100 justify-content-between">
            <div className="d-flex flex-column gap-3">
              <SidebarButtonHome />
              <ButtonMap />
              <CalculationsViewer />
              <ButtonSummary />
            </div>
          </div>
        </div>
      </Sidebar>
    </SidebarProvider>
  );
};
