// libraries.
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// hooks.
import { pathFeedbackRemo } from "@pages/InventoryPage/routes/routes.types";
import { pathInventory } from "@routes/routes.types";
import { useUserLeadDetail } from "@shared/hooks/userLeadDetail.hook";
import { useNotificationsList } from "@store/apiSlices/notificationsInfo/notifications.hooks";
import { NotificationsInfoTransformed } from "@store/apiSlices/notificationsInfo/notificationsInfo.endpoints";
import { useGetNotificationsUpdateMutation } from "@store/apiSlices/notificationsInfo/notificationsUpdate.endpoint";

// components.
import { CheckboxItem } from "@shared/Inputs/Dropdown/components/CheckboxItem";

// styles.
import { WrapperCardNotification } from "./styles";
import { NotificationIcon } from "./components/NotificationIcon";
import { IconWarning } from "@assets";

export const CardNotification = () => {
  const navigate = useNavigate();
  const { country } = useUserLeadDetail();
  const [mutateNotifications, { isSuccess, isError, isLoading }] =
    useGetNotificationsUpdateMutation();

  const [notificationsList, setNotificationsList] = useState<
    NotificationsInfoTransformed[]
  >([]);

  const hasNotificationsSelected = notificationsList.some(
    (notification) => notification.selected,
  );

  const {
    getNotificationsInfo,
    notificationsList: notifications,
    notificationsLen,
  } = useNotificationsList();

  useEffect(() => {
    setNotificationsList(notifications);
  }, [notifications]);

  const handleCheckNotification = (ticketId: number) => {
    const newNotificationsList = notificationsList.map((notification) => {
      if (notification.ticketId === ticketId) {
        return {
          ...notification,
          selected: !notification.selected,
        };
      }
      return notification;
    });

    setNotificationsList(newNotificationsList);
  };

  const handleUpdateNotifications = () => {
    const notificationIds = notificationsList
      .filter((notification) => notification.selected)
      .map((notification) => notification.ticketId);

    mutateNotifications({ country, notificationIds });
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Notificaciones actualizadas correctamente");
      toast.dismiss("loading-notifications");
      setNotificationsList([]);
      getNotificationsInfo.refetch();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      toast.error("Ocurrió un error al actualizar las notificaciones");
      toast.dismiss("loading-notifications");
    }
  }, [isError]);

  useEffect(() => {
    if (isLoading) {
      toast.loading("Actualizando notificaciones... xxx", {
        toastId: "loading-notifications",
      });
    }
  }, [isLoading]);

  if (notificationsLen === 0) return null;
  const complexInfo =
    country === "CO" ? "Sin nombre del conjunto" : "Sin nombre de colonia";

  return (
    <>
      {notificationsList.map((notification) => {
        return (
          <WrapperCardNotification
            key={notification.ticketId}
            className="p-4 border-bottom d-flex flex-column gap-1"
          >
            <div className="d-flex justify-content-between">
              <div className="d-flex gap-2 align-items-center">
                <div>
                  {notification.ticketStatus === "pending" && (
                    <CheckboxItem
                      id={`${notification.ticketId}`}
                      checked={notification.selected}
                      onChange={() =>
                        handleCheckNotification(notification.ticketId)
                      }
                      className="checkbox"
                      dataTestId={`checkbox-notification-${notification.ticketId}`}
                    />
                  )}
                  {notification.ticketStatus === "saw" && (
                    <div className="circle-saw" />
                  )}
                </div>
                <div className="title">{notification.nid}</div>
              </div>
              <div className="date d-flex align-items-center">
                {notification.creationDate}
              </div>
            </div>
            <div className="d-flex gap-1 align-items-center">
              {notification.ticketType === "budget" && <IconWarning />}
              {notification.ticketType !== "budget" && (
                <NotificationIcon notification={notification} />
              )}
              <div className="complex">
                {notification.complexName || complexInfo}
              </div>
            </div>
            {notification.ticketType === "price" && (
              <div className="comment">
                El precio se ha actualizado correctamente.
              </div>
            )}
            {notification.ticketType === "budget" && (
              <div
                className="budget-route fw-bold"
                dangerouslySetInnerHTML={{ __html: notification.comment }}
              />
            )}
            {notification.ticketType !== "budget" && (
              <div className="comment">{notification.comment}</div>
            )}
            <div className="d-flex justify-content-between">
              <div className="title">{notification.ticketLabel} </div>
              <div
                onClick={() => {
                  mutateNotifications({
                    country,
                    notificationIds: [notification.ticketId],
                  });
                  getNotificationsInfo.refetch();
                  navigate(
                    `${pathInventory}/${pathFeedbackRemo}/${notification.nid}?tab=${notification.ticketType}`,
                  );
                }}
                className="fw-bold link pointer"
                data-testid={`notification-link-${notification.ticketId}`}
              >
                Ver detalle
              </div>
            </div>
          </WrapperCardNotification>
        );
      })}
      {hasNotificationsSelected && (
        <div className="fixed-bottom w-100 bg-white px-4 py-3">
          <Button
            variant="primary"
            className="w-100 btn-habi"
            onClick={handleUpdateNotifications}
          >
            Marcar como leído
          </Button>
        </div>
      )}
    </>
  );
};
