// libraries.
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export enum ViewCommentsType {
  TABLE_COMMENTS = "tableComments",
  CARD_COMMENTS = "cardComments",
}

export enum ClickedTapType {
  ARCHIVED_COMMENTS = "archivedComments",
  NON_ARCHIVED_COMMENTS = "nonArchivedComments",
}

interface InitialStatePageModuleFeedbackLook {
  clickedTapType: ClickedTapType;
  viewCommentsType: ViewCommentsType;
}

const initialStatePageModuleFeedbackLook: InitialStatePageModuleFeedbackLook = {
  clickedTapType: ClickedTapType.NON_ARCHIVED_COMMENTS,
  viewCommentsType: ViewCommentsType.TABLE_COMMENTS,
};

export const pageModuleFeedbackLookSlice = createSlice({
  initialState: initialStatePageModuleFeedbackLook,
  name: "pageModuleFeedbackLook",
  reducers: {
    setClickedTapType: (state, action: PayloadAction<ClickedTapType>) => {
      state.clickedTapType = action.payload;
    },
    setViewCommentsType: (state, action: PayloadAction<ViewCommentsType>) => {
      state.viewCommentsType = action.payload;
    },
  },
});

export const { setViewCommentsType, setClickedTapType } =
  pageModuleFeedbackLookSlice.actions;

export default pageModuleFeedbackLookSlice.reducer;
