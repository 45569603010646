// hooks.
import { UserRoles } from "@shared/WrapperByUserRole/WrapperByUserRole";
import { useRoleByUser } from "@store/apiSlices/roles/role.hook";
import { formatPrice } from "@utils";
import {
  PriceRange,
  isPriceWithinPercentageRange,
} from "@utils/priceUtils.ts/isPriceWithinPercentageRange";

export const useApplyRangeToPublicationPrice = () => {
  const { userRole } = useRoleByUser();

  const applyRangeToPublicationPrice = ({
    defaultPrice,
    leader,
    price,
  }: {
    defaultPrice: number;
    leader: string;
    price: number;
  }) => {
    const defaultPublicationPriceFormatted = formatPrice(defaultPrice);
    if (userRole === UserRoles.LEADER || leader) return;
    const priceInRange = isPriceWithinPercentageRange(price, defaultPrice, 5);
    if (priceInRange === PriceRange.UP) {
      return `El precio original (${defaultPublicationPriceFormatted}) no puede superar el 5% de incremento`;
    } else if (priceInRange === PriceRange.DOWN) {
      return `El precio original (${defaultPublicationPriceFormatted}) no puede superar el 5% de descuento`;
    }
  };

  return { applyRangeToPublicationPrice };
};
