import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { apiInventoryPortal } from "../../apiSlices";
import { ModelError } from "../../interfaces/interfaces";
import { CommentStatus, IComment } from "./comments.service";

interface ICommentParams {
  country?: string;
  nid?: string;
}

interface ICommentFromBack {
  agent: {
    email: string;
    id: number;
    name: string;
  };
  category: string;
  comment: string;
  comment_id: number;
  created_at: string;
  experience: string;
  hash_list: string[];
  last_updated: string;
  nid: number;
  radius: number;
  status: string;
  type: string;
}

interface ICommentsResponse {
  body: ICommentFromBack[];
  code: number;
  success: boolean;
}

export const comments = apiInventoryPortal.injectEndpoints({
  endpoints: (builder) => ({
    getComments: builder.query({
      query: ({ country, nid }: ICommentParams) => {
        return `/feedback_loop?country=${country}&nid=${nid}`;
      },
      transformErrorResponse: (error: FetchBaseQueryError) => {
        const modelError: ModelError = {
          errorStatus: error.status,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          messageFromServer: (error.data as any)?.message,
          serviceErrorName: "getComments",
        };
        return modelError;
      },
      transformResponse: (response: ICommentsResponse) => {
        const comments: IComment[] = response.body.map((comment) => {
          return {
            commentCategory: comment.category,
            commentExperience: comment.experience,
            commentId: comment.comment_id,
            commentStatus: comment.status as CommentStatus,
            commentText: comment.comment,
            commentType: comment.type,
            datePosted: comment.created_at,
            dateUpdated: comment.last_updated,
            email: comment.agent.email,
            hashList: comment.hash_list || [],
            nid: comment.nid,
            radius: comment.radius,
            user: comment.agent.name,
            userId: comment.agent.id,
          };
        });
        return { comments };
      },
    }),
  }),
});

export const { useGetCommentsQuery } = comments;
