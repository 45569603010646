import { useUserProfile } from "@shared/GoogleLoginButton/user-slice/useUserProfile.hook";
import { useGetIncreasePricesQuery } from "./increaseApprovalPrice.endpoints";
import { useGetPublicationPriceInfo } from "../pricing/pricing.hooks";

interface IIncreaseApprovalPriceHook {
  priceMinimumOriginal: number;
}

export const useIncreaseApprovalPrice = ({
  priceMinimumOriginal,
}: IIncreaseApprovalPriceHook) => {
  const { country } = useUserProfile();
  const {
    getPublicationPriceInfoQuery: { data: publicationPriceInfo },
  } = useGetPublicationPriceInfo();

  const isCurrentRevenue = publicationPriceInfo?.isCurrentRevenueStandard;
  const revenueStandard = publicationPriceInfo?.revenueStandard;
  const categoryPriceName = publicationPriceInfo?.categoryPriceName;

  const category = isCurrentRevenue ? categoryPriceName : revenueStandard;

  const shouldFetchIncreaseApprovalPrice = country && priceMinimumOriginal;

  const increaseApprovalPriceQuery = useGetIncreasePricesQuery(
    {
      category: category,
      country,
      price: priceMinimumOriginal,
    },
    { skip: !shouldFetchIncreaseApprovalPrice },
  );

  return { increaseApprovalPriceQuery };
};
