// libraries.
import styled from "styled-components";

export const CustomDropdownStyles = styled.div`
  .noShowArrow {
    &::after {
      display: none !important;
    }
  }
`;
