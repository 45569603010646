// libraries.
import { useNid } from "@pages";
import { useUserProfile } from "@shared/GoogleLoginButton/user-slice/useUserProfile.hook";
import { useParams } from "react-router-dom";
import { useGetInventoryPropertyInfoQuery } from "./inventoryPropertyInfo.endpoints";

export const useGetInventoryPropertyInfo = () => {
  const paths = useParams();
  const { nid } = useNid();
  const { country } = useUserProfile();

  const nidSelected = paths.nid ? paths.nid : nid;
  const shouldFetch = !!nidSelected && !!country;
  const getInventoryPropertyInfoQuery = useGetInventoryPropertyInfoQuery(
    {
      country,
      nid: nidSelected,
    },
    { skip: !shouldFetch },
  );

  const publicationPrice =
    getInventoryPropertyInfoQuery.data?.publication_price ?? 0;
  const minPublicationPrice =
    getInventoryPropertyInfoQuery.data?.min_publication_price ?? 0;
  const utilityInitial =
    getInventoryPropertyInfoQuery.data?.initial_utility ?? 0;
  const grossMargin = getInventoryPropertyInfoQuery.data?.gross_margin ?? 0;
  const isPreSale = getInventoryPropertyInfoQuery.data?.pre_sale === 1;
  const dayWithdrawal = getInventoryPropertyInfoQuery.data?.day_withdrawal ?? 0;
  const lastCommercialCycle =
    getInventoryPropertyInfoQuery.data?.last_commercial_cycle ?? 0;

  return {
    dayWithdrawal,
    getInventoryPropertyInfoQuery,
    grossMargin,
    isPreSale,
    lastCommercialCycle,
    minPublicationPrice,
    publicationPrice,
    utilityInitial,
  };
};
