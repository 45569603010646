// components.
import { RowsActionsRemo } from "./components/RowsActionsRemo";

export const TableActionsRemo = () => {
  return (
    <div
      style={{
        height: "calc(100vh - 165px)",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <RowsActionsRemo />
    </div>
  );
};
