import { useUserProfile } from "@shared/GoogleLoginButton/user-slice/useUserProfile.hook";
import { useGetLeadDetailInfo } from "../leadDetail/leadDetail.hooks";
import { useGetFeedbackActionsQuery } from "./feedbackActions.endpoints";

export const useFeedbackActions = () => {
  const { country } = useUserProfile();
  const { nidSelected } = useGetLeadDetailInfo();

  const getFeedbackActionsQuery = useGetFeedbackActionsQuery({
    country: country,
    nid: Number(nidSelected),
  });

  const itemsFeedbackActionsLen =
    getFeedbackActionsQuery.data?.feedbackActions.length || 0;

  return { getFeedbackActionsQuery, itemsFeedbackActionsLen };
};
