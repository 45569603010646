// utils.
import { WrapperInputCheck } from "@pages/InventoryPage/modules/InventorySummary/components/InventorySummaryCO/PriceInventorySummaryCO/components/PublicationPriceInputsCO/components/styles";

export const PercentageInPricesMX = () => {
  return (
    <WrapperInputCheck>
      <div
        className="d-flex justify-content-end m-3"
        data-testid="PercentageInPricesMX"
      >
        Precio mínimo publicado es calculado al 3-4% con base en el Precio
        publicado.
      </div>
    </WrapperInputCheck>
  );
};
