// libraries.
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

// hooks.
import { useGetLeadDetailInfo } from "@store/apiSlices/leadDetail/leadDetail.hooks";
import { useGetInventoryPropertyInfo } from "@store/apiSlices/inventoryPropertyInfo/inventoryPropertyInfo.hook";

// utils.
import { formatPrice } from "@utils/formatPrice.utils";
import {
  calculatePercentageInPrice,
  percentageToApplyInRelatedPricesCO,
} from "@shared/DashboardComponentsSummary/components/utils";
import { useApplyRangeToPublicationPrice } from "@pages/InventoryPage/modules/InventorySummary/components/shared/hooks/applyRangeToPublicationPrice";

export const PublicationPrice = () => {
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const pricePublicationInput = watch("publicationPrice");
  const applyPercentageToPrices = watch("applyPercentageToPrices");
  const leader = watch("leader");

  const { applyRangeToPublicationPrice } = useApplyRangeToPublicationPrice();

  const { area } = useGetLeadDetailInfo();

  const { publicationPrice: defaultPublicationPrice } =
    useGetInventoryPropertyInfo();

  const publicationPriceM2 =
    Number(pricePublicationInput ?? defaultPublicationPrice) / area;

  useEffect(() => {
    setValue("publicationPrice", defaultPublicationPrice);
  }, [defaultPublicationPrice]);

  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <div>Precio Publicado</div>
        <input
          data-testid="publicationPriceInput"
          {...register("publicationPrice", {
            onChange: (e) => {
              if (applyPercentageToPrices) {
                const priceMin = calculatePercentageInPrice(
                  e.target.value,
                  false,
                  percentageToApplyInRelatedPricesCO,
                );
                setValue("minPublicationPrice", priceMin, {
                  shouldDirty: true,
                });
              }
            },
            validate: (value) =>
              applyRangeToPublicationPrice({
                defaultPrice: defaultPublicationPrice,
                leader,
                price: value,
              }),
            valueAsNumber: true,
          })}
          type="text"
          className="rounded-3 p-2"
        />
      </div>

      <div className="d-flex justify-content-end w-100">
        <small className="text-white">
          Valor por m²: {formatPrice(publicationPriceM2) || 0}
        </small>
      </div>
      {errors.publicationPrice && (
        <small className="d-flex justify-content-end text-danger fw-bold">
          {String(errors.publicationPrice.message)}
        </small>
      )}
    </>
  );
};
