import { electricPurple600, monserratSemiBold } from "@habitech/shared";
import styled from "styled-components";

export const WrapperTabs = styled.div`
  .title-tabs {
    font-family: ${monserratSemiBold};
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: ${electricPurple600};
  }
  .filters {
    font-size: 12px;
    font-family: ${monserratSemiBold};
  }
`;
