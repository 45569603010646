import { robotoRegular, scarpaGray500 } from "@habitech/shared";
import { styled } from "styled-components";

export const WrapperFeedbackTabs = styled.div`
  font-family: ${robotoRegular};
  .tab-remo {
    font-size: 15px;
  }
  .tab-actions {
    font-size: 15px;
  }
  span {
    font-size: 14px;
    color: ${scarpaGray500};
  }
`;

export const WrapperAnalyticTitle = styled.div`
  font-family: ${robotoRegular};
  font-size: 12px;
  .percentage {
    font-size: 16px;
  }
`;
