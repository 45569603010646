// assets.
import { IconTooltip } from "@assets";

// styles.
import { IconInfoHover } from "./styles";

interface InfoTagHoverProps {
  children?: React.ReactNode;
  icon?: React.ReactNode;
  style?: React.CSSProperties;
}

export const InfoTagHover = ({ children, style, icon }: InfoTagHoverProps) => {
  const customStyle = {
    left: style?.left || "0",
    top: style?.top || "0",
  };
  return (
    <IconInfoHover>
      <div className="message" style={customStyle}>
        {children}
      </div>
      {icon ? icon : <IconTooltip />}
    </IconInfoHover>
  );
};
