// Libraries.
import styled from "styled-components";
import { electricPurple600 } from "@habitech/shared";

export const ButtonGoogleHabi = styled.div`
  background: ${electricPurple600};
  color: #ffffff;
  font-size: 16px;
  width: 300px;
  height: 48px;
  line-height: 19px;
  border-radius: 100px;
  padding: 15px 54px;
  cursor: pointer;
  width: 300px;
  .ingresar {
    text-align: center;
    color: #ffffff;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }

  img {
    margin: 0;
    margin-right: 8px;
    border-radius: 100px;
  }
`;
