// libraries.
import { Form } from "react-bootstrap";

// hooks.
import { WrapperSelect } from "./styles";
import { useLeaders } from "./leaders.hook";
import { useFormContext } from "react-hook-form";

export const InputSelectLeader = () => {
  const { leaders } = useLeaders();

  const { register } = useFormContext();

  return (
    <WrapperSelect className="d-flex justify-content-between align-items-center my-3">
      <Form.Label className="mb-0">Aprobación de líder</Form.Label>
      <Form.Select
        aria-label="Default leader"
        className="select-leader"
        data-testid="leader"
        {...register("leader")}
      >
        <option value="">No necesita </option>
        {leaders.map((leader) => (
          <option key={leader.id} value={leader.email}>
            {leader.name}
          </option>
        ))}
      </Form.Select>
    </WrapperSelect>
  );
};
