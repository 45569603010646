// hooks.
import { useUserLeadDetail } from "@shared/hooks/userLeadDetail.hook";
import { useGetPriceCountdownQuery } from "./priceCountdown.endpoints";

export const usePriceCountdown = () => {
  const { country, nid } = useUserLeadDetail();

  const shouldFetch = !!country && !!nid;

  const getPriceCountdownInfo = useGetPriceCountdownQuery(
    {
      country,
      nid: nid,
    },
    { skip: !shouldFetch },
  );

  const priceCountdown = getPriceCountdownInfo.data?.finalCountdown || 0;
  const isAbleToPrice = getPriceCountdownInfo.data?.isAbleToPrice === 1;
  const isLoading = getPriceCountdownInfo.isLoading;

  return {
    getPriceCountdownInfo,
    isAbleToPrice,
    isLoading,
    priceCountdown,
  };
};
