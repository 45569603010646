// libraries.
import { electricPurple800, electricPurple900 } from "@habitech/shared";
import { commentExperienceOptions } from "@pages/InventoryPage/modules/FeedbackLook/utils/comment-experiences";
import { Form } from "react-bootstrap";
import { useFormContext } from "react-hook-form";

export const CommentExperienceInput = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div className="w-100">
      <div className="d-flex justify-content-between">
        <Form.Label className="text-white simple-text">
          Experiencia *
        </Form.Label>
        {errors.commentExperience && (
          <small>
            <p className="text-danger mb-1 fw-bold simple-text">
              {errors.commentExperience.message as string}
            </p>
          </small>
        )}
      </div>
      <Form.Select
        aria-label="Selecciona una experiencia"
        data-testid="commentExperience"
        {...register("commentExperience", {
          required: "Campo requerido",
        })}
        defaultValue=""
        className="text-white p-3 simple-text"
        style={{
          backgroundColor: electricPurple900,
          borderColor: electricPurple800,
        }}
      >
        <option value="">Selecciona</option>
        {commentExperienceOptions.map((option) => (
          <option key={option.id} value={option.id}>
            {option.label}
          </option>
        ))}
      </Form.Select>
    </div>
  );
};
