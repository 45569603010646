// libraries.
import Avatar from "react-avatar";

// hooks.
import { useUserProfile } from "@shared/GoogleLoginButton/user-slice/useUserProfile.hook";

export const UserImage = ({ nameUser }: { nameUser?: string }) => {
  const { name, imageUrl } = useUserProfile();
  const nameUserIsTheSame = nameUser === name;

  if (nameUserIsTheSame) {
    return (
      <div className="d-flex gap-2 fw-bold align-items-center">
        <div>
          <img
            src={imageUrl}
            alt={nameUser}
            className="rounded-circle"
            style={{ height: "30px", width: "30px" }}
          />
        </div>
        <div className="d-flex align-items-center">{nameUser || name}</div>
      </div>
    );
  }

  return (
    <div className="d-flex gap-2 fw-bold align-items-center">
      <Avatar
        name={nameUser}
        size="25"
        round
        style={{ height: "25px", width: "25px" }}
      />
      <div className="d-flex align-items-center">{nameUser || name}</div>
    </div>
  );
};
