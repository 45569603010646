import { IconHabiLogoWhiteCO, IconHabiLogoWhiteMX } from "@assets";

import { useUserProfile } from "./GoogleLoginButton/user-slice/useUserProfile.hook";

const LogoWhiteByCountry = [
  {
    component: <IconHabiLogoWhiteMX />,
    country: "MX",
  },
  {
    component: <IconHabiLogoWhiteCO />,
    country: "CO",
  },
];

export const LogoCountryWhite = () => {
  const { user } = useUserProfile();

  const countryLogo = LogoWhiteByCountry.find(
    (logoItem) => logoItem.country === user?.country,
  );

  return countryLogo && countryLogo.component;
};
