import { createContext } from "react";
import DropdownBootstrap from "react-bootstrap/Dropdown";
import {
  OptionValue,
  OnChangeArgs,
  useSelectValue,
} from "../useSelectValue.hook";

import { CustomDropdownStyles } from "./styles";

export const DropdownContext = createContext({} as DropdownContextProps);
const { Provider } = DropdownContext;

interface DropdownContextProps {
  handleChange?: (value: OptionValue) => void;
  setSelectedValue?: (value: string) => void;
  value?: OptionValue;
}

interface DropdownProps {
  children: React.ReactNode;
  className?: string;
  onChange?: (value: OnChangeArgs) => void;
  style?: React.CSSProperties;
  value?: OptionValue;
}

export const Dropdown = ({
  style,
  className,
  children,
  value,
  onChange,
}: DropdownProps) => {
  const { selectedValue, handleChange } = useSelectValue({
    onChange,
    value,
  });
  return (
    <Provider value={{ handleChange, value: selectedValue }}>
      <CustomDropdownStyles>
        <DropdownBootstrap className={className} style={style}>
          {children}
        </DropdownBootstrap>
      </CustomDropdownStyles>
    </Provider>
  );
};
