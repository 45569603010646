// libraries.
import { Card } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// hooks.
import { useLeadInventory } from "@store/apiSlices/inventory/searchByNid/leadInventory.hook";

// utils.
import { pathInventory } from "@routes/routes.types";
import { pathInventorySummary } from "@pages/InventoryPage/routes/routes.types";

// components.
import { CardManager } from "../../../../CardInventoryManagerCO/style";

export const LeadInventoryCard = ({ nid }: { nid: number }) => {
  const [showCard, setShowCard] = useState(false);

  const { leadInventoryQuery } = useLeadInventory({ nid });

  const leadInventory = leadInventoryQuery.data;
  const nidValue = leadInventory?.nid;
  const publicationDate = leadInventory?.publicationDate;
  const publicationPrice = leadInventory?.publicationPrice;
  const address = leadInventory?.address;
  const alertType = leadInventory?.alertType;
  const features = leadInventory?.features;
  const residentialName = leadInventory?.residentialName;
  const postalCode = leadInventory?.postalCode;

  const leadInventoryHasNid = !!nidValue;

  const isLoading = leadInventoryQuery.isLoading;

  const isError = leadInventoryQuery.isError;

  useEffect(() => {
    if (leadInventoryHasNid || isError) {
      setShowCard(true);
    }
  }, [leadInventoryHasNid, isError, nid]);

  if (isError && showCard)
    return (
      <Card
        className=" position-fixed z-3 mt-1"
        style={{
          width: "300px",
        }}
      >
        <Card.Body>
          <CardManager>
            <div className="d-flex flex-column ">
              <div className="d-flex justify-content-between">
                <div className=" fw-bold">Sin resultados</div>
                <div
                  onClick={() => setShowCard(false)}
                  className="text-muted fw-bold pointer"
                >
                  x
                </div>
              </div>
              <small>
                Por favor revisa que el numero de NID esté correcto o verifica
                que este inmueble se encuentre publicado en el portal de Brokers
              </small>
            </div>
          </CardManager>
        </Card.Body>
      </Card>
    );

  if (isLoading)
    return (
      <Card className=" position-fixed z-3 mt-1">
        <Card.Body>
          <CardManager>
            <div className="d-flex gap-5 justify-content-between align-items-center">
              <div className="nid-and-info">Cargando...</div>
            </div>
          </CardManager>
        </Card.Body>
      </Card>
    );

  if (!showCard || isError) return null;

  return (
    <Card className=" position-fixed z-3 mt-1">
      <Card.Body>
        <CardManager>
          <div className="d-flex gap-5 justify-content-between align-items-center">
            <Link
              to={`${pathInventory}/${pathInventorySummary}/${nidValue}`}
              className="rounded-3 pointer "
            >
              <div className="nid-and-info">{nidValue}</div>
            </Link>

            <div className="date text-wrap">{publicationDate}</div>
            <div
              onClick={() => setShowCard(false)}
              className="text-muted fw-bold pointer"
            >
              x
            </div>
          </div>
          <div className="flex-column">
            <div className="card-price">{publicationPrice}</div>
            <div className="complex-name mt-1">
              {postalCode ? `CP: ${postalCode} - ` : ""}
              {residentialName || "S/C"}
            </div>
            <div className="address">{address}</div>
            <div className="d-flex gap-2 nid-and-info mt-1">{features}</div>
            <div className="badge pill badge-inventory mt-1">{alertType}</div>
          </div>
        </CardManager>
      </Card.Body>
    </Card>
  );
};
