import { Modal } from "react-bootstrap";
import { SaveZone } from "./components/SaveZone";

export const ActionsModalZone = () => {
  return (
    <Modal.Footer className="d-flex justify-content-end footer">
      <SaveZone />
    </Modal.Footer>
  );
};
