import { useDropdownCategory } from "@shared/DropdownCategories/dropdownCategory-slice/dropdownCategory.hook";
import { useEffect } from "react";

export const useClearState = () => {
  const { clearCategoryFromUser } = useDropdownCategory();

  useEffect(() => {
    clearCategoryFromUser();
  }, [clearCategoryFromUser]);
};
