// libraries.
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

// slices.
import { apiPortalPricing } from "../apiSlices";

// interfaces.
import { ModelError } from "../interfaces/interfaces";

export interface AbortLeadModel {
  agent: string;
  country: string;
  id: string;
  status: string;
}

interface AbortLeadResponse {
  message: string;
  nid_progress: number;
  status: string;
  success: boolean;
}

export const abortLead = apiPortalPricing.injectEndpoints({
  endpoints: (builder) => ({
    abortLead: builder.mutation({
      query: (newData: AbortLeadModel) => ({
        body: newData,
        method: "POST",
        url: "/post_status_pricing",
      }),
      transformErrorResponse: (error: FetchBaseQueryError): ModelError => {
        const modelError: ModelError = {
          errorStatus: error?.status,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          messageFromServer: (error?.data as any)?.message,
          serviceErrorName: "post_status_pricing aborted",
        };
        return modelError;
      },
      transformResponse: (response: AbortLeadResponse): AbortLeadResponse => {
        return response;
      },
    }),
  }),
});

export const { useAbortLeadMutation } = abortLead;
