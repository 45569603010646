// slices
import { apiInventoryPortal } from "../apiSlices";
import { ModelError } from "../interfaces/interfaces";

interface MinPrice {
  abs: number;
  m2: number;
}

interface PublicPrice {
  abs: number;
  m2: number;
}

interface PercentileCalculatorInfoResponse {
  body: {
    min_publication_price: MinPrice;
    publication_price: PublicPrice;
  };
}

interface PercentileCalculatorModal {
  comparables: string;
  country?: string;
  minPublicationPriceAbs?: number;
  minPublicationPriceM2: number;
  publicationPriceAbs?: number;
  publicationPriceM2: number;
}

export const percentileCalculator = apiInventoryPortal.injectEndpoints({
  endpoints: (builder) => ({
    getPercentileCalculator: builder.query({
      query: ({
        comparables,
        country,
        minPublicationPriceAbs,
        minPublicationPriceM2,
        publicationPriceAbs,
        publicationPriceM2,
      }: PercentileCalculatorModal) => {
        return `/get_percentile_calculator?country=${country}&comparables=${comparables}&min_publication_price_abs=${minPublicationPriceAbs}&min_publication_price_m2=${minPublicationPriceM2}&publication_price_abs=${publicationPriceAbs}&publication_price_m2=${publicationPriceM2}`;
      },

      transformErrorResponse: (error: {
        data?: { error: string };
        status: number;
      }) => {
        const modelError: ModelError = {
          errorStatus: error.status,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          messageFromServer: (error.data as any)?.message,
          serviceErrorName: "getPercentileCalculator",
        };
        return modelError;
      },

      transformResponse: (response: PercentileCalculatorInfoResponse) => {
        const minPublicationPrice = response.body.min_publication_price;
        const publicationPrice = response.body.publication_price;
        return { minPublicationPrice, publicationPrice };
      },
    }),
  }),
});

export const {
  useGetPercentileCalculatorQuery,
  useLazyGetPercentileCalculatorQuery,
} = percentileCalculator;
