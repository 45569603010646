// libraries
import { Col } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";

// components.
import { PriceCardsInfoMX } from "./PriceCardsInfoMX/PriceCardsInfoMX";
import { PriceApprovalSummaryMX } from "./PriceApprovalSummaryMX/PriceApprovalSummaryMX";
export interface InterfaceFormApprovalMX {
  commentInput: string;
  finalMinPublicationPrice: number;
  isLoading: boolean;
  minPublicationPrice: number;
  originalMinimumPrice: number;
  publicationPrice: number;
  utilityInitial: number | null;
}

export const PublicationPriceSummaryMX = () => {
  const methods = useForm<InterfaceFormApprovalMX>({
    defaultValues: {
      commentInput: "",
      finalMinPublicationPrice: 0,
      isLoading: false,
      minPublicationPrice: 0,
      originalMinimumPrice: 0,
      publicationPrice: 0,
      utilityInitial: null,
    },
    mode: "all",
  });
  return (
    <>
      <FormProvider {...methods}>
        <form className="d-flex h-100 p-0" data-testid="approval-form-mx">
          <Col
            md={9}
            className="h-100 p-4 price-info  d-flex flex-column gap-4"
          >
            <PriceCardsInfoMX />
          </Col>
          <Col md={3} className="h-100 p-4 price-summary text-white">
            <PriceApprovalSummaryMX />
          </Col>
        </form>
      </FormProvider>
    </>
  );
};
