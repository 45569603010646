import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@store/index";
import {
  clearNotifications,
  setNotificationFilter,
} from "./moduleNotifications.slice";

export const useModuleNotifications = () => {
  const dispatch = useDispatch();

  // Selectors
  const notificationFilter = useSelector((state: RootState) => {
    return state.moduleNotificationsReducer.notificationFilter;
  });

  // Action Dispatchers
  const updateNotificationFilter = (value: string) => {
    dispatch(setNotificationFilter(value));
  };

  const clearState = () => {
    dispatch(clearNotifications());
  };

  return {
    clearState,
    notificationFilter,
    updateNotificationFilter,
  };
};
