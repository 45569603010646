// libraries.
import { useRef } from "react";
import { useLocation } from "react-router-dom";

// hooks.
import { useGetLeadDetailInfo } from "@store/apiSlices/leadDetail/leadDetail.hooks";
import { useUserProfile } from "@shared/GoogleLoginButton/user-slice/useUserProfile.hook";

// components.
import { WrapperLeadDetailItem } from "./components/WrapperLeadDetailItem";
import { ButtonScroll } from "./components/ButtonScroll";
import { SourceBadgeTags } from "@shared/SourceBadges/SourceBadges";

// styles.
import { WrapperScroll, WrapperButtonScroll } from "./styles";

export const DetailNavbar = () => {
  const location = useLocation();
  const inventoryPath = location.pathname.includes("/inventory");

  const { user } = useUserProfile();
  const countryCO = user?.country === "CO";

  const {
    getLeadDetailInfoQuery: { data },
  } = useGetLeadDetailInfo();

  const scrollRef = useRef<HTMLDivElement>(null);

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ behavior: "smooth", left: -200 });
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ behavior: "smooth", left: 200 });
    }
  };

  return (
    <div className="position-relative">
      <WrapperScroll
        ref={scrollRef}
        className="d-flex position-relative justify-content-between align-content-center font-weight-normal align-items-center p-3"
        data-testid="scroll-ref"
      >
        <WrapperLeadDetailItem>
          <p className="title">{data?.utils_date.date}</p>
        </WrapperLeadDetailItem>
        <WrapperLeadDetailItem>
          <p className="title">{data?.nid}</p>
        </WrapperLeadDetailItem>
        <WrapperLeadDetailItem>
          <p className="title">{data?.location.city.label}</p>
        </WrapperLeadDetailItem>
        <WrapperLeadDetailItem>
          <SourceBadgeTags color={data?.source.label}>
            <p>{data?.source.label}</p>
          </SourceBadgeTags>
        </WrapperLeadDetailItem>
        <WrapperLeadDetailItem>
          <p className="title">Tipo de inmuebles</p>
          <p className="fw-bold">{data?.property.property_type}</p>
        </WrapperLeadDetailItem>
        <WrapperLeadDetailItem>
          <p className="title">Dirección</p>
          <p className="fw-bold">{data?.location.address}</p>
        </WrapperLeadDetailItem>
        {countryCO && inventoryPath && (
          <WrapperLeadDetailItem>
            <p className="title">Conjunto</p>
            <p className="fw-bold">{data?.location.house_complex}</p>
          </WrapperLeadDetailItem>
        )}
        {inventoryPath && (
          <>
            <WrapperLeadDetailItem>
              <p className="title">Zona</p>
              <p className="fw-bold">{data?.location.median_zone}</p>
            </WrapperLeadDetailItem>
            <WrapperLeadDetailItem>
              <p className="title">Bucket</p>
              <p className="fw-bold">{data?.bucket || "No hay bucket"}</p>
            </WrapperLeadDetailItem>
            <WrapperLeadDetailItem>
              <p className="title">Revenue</p>
              <p className="fw-bold">
                {data?.revenue_standard || "No hay Revenue"}
              </p>
            </WrapperLeadDetailItem>
            <WrapperLeadDetailItem>
              <p className="title">Precio publicación</p>
              <p className="fw-bold">{data?.publication_price}</p>
            </WrapperLeadDetailItem>
            <WrapperLeadDetailItem>
              <p className="title">Precio de compra</p>
              <p className="fw-bold">{data?.purchase_price}</p>
            </WrapperLeadDetailItem>
          </>
        )}
        <WrapperLeadDetailItem>
          <p className="title">Terreno</p>
          <p className="fw-bold">{data?.property.land}</p>
        </WrapperLeadDetailItem>
        <WrapperLeadDetailItem>
          <p className="title">Área construida</p>
          <p className="fw-bold">{data?.property.area}</p>
        </WrapperLeadDetailItem>
        <WrapperLeadDetailItem>
          <p className="title">Antigüedad</p>
          <p className="fw-bold">{data?.property.antiquity}</p>
        </WrapperLeadDetailItem>
        <WrapperLeadDetailItem>
          <p className="title">Precio del cliente</p>
          <p className="fw-bold">{data?.property.price.last_price}</p>
        </WrapperLeadDetailItem>
        <WrapperLeadDetailItem>
          <p className="title">Piso</p>
          <p className="fw-bold">{data?.property.floor}</p>
        </WrapperLeadDetailItem>
        <WrapperLeadDetailItem>
          <p className="title">Habit</p>
          <p className="fw-bold">{data?.property.rooms}</p>
        </WrapperLeadDetailItem>
        <WrapperLeadDetailItem>
          <p className="title">Baños</p>
          <p className="fw-bold">{data?.property.baths}</p>
        </WrapperLeadDetailItem>
        <WrapperLeadDetailItem>
          <p className="title">Estac</p>
          <p className="fw-bold">{data?.property.garage}</p>
        </WrapperLeadDetailItem>
        <WrapperLeadDetailItem>
          <p className="title">Elevador</p>
          <p className="fw-bold">{data?.property.elevator}</p>
        </WrapperLeadDetailItem>
        <WrapperLeadDetailItem style={{ marginRight: "80px" }}>
          <p className="title">Precio de Publicación por m2</p>
          <p className="fw-bold">{data?.property.price.price_m2}</p>
        </WrapperLeadDetailItem>
      </WrapperScroll>
      <WrapperButtonScroll className="px-3 d-flex gap-3 align-items-center justify-content-center">
        <ButtonScroll onScrollLeft={scrollLeft} onScrollRight={scrollRight} />
      </WrapperButtonScroll>
    </div>
  );
};
