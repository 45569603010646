// libraries.
import {
  GoogleLogin,
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from "react-google-login";
import { useNavigate } from "react-router-dom";

import { ButtonGoogleHabi } from "./styles";
import { useUserProfile } from "./user-slice/useUserProfile.hook";
import { pathHome } from "@routes/routes.types";
import { pathHomeReleaseNotes } from "@pages/HomePage/routes/routes.types";

const GoogleLoginButton = ({ clientId }: { clientId: string }) => {
  const navigate = useNavigate();

  const { updateUserProfile } = useUserProfile();

  const onSuccess = (
    response: GoogleLoginResponse | GoogleLoginResponseOffline,
  ) => {
    if ("profileObj" in response) {
      updateUserProfile({
        email: response.profileObj.email,
        familyName: response.profileObj?.familyName,
        givenName: response.profileObj?.givenName,
        googleId: response.profileObj.googleId,
        imageUrl: response.profileObj?.imageUrl,
        name: response.profileObj?.name,
      });
      navigate(`${pathHome}/${pathHomeReleaseNotes}`);
    }
  };

  const onFailure = (response: unknown) => {
    console.log("Login failed: res:", response);
  };

  return (
    <GoogleLogin
      clientId={clientId}
      buttonText="Iniciar sesión con Google"
      onSuccess={onSuccess}
      onFailure={onFailure}
      cookiePolicy={"single_host_origin"}
      render={(renderProps) => (
        <ButtonGoogleHabi onClick={renderProps.onClick}>
          <p className="ingresar">Ingresar</p>
        </ButtonGoogleHabi>
      )}
    />
  );
};

export default GoogleLoginButton;
