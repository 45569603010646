import { scarpaGray900, tomatoCoral500, turquoise600 } from "@habitech/shared";
import { useGetHistoryLeadInfo } from "@store/apiSlices/historyLeadInfo/historyLeadInfo.hooks";

interface NormalizedProps {
  comparableId: number;
}

export const normalizationFlags = ({ comparableId }: NormalizedProps) => {
  const {
    getHistoryLeadInfoQuery: { data },
  } = useGetHistoryLeadInfo();
  const usedNormalization = data?.used_normalizations;
  const usedComps =
    data?.used_comparables
      ?.filter((item) => item?.comparable_id === comparableId)
      ?.map((comp) => ({
        last_ask_price: comp.last_ask_price,
        price_per_m2: comp.price_per_m2,
      })) || [];

  const flagsList =
    usedNormalization
      ?.filter((flag) => flag?.comparable_id === comparableId)
      .map((flag) => flag.norm_flags) || [];

  const priceList =
    usedNormalization
      ?.filter((item) => item?.comparable_id === comparableId)
      .map((item) => ({
        higher_price: item.higher_price,
        higher_price_m2: item.higher_price_m2,
        normalized_price: item.normalized_price,
        normalized_price_m2: item.normalized_price_m2,
        original_price: item.original_price,
        original_price_m2: item.original_price_m2,
      })) || [];

  const flagTooltip =
    flagsList.length > 0 &&
    flagsList.map((flags, key) => (
      <div key={key}>
        {flags.map((item, innerKey) => (
          <li className="flag-item" key={innerKey}>
            {item}
          </li>
        ))}
      </div>
    ));

  const colorIndicatorPrice =
    (priceList[0]?.higher_price === "lower" && tomatoCoral500) ||
    (priceList[0]?.higher_price === "higher" && turquoise600) ||
    scarpaGray900;

  const colorIndicatorPriceM2 =
    (priceList[0]?.higher_price === "lower" && tomatoCoral500) ||
    (priceList[0]?.higher_price === "higher" && turquoise600) ||
    scarpaGray900;

  return {
    colorIndicatorPrice,
    colorIndicatorPriceM2,
    flagTooltip,
    priceList,
    usedComps,
  };
};
