// libraries.
import { Tab, Tabs } from "react-bootstrap";

// hooks.
import { useGetHistoryLeadInfo } from "@store/apiSlices/historyLeadInfo/historyLeadInfo.hooks";

// components.
import { DownloadButton } from "../DownloadButton/DownloadButton";
import { TableMX } from "./TableMX";

// styles.
import { WrapperTabs } from "./styles";

export const CalculationsTabsMX = () => {
  const {
    getHistoryLeadInfoQuery: { data },
  } = useGetHistoryLeadInfo();

  const showComparablesTab =
    data?.used_comparables && data.used_comparables.length > 0;
  const showDuplicatedTab =
    data?.possible_duplicated_comps &&
    data?.possible_duplicated_comps.length > 0;
  const showDeletedTab =
    data?.feature_delete_comps && data.feature_delete_comps.length > 0;

  return (
    <WrapperTabs>
      <DownloadButton data={data} />
      <Tabs defaultActiveKey="comparables" id="tabs">
        {showComparablesTab && (
          <Tab
            eventKey="comparables"
            title={`Comparables (${data.used_comparables.length})`}
          >
            <TableMX comparablesList={data?.used_comparables} />
          </Tab>
        )}
        {showDuplicatedTab && (
          <Tab eventKey="duplicates" title="Duplicados">
            <TableMX comparablesList={data.possible_duplicated_comps.flat()} />
          </Tab>
        )}
        {showDeletedTab && (
          <Tab eventKey="deleted" title="Eliminados">
            <TableMX comparablesList={data.feature_delete_comps} />
          </Tab>
        )}
      </Tabs>
    </WrapperTabs>
  );
};
