import {
  ImmerReducer,
  createReducerFunction,
  createActionCreators,
} from "immer-reducer";
import { ReactNode, createContext, useContext, useReducer } from "react";

export enum modalHomeOptions {
  "modalLeadDetail" = "modalLeadDetail",
  "modalLeadDetailAbort" = "modalLeadDetailAbort",
}

class SettingsType {
  modalHome!: Array<modalHomeOptions>;
}

const initialState: SettingsType = {
  modalHome: [],
};

class HomeReducer extends ImmerReducer<typeof initialState> {
  closeModalHome() {
    this.draftState.modalHome = [];
  }
  closeThisModal(modal: modalHomeOptions) {
    this.draftState.modalHome = this.draftState.modalHome.filter(
      (m) => m !== modal,
    );
  }
  setModalHome(option: Array<modalHomeOptions> | modalHomeOptions) {
    this.draftState.modalHome = Array.isArray(option) ? option : [option];
  }
}

const HomeStateContext = createContext(initialState);
const HomeContextProvider = HomeStateContext.Provider;

export const HomeReducerFunction = createReducerFunction(HomeReducer);
export const HomeActions = createActionCreators(HomeReducer);

export const HomeDispatchContext = createContext({});

function HomeProvider(props: { children: ReactNode }) {
  const { children } = props;
  const [state, dispatch] = useReducer(HomeReducerFunction, initialState);
  return (
    <HomeContextProvider value={{ ...state }}>
      <HomeDispatchContext.Provider value={dispatch}>
        {children}
      </HomeDispatchContext.Provider>
    </HomeContextProvider>
  );
}

function useHomeState() {
  const context = useContext(HomeStateContext);
  if (context === undefined) {
    throw new Error("useHomeState must be used within a HomeProvider");
  }
  return context;
}

function useHomeDispatch() {
  const context = useContext(HomeDispatchContext);
  if (context === undefined) {
    throw new Error("useHomeDispatch must be used within a HomeProvider");
  }
  return context as React.Dispatch<unknown>;
}

function useHomeContext() {
  return [useHomeState(), useHomeDispatch()] as [
    typeof initialState,
    React.Dispatch<unknown>,
  ];
}

export { HomeProvider, useHomeState, useHomeDispatch, useHomeContext };
