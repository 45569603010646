// utils.
import { normalizationFlags } from "./utils";
import { formatPrice } from "@utils/formatPrice.utils";

// styles.
import { WrapperColorPrice, WrapperNormalization } from "./styles";
import { InfoTagHover } from "@shared/InfoTagHover/InfoTagHover";

interface NormalizedProps {
  comparableId: number;
}

export const NormalizedClientPrice = ({ comparableId }: NormalizedProps) => {
  const { flagTooltip, colorIndicatorPrice, priceList, usedComps } =
    normalizationFlags({
      comparableId,
    });

  return (
    <WrapperNormalization>
      {priceList[0] && priceList[0]?.higher_price !== "same" && (
        <InfoTagHover style={{ left: "-45px", top: "26px" }}>
          <div className="flag-message">{flagTooltip}</div>
        </InfoTagHover>
      )}
      <WrapperColorPrice pricecolor={colorIndicatorPrice}>
        {priceList[0]
          ? formatPrice(priceList[0]?.normalized_price)
          : formatPrice(usedComps[0]?.last_ask_price || 0)}
      </WrapperColorPrice>
    </WrapperNormalization>
  );
};
