import {
  robotoRegular,
  monserratRegular,
  electricPurple900,
  electricPurple600,
} from "@habitech/shared";
import styled from "styled-components";

export const WrapperHistoryRecord = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0 8px 8px 0;
  gap: 8px;
  background: #f3f3f3;
  .card {
    border-color: #f3f3f3;
  }
  .card-header {
    border-bottom: none;
    background: #ffffff;
    box-shadow:
      0px 0px 1px rgba(48, 49, 51, 0.05),
      0px 1px 3px rgba(48, 49, 51, 0.1);
    display: flex;
    padding: 12px 20px 8px 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    color: var(--font-habi-pcom-main-aaa, #252129);
    font-family: ${robotoRegular};
    font-size: 12px;
    .title {
      font-size: 14px;
      font-weight: 900;
    }
  }
  .card-body {
    padding: 8px 20px 12px;
    background: #fcfcfc;
    border: 1px solid #f3f3f3;
    box-shadow:
      0px 0px 1px rgba(48, 49, 51, 0.05),
      0px 1px 3px rgba(48, 49, 51, 0.1);
    border-radius: 0px 0px 8px 8px;
    font-family: ${robotoRegular};
    font-weight: 800;
    font-size: 13px;
  }
`;

export const WrapperTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.div`
  font-family: ${monserratRegular};
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: ${electricPurple900};
`;

export const WatchExercise = styled.div`
  font-family: ${robotoRegular};
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  color: ${electricPurple600};
`;
