// Libraries.
import { useEffect } from "react";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { useNid } from "../SearchByNid/nid-slice/useNid.hook";

// hooks.
import { useGetLeadDetailInfo } from "@store/apiSlices/leadDetail/leadDetail.hooks";
import {
  HomeActions,
  modalHomeOptions,
  useHomeContext,
} from "@pages/HomePage/context/home.context";

// Components.
import { SourceBadgeTags } from "@shared/SourceBadges/SourceBadges";
import { PropertyLayout } from "./components/PropertyFeatures/PropertyLayout";
import { PropertyInfoLayout } from "./components/PropertyInfo/PropertyInfoLayout";
import { DropdownBusinessOpportunities } from "./components/DropdownBusinessOpportunities";
import { StartPricing } from "./components/StartPricing/StartPricing";
import { LeadRecordCard } from "./components/LeadRecord/LeadRecordCard";

// Styles.
import { WrapperNidModal } from "./styles";

export const LeadDetailCard = () => {
  const { clearNidState } = useNid();
  const [{ modalHome }, dispatch] = useHomeContext();
  const modalLeadDetail = modalHome.includes(modalHomeOptions.modalLeadDetail);

  const {
    getLeadDetailInfoQuery: { data, isLoading, isSuccess, error },
  } = useGetLeadDetailInfo();

  useEffect(() => {
    if (isLoading) {
      toast.loading("Cargando Lead...", {
        autoClose: false,
        position: "bottom-center",
        toastId: "leadLoading",
      });
    } else if (error) {
      toast.error("Error al cargar el lead", {
        position: "bottom-center",
      });
    } else if (isSuccess && data) {
      toast.dismiss("leadLoading");
      toast.success("Lead cargado correctamente", {
        position: "bottom-center",
        toastId: "leadLoadSuccess",
      });
      dispatch(HomeActions.setModalHome(modalHomeOptions.modalLeadDetail));
    } else if (isSuccess && !data) {
      toast.dismiss();
      toast.error("No se encontró el lead", {
        position: "bottom-center",
      });
    } else {
      toast.dismiss("leadLoading");
    }
  }, [isLoading, error, isSuccess, data]);

  const handleOnClose = () => {
    clearNidState();
    dispatch(HomeActions.closeModalHome());
  };

  if (!modalLeadDetail || !data) return null;

  return (
    <Modal
      id="portal-root"
      show={modalLeadDetail}
      onHide={handleOnClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="xl"
      data-testid="modalLeadDetail"
    >
      <WrapperNidModal className="d-flex w-100">
        <div className="w-100">
          <Modal.Header closeButton className="p-4">
            <Modal.Title
              id="contained-modal-title-vcenter"
              className="w-100 d-flex flex-column gap-2"
            >
              <div>
                <DropdownBusinessOpportunities />
              </div>
              <div>
                <div>Detalle de la vivienda</div>
                <div className="d-flex justify-content-between">
                  <div className="wrapper-nid">
                    <a
                      className="nid-title"
                      href={data.hubspot_link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {data.nid}
                    </a>
                    <SourceBadgeTags
                      color={data.source?.label}
                      className="badge"
                    >
                      {data.source?.label}
                    </SourceBadgeTags>
                  </div>
                  <div data-id={`leadHour-${data.nid}`} className="date-create">
                    Creado {data.utils_date?.create_date_description}
                  </div>
                </div>
              </div>
            </Modal.Title>
          </Modal.Header>
          <div className="d-flex flex-column">
            <Modal.Body className="px-4">
              <PropertyInfoLayout data={data} />
            </Modal.Body>
            <div
              className="p-4"
              style={{
                background:
                  "var(--behavior-neutral-colors-scarpa-gray-25, #fafafa)",
              }}
            >
              <PropertyLayout data={data} />
            </div>
          </div>
          <StartPricing />
        </div>
        <LeadRecordCard />
      </WrapperNidModal>
    </Modal>
  );
};
