// interfaces.
interface PropsTitleSummary {
  subtitle?: string;
  title: string;
}

export const TitleSummary = ({ title, subtitle }: PropsTitleSummary) => {
  return (
    <div className="d-flex gap-4 flex-column">
      <div>
        <div className="title">{title}</div>
        {subtitle && <p className="subtitle">{subtitle}</p>}
      </div>
      <div className="line" />
    </div>
  );
};
