// libraries.
import { useNavigate } from "react-router-dom";

// components.
import { useSidebarContext } from "@shared/Sidebar";
import { SidebarButton } from "@shared/Sidebar/components/SidebarButton/SidebarButton";

// hooks.
import {
  WrapperByUserRole,
  UserRoles,
} from "@shared/WrapperByUserRole/WrapperByUserRole";

// types.
import { SIDEBAR_BUTTON_BULK_UPLOAD } from "../sidebarHome.types";

// assets.
import { IconUpload } from "@assets";
import { pathHome } from "@routes/routes.types";
import { pathHomeBulkUpload } from "@pages/HomePage/routes/routes.types";

export const ButtonBulkUpload = () => {
  const navigate = useNavigate();
  const {
    setSelectedButton,
    state: { selectedSidebarButton },
  } = useSidebarContext();

  const handleOnClick = () => {
    setSelectedButton(SIDEBAR_BUTTON_BULK_UPLOAD);
    navigate(`${pathHome}/${pathHomeBulkUpload}`);
  };

  return (
    <WrapperByUserRole role={[UserRoles.LEADER]}>
      <SidebarButton
        hoverText="Cambios masivos"
        onClick={handleOnClick}
        selected={selectedSidebarButton === SIDEBAR_BUTTON_BULK_UPLOAD}
      >
        <IconUpload fill="white" />
      </SidebarButton>
    </WrapperByUserRole>
  );
};
