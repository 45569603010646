export enum PriceRange {
  UP = "up",
  DOWN = "down",
  IN_RANGE = "inRange",
}

/**
 * Check if the price is within the percentage range
 * @param price current price, is the price to compare
 * @param defaultPrice default price, is the original price
 * @param percentage percentage to compare, natural number
 * @returns 'up' if price is above the percentage range, 'down' if price is below the percentage range, undefined if price is within the percentage range
 */
export const isPriceWithinPercentageRange = (
  currentPrice: number,
  defaultPrice: number,
  percentage: number,
) => {
  const priceMax = defaultPrice * (1 + percentage * 0.01);
  const priceMin = defaultPrice * (1 - percentage * 0.01);

  if (currentPrice > priceMax) {
    return PriceRange.UP;
  } else if (currentPrice < priceMin) {
    return PriceRange.DOWN;
  }
  return PriceRange.IN_RANGE;
};
