import { useNid } from "@pages";
import { useUserProfile } from "@shared/GoogleLoginButton/user-slice/useUserProfile.hook";
import { useGetStatusPricingByAgentQuery } from "./statusPricingByAgent";

interface LeadAgentStatus {
  isLeadInProgress: boolean;
  isTheSameNid: boolean;
  nid?: string;
}

export const useGetStatusPricingByAgent = () => {
  const { user } = useUserProfile();
  const { nid } = useNid();

  const shouldFetch = user.email && user.country;

  const getStatusPricingByAgentQuery = useGetStatusPricingByAgentQuery(
    {
      country: user.country,
      email: user.email,
    },
    { refetchOnMountOrArgChange: true, skip: !shouldFetch },
  );

  const nidStatus = getStatusPricingByAgentQuery.data?.status;
  const isNidFromAgentInProgress = nidStatus === "lead_in_progress";
  const isTheSameNid = nid === getStatusPricingByAgentQuery.data?.nid;

  const leadAgentStatus: LeadAgentStatus = {
    isLeadInProgress: isNidFromAgentInProgress,
    isTheSameNid,
    nid: getStatusPricingByAgentQuery.data?.nid,
  };

  return { getStatusPricingByAgentQuery, leadAgentStatus };
};
