// libraries.
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface InventoryState {
  alertOptions: string;
  bucketFilter: string;
  endDateFilter: Date | null;
  startDateFilter: Date | null;
}

export const initialStateInventory: InventoryState = {
  alertOptions: "",
  bucketFilter: "",
  endDateFilter: null,
  startDateFilter: null,
};

const moduleInventoryListSlice = createSlice({
  initialState: initialStateInventory,
  name: "inventory_list",
  reducers: {
    clearInventory: () => initialStateInventory,
    setAlertOptions: (state, action: PayloadAction<string>) => {
      state.alertOptions = action.payload;
    },
    setBucketFilter: (state, action: PayloadAction<string>) => {
      state.bucketFilter = action.payload;
    },
    setEndDateFilter: (state, action: PayloadAction<Date | null>) => {
      state.endDateFilter = action.payload;
    },
    setStartDateFilter: (state, action: PayloadAction<Date | null>) => {
      state.startDateFilter = action.payload;
    },
  },
});

export const {
  clearInventory,
  setAlertOptions,
  setBucketFilter,
  setStartDateFilter,
  setEndDateFilter,
} = moduleInventoryListSlice.actions;

export default moduleInventoryListSlice.reducer;
