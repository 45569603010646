// libraries.
import { electricPurple800, electricPurple900 } from "@habitech/shared";
import { Form } from "react-bootstrap";
import { useFormContext } from "react-hook-form";

export const CommentText = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div>
      <div className="d-flex justify-content-end">
        {errors.commentText && (
          <small>
            <p className="text-danger my-1 fw-bold simple-text">
              {errors.commentText.message as string}
            </p>
          </small>
        )}
      </div>

      <Form.Control
        data-testid="commentText"
        as="textarea"
        {...register("commentText", { required: "Campo requerido" })}
        rows={3}
        aria-label="Agregar comentario"
        placeholder="Agregar comentario"
        className="text-white ph-white p-3 simple-text"
        style={{
          backgroundColor: electricPurple900,
          borderColor: electricPurple800,
        }}
      />
    </div>
  );
};
