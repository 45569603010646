import {
  CardItem,
  Title,
  WrapperCardItems,
} from "@shared/DashboardComponentsSummary";
import { useGetInventoryPropertyInfo } from "@store/apiSlices/inventoryPropertyInfo/inventoryPropertyInfo.hook";
import { formatPrice } from "@utils/formatPrice.utils";

export const DiagnosisCards = () => {
  const {
    getInventoryPropertyInfoQuery: { data },
    dayWithdrawal,
    lastCommercialCycle,
  } = useGetInventoryPropertyInfo();

  const alertWithoutData = data?.alert ? data.alert : "No hay data";

  return (
    <div className="d-flex flex-column gap-2">
      <Title>Diagnóstico del inmueble</Title>
      <WrapperCardItems className="price-info-grid">
        <CardItem classNameData="warning-shadow rounded-3 card-price-info p-3 d-flex flex-column gap-2">
          <small className="title-card">Tipo de alerta</small>
          <div className="warning">{alertWithoutData}</div>
        </CardItem>
        <CardItem classNameData="default-shadow rounded-3 card-price-info p-3 d-flex flex-column gap-2">
          <small className="title-card">Trans. desde la alerta </small>
          <div className="subtitle-card">{`${data?.days_from_alert} días`}</div>
        </CardItem>
        <CardItem classNameData="default-shadow rounded-3 card-price-info p-3 d-flex flex-column gap-2">
          <small className="title-card">Tiempo financiero</small>
          <div className="subtitle-card">{`${data?.financial_days} días`}</div>
        </CardItem>
        <CardItem classNameData="default-shadow rounded-3 card-price-info p-3 d-flex flex-column gap-2">
          <small className="title-card">Tiempo Comercial</small>
          <div className="subtitle-card">{`${data?.commercial_days} días`}</div>
        </CardItem>
        <CardItem classNameData="default-shadow rounded-3 card-price-info p-3 d-flex flex-column gap-2">
          <small className="title-card">Total presupuesto</small>
          <div className="subtitle-card">
            {data?.total_budget && formatPrice(data.total_budget)}
          </div>
        </CardItem>
        <CardItem
          classNameData={`default-shadow rounded-3 card-price-info p-3 d-flex flex-column gap-2`}
        >
          <small className="title-card">
            Holding comercial desde desistimiento
          </small>
          <div className="subtitle-card">{`${dayWithdrawal} días`}</div>
        </CardItem>
        <CardItem
          classNameData={`default-shadow rounded-3 card-price-info p-3 d-flex flex-column gap-2`}
        >
          <small className="title-card">Ultimo ciclo comercial</small>
          <div className="subtitle-card">{`${lastCommercialCycle} días`}</div>
        </CardItem>
      </WrapperCardItems>
    </div>
  );
};
