// libraries.
import { useParams } from "react-router-dom";

// hooks.
import { useUserProfile } from "@shared/GoogleLoginButton/user-slice/useUserProfile.hook";
import { useGetPublicationPriceInfoQuery } from "./pricing.endpoints";

import { useNid } from "@pages";

export const useGetPublicationPriceInfo = () => {
  const paths = useParams();
  const { nid } = useNid();
  const { user: country } = useUserProfile();

  const nidSelected = paths.nid ? paths.nid : nid;

  const getPublicationPriceInfoQuery = useGetPublicationPriceInfoQuery({
    country: country.country,
    nid: nidSelected,
  });

  return { getPublicationPriceInfoQuery };
};

export const useVariationFromPublicationPrice = () => {
  const {
    getPublicationPriceInfoQuery: { data },
  } = useGetPublicationPriceInfo();

  const isInVariationRange =
    data?.variationPrice &&
    data.variationPrice >= -5 &&
    data.variationPrice <= 5;

  return { isInVariationRange };
};
