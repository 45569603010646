// libraries.
import { Container, Row } from "react-bootstrap";

// components.
import { InventorySummaryByCountry } from "./components";

// styles.
import { WrapperPublicationPriceSummary } from "@pages/PublicationPricePage/modules/PublicationPriceSummary/styles";

export const InventorySummary = () => {
  return (
    <WrapperPublicationPriceSummary
      className="h-100"
      data-testid="inventory-summary-page"
    >
      <Container fluid className="h-100">
        <Row className="h-100">
          <InventorySummaryByCountry />
        </Row>
      </Container>
    </WrapperPublicationPriceSummary>
  );
};
