// libraries.
import { useEffect, createContext, useContext } from "react";

import { useSidebar } from "@shared/Sidebar/sidebar-reducer/sidebar.hook";

import { WrapperSidebar } from "./styles";
import { Styles } from "@interfaces/styles";

interface SidebarProps extends Styles {
  children: React.ReactNode;
}

type SidebarContextType = ReturnType<typeof useSidebar>;

const SidebarContext = createContext<SidebarContextType | undefined>(undefined);
export const SidebarProvider = SidebarContext.Provider;

export const Sidebar = ({ children, className }: SidebarProps) => {
  const sidebar = useSidebarContext();

  useEffect(() => {
    return () => {
      sidebar.reset();
    };
  }, []);

  return (
    <WrapperSidebar
      className={`py-4 px-3 ${className}`}
      onMouseEnter={() => sidebar.setIsHovered(true)}
      onMouseLeave={() => sidebar.setIsHovered(false)}
    >
      {children}
    </WrapperSidebar>
  );
};

export const useSidebarContext = (): SidebarContextType => {
  const context = useContext(SidebarContext);
  if (!context) {
    throw new Error("useSidebarContext must be used within a SidebarProvider");
  }
  return context;
};
