// libraries.
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OptionValue } from "@shared/Inputs/Dropdown";

export interface UserProfileState {
  countries?: OptionValue[];
  country?: string;
  email: string;
  familyName?: string;
  givenName?: string;
  googleId: string;
  imageUrl?: string;
  name?: string;
  role?: string;
}

export const initialState: UserProfileState = {
  countries: [],
  country: "",
  email: "",
  familyName: "",
  givenName: "",
  googleId: "",
  imageUrl: "",
  name: "",
  role: "",
};

export const userSlice = createSlice({
  initialState,
  name: "user",
  reducers: {
    clearUserProfile: () => initialState,
    setCountry: (state, action: PayloadAction<string>) => {
      state.country = action.payload;
    },
    setUserProfile: (state, action: PayloadAction<UserProfileState>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setUserProfile, clearUserProfile, setCountry } =
  userSlice.actions;

export default userSlice.reducer;
