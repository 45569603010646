import imagePin from "@assets/images/pin.png";
import { MarkerF } from "@react-google-maps/api";

interface Props {
  icon?: string;
  position?: google.maps.LatLngLiteral;
}

export const Marker = ({ position = { lat: 0, lng: 0 }, icon }: Props) => {
  const finalIcon = icon || imagePin;

  return <MarkerF icon={finalIcon} position={position} />;
};
