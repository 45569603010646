// libraries.
import { Container } from "react-bootstrap";
import { scarpaGray50 } from "@habitech/shared";

// shared.
import { Title } from "@shared/DashboardComponentsSummary";

// components
import { RemoTabs } from "./components/RemoTabs";

export const FeedbackRemo = () => {
  return (
    <div
      data-testid="feedback-remo-page"
      className="h-100"
      style={{
        width: "calc(100vw - 73px)",
      }}
    >
      <Container
        fluid
        className="m-0 pe-0 ps-4"
        data-testid="TitleFeedbackRemo"
        style={{ backgroundColor: scarpaGray50 }}
      >
        <div className="d-flex flex-column h-100">
          <Title className="m-4">Histórico</Title>
          <RemoTabs />
        </div>
      </Container>
    </div>
  );
};
