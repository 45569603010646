import { CalculationsTableContextCO } from "@pages/PublicationPricePage/modules/PublicationPriceCalculations/PublicationPriceCalculations";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  OptionValue,
} from "@shared/Inputs/Dropdown";
import { useGetHistoryLeadInfo } from "@store/apiSlices/historyLeadInfo/historyLeadInfo.hooks";
import { useContext } from "react";

export const DropdownUsedComparables = () => {
  const { categoryZone, setCategoryZone } = useContext(
    CalculationsTableContextCO,
  );

  const {
    getHistoryLeadInfoQuery: { data },
  } = useGetHistoryLeadInfo();

  const showDropdown =
    data?.used_comparables && data?.set_comps_complex?.comps_complex;

  const options = [
    {
      label: "Zona",
      value: "Zona",
    },
    {
      label: "Conjunto",
      value: "Conjunto",
    },
  ];

  const handleChange = ({ value }: { value?: OptionValue }) => {
    if (!value) return;
    setCategoryZone(value);
  };

  if (!showDropdown) return null;

  return (
    <div>
      <Dropdown value={categoryZone} onChange={handleChange}>
        <DropdownToggle
          style={{
            background: "white",
            border: "1px solid #949494",
            color: "black",
            fontWeight: "600",
            height: "42px",
          }}
          className="d-flex gap-2 align-items-center rounded-2 px-3"
          id="dropdownBusinessOpportunity"
          label="Zona"
          showArrow
        />
        <DropdownMenu>
          {options?.map((option) => (
            <DropdownItem
              key={option.value}
              value={{
                label: option.label,
                value: option.value,
              }}
            >
              {option.label}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};
