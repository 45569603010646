import { useFeedbackActions } from "@store/apiSlices/feedbackRemo/feedbackActions.hook";
import { useFeedbackRemo } from "@store/apiSlices/feedbackRemo/feedbackRemo.hook";
import { WrapperFeedbackTabs } from "./styles";
import { useFeedbackBrokers } from "@store/apiSlices/feedbackRemo/feedbackBrokers.hooks";

interface PropsTitleTabsRemo {
  active: boolean;
  title: string | JSX.Element;
}

export const TitleTabsRemo = ({ active, title }: PropsTitleTabsRemo) => {
  return <div className={`${active && "fw-bold"}`}>{title}</div>;
};

export const TitleFeedbackRemo = ({ active }: { active: boolean }) => {
  const { itemsFeedbackRemoLen } = useFeedbackRemo();

  return (
    <WrapperFeedbackTabs>
      <TitleTabsRemo
        active={active}
        title={
          <div className="d-flex gap-1 align-items-center">
            <div className="tab-remo">Feedback Remo</div>
            <span>({itemsFeedbackRemoLen})</span>
          </div>
        }
      />
    </WrapperFeedbackTabs>
  );
};

export const TitleFeedbackActions = ({ active }: { active: boolean }) => {
  const { itemsFeedbackActionsLen } = useFeedbackActions();

  return (
    <WrapperFeedbackTabs>
      <TitleTabsRemo
        active={active}
        title={
          <div className="d-flex gap-1 align-items-center">
            <div className="tab-actions">Alertas y acciones</div>
            <span>({itemsFeedbackActionsLen})</span>
          </div>
        }
      />
    </WrapperFeedbackTabs>
  );
};

export const TitleFeedbackBrokers = ({ active }: { active: boolean }) => {
  const { itemsFeedbackBrokersLen } = useFeedbackBrokers();

  return (
    <WrapperFeedbackTabs>
      <TitleTabsRemo
        active={active}
        title={
          <div className="d-flex gap-1 align-items-center">
            <div className="tab-actions">Feedback Brokers</div>
            <span>({itemsFeedbackBrokersLen})</span>
          </div>
        }
      />
    </WrapperFeedbackTabs>
  );
};
