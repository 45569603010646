// libraries.
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useDebounce } from "@uidotdev/usehooks";
import { useFormContext } from "react-hook-form";

// hooks.
import { useValidateTope } from "@store/apiSlices/limitPrice/validateTope.hook";

// assets.
import { IconWarning } from "@assets";

// utils.
import { formatPrice } from "@utils";

export const ValidateTope = ({
  watchPriceName,
}: {
  watchPriceName: string;
}) => {
  const { watch } = useFormContext();

  const pricePublicationInput = watch(watchPriceName);
  const deferredValue = useDebounce(pricePublicationInput, 1000);

  const {
    getValidateTopeQuery: { data, isLoading, isSuccess, isError, isFetching },
  } = useValidateTope({ price: deferredValue });

  const isValidTope = data?.isValidTope;
  const topePrice = data?.priceTope || 0;

  useEffect(() => {
    if (isLoading || isFetching) {
      toast.loading("Validando tope de precio", { toastId: "validateTope" });
    } else if (isSuccess) {
      toast.dismiss("validateTope");
      toast.success("Tope de precio validado", {
        toastId: "validTopeSuccess",
      });
    } else if (isError) {
      toast.dismiss("validateTope");
      toast.error("Error al validar tope de precio", {
        toastId: "validTopeError",
      });
    } else {
      toast.dismiss("validateTope");
    }
  }, [isLoading, isFetching, isSuccess, isError]);

  return (
    <small className="text-white d-flex gap-2 mt-3">
      {!isValidTope && (
        <div className="d-flex align-items-center">
          <IconWarning />
          <div>Max. permitido: {formatPrice(topePrice)}</div>
        </div>
      )}
    </small>
  );
};
