import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { apiInventoryPortal } from "@store/apiSlices/apiSlices";
import { ModelError } from "@store/apiSlices/interfaces/interfaces";

interface LeadInventoryBack {
  address: string;
  agent: string;
  alert: string;
  features: string;
  nid: string;
  postal_code: string;
  publication_date: string;
  publication_price: string;
  residential: string;
}

interface LeadInventoryResponseFromBack {
  body: LeadInventoryBack;
  code: number;
  success: boolean;
}

interface LeadInventoryResponse {
  address: string;
  agentEmail: string;
  alertType: string;
  features: string;
  nid: string;
  postalCode: string;
  publicationDate: string;
  publicationPrice: string;
  residentialName: string;
}

export const inventorySearchByNid = apiInventoryPortal.injectEndpoints({
  endpoints: (builder) => ({
    getInventoryByNid: builder.query({
      query: ({ country, nid }) => {
        return `/get_lead_inventory?nid=${nid}&country=${country}`;
      },
      transformErrorResponse: (error: FetchBaseQueryError): ModelError => {
        const modelError: ModelError = {
          errorStatus: error.status,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          messageFromServer: (error.data as any).body.message,
          serviceErrorName: "getLeadInventory",
        };
        return modelError;
      },
      transformResponse: (
        response: LeadInventoryResponseFromBack,
      ): LeadInventoryResponse => {
        const modelLeadInventory = response.body;

        const leadInventoryResponse: LeadInventoryResponse = {
          address: modelLeadInventory.address || "",
          agentEmail: modelLeadInventory.agent || "",
          alertType: modelLeadInventory.alert || "",
          features: modelLeadInventory.features || "",
          nid: modelLeadInventory.nid || "",
          postalCode: modelLeadInventory.postal_code || "",
          publicationDate: modelLeadInventory.publication_date || "",
          publicationPrice: modelLeadInventory.publication_price || "",
          residentialName: modelLeadInventory.residential || "",
        };

        return leadInventoryResponse;
      },
    }),
  }),
});

export const { useGetInventoryByNidQuery, useLazyGetInventoryByNidQuery } =
  inventorySearchByNid;
