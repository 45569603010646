// libraries.
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

// slices.
import { apiInventoryPortal } from "../apiSlices";

// interfaces.
import { ModelError } from "../interfaces/interfaces";

export interface InventoryPricingModel {
  agent: string;
  comment: string;
  commission: string;
  country?: string;
  current_utility?: number;
  initial_utility?: number;
  leader_approval?: string;
  min_publication_price: number;
  nid: number;
  price_type: string;
  publication_price: number;
  status: string;
}

interface InventoryPricingResponse {
  message: string;
  nid_progress: number;
  success: boolean;
}

export const inventoryPricing = apiInventoryPortal.injectEndpoints({
  endpoints: (builder) => ({
    inventoryPricing: builder.mutation({
      query: (newData: InventoryPricingModel) => ({
        body: newData,
        method: "POST",
        url: "/post_pricing",
      }),
      transformErrorResponse: (error: FetchBaseQueryError): ModelError => {
        const modelError: ModelError = {
          errorStatus: error.status,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          messageFromServer: (error.data as any)?.message,
          serviceErrorName: "postInventoryPricing",
        };
        return modelError;
      },
      transformResponse: (
        response: InventoryPricingResponse,
      ): InventoryPricingResponse => {
        return response;
      },
    }),
  }),
});

export const { useInventoryPricingMutation } = inventoryPricing;
