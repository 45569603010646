import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@store/index"; // Importa el tipo RootState si es necesario
import {
  setStartDateFilter,
  setEndDateFilter,
  clearInventory,
  setAlertOptions,
  setBucketFilter,
} from "./moduleInventoryList.slice";

export const useModuleInventoryList = () => {
  const dispatch = useDispatch();

  // Selectors
  const startDateFilter = useSelector((state: RootState) => {
    return state.moduleInventoryListReducer.startDateFilter;
  });
  const endDateFilter = useSelector((state: RootState) => {
    return state.moduleInventoryListReducer.endDateFilter;
  });

  const alertOptions = useSelector((state: RootState) => {
    return state.moduleInventoryListReducer.alertOptions;
  });

  const bucketFilter = useSelector((state: RootState) => {
    return state.moduleInventoryListReducer.bucketFilter;
  });

  // Action Dispatchers
  const updateStartDateFilter = (date: Date) => {
    dispatch(setStartDateFilter(date));
  };

  const updateEndDateFilter = (date: Date) => {
    dispatch(setEndDateFilter(date));
  };

  const updateAlertOptions = (value: string) => {
    dispatch(setAlertOptions(value));
  };

  const updateBucketFilter = (value: string) => {
    dispatch(setBucketFilter(value));
  };

  const clearState = () => {
    dispatch(clearInventory());
  };

  return {
    alertOptions,
    bucketFilter,
    clearState,
    endDateFilter,
    startDateFilter,
    updateAlertOptions,
    updateBucketFilter,
    updateEndDateFilter,
    updateStartDateFilter,
  };
};
