// Slices
import { countries, countryCodes } from "@utils";
import { apiAuth } from "../apiSlices";
import { ModelError } from "../interfaces/interfaces";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

export interface AuthTransformedResponse {
  countriesByUser: string[];
  countriesSelect: { label: string; value: string }[];
  roles: string[];
}

export interface AuthResponse {
  role: string[];
  success: boolean;
}

export const auth = apiAuth.injectEndpoints({
  endpoints: (builder) => ({
    auth: builder.mutation({
      query: (newData) => ({
        body: newData,
        method: "POST",
        url: "/get_cognito_user_role",
      }),
      transformErrorResponse: (error: FetchBaseQueryError): ModelError => {
        const modelError: ModelError = {
          errorStatus: error?.status,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          messageFromServer: (error?.data as any)?.message,
          serviceErrorName: "get_cognito_user_role",
        };
        return modelError;
      },
      transformResponse: (response: AuthResponse): AuthTransformedResponse => {
        const countriesByUser = response.role.filter((role) =>
          countryCodes.includes(role),
        );

        const countriesSelect = countries
          .filter((country) => countriesByUser.includes(country.countryCode))
          .map((country) => ({
            label: country.name,
            value: country.countryCode,
          }));

        // Mock to simulate one country
        // const countriesSelect = [
        //   {
        //     value: "CO",
        //     label: "Colombia",
        //   },
        // ];

        const roles = response.role.filter(
          (role) => !countryCodes.includes(role),
        );

        return { countriesByUser, countriesSelect, roles };
      },
    }),
  }),
});

export const { useAuthMutation } = auth;
