// hooks.
import { useUserProfile } from "@shared/GoogleLoginButton/user-slice/useUserProfile.hook";

// components.
import { UsedFiltersMX } from "./components/UsedFiltersMX/UsedFiltersMX";
import { UsedFiltersCO } from "./components/UsedFiltersCO/UsedFiltersCO";

interface FiltersByCountryItem {
  component: React.ReactNode;
  country: string;
}

export const FiltersByCountry: FiltersByCountryItem[] = [
  {
    component: <UsedFiltersMX />,
    country: "MX",
  },
  {
    component: <UsedFiltersCO />,
    country: "CO",
  },
];

export const FiltersOnMap = () => {
  const { user } = useUserProfile();

  const mapFilters = FiltersByCountry.find(
    (filter) => filter.country === user?.country,
  );

  return mapFilters && mapFilters.component;
};
