// libraries.
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

// slices.
import { apiInventoryPortal } from "@store/apiSlices/apiSlices";

// interfaces.
import { ModelError } from "@store/apiSlices/interfaces/interfaces";
import { CommentStatus } from "./comments.service";

export interface UpdateCommentModel {
  agent: string;
  category: string;
  comment: string;
  comment_id: number;
  country: string;
  experience: string;
  hash_list: string[];
  nid: number;
  radius: number;
  status: CommentStatus;
  type: string;
}

interface UpdateCommentResponse {
  message: string;
  nid_progress: number;
  success: boolean;
}

export const updateComment = apiInventoryPortal.injectEndpoints({
  endpoints: (builder) => ({
    updateComment: builder.mutation({
      query: (newData: UpdateCommentModel) => ({
        body: newData,
        method: "PUT",
        url: "/feedback_loop",
      }),
      transformErrorResponse: (error: FetchBaseQueryError): ModelError => {
        const modelError: ModelError = {
          errorStatus: error.status,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          messageFromServer: (error.data as any)?.message,
          serviceErrorName: "updateCommentError",
        };
        return modelError;
      },
      transformResponse: (
        response: UpdateCommentResponse,
      ): UpdateCommentResponse => {
        return response;
      },
    }),
  }),
});

export const { useUpdateCommentMutation } = updateComment;
