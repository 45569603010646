// libraries.
import {
  electricPurple300,
  electricPurple700,
  robotoRegular,
  scarpaGray400,
  scarpaGray500,
  scarpaGray800,
  scarpaGray900,
  warning,
} from "@habitech/shared";
import { styled } from "styled-components";

export const WrapperCardNotification = styled.div`
  font-family: ${robotoRegular};
  color: ${scarpaGray500};
  .checkbox {
    input {
      border: 1px solid ${scarpaGray500};
      border-radius: 5px;
    }
  }

  .title {
    font-size: 12px;
  }

  .date {
    font-size: 11px;
  }
  .comment {
    color: ${scarpaGray800};
    font-size: 13px;
  }
  .link {
    color: ${electricPurple700};
    font-size: 12px;
    text-decoration: underline;
  }
  .circle-saw {
    background-color: ${electricPurple300};
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
  .complex {
    font-size: 14px;
    color: ${scarpaGray900};
    font-weight: 900;
  }

  .budget-route {
    font-size: 12px;
    color: ${scarpaGray800};
    span:first-child {
      color: ${warning};
    }
    span:last-child {
      color: ${scarpaGray400};
    }
  }
`;
