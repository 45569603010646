// libraries.
import { ReactNode } from "react";

// hooks.
import { useRoleByUser } from "@store/apiSlices/roles/role.hook";

// interfaces.
export interface UserRoleProps {
  children: ReactNode;
  role?: string | string[];
}

export enum UserRoles {
  LEADER = "leader",
  INVENTORY = "inventory_manager",
  APPRAISER = "appraiser",
}

export const WrapperByUserRole = ({ children, role }: UserRoleProps) => {
  const { getRolesQuery } = useRoleByUser();
  const roleFromUser = getRolesQuery?.data?.role;

  if (!role || !roleFromUser) return null;

  const isRoleValid = Array.isArray(role)
    ? role.includes(roleFromUser)
    : role === roleFromUser;

  if (!isRoleValid) return null;

  return children;
};
