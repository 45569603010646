// Libraries
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDebounce } from "@uidotdev/usehooks";

// Hooks
import { useNid } from "@pages";
import {
  modalHomeOptions,
  useHomeContext,
} from "@pages/HomePage/context/home.context";

// Styles.
import { InputSearch } from "@shared/Inputs/styles";
import { WrapperSearchInput } from "./styles";

export const SearchByNid: React.FC = () => {
  const [{ modalHome }] = useHomeContext();
  const modalLeadDetail = modalHome.includes(modalHomeOptions.modalLeadDetail);
  const { setNidValue, clearNidState } = useNid();

  const { register, reset, watch } = useForm();

  const nidValue = watch("nid");

  const deferredNidValue = useDebounce(nidValue, 1000);

  const limitSearch = 8;

  useEffect(() => {
    if (deferredNidValue?.length >= limitSearch) {
      setNidValue(deferredNidValue);
    } else if (deferredNidValue?.length === 0) {
      reset({ nid: "" });
      clearNidState();
    }
  }, [deferredNidValue]);

  useEffect(() => {
    if (!modalLeadDetail) {
      reset({ nid: "" });
      clearNidState();
    }
  }, [modalLeadDetail]);

  return (
    <WrapperSearchInput>
      <InputSearch
        type="number"
        id="nid"
        data-id="searchByNid"
        placeholder="Busca por NID"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...register("nid")}
        className="px-2 rounded-2"
      />
    </WrapperSearchInput>
  );
};
