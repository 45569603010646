// libraries.
import { useNavigate } from "react-router-dom";

// hooks.
import { useNid } from "@pages";

// components.
import { IconCalculations } from "@assets";

// shared components.
import { SidebarButton, useSidebarContext } from "@shared/Sidebar";

// types.
import { SIDEBAR_PUBLICATION_PRICE_CALCULATIONS } from "../sidebarPublicationPrice.types";
import { pathPublicationPrice } from "@routes/routes.types";
import { pathPublicationPriceCalculations } from "@pages/PublicationPricePage/routes/routes.types";

export const CalculationsViewer = () => {
  const navigate = useNavigate();

  const { nid } = useNid();

  const {
    setSelectedButton,
    state: { selectedSidebarButton },
  } = useSidebarContext();
  const isSelected =
    selectedSidebarButton === SIDEBAR_PUBLICATION_PRICE_CALCULATIONS;

  const handleSelect = () => {
    navigate(
      `${pathPublicationPrice}/${pathPublicationPriceCalculations}/${nid}`,
    );
    setSelectedButton(SIDEBAR_PUBLICATION_PRICE_CALCULATIONS);
  };

  return (
    <SidebarButton
      hoverText="Cálculos"
      selected={isSelected}
      onClick={() => handleSelect()}
    >
      <IconCalculations fill={"white"} />
    </SidebarButton>
  );
};
