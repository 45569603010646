// libraries.
import { Form } from "react-bootstrap";

interface CheckboxOptionProps {
  checked: boolean;
  className?: string;
  dataTestId?: string;
  id: string;
  label?: string;
  onChange: () => void;
  style?: React.CSSProperties;
}

export const CheckboxItem = ({
  id,
  checked,
  className,
  dataTestId,
  onChange,
  label,
  style,
}: CheckboxOptionProps) => {
  return (
    <Form.Check
      id={id}
      label={label}
      type="checkbox"
      className={className}
      checked={checked}
      onChange={(e) => {
        onChange();
        e.stopPropagation();
      }}
      style={style}
      data-testid={dataTestId}
    />
  );
};
