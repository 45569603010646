// components.
import {
  WrapperSummary,
  TitleSummary,
} from "@shared/DashboardComponentsSummary";
import { PublicationPriceInputsCO } from "./components/PublicationPriceInputsCO/PublicationPriceInputsCO";

// shared.
import { SaveInventoryPricingButton } from "../../shared";
import { CommentInput } from "../../shared/CommentInput";
import { DefaultCommission } from "../../shared/DefaultCommission";
import { InputSelectLeader } from "../../shared/InputSelectLeader/InputSelectLeader";

export const PriceInventorySummaryCO = () => {
  return (
    <div className="d-flex flex-column h-100 justify-content-between">
      <WrapperSummary>
        <TitleSummary
          title="Precio y comisión"
          subtitle="Puedes editar los valores y guardar los cambios"
        />
        <InputSelectLeader />
        <PublicationPriceInputsCO />
        <DefaultCommission />
      </WrapperSummary>
      <div className="d-flex flex-column gap-4">
        <div className="line" />
        <CommentInput />
        <SaveInventoryPricingButton />
      </div>
    </div>
  );
};
