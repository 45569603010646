// libraries.
import { tomatoCoral500 } from "@habitech/shared";

import { useUserProfile } from "@shared/GoogleLoginButton/user-slice/useUserProfile.hook";

import { IconLogout } from "@assets";

import { SidebarButton } from "@shared/Sidebar";

export const CloseSession = () => {
  const { clearUser } = useUserProfile();

  return (
    <SidebarButton hoverText="Cerrar sesión" onClick={() => clearUser()}>
      <IconLogout fill={tomatoCoral500} />
    </SidebarButton>
  );
};
