import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { apiInventoryPortal } from "../../apiSlices";
import { ModelError } from "../../interfaces/interfaces";

interface CommentHexagonParams {
  country?: string;
  latitude: number;
  longitude: number;
}

interface CommentHexagons {
  lat: number;
  lng: number;
}

interface ResponseCommentHexagon {
  body: Hexagon[];
  code: number;
  success: boolean;
}

export interface Hexagon {
  hash: string;
  path: CommentHexagons[];
  selected: boolean;
}

export interface Hexagons {
  hexagons: Hexagon[];
}

export const hexagons = apiInventoryPortal.injectEndpoints({
  endpoints: (builder) => ({
    getHexagons: builder.query({
      query: ({ country, latitude, longitude }: CommentHexagonParams) => {
        return `/polygon_hex?country=${country}&latitude=${latitude}&longitude=${longitude}`;
      },
      transformErrorResponse: (error: FetchBaseQueryError) => {
        const modelError: ModelError = {
          errorStatus: error.status,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          messageFromServer: (error.data as any)?.message,
          serviceErrorName: "getHexagons",
        };
        return modelError;
      },
      transformResponse: (response: ResponseCommentHexagon): Hexagons => {
        const hexagons = response.body;

        return { hexagons };
      },
    }),
  }),
});

export const { useGetHexagonsQuery } = hexagons;
