// libraries.
import { Button, Container } from "react-bootstrap";
import * as XLSX from "xlsx";

// hooks.
import { useModuleHomeBulkUpload } from "../bulkUpload-slice/bulkUpload.hook";

export const UploadButton = () => {
  const { updateFileData, hasFile } = useModuleHomeBulkUpload();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = e.target?.result;
      if (!data) {
        console.error("File reading failed or no content");
        return;
      }
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const sheetData = XLSX.utils.sheet_to_json(sheet);
      updateFileData(sheetData);
    };

    reader.readAsBinaryString(file);
  };

  if (hasFile) {
    return null;
  }

  return (
    <div className="w-100 h-100 " data-testid="massive-load">
      <Container fluid className="h-100 pb-3">
        <div className="empty-load">
          <div
            data-testid="upload-button"
            style={{
              display: "inline-block",
              overflow: "hidden",
              position: "relative",
            }}
          >
            <Button
              className="fw-bold rounded-2 btn-habi py-2 px-3"
              variant="primary"
            >
              <span>Seleccionar archivo</span>
              <input
                aria-label="Select file for upload"
                data-testid="upload-input"
                type="file"
                accept=".xlsx,.xls"
                style={{
                  bottom: 0,
                  cursor: "pointer",
                  height: "100%",
                  left: 0,
                  opacity: 0,
                  position: "absolute",
                  right: 0,
                  top: 0,
                  width: "100%",
                }}
                onChange={handleFileChange}
              />
            </Button>
          </div>
          <div> Archivos excel</div>
        </div>
      </Container>
    </div>
  );
};
