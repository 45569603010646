interface WrapperLeadDetailItemProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

export const WrapperLeadDetailItem = ({
  children,
  style,
}: WrapperLeadDetailItemProps) => {
  return (
    <div
      className="p-3 d-flex flex-column justify-content-center  border-end text-nowrap "
      style={style}
      data-testid="WrapperLeadDetailItem"
    >
      {children}
    </div>
  );
};
