// libraries.
import { useNavigate } from "react-router-dom";

// hooks.
import { useGetLeadDetailInfo } from "@store/apiSlices/leadDetail/leadDetail.hooks";
import { useSidebarContext } from "@shared/Sidebar";

// components.
import { SidebarButton } from "@shared/Sidebar/components/SidebarButton/SidebarButton";

// utils.
import { pathInventory } from "@routes/routes.types";
import { pathFeedbackRemo } from "@pages/InventoryPage/routes/routes.types";
import { ButtonSidebarInventory } from "../sidebar-inventory.utils";

// assets.
import { IconFeedRemo } from "@assets";
import { useUserProfile } from "@shared/GoogleLoginButton/user-slice/useUserProfile.hook";

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer: any[];
  }
}

export const ButtonFeedbackRemo = () => {
  const navigate = useNavigate();
  const { country } = useUserProfile();
  const { nidSelected } = useGetLeadDetailInfo();
  const {
    setSelectedButton,
    state: { selectedSidebarButton },
  } = useSidebarContext();

  const handleOnClick = () => {
    setSelectedButton(ButtonSidebarInventory.FEEDBACK_REMO);
    navigate(`${pathInventory}/${pathFeedbackRemo}/${nidSelected}`);

    const id = `${country}-tab-actions`.toLocaleLowerCase();

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "tab_change",
      tab: id,
    });
  };
  return (
    <SidebarButton
      hoverText="Histórico"
      onClick={handleOnClick}
      selected={selectedSidebarButton === ButtonSidebarInventory.FEEDBACK_REMO}
    >
      <IconFeedRemo fill="white" />
    </SidebarButton>
  );
};
