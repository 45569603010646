// libraries.
import { Button } from "react-bootstrap";

// hooks.
import { modalOptions, useModalOptions } from "@store/slices/modals";

// styles.
import { robotoRegular, scarpaGray50 } from "@habitech/shared";

export const NewComment = () => {
  const { openModal } = useModalOptions();

  return (
    <div data-testid="new-comment">
      <Button
        style={{
          boxShadow: `0px 0px 16px 0px ${scarpaGray50}`,
          fontFamily: `${robotoRegular}`,
        }}
        className="btn-habi rounded-2 fw-bold py-2 px-3"
        size="sm"
        onClick={() => openModal(modalOptions.modalInventoryPageCreateComment)}
      >
        Nuevo comentario
      </Button>
    </div>
  );
};
