// libraries.
import { BrowserRouter, Route, Routes } from "react-router-dom";

// pages.
import { routes } from "./routes";
import { NotFoundPage } from "@pages";
import { InventoryPortalRoute } from "@interfaces";

export const Router = () => {
  const renderRoutes = (routes: InventoryPortalRoute[]) =>
    routes.map((route) => {
      return (
        <Route key={route.id} path={route.path} element={route.element}>
          {route.children && renderRoutes(route.children)}
        </Route>
      );
    });

  return (
    <BrowserRouter>
      <Routes>
        {renderRoutes(routes)}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};
