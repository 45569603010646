import SearchIcon from "./search-icon.svg";
import AreaIcon from "./area-icon.svg";
import RoomIcon from "./room-icon.svg";
import BathIcon from "./bath-icon.svg";
import GarageIcon from "./garage-icon.svg";
import ElevatorIcon from "./elevator-icon.svg";
import LocationIcon from "./location-icon.svg";
import PropertyTypeIcon from "./property-type-icon.svg";
import AntiquityIcon from "./antiquity-icon.svg";
import PriceClientIcon from "./price-client-icon.svg";
import AgentIcon from "./agent-icon.svg";
import GrayWorkIcon from "./gray-work-icon.svg";
import HabiLogo from "./habi-logo.svg";
import KanbanIcon from "./kanban-icon.svg";
import MapIcon from "./map-icon.svg";
import CalculationsIcon from "./calculations-icon.svg";
import SummaryIcon from "./summary-icon.svg";
import RejectIcon from "./reject-icon.svg";
import LogoutIcon from "./logout-icon.svg";
import AddOrZoomInIcon from "./add-zoom-in-icon.svg";
import Search2Icon from "./search2-icon.svg";
import TooltipIcon from "./tooltip-icon.svg";
import CalendarIcon from "./calendar-icon.svg";
import FilterIcon from "./filter-icon.svg";
import HermesIcon from "./hermes-icon.svg";
import MoneyIcon from "./money-icon.svg";
import MotionIcon from "./motion-icon.svg";
import RefreshIcon from "./refresh-icon.svg";
import TypologiesIcon from "./typologies-icon.svg";
import EditLocationIcon from "./edit-location-icon.svg";
import ClustersIcon from "./clusters-icon.svg";
import MedianZonesIcon from "./median-zones-icon.svg";
import SignificanceSitesIcon from "./significance-sites-icon.svg";
import PinIcon from "./pin-icon.svg";
import SatelliteIcon from "./satellite-icon.svg";
import ComparablesIcon from "./comparables-icon.svg";
import HistoricalPricesIcon from "./historical-prices-icon.svg";
import DuplicatesIcon from "./duplicates-icon.svg";
import DeleteIcon from "./delete-icon.svg";
import ComplexIcon from "./complex-icon.svg";
import ZoneIcon from "./zone-icon.svg";
import ColombiaIcon from "./colombia-icon.svg";
import MexicoIcon from "./mexico-icon.svg";
import HabiLogoWhiteMX from "./habi-white-icon-mx.svg";
import HabiLogoWhiteCO from "./habi-white-icon-co.svg";
import ArrowLeft from "./arrow-left-icon.svg";
import ArrowRight from "./arrow-right-icon.svg";
import DownloadIcon from "./download-icon.svg";
import WarningIcon from "./warning-icon.svg";
import UptrendIcon from "./uptrend-icon.svg";
import DowntrendIcon from "./downtrend-icon.svg";
import FeedchatIcon from "./feedchat-icon.svg";
import CloseIcon from "./close-icon.svg";
import TableListIcon from "./tableList-icon.svg";
import CardsIcon from "./cards-icon.svg";
import HideIcon from "./hide-icon.svg";
import FeedremoIcon from "./feedremo-icon.svg";
import SuccessIcon from "./success-icon.svg";
import RemoActionsIcon from "./remo-actions.svg";
import RemoBrokersIcon from "./remo-brokers.svg";
import InventoryIcon from "./inventory-icon.svg";
import UploadIcon from "./upload-icon.svg";
import IIcon from "./i-icon.svg";
import CheckIcon from "./check-icon.svg";
import NotificationIcon from "./notification-icon.svg";
import ErrorIcon from "./error-icon.svg";

interface IconProps {
  fill?: string;
  height?: string;
  width?: string;
}

export const IconSuccess = ({
  fill,
  width = "24",
  height = "24",
}: IconProps) => {
  return <SuccessIcon style={{ color: fill }} width={width} height={height} />;
};

export const IconHide = ({ fill, width = "24", height = "24" }: IconProps) => {
  return <HideIcon style={{ color: fill }} width={width} height={height} />;
};

export const IconCards = ({ fill, width = "24", height = "24" }: IconProps) => {
  return <CardsIcon style={{ color: fill }} width={width} height={height} />;
};

export const IconTableList = ({
  fill,
  width = "24",
  height = "24",
}: IconProps) => {
  return (
    <TableListIcon style={{ color: fill }} width={width} height={height} />
  );
};

export const IconClose = ({
  fill = "none",
  width = "24",
  height = "24",
}: IconProps) => {
  return <CloseIcon style={{ color: fill }} width={width} height={height} />;
};

export const IconFeedchat = ({
  fill,
  width = "24",
  height = "24",
}: IconProps) => {
  return <FeedchatIcon style={{ color: fill }} width={width} height={height} />;
};

export const IconFeedRemo = ({
  fill,
  width = "24",
  height = "24",
}: IconProps) => {
  return <FeedremoIcon style={{ color: fill }} width={width} height={height} />;
};

export const IconDowntrend = ({
  fill,
  width = "24",
  height = "24",
}: IconProps) => {
  return (
    <DowntrendIcon style={{ color: fill }} width={width} height={height} />
  );
};

export const IconUptrend = ({
  fill,
  width = "24",
  height = "24",
}: IconProps) => {
  return <UptrendIcon style={{ color: fill }} width={width} height={height} />;
};

export const IconArrowRight = ({
  fill = "#7C01FF",
  width = "24",
  height = "24",
}: IconProps) => {
  return <ArrowRight style={{ color: fill }} width={width} height={height} />;
};

export const IconArrowLeft = ({
  fill = "#7C01FF",
  width = "24",
  height = "24",
}: IconProps) => {
  return <ArrowLeft style={{ color: fill }} width={width} height={height} />;
};

export const IconZone = ({
  fill = "#7C01FF",
  width = "24",
  height = "24",
}: IconProps) => {
  return <ZoneIcon style={{ color: fill }} width={width} height={height} />;
};

export const IconComplex = ({
  fill = "#7C01FF",
  width = "24",
  height = "24",
}: IconProps) => {
  return <ComplexIcon style={{ color: fill }} width={width} height={height} />;
};

export const IconDelete = ({
  fill = "#7C01FF",
  width = "24",
  height = "24",
}: IconProps) => {
  return <DeleteIcon style={{ color: fill }} width={width} height={height} />;
};

export const IconDuplicates = ({
  fill = "#7C01FF",
  width = "24",
  height = "24",
}: IconProps) => {
  return (
    <DuplicatesIcon style={{ color: fill }} width={width} height={height} />
  );
};

export const IconHistoricPrices = ({
  fill = "#7C01FF",
  width = "24",
  height = "24",
}: IconProps) => {
  return (
    <HistoricalPricesIcon
      style={{ color: fill }}
      width={width}
      height={height}
    />
  );
};

export const IconComparables = ({
  fill = "#7C01FF",
  width = "24",
  height = "24",
}: IconProps) => {
  return (
    <ComparablesIcon style={{ color: fill }} width={width} height={height} />
  );
};

export const IconSatellite = ({
  fill = "#7C01FF",
  width = "24",
  height = "24",
}: IconProps) => {
  return (
    <SatelliteIcon style={{ color: fill }} width={width} height={height} />
  );
};

export const IconPin = ({
  fill = "#7C01FF",
  width = "24",
  height = "24",
}: IconProps) => {
  return <PinIcon style={{ color: fill }} width={width} height={height} />;
};

export const IconSignificanceSites = ({
  fill = "#7C01FF",
  width = "24",
  height = "24",
}: IconProps) => {
  return (
    <SignificanceSitesIcon
      style={{ color: fill }}
      width={width}
      height={height}
    />
  );
};

export const IconMedianZones = ({
  fill = "#7C01FF",
  width = "24",
  height = "24",
}: IconProps) => {
  return (
    <MedianZonesIcon style={{ color: fill }} width={width} height={height} />
  );
};

export const IconClusters = ({
  fill = "#7C01FF",
  width = "24",
  height = "24",
}: IconProps) => {
  return <ClustersIcon style={{ color: fill }} width={width} height={height} />;
};

export const IconEditLocation = ({
  fill = "#7C01FF",
  width = "24",
  height = "24",
}: IconProps) => {
  return (
    <EditLocationIcon style={{ color: fill }} width={width} height={height} />
  );
};

export const IconTypologies = ({
  fill = "#7C01FF",
  width = "24",
  height = "24",
}: IconProps) => {
  return (
    <TypologiesIcon style={{ color: fill }} width={width} height={height} />
  );
};

export const IconRefresh = ({
  fill = "#7C01FF",
  width = "24",
  height = "24",
}: IconProps) => {
  return <RefreshIcon style={{ color: fill }} width={width} height={height} />;
};

export const IconMotion = ({
  fill = "#7C01FF",
  width = "24",
  height = "24",
}: IconProps) => {
  return <MotionIcon style={{ color: fill }} width={width} height={height} />;
};

export const IconMoney = ({
  fill = "#7C01FF",
  width = "24",
  height = "24",
}: IconProps) => {
  return <MoneyIcon style={{ color: fill }} width={width} height={height} />;
};

export const IconHermes = ({
  fill = "#7C01FF",
  width = "24",
  height = "24",
}: IconProps) => {
  return <HermesIcon style={{ color: fill }} width={width} height={height} />;
};

export const IconFilter = ({
  fill = "#7C01FF",
  width = "24",
  height = "24",
}: IconProps) => {
  return <FilterIcon style={{ color: fill }} width={width} height={height} />;
};

export const IconCalendar = ({
  fill = "#7C01FF",
  width = "24",
  height = "24",
}: IconProps) => {
  return <CalendarIcon style={{ color: fill }} width={width} height={height} />;
};

export const IconTooltip = ({ width = "24", height = "24" }: IconProps) => {
  return <TooltipIcon width={width} height={height} />;
};

export const IconSearch2 = ({
  fill = "#7C01FF",
  width = "24",
  height = "24",
}: IconProps) => {
  return <Search2Icon style={{ color: fill }} width={width} height={height} />;
};

export const IconAddOrZoomIn = ({
  fill = "#7C01FF",
  width = "24",
  height = "24",
}: IconProps) => {
  return (
    <AddOrZoomInIcon style={{ color: fill }} width={width} height={height} />
  );
};

export const IconLogout = ({ width = "24", height = "24" }: IconProps) => {
  return <LogoutIcon width={width} height={height} />;
};

export const IconReject = ({ width = "24", height = "24" }: IconProps) => {
  return <RejectIcon width={width} height={height} />;
};

export const IconSummary = ({
  fill = "#7C01FF",
  width = "24",
  height = "24",
}: IconProps) => {
  return <SummaryIcon style={{ color: fill }} width={width} height={height} />;
};

export const IconCalculations = ({
  fill = "#7C01FF",
  width = "24",
  height = "24",
}: IconProps) => {
  return (
    <CalculationsIcon style={{ color: fill }} width={width} height={height} />
  );
};

export const IconMap = ({
  fill = "#7C01FF",
  width = "24",
  height = "24",
}: IconProps) => {
  return <MapIcon style={{ color: fill }} width={width} height={height} />;
};

export const IconKanban = ({
  fill = "#7C01FF",
  width = "24",
  height = "24",
}: IconProps) => {
  return <KanbanIcon style={{ color: fill }} width={width} height={height} />;
};

export const IconInventory = ({
  fill = "#7C01FF",
  width = "24",
  height = "24",
}: IconProps) => {
  return (
    <InventoryIcon style={{ color: fill }} width={width} height={height} />
  );
};

export const IconUpload = ({
  fill = "#7C01FF",
  width = "24",
  height = "24",
}: IconProps) => {
  return <UploadIcon style={{ color: fill }} width={width} height={height} />;
};

export const IconHabi = ({ width = "185", height = "80" }: IconProps) => {
  return <HabiLogo width={width} height={height} />;
};

export const IconSearch = ({
  fill = "#7C01FF",
  width = "24",
  height = "24",
}: IconProps) => {
  return <SearchIcon style={{ color: fill }} width={width} height={height} />;
};

export const IconArea = ({
  fill = "#7C01FF",
  width = "24",
  height = "24",
}: IconProps) => {
  return <AreaIcon style={{ color: fill }} width={width} height={height} />;
};

export const IconRoom = ({
  fill = "#7C01FF",
  width = "24",
  height = "24",
}: IconProps) => {
  return <RoomIcon style={{ color: fill }} width={width} height={height} />;
};

export const IconBath = ({
  fill = "#7C01FF",
  width = "24",
  height = "24",
}: IconProps) => {
  return <BathIcon style={{ color: fill }} width={width} height={height} />;
};

export const IconGarage = ({
  fill = "#7C01FF",
  width = "24",
  height = "24",
}: IconProps) => {
  return <GarageIcon style={{ color: fill }} width={width} height={height} />;
};

export const IconElevator = ({
  fill = "#7C01FF",
  width = "24",
  height = "24",
}: IconProps) => {
  return <ElevatorIcon style={{ color: fill }} width={width} height={height} />;
};

export const IconLocation = ({
  fill = "#7C01FF",
  width = "24",
  height = "24",
}: IconProps) => {
  return <LocationIcon style={{ color: fill }} width={width} height={height} />;
};

export const IconPropertyType = ({
  fill = "#7C01FF",
  width = "24",
  height = "24",
}: IconProps) => {
  return (
    <PropertyTypeIcon style={{ color: fill }} width={width} height={height} />
  );
};

export const IconAntiquity = ({
  fill = "#7C01FF",
  width = "24",
  height = "24",
}: IconProps) => {
  return (
    <AntiquityIcon style={{ color: fill }} width={width} height={height} />
  );
};

export const IconPriceClient = ({
  fill = "#7C01FF",
  width = "24",
  height = "24",
}: IconProps) => {
  return (
    <PriceClientIcon style={{ color: fill }} width={width} height={height} />
  );
};

export const IconAgent = ({
  fill = "#7C01FF",
  width = "24",
  height = "24",
}: IconProps) => {
  return <AgentIcon style={{ color: fill }} width={width} height={height} />;
};

export const IconGrayWork = ({
  fill = "#7C01FF",
  width = "24",
  height = "24",
}: IconProps) => {
  return <GrayWorkIcon style={{ color: fill }} width={width} height={height} />;
};

export const IconMexico = ({ width = "24", height = "24" }: IconProps) => {
  return <MexicoIcon width={width} height={height} />;
};

export const IconColombia = ({ width = "24", height = "24" }: IconProps) => {
  return <ColombiaIcon width={width} height={height} />;
};

export const IconHabiLogoWhiteMX = ({
  width = "24",
  height = "24",
}: IconProps) => {
  return <HabiLogoWhiteMX width={width} height={height} />;
};

export const IconHabiLogoWhiteCO = ({
  width = "24",
  height = "24",
}: IconProps) => {
  return <HabiLogoWhiteCO width={width} height={height} />;
};

export const IconDownload = ({
  fill = "#7C01FF",
  width = "22",
  height = "22",
}: IconProps) => {
  return <DownloadIcon style={{ color: fill }} width={width} height={height} />;
};

export const IconWarning = ({
  fill = "#F09700",
  height = "24",
  width = "24",
}: IconProps) => {
  return <WarningIcon style={{ color: fill }} height={height} width={width} />;
};

export const IconRemoActions = ({
  fill = "#7C01FF",
  height = "22",
  width = "22",
}: IconProps) => {
  return (
    <RemoActionsIcon style={{ color: fill }} height={height} width={width} />
  );
};

export const IconRemoBrokers = ({
  fill = "",
  height = "22",
  width = "22",
}: IconProps) => {
  return (
    <RemoBrokersIcon style={{ color: fill }} height={height} width={width} />
  );
};

export const IconCheck = ({
  fill = "#7C01FF",
  height = "24",
  width = "24",
}: IconProps) => {
  return <CheckIcon height={height} width={width} style={{ color: fill }} />;
};

export const IconI = ({
  fill = "#7C01FF",
  height = "24",
  width = "24",
}: IconProps) => {
  return <IIcon height={height} width={width} style={{ color: fill }} />;
};

export const IconNotification = ({
  fill = "#7C01FF",
  height = "24",
  width = "24",
}: IconProps) => {
  return (
    <NotificationIcon height={height} width={width} style={{ color: fill }} />
  );
};

export const IconError = ({
  fill = "7C01FF",
  height = "24",
  width = "24",
}: IconProps) => {
  return <ErrorIcon style={{ color: fill }} height={height} width={width} />;
};
