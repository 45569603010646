// libraries.
import { Modal } from "react-bootstrap";

// assets.
import { ImageThumbUp } from "@assets";

export const EmptyNotifications = () => {
  return (
    <Modal.Body>
      <div className="d-flex flex-column justify-content-center align-items-center gap-4 w-100 h-100">
        <ImageThumbUp width={80} height={70} />
        <div className="d-flex flex-column align-items-center">
          <span className="titles">Estás al día</span>
          <span className="sub-titles">No tienes notificaciones nuevas.</span>
        </div>
      </div>
    </Modal.Body>
  );
};
