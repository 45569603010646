// hooks.
import { useUserLeadDetail } from "@shared/hooks/userLeadDetail.hook";
import { useGetPublicationPriceInfo } from "../pricing/pricing.hooks";
import { useGetHistoryLeadInfo } from "../historyLeadInfo/historyLeadInfo.hooks";
import { useGetValidateTopeQuery } from "./limitPrice.endpoints";

export const useValidateTope = ({ price }: { price?: number }) => {
  const { country, area, medianZone, medianZoneId } = useUserLeadDetail();

  const {
    getPublicationPriceInfoQuery: { data },
  } = useGetPublicationPriceInfo();

  const {
    getHistoryLeadInfoQuery: { data: historyLeadInfo },
  } = useGetHistoryLeadInfo();

  const comparableInfoUsed = historyLeadInfo?.used_comparables?.map(
    ({ price_per_m2, last_ask_price }) => ({
      price_m2: price_per_m2,
      sale_value: last_ask_price,
    }),
  );

  const comparableToJsonString = JSON.stringify(comparableInfoUsed);
  const comparableToEncodedString = encodeURIComponent(comparableToJsonString);

  const category = data?.categoryPriceName;
  const finalMedianZone = country === "MX" ? medianZone : medianZoneId;

  const shouldFetch =
    !!price &&
    !!country &&
    !!area &&
    !!finalMedianZone &&
    comparableToEncodedString !== null;

  const getValidateTopeQuery = useGetValidateTopeQuery(
    {
      area,
      category,
      comparables: comparableToEncodedString,
      country,
      medianZone: finalMedianZone,
      price,
    },
    {
      skip: !shouldFetch,
    },
  );

  return { getValidateTopeQuery };
};
