// libraries.
import { toast } from "react-toastify";
import { useEffect } from "react";
import { IComment } from "./comments.service";

// hooks.
import { useUserProfile } from "@shared/GoogleLoginButton/user-slice/useUserProfile.hook";

// endpoints.
import { useGetCommentsQuery } from "./commentList.endpoint";
import { useUpdateCommentMutation } from "./updateComment.endpoints";
import { useGetLeadDetailInfo } from "@store/apiSlices/leadDetail/leadDetail.hooks";
import { useCreateCommentMutation } from "./createComment.endpoint";

export function useComments() {
  const { nidSelected } = useGetLeadDetailInfo();
  const { country } = useUserProfile();

  const getCommentsQuery = useGetCommentsQuery({
    country,
    nid: nidSelected,
  });

  const [mutateCreateComment, mutateCreateCommentQuery] =
    useCreateCommentMutation();

  const [mutateUpdateComment, mutateUpdateCommentQuery] =
    useUpdateCommentMutation();

  const comments = getCommentsQuery?.data?.comments ?? [];

  const commentsNoArchived = comments.filter(
    (comment) =>
      comment.commentStatus !== "archived" &&
      comment.commentStatus !== "deleted",
  );
  const commentsArchived = comments.filter(
    (comment) => comment.commentStatus === "archived",
  );

  const createComment = (commentData: IComment) => {
    if (
      commentData.email &&
      commentData.commentCategory &&
      commentData.commentText &&
      commentData.nid &&
      commentData.commentExperience &&
      commentData.hashList &&
      country &&
      (commentData.commentType === "zone" || commentData.commentType === "nid")
    ) {
      mutateCreateComment({
        agent: commentData.email,
        category: commentData.commentCategory,
        comment: commentData.commentText,
        country: country,
        experience: commentData.commentExperience,
        hash_list: commentData.hashList,
        nid: commentData.nid,
        radius: commentData.radius,
        status: commentData.commentStatus,
        type: commentData.commentType,
      });
    }
  };

  useEffect(() => {
    if (mutateCreateCommentQuery.isLoading) {
      toast.loading("Creando comentario...", {
        autoClose: false,
        toastId: "createLoading",
      });
    } else if (mutateCreateCommentQuery.isSuccess) {
      getCommentsQuery.refetch();
      toast.dismiss("createLoading");
      toast.success("Comentario creado correctamente");
    } else if (mutateCreateCommentQuery.error) {
      toast.error("Error al crear el comentario");
      toast.dismiss("createLoading");
    } else {
      toast.dismiss("createLoading");
    }
  }, [
    mutateCreateCommentQuery.isSuccess,
    mutateCreateCommentQuery.isLoading,
    mutateCreateCommentQuery.error,
  ]);

  const editComment = (commentData: IComment) => {
    if (
      country &&
      commentData.email &&
      commentData.commentCategory &&
      commentData.commentText &&
      commentData.commentId &&
      commentData.nid &&
      commentData.commentStatus &&
      commentData.commentType &&
      commentData.commentExperience &&
      country
    ) {
      mutateUpdateComment({
        agent: commentData.email,
        category: commentData.commentCategory,
        comment: commentData.commentText,
        comment_id: commentData.commentId,
        country: country,
        experience: commentData.commentExperience,
        hash_list: commentData.hashList,
        nid: commentData.nid,
        radius: commentData.radius,
        status: commentData.commentStatus,
        type: commentData.commentType,
      });
    }
  };

  useEffect(() => {
    if (mutateUpdateCommentQuery.isLoading) {
      toast.loading("Actualizando comentario...", {
        autoClose: false,
        toastId: "updateLoading",
      });
    } else if (mutateUpdateCommentQuery.isSuccess) {
      getCommentsQuery.refetch();
      toast.dismiss("updateLoading");
      toast.success("Comentario actualizado correctamente");
    } else if (mutateUpdateCommentQuery.error) {
      toast.error("Error al actualizar el comentario");
      toast.dismiss("updateLoading");
    } else {
      toast.dismiss("updateLoading");
    }
  }, [
    mutateUpdateCommentQuery.isSuccess,
    mutateUpdateCommentQuery.isLoading,
    mutateUpdateCommentQuery.error,
  ]);

  return {
    comments,
    commentsArchived,
    commentsNoArchived,
    createComment,
    editComment,
    getCommentsQuery,
    mutateUpdateCommentQuery,
  };
}
