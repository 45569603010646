// libraries.
import styled from "styled-components";
import { SpaceButtons } from "../SidebarButton/styles";

export const WrapperSidebar = styled.div`
  background: #320066;
  box-shadow: 0px 0px 32px rgba(51, 51, 51, 0.24);
  transition: width 1s ease-in-out;
  height: 100vh;

  &:hover {
    ${SpaceButtons} {
      .hover-button {
        div {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
`;
