// libraries.
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "@store";
import {
  clearNid,
  setNid,
  setBusinessOpportunity as setBusinessOpportunityValue,
  setBusinessOpportunityIsConfirmed as setBusinessOpportunityIsConfirmedValue,
} from "./nid.slice";
import { OptionValue } from "@shared/Inputs/Dropdown";

export const useNid = () => {
  const dispatch = useDispatch();
  const nid = useSelector((state: RootState) => state.nidReducer.nid);
  const businessOpportunity = useSelector(
    (state: RootState) => state.nidReducer.businessOpportunity,
  );
  const businessOpportunityIsConfirmed = useSelector(
    (state: RootState) => state.nidReducer.businessOpportunityIsConfirmed,
  );

  const setNidValue = (newNid: string) => {
    dispatch(setNid(newNid));
  };

  const setBusinessOpportunity = (
    newBusinessOpportunity: OptionValue | null,
  ) => {
    dispatch(setBusinessOpportunityValue(newBusinessOpportunity));
  };

  const setBusinessOpportunityIsConfirmed = (newValue: boolean) => {
    dispatch(setBusinessOpportunityIsConfirmedValue(newValue));
  };

  const clearNidState = () => {
    dispatch(clearNid());
  };

  return {
    businessOpportunity,
    businessOpportunityIsConfirmed,
    clearNidState,
    nid,
    setBusinessOpportunity,
    setBusinessOpportunityIsConfirmed,
    setNidValue,
  };
};
