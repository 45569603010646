// libraries.
import { robotoRegular } from "@habitech/shared";

// utils.
import { commentCategoriesOptions } from "../utils/comment-categories";

export const BadgeCommentCategory = ({
  commentCategory,
}: {
  commentCategory?: string;
}) => {
  const commentCategoryValue =
    commentCategoriesOptions.find((category) => category.id === commentCategory)
      ?.label || commentCategory;

  return (
    <div style={{ fontFamily: `${robotoRegular}` }}>{commentCategoryValue}</div>
  );
};
