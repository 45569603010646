// libraries.
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface NotificationsState {
  notificationFilter: string;
}

export const initialStateNotifications: NotificationsState = {
  notificationFilter: "",
};

const moduleNotificationsSlice = createSlice({
  initialState: initialStateNotifications,
  name: "notifications_list",
  reducers: {
    clearNotifications: () => initialStateNotifications,
    setNotificationFilter: (state, action: PayloadAction<string>) => {
      state.notificationFilter = action.payload;
    },
  },
});

export const { clearNotifications, setNotificationFilter } =
  moduleNotificationsSlice.actions;

export default moduleNotificationsSlice.reducer;
