// components.
import { UsedComparablesList } from "./components/UsedComparablesList";
import { FiltersOnMap } from "./components/ComparablesFilters";
import { ComparablesMap } from "./components/ComparablesMap/ComparablesMap";

export const PublicationPriceMap = () => {
  return (
    <div className="d-flex h-100">
      <UsedComparablesList />
      <div className="w-100">
        <ComparablesMap />
      </div>
      <FiltersOnMap />
    </div>
  );
};
