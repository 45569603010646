// libraries.
import Card from "react-bootstrap/Card";

// components.
import { SourceBadgeTags } from "@shared/SourceBadges/SourceBadges";

// styles.
import { WrapperCard } from "./styles";

interface UsedComparablesCardProps {
  activateDelete?: boolean;
  address?: string;
  color?: string | number;
  condominium?: string;
  country?: string;
  elevator?: number;
  features?: string;
  nid?: number | null;
  price?: number | string;
  pricem2?: number | string;
  reasonForDelete?: boolean;
  source?: string | number;
  textReason?: string;
}

export const UsedComparablesCard = ({
  nid,
  color,
  source,
  price,
  pricem2,
  address,
  condominium,
  features,
  country,
  elevator,
}: UsedComparablesCardProps) => (
  <WrapperCard>
    <Card bg="light" style={{ width: "340px" }}>
      <Card.Body className="py-4 d-flex flex-column gap-2">
        <div className="d-flex justify-content-between align-items-center">
          <div className="nid">{nid || "s/nid"}</div>
          <SourceBadgeTags color={color || "WEB"}>
            {source || "WEB"}
          </SourceBadgeTags>
        </div>
        <div>
          <div className="comparable-price">{price}</div>
          <div className="features">
            Precio por m2: <span>{pricem2}</span>
          </div>
          <div className="features">
            {address || "Direccion"}{" "}
            {country === "CO" && condominium
              ? `| ${condominium}`
              : country === "CO" && "| Sin info"}
          </div>
          <div className="properties">
            {features || "Features inmueble"}・ {elevator}{" "}
            {country === "CO" ? "Asc." : "Elev."}
          </div>
        </div>
      </Card.Body>
    </Card>
  </WrapperCard>
);
