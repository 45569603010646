// libraries.
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// shared components.
import { useUserProfile } from "@shared/GoogleLoginButton/user-slice/useUserProfile.hook";

// hooks.
import { useLeadInProgressMutation } from "./statusPricing";
import { useGetLeadDetailInfo } from "../leadDetail/leadDetail.hooks";
import { pathPublicationPrice } from "@routes/routes.types";
import { pathPublicationPriceMap } from "@pages/PublicationPricePage/routes/routes.types";
import { useAbortLeadMutation } from "./abortLead";
import { useNid } from "@pages";
import { toast } from "react-toastify";

export const useLeadMutationAux = () => {
  const navigate = useNavigate();
  const {
    setBusinessOpportunity,
    setBusinessOpportunityIsConfirmed,
    businessOpportunity,
  } = useNid();

  const { user } = useUserProfile();
  const { getLeadDetailInfoQuery } = useGetLeadDetailInfo();

  const emailFromLeadDetail = getLeadDetailInfoQuery?.data?.agent?.email;
  const emailFromCurrentUser = user?.email;

  const leadIsInProgressByCurrentUser =
    emailFromCurrentUser === emailFromLeadDetail;

  const leadIsInProgressByOtherUser =
    emailFromLeadDetail && emailFromCurrentUser !== emailFromLeadDetail;

  const leadCanStartPricing = !getLeadDetailInfoQuery?.data?.agent?.email;

  const [leadInProgressMutation, { data, isLoading: isLoadingLeadInProgress }] =
    useLeadInProgressMutation();

  const [abortLead, { data: abortLeadData, isLoading: isLoadingAbortLead }] =
    useAbortLeadMutation();

  const handleLeadInProgressMutation = () => {
    if (
      !user?.country ||
      !getLeadDetailInfoQuery.data?.deal_id ||
      !businessOpportunity?.value
    )
      return;

    leadInProgressMutation({
      agent: user.email,
      country: user.country,
      id: getLeadDetailInfoQuery.data?.deal_id + "",
      price_type: businessOpportunity.value,
      status: "progress",
    });
    getLeadDetailInfoQuery.refetch();
  };

  const handleLeadAbortedMutation = () => {
    if (!user?.country || !getLeadDetailInfoQuery.data?.deal_id) return;

    abortLead({
      agent: user.email,
      country: user.country,
      id: getLeadDetailInfoQuery.data?.deal_id + "",
      status: "aborted",
    });
    getLeadDetailInfoQuery.refetch();
  };

  const handleContinueLead = () => {
    navigate(
      `${pathPublicationPrice}/${pathPublicationPriceMap}/${getLeadDetailInfoQuery.data?.nid}`,
    );
  };

  useEffect(() => {
    const leadIsInProgress =
      data?.message === "Success" && data?.status === "progress";

    if (leadIsInProgress) {
      navigate(
        `${pathPublicationPrice}/${pathPublicationPriceMap}/${getLeadDetailInfoQuery.data?.nid}`,
      );
    }
  }, [data?.message]);

  useEffect(() => {
    const leadIsAborted =
      abortLeadData?.message === "Success" &&
      abortLeadData?.status === "aborted";

    const leadIsNotAborted = abortLeadData?.message === "Not allow";

    if (leadIsAborted) {
      toast.warning("El lead ha sido abortado", {
        autoClose: 5000,
        toastId: "leadAbortWarning",
      });
      setBusinessOpportunityIsConfirmed(false);
      setBusinessOpportunity(null);
      getLeadDetailInfoQuery.refetch();
    } else if (leadIsNotAborted) {
      alert("No se pudo abortar el lead contacte a sistemas");
    }
  }, [abortLeadData?.message]);

  return {
    handleContinueLead,
    handleLeadAbortedMutation,
    handleLeadInProgressMutation,
    isLoadingAbortLead,
    isLoadingLeadInProgress,
    leadCanStartPricing,
    leadIsInProgressByCurrentUser,
    leadIsInProgressByOtherUser,
    status: data?.message,
  };
};
