// libraries.
import { useFormContext } from "react-hook-form";

// hooks.
import { usePriceCountdown } from "@store/apiSlices/priceCountdown/priceCountdown.hook";

//styles.
import { WrapperComment } from "./styles";

export const CommentInput = () => {
  const {
    watch,
    register,
    formState: { errors },
  } = useFormContext();
  const leader = watch("leader");

  const { isAbleToPrice, priceCountdown } = usePriceCountdown();

  if (!leader && !isAbleToPrice && priceCountdown > 0) {
    return;
  }

  return (
    <WrapperComment>
      <textarea
        {...register("commentInput", { required: "Este campo es obligatorio" })}
        className="w-100 rounded-3 p-2 "
        placeholder="Agregar comentario"
        style={{ height: "150px" }}
        data-testid="commentInput"
      />
      {errors.commentInput && (
        <small className="text-danger fw-bold d-flex justify-content-center">
          {String(errors.commentInput.message)}
        </small>
      )}
    </WrapperComment>
  );
};
