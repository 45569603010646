// libraries.
import { AnalyticsComments } from "./components/AnalyticsComments";
import { NewComment } from "@shared/NewComment";
import { useComments } from "@store/apiSlices/feedbackLoop/comments/comments.hook";
import { SwitchButton } from "./components/SwitchButton/SwitchButton";
import { monserratSemiBold } from "@habitech/shared";

export const TitleFeedbackLook = () => {
  const { commentsArchived, commentsNoArchived } = useComments();

  const showNewComment = (commentsNoArchived.length > 0 ||
    commentsArchived.length > 0) && <NewComment />;

  return (
    <div className="d-flex-column mb-4" data-testid="TitleFeedbackLook">
      <div className="d-flex justify-content-between align-items-center m-4">
        <div>
          <div
            className="mb-3"
            style={{ fontFamily: `${monserratSemiBold}`, fontWeight: "900" }}
          >
            Retroalimentación
          </div>
          <div className="d-flex gap-2 align-items-center">
            <AnalyticsComments />
          </div>
        </div>
        <div className="d-flex flex-column align-items-end gap-3">
          {showNewComment}
          <SwitchButton />
        </div>
      </div>
    </div>
  );
};
