// libraries.
import { useNavigate } from "react-router-dom";

// components.
import { useSidebarContext } from "@shared/Sidebar";
import { SidebarButton } from "@shared/Sidebar/components/SidebarButton/SidebarButton";

// hooks.
import {
  WrapperByUserRole,
  UserRoles,
} from "@shared/WrapperByUserRole/WrapperByUserRole";

// types.
import { SIDEBAR_BUTTON_INVENTORY } from "../sidebarHome.types";

// assets.
import { IconInventory } from "@assets";
import { pathHome } from "@routes/routes.types";
import { pathHomeInventoryList } from "@pages/HomePage/routes/routes.types";

export const ButtonInventory = () => {
  const navigate = useNavigate();
  const {
    setSelectedButton,
    state: { selectedSidebarButton },
  } = useSidebarContext();

  const handleOnClick = () => {
    setSelectedButton(SIDEBAR_BUTTON_INVENTORY);
    navigate(`${pathHome}/${pathHomeInventoryList}`);
  };

  return (
    <WrapperByUserRole role={[UserRoles.LEADER, UserRoles.INVENTORY]}>
      <SidebarButton
        hoverText="Inventario"
        onClick={handleOnClick}
        selected={selectedSidebarButton === SIDEBAR_BUTTON_INVENTORY}
      >
        <IconInventory fill="white" />
      </SidebarButton>
    </WrapperByUserRole>
  );
};
