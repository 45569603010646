// libraries.
import {
  electricPurple50,
  robotoRegular,
  scarpaGray500,
} from "@habitech/shared";

// components.
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  OptionValue,
} from "@shared/Inputs/Dropdown";

// utils.
import { categories } from "./utils";
import { useDropdownCategory } from "../dropdownCategory-slice/dropdownCategory.hook";

export const DropdownCategories = () => {
  const { selectedCategoryFromUser, setCategoryFromUser } =
    useDropdownCategory();

  const defaultOption = {
    label: selectedCategoryFromUser || "Seleccionar",
    value: "",
  };

  const onChange = ({ value }: { value?: OptionValue }) => {
    if (value) {
      setCategoryFromUser(value.value);
    }
  };

  return (
    <div className="">
      <Dropdown
        value={defaultOption}
        onChange={onChange}
        style={{ fontFamily: robotoRegular }}
      >
        <DropdownToggle
          id="categories"
          showArrow
          className="w-100 d-flex justify-content-between align-items-center bg-white"
          style={{
            border: `1px solid ${electricPurple50}`,
            color: scarpaGray500,
          }}
        />
        <DropdownMenu>
          {categories.map((option) => (
            <DropdownItem key={option.value} value={option}>
              {option.label}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};
