// libraries.
import { Modal, Form } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";

// hooks.
import { modalOptions, useModalOptions } from "@store/slices/modals";

// components.
import { TitleCreateComment } from "./components/TitleCreateComment";
import { FormCreateComment } from "./components/FormCreateComment/FormCreateComment";
import { ActionsToCreateComment } from "./components/ActionsToCreateComment";
import {
  CommentStatus,
  IComment,
} from "@store/apiSlices/feedbackLoop/comments/comments.service";
import { useComments } from "@store/apiSlices/feedbackLoop/comments/comments.hook";
import { useEffect } from "react";
import { electricPurple800 } from "@habitech/shared";
import { CommentPreview } from "./components/CommentPreview";
import { useGetLeadDetailInfo } from "@store/apiSlices/leadDetail/leadDetail.hooks";

export interface IFormCreateComment {
  commentCategory: string;
  commentExperience: string;
  commentId: number;
  commentText: string;
  commentType: "nid" | "zone";
  email: string;
  hashList: string[];
  nid: number;
  radius: number;
  user: string;
}

interface IUpdateComment {
  commentId: number | null;
}

export const ModalCreateComment = () => {
  const { nidSelected } = useGetLeadDetailInfo();

  const { createComment, comments, editComment } = useComments();
  const { isModalOpen, args, closeModal } = useModalOptions<IUpdateComment>();
  const show = isModalOpen(modalOptions.modalInventoryPageCreateComment);

  const methods = useForm<IFormCreateComment>({
    defaultValues: {
      commentCategory: "",
      commentExperience: "",
      commentId: 0,
      commentText: "",
      commentType: "nid",
      email: "",
      hashList: [],
      nid: Number(nidSelected),
      radius: 0,
      user: "",
    },
    mode: "all",
  });

  const commentId = methods.watch("commentId");
  const hasCommentId =
    commentId !== undefined && commentId !== null && commentId !== 0;

  const onSubmit = (data: IFormCreateComment) => {
    if (hasCommentId) {
      const serviceData: IComment = {
        commentCategory: data.commentCategory,
        commentExperience: data.commentExperience,
        commentId: commentId,
        commentStatus: CommentStatus.ACTIVE,
        commentText: data.commentText,
        commentType: data.commentType,
        email: data.email,
        hashList: data.hashList || [],
        nid: data.nid,
        radius: data.radius,
        user: data.user,
      };
      editComment(serviceData);
    } else {
      const serviceData: IComment = {
        commentCategory: data.commentCategory,
        commentExperience: data.commentExperience,
        commentStatus: CommentStatus.ACTIVE,
        commentText: data.commentText,
        commentType: data.commentType,
        email: data.email,
        hashList: data.hashList,
        nid: data.nid,
        radius: data.radius,
        user: data.user,
      };
      createComment(serviceData);
    }
    methods.reset();
    closeModal(modalOptions.modalInventoryPageCreateComment);
  };

  useEffect(() => {
    if (args?.commentId) {
      const comment = comments.find(
        (comment) => comment.commentId === args.commentId,
      );
      if (comment?.commentId) {
        methods.setValue("commentId", comment.commentId);
        methods.setValue("commentType", comment.commentType as "nid" | "zone");
        methods.setValue("radius", comment.radius);
        methods.setValue("commentCategory", comment.commentCategory);
        methods.setValue("commentExperience", comment.commentExperience);
        methods.setValue("commentText", comment.commentText);
        methods.setValue("nid", comment.nid || 0);
        methods.setValue("email", comment.email || "");
        methods.setValue("hashList", comment.hashList);
      }
    }
  }, [args?.commentId, methods]);

  return (
    <Modal
      data-testid="ModalCreateComment"
      show={show}
      centered={true}
      contentClassName="modal-content-right"
      dialogClassName="modal-dialog-right"
      className="mobile-modal modal-from-right"
      style={{ marginLeft: "calc(100vw - 380px)", width: "380px" }}
    >
      <FormProvider {...methods}>
        <Form
          onSubmit={methods.handleSubmit(onSubmit)}
          data-testid="FormCreateComment"
          className="h-100 d-flex p-3 flex-column justify-content-between"
          style={{ backgroundColor: electricPurple800 }}
        >
          <TitleCreateComment />
          <Modal.Body>
            <CommentPreview />
          </Modal.Body>
          <div>
            <FormCreateComment />
            <ActionsToCreateComment />
          </div>
        </Form>
      </FormProvider>
    </Modal>
  );
};
