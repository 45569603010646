// slices.
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { apiInventoryPortal } from "../apiSlices";
import { ModelError } from "../interfaces/interfaces";

interface Polygon {
  lat: number;
  lng: number;
}

interface FilterOption {
  count?: number | null;
  id: string | number;
  label: string;
  selected: boolean;
}
interface UsedFilter {
  area_max: number;
  area_min: number;
  baths: FilterOption[];
  buttonFilterOnMap: boolean;
  elevator: FilterOption[];
  from_date_create: FilterOption[];
  garage: FilterOption[];
  high_confidence: FilterOption[];
  low_confidence: FilterOption[];
  medium_confidence: FilterOption[];
  more_sources: number;
  no_confidence: FilterOption[];
  property_type_id: FilterOption[];
  radius: number;
  rooms?: FilterOption[];
  scale: string;
  years_old_max?: number;
  years_old_min?: number;
  years_old_null: FilterOption[];
}

export interface UsedComparables {
  activateDelete?: boolean;
  address?: string;
  address_code?: string | null;
  appraisal_date?: string | null;
  area?: number;
  balcony_area?: number | null;
  banos?: number;
  bath?: number;
  big_zone_id?: number;
  city_id?: number;
  comparable_category?: string;
  comparable_id?: number;
  condominium?: string | null;
  condominium_id?: number | null;
  confidence_levels?: string;
  date_create?: string;
  distance_m?: number;
  elevator?: number;
  f_p_area?: number | null;
  features?: string;
  flag_conjunto?: number;
  floor_num?: number;
  floor_number?: number;
  full_address?: string;
  garage?: number;
  gray_work?: number;
  ground_area?: number | null;
  half_bathroom?: number;
  isOutlier?: boolean;
  last_ask_price: number;
  latitude?: number;
  longitude?: number;
  lote_id?: number | null;
  median_zone_id?: number;
  median_zone_label?: string;
  name?: string;
  nid?: number | null;
  nid_comparable?: number;
  norm_price?: number;
  norm_price_m2?: number;
  normalizacion_garaje?: number | null;
  normalized?: {
    comparable_id?: number;
    higher_price?: string;
    higher_price_m2?: string;
    norm_flags?: string[];
    normalized_price?: number;
    normalized_price_m2?: number;
    original_price?: number;
    original_price_m2?: number;
  };
  num_apartment?: number | null;
  outliers?: number;
  phone?: string;
  priceClientNormalized?: number;
  pricePerM2Normalized?: number;
  price_per_m2?: number;
  property_deal_id?: number | null;
  property_id?: number | null;
  property_type?: string;
  property_type_id?: number;
  render_market?: boolean;
  room_num?: number | null;
  source?: string;
  source_id?: string | number;
  url?: string | null;
  years_old?: number;
  zip_code?: string | null;
}

interface UsedNormalization {
  comparable_id: number;
  higher_price: string;
  higher_price_m2: string;
  norm_flags: string[];
  normalized_price: number;
  normalized_price_m2: number;
  original_price: number;
  original_price_m2: number;
}

interface ExtraArea {
  extra_area: number;
  kind: string;
  percent: number;
  roof: string;
}

interface SetCompsComplex {
  comps_complex: {
    deleted_comparables: string[]; // Define Comparable según la estructura de tus comparables
    used_comps: UsedComparables[]; // Define Comparable según la estructura de tus comparables
  };
}

export interface HistoryLeadInfo {
  coefficient_of_variation_conj: number;
  coefficient_of_variation_zone: number;
  coefficient_variation: number;
  created_at: string;
  delete_comps_from_map: null | string[];
  discount_price: number;
  extra_area: ExtraArea[];
  feature_delete_comps: UsedComparables[];
  filters_comparable_zone: [];
  m2: number;
  median_price_m2: number;
  median_price_m2_conj: number;
  median_price_m2_zone?: number;
  meta_filter_step: string;
  nid: number;
  no_metafilter_comment: string;
  outliers: number[];
  percentage_calculation: number;
  percentil: number;
  polygon: Polygon[];
  possible_duplicated_comps: UsedComparables[];
  price_type: string;
  pricing_mode: number;
  set_comps_complex: SetCompsComplex;
  set_comps_zone: {
    comps_zone: {
      deleted_comparables?: [];
      used_comps: UsedComparables[];
    };
  };
  softec_comps?: string[];
  starting_price: number;
  used_comparables: UsedComparables[];
  used_filters: UsedFilter;
  used_normalizations: UsedNormalization[];
}

export const historyLeadInfo = apiInventoryPortal.injectEndpoints({
  endpoints: (builder) => ({
    getHistoryLeadInfo: builder.query({
      query: ({ country, nid }: { country?: string; nid?: string }) => {
        return `/get_last_price_info?country=${country}&nid=${nid}`;
      },
      transformErrorResponse: (error: FetchBaseQueryError) => {
        const modelError: ModelError = {
          errorStatus: error.status,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          messageFromServer: (error.data as any)?.message,
          serviceErrorName: "getHistoryLeadInfo",
        };
        return modelError;
      },
      transformResponse: (response: HistoryLeadInfo) => {
        return response;
      },
    }),
  }),
});

export const { useGetHistoryLeadInfoQuery } = historyLeadInfo;
