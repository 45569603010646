// libraries.
import { FileTypeData } from "@pages/HomePage/modules/BulkUpload/bulkUpload-slice/bulkUpload.slice";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

// slices.
import { apiInventoryPortal } from "@store/apiSlices/apiSlices";

// interfaces.
import { ModelError } from "@store/apiSlices/interfaces/interfaces";

export interface UpdateMassivePricesModel {
  agent: string;
  country?: string;
  list_prices: FileTypeData[];
}

interface UpdateMassivePricesResponse {
  body: string[];
  message: string;
  success: boolean;
}

export const updateMassivePrices = apiInventoryPortal.injectEndpoints({
  endpoints: (builder) => ({
    updateMassivePrices: builder.mutation({
      query: (newData: UpdateMassivePricesModel) => ({
        body: newData,
        method: "PUT",
        url: "/put_massive_price",
      }),
      transformErrorResponse: (error: FetchBaseQueryError): ModelError => {
        const modelError: ModelError = {
          errorStatus: error.status,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          messageFromServer: (error.data as any)?.message,
          serviceErrorName: "updateMassivePricesError",
        };
        return modelError;
      },
      transformResponse: (response: UpdateMassivePricesResponse): string[] => {
        return response.body;
      },
    }),
  }),
});

export const { useUpdateMassivePricesMutation } = updateMassivePrices;
