import { useUserProfile } from "@shared/GoogleLoginButton/user-slice/useUserProfile.hook";
import { useGetLeadDetailInfo } from "@store/apiSlices/leadDetail/leadDetail.hooks";

export const useUserLeadDetail = () => {
  const { country, email } = useUserProfile();
  const { nidSelected, area, medianZone, medianZoneId } =
    useGetLeadDetailInfo();

  const nid = nidSelected;

  return {
    area,
    country,
    email,
    medianZone,
    medianZoneId,
    nid,
  };
};
