// libraries.
import styled from "styled-components";
import {
  electricPurple000,
  monserratRegular,
  scarpaGray500,
  scarpaGray50,
  scarpaGray900,
  robotoRegular,
  monserratSemiBold,
} from "@habitech/shared";

export const WrapperCard = styled.div`
  cursor: pointer;
  background: ${electricPurple000};
  .card {
    border-radius: 0;
    border: 1px solid ${scarpaGray50};
  }
  .card-header {
    border-bottom: none;
    background: ${electricPurple000};
  }
  .card-body {
    background: ${electricPurple000};
  }
  .nid {
    font-family: ${monserratRegular};
    font-weight: 600;
    font-size: 12px;
  }
  .comparable-price {
    font-family: ${monserratSemiBold};
    font-weight: 600;
    font-size: 16px;
  }
  .features {
    font-family: ${robotoRegular};
    font-size: 12px;
    color: ${scarpaGray500};
    span {
      font-weight: 900;
      color: ${scarpaGray900};
    }
  }
  .properties {
    font-family: ${robotoRegular};
    font-size: 12px;
    font-weight: 900;
    color: ${scarpaGray900};
  }
`;

export const WrapperComparablesCard = styled.div`
  height: calc(100vh - 150px);
  overflow-y: scroll;
`;
