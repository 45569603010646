// shared components.
import { IconColombia, IconMexico } from "@assets";
import { electricPurple900, white } from "@habitech/shared";
import { useUserProfile } from "@shared/GoogleLoginButton/user-slice/useUserProfile.hook";
import { setCountry } from "@shared/GoogleLoginButton/user-slice/userSlice";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  OptionValue,
} from "@shared/Inputs/Dropdown";
import { useSidebarContext } from "@shared/Sidebar/components/Sidebar/Sidebar";
import { useDispatch } from "react-redux";
import { WrapperCountryDropdown } from "./styles";
import { useAuthMutationQuery } from "@store/apiSlices/auth/auth.hook";

export const CountryDropdown = () => {
  const dispatch = useDispatch();
  useAuthMutationQuery();
  const { country, countries } = useUserProfile();
  const {
    state: { isHoveredSidebar },
  } = useSidebarContext();

  const countryLen = countries.length;

  const defaultCountry = countries.find((item) => item.value === country);

  const onChange = ({ value }: { value?: OptionValue }) => {
    if (value) dispatch(setCountry(value.value));
  };

  if (countryLen === 1)
    return (
      <>
        <div>
          {countries[0].value === "CO" ? <IconColombia /> : <IconMexico />}{" "}
          {countries[0].label}
        </div>
      </>
    );

  return (
    <WrapperCountryDropdown data-testid="dropdownCountry">
      <Dropdown value={defaultCountry} onChange={onChange}>
        <DropdownToggle
          style={{
            background: electricPurple900,
            borderColor: electricPurple900,
            color: white,
            fontWeight: "600",
            height: "42px",
          }}
          className="p-2 d-flex gap-2 w-100 align-items-center"
          id="dropdown-basic"
          label="País"
          showArrow={isHoveredSidebar || false}
        >
          <>
            {defaultCountry?.value === "CO" && (
              <>
                <IconColombia /> {isHoveredSidebar && defaultCountry.label}
              </>
            )}
            {defaultCountry?.value === "MX" && (
              <>
                <IconMexico /> {isHoveredSidebar && defaultCountry.label}
              </>
            )}
          </>
        </DropdownToggle>
        <DropdownMenu
          style={{
            background: electricPurple900,
            borderColor: electricPurple900,
            color: white,
            fontWeight: "600",
          }}
        >
          {countries.map((country) => (
            <DropdownItem
              style={{
                color: white,
                fontWeight: "600",
                gap: "10px",
              }}
              key={country.value}
              value={country}
              className="d-flex py-2"
            >
              {country.value === "CO" ? <IconColombia /> : <IconMexico />}
              <p>{country.label}</p>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </WrapperCountryDropdown>
  );
};
