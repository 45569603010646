import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum CommentStatus {
  ACTIVE = "active",
  ARCHIVED = "archived",
  DELETED = "deleted",
}

export interface IComment {
  commentCategory: string;
  commentExperience: string;
  commentId?: number;
  commentStatus: CommentStatus;
  commentText: string;
  commentType: string;
  datePosted?: string;
  dateUpdated?: string;
  email?: string;
  hashList: string[];
  nid?: number;
  number?: number;
  radius: number;
  user: string;
  userId?: number;
}

export interface IComments {
  comments: IComment[];
}

const initialStateComments: IComments = {
  comments: [],
};

export const commentsSlice = createSlice({
  initialState: initialStateComments,
  name: "comments",
  reducers: {
    addComment: (state, action: PayloadAction<IComment>) => {
      state.comments.push(action.payload);
    },
    deleteComment: (state, action: PayloadAction<number>) => {
      state.comments = state.comments.filter(
        (comment) => comment.commentId !== action.payload,
      );
    },

    updateComment: (state, action: PayloadAction<IComment>) => {
      const index = state.comments.findIndex(
        (comment) => comment.commentId === action.payload.commentId,
      );
      if (index !== -1) {
        state.comments[index] = action.payload;
      }
    },
  },
});

export const { addComment, updateComment, deleteComment } =
  commentsSlice.actions;

export default commentsSlice.reducer;
