// libraries.
import { white } from "@habitech/shared";

import { IconSearch } from "@assets";

// components.
import { useSidebarContext } from "@shared/Sidebar";
import { SIDEBAR_SEARCH_LEAD } from "../sidebarHome.types";
import { SpaceButtons } from "@shared/Sidebar/components/SidebarButton/styles";
import { SearchByNid } from "../../NavBarUser/SearchByNid/SearchByNid";

export const SearchLead = () => {
  const {
    setSelectedButton,
    state: { selectedSidebarButton, isHoveredSidebar },
  } = useSidebarContext();

  const isSelected = selectedSidebarButton === SIDEBAR_SEARCH_LEAD;

  const handleOnClick = () => {
    setSelectedButton(SIDEBAR_SEARCH_LEAD);
  };

  return (
    <SpaceButtons
      onClick={handleOnClick}
      aria-hidden
      className={`p-2 ${isSelected ? "selected" : ""}`}
    >
      <div className="d-flex align-items-center gap-2 text-white fw-bold hover-button">
        {isHoveredSidebar && <SearchByNid />}
        <IconSearch fill={white} />
      </div>
    </SpaceButtons>
  );
};
