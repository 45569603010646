import { electricPurple300, scarpaGray400, skyBlue600 } from "@habitech/shared";
import { PolygonF } from "@react-google-maps/api";
import { Hexagon } from "@store/apiSlices/feedbackLoop/hexagons/commentHexagon.endpoint";
import { useGetHexagons } from "@store/apiSlices/feedbackLoop/hexagons/commentHexagon.hook";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

export const optionsDefaultPolygon = {
  fillColor: skyBlue600,
  fillOpacity: 0.5,
  strokeColor: skyBlue600,
  strokeWeight: 1,
};

export const optionsSelectedPolygon = {
  fillColor: electricPurple300,
  fillOpacity: 0.5,
  strokeColor: electricPurple300,
  strokeWeight: 1,
};

export const optionsHoverPolygon = {
  fillColor: scarpaGray400,
  fillOpacity: 0.5,
  strokeColor: scarpaGray400,
  strokeWeight: 1,
};

export const Hexagons = () => {
  const { setValue, watch } = useFormContext();

  const hashList = watch("hashList");

  const { hexagons } = useGetHexagons();
  const [hexagonsSelected, setHexagonsSelected] = useState<Hexagon[] | []>([]);
  const [hoveredHexagon, setHoveredHexagon] = useState<string | null>(null);

  useEffect(() => {
    if (hexagons.length > 0) {
      if (hashList.length === 0) {
        setHexagonsSelected(hexagons);
      } else {
        const hexagonsToDisplay = hexagons.map((hexagon) => {
          const isSelected = hashList.includes(hexagon.hash);
          return { ...hexagon, selected: isSelected };
        });
        setHexagonsSelected(hexagonsToDisplay);
      }
    }
  }, [hexagons]);

  const updateHexagon = (hash: string) => {
    const updatedHexagons = hexagonsSelected.map((hexagon: Hexagon) => {
      let selected = hexagon.selected;
      if (hexagon.hash === hash) {
        selected = !hexagon.selected;
      }
      return { ...hexagon, selected };
    });
    setHexagonsSelected(updatedHexagons);
    setValue(
      "hashList",
      updatedHexagons
        .filter((hexagon) => hexagon.selected)
        .map((hexagon) => hexagon.hash),
    );
  };

  const renderPolygons = () =>
    hexagonsSelected.map((hexagon: Hexagon) => {
      const isSelected = hexagon.selected
        ? optionsSelectedPolygon
        : optionsDefaultPolygon;

      const isHovered =
        hexagon.hash === hoveredHexagon ? optionsHoverPolygon : isSelected;

      return (
        <PolygonF
          data-testid={`polygon-${hexagon.hash}`}
          key={hexagon.hash}
          path={hexagon.path}
          options={isHovered}
          onClick={() => updateHexagon(hexagon.hash)}
          onMouseOver={() => setHoveredHexagon(hexagon.hash)}
          onMouseOut={() => setHoveredHexagon(null)}
          draggable={false}
        />
      );
    });

  return renderPolygons();
};
