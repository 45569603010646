// libraries.
import { useDebounce } from "@uidotdev/usehooks";
import { useFormContext } from "react-hook-form";

// shared
import { CardItem } from "@shared/DashboardComponentsSummary";
import { usePriceUtility } from "@store/apiSlices/utilityService/priceUtility.hook";
import { CardDropdownCategories } from "@shared/DropdownCategories";
import { useDropdownCategory } from "@shared/DropdownCategories/dropdownCategory-slice/dropdownCategory.hook";

export const CardUtilityMX = () => {
  const { watch } = useFormContext();

  const finalMinPublicationPrice = watch("finalMinPublicationPrice");
  const deferredOriginalValue = useDebounce(finalMinPublicationPrice, 1000);
  const { selectedCategoryFromUser } = useDropdownCategory();

  const showCategoryFromUser = selectedCategoryFromUser ? (
    <span className="fw-bold">({selectedCategoryFromUser})</span>
  ) : null;

  const {
    getMinimumPriceUtilityQuery: { isFetching, isError, status },
    utilityInitial,
  } = usePriceUtility({
    price: deferredOriginalValue,
  });

  const isErrorUtility = isError ? "Error API" : null;

  const cardStyle = "rounded-3 card-price-info p-3 d-flex flex-column gap-2";

  if (status === "uninitialized") {
    return (
      <CardItem classNameData={`default-shadow ${cardStyle}`}>
        <small className="title-card">Utilidad esperada</small>
        <div className="subtitle-card fw-bold">Sin calcularse</div>
      </CardItem>
    );
  }

  if (isFetching) {
    return (
      <CardItem classNameData={`default-shadow ${cardStyle}`}>
        <small className="title-card">
          Utilidad esperada {showCategoryFromUser}
        </small>
        <div className="subtitle-card fw-bold">Cargando...</div>
      </CardItem>
    );
  }

  if (utilityInitial === null) {
    return (
      <>
        <CardItem classNameData={`default-shadow ${cardStyle}`}>
          <small className="title-card">
            Utilidad esperada {showCategoryFromUser}
          </small>
          <div className="subtitle-card fw-bold">No se puede calcular</div>
        </CardItem>
        <CardDropdownCategories />
      </>
    );
  }

  return (
    <CardItem classNameData={`default-shadow ${cardStyle}`}>
      <small className="title-card">
        Utilidad esperada {showCategoryFromUser}
      </small>
      <div className="subtitle-card fw-bold">
        {isErrorUtility || `${utilityInitial}%`}
      </div>
    </CardItem>
  );
};
