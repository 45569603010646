// hooks.
import { useUserLeadDetail } from "@shared/hooks/userLeadDetail.hook";
import { useGetNotificationsInfoQuery } from "./notificationsInfo.endpoints";
import { useModuleNotifications } from "@pages/HomePage/modules/Notifications/notifications-slice/notifications.hook";

export const useNotificationsList = () => {
  const { country, email } = useUserLeadDetail();
  const { notificationFilter } = useModuleNotifications();

  const shouldFetch = !country && !email;

  const getNotificationsInfo = useGetNotificationsInfoQuery(
    {
      country,
      agent: email,
      filters: notificationFilter,
    },
    { skip: shouldFetch },
  );

  const notificationsList = getNotificationsInfo.data || [];
  const notificationsLen = getNotificationsInfo.data?.length || 0;
  const isLoading = getNotificationsInfo.isLoading;

  return {
    getNotificationsInfo,
    isLoading,
    notificationsLen,
    notificationsList,
  };
};
