// libraries.
import { useDebounce } from "@uidotdev/usehooks";
import { useFormContext } from "react-hook-form";

// hooks.
import { useGetPublicationPriceInfo } from "@store/apiSlices/pricing/pricing.hooks";
import { useIncreaseApprovalPrice } from "@store/apiSlices/increaseApprovalPrice/increaseApprovalPrice.hook";

// utils.
import { IncreasesInfo } from "./components/IncreasesInfo";
import { INTELLIGENCE_MARKET, REVENUE_STANDARD, TRADING_RANGES } from "./utils";
import { TotalPrices } from "./components/TotalPrices";
import { useEffect } from "react";

export const IncreaseApprovalPrice = () => {
  const { watch, setValue } = useFormContext();

  const {
    getPublicationPriceInfoQuery: { data: publicationPriceInfo },
  } = useGetPublicationPriceInfo();

  const isCurrentRevenue = publicationPriceInfo?.isCurrentRevenueStandard;
  const revenueStandard = publicationPriceInfo?.revenueStandard;
  const categoryPriceName = publicationPriceInfo?.categoryPriceName;

  const category = isCurrentRevenue ? categoryPriceName : revenueStandard;

  const originalMinimumPriceInput = watch("originalMinimumPrice");
  const deferredOriginalPrice = useDebounce(originalMinimumPriceInput, 1000);

  const {
    increaseApprovalPriceQuery: { data: increasePrices },
  } = useIncreaseApprovalPrice({
    priceMinimumOriginal: deferredOriginalPrice,
  });

  const finalMinPublicationPrice = increasePrices?.minimumPublicationPrice;

  useEffect(() => {
    if (finalMinPublicationPrice) {
      setValue("finalMinPublicationPrice", finalMinPublicationPrice);
    }
  }, [finalMinPublicationPrice, setValue]);

  if (!increasePrices) {
    return null;
  }

  return (
    <>
      <IncreasesInfo
        originalMinimumPrice={deferredOriginalPrice}
        increasePrices={increasePrices}
        increaseType={INTELLIGENCE_MARKET}
        revenueStandard={category}
      />
      <IncreasesInfo
        originalMinimumPrice={deferredOriginalPrice}
        increasePrices={increasePrices}
        increaseType={TRADING_RANGES}
        revenueStandard={category}
      />
      <IncreasesInfo
        originalMinimumPrice={deferredOriginalPrice}
        increasePrices={increasePrices}
        increaseType={REVENUE_STANDARD}
        revenueStandard={category}
      />
      <div className="line my-3" />
      <TotalPrices
        priceMin={increasePrices.minimumPublicationPrice}
        pricePublished={increasePrices.publicationPrice}
      />
    </>
  );
};
