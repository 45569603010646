// libraries.
import { electricPurple600, robotoRegular } from "@habitech/shared";
import styled from "styled-components";

export const WrapperInventoryFilters = styled.div`
  .filter-toggle {
    font-size: 14px;
    width: 160px;
    height: 42px;
    font-family: ${robotoRegular};
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
  .filter-check {
    padding-left: 2.5rem;
    margin-bottom: 0.25rem;
    box-shadow: none;
    .form-check-label {
      font-size: 12px;
      font-family: ${robotoRegular};
      cursor: pointer;
    }
    .form-check-input:active,
    .form-check-input:focus {
      box-shadow: none !important;
      border: var(--bs-border-width) solid var(--bs-border-color);
    }
    .form-check-input:checked {
      background-color: ${electricPurple600};
      border-color: ${electricPurple600};
      box-shadow: none;
    }
  }
  .counter {
    background-color: ${electricPurple600};
    color: white;
    border-radius: 50%;
    padding: 4px;
    line-height: 1;
    height: 20px;
    position: absolute;
    top: -7px;
    right: -7px;
    min-width: 20px;
    font-size: 12px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: ${robotoRegular};
  }
`;
