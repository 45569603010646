// hook.
import { useGetInventoryPropertyInfo } from "@store/apiSlices/inventoryPropertyInfo/inventoryPropertyInfo.hook";

// component.
import { UtilityCardsCO } from "./components/UtilityCardsCO";

// styles
import {
  CardItem,
  Title,
  WrapperCardItems,
} from "@shared/DashboardComponentsSummary";
import { CardGrossMargin } from "../../../../shared/CardGrossMargin";

export const PropertyUnitCO = () => {
  const {
    getInventoryPropertyInfoQuery: { data },
  } = useGetInventoryPropertyInfo();

  const percentil = data?.percentil || 0;

  return (
    <>
      <Title>Unit del inmueble</Title>
      <WrapperCardItems className="price-info-grid">
        <UtilityCardsCO />
        <CardItem classNameData="default-shadow rounded-3 card-price-info p-3 d-flex flex-column gap-2">
          <small className="title-card">Percentil</small>
          <div className="subtitle-card">{percentil}</div>
        </CardItem>
        <CardGrossMargin />
      </WrapperCardItems>
    </>
  );
};
