// libraries.
import { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import { toast } from "react-toastify";

// hooks.
import { PriceSummaryProvider } from "./priceSummary-reducer/priceSummary.context";
import { useGetPublicationPriceInfo } from "@store/apiSlices/pricing/pricing.hooks";

// components.
import { PublicationPriceSummaryByCountry } from "./components";

// styles.
import { WrapperPublicationPriceSummary } from "./styles";

export const PublicationPriceSummary = () => {
  const {
    getPublicationPriceInfoQuery: { isLoading },
  } = useGetPublicationPriceInfo();

  useEffect(() => {
    if (isLoading) {
      toast.loading("Cargando info publication price...", {
        toastId: "loadingPublicationPriceInfo",
      });
    } else {
      toast.dismiss("loadingPublicationPriceInfo");
    }
  }, [isLoading]);

  return (
    <PriceSummaryProvider>
      <WrapperPublicationPriceSummary style={{ height: "calc(100vh - 50px)" }}>
        <Container fluid className="h-100 overflow-auto">
          <Row className="h-100">
            <PublicationPriceSummaryByCountry />
          </Row>
        </Container>
      </WrapperPublicationPriceSummary>
    </PriceSummaryProvider>
  );
};
