import DropdownBootstrap from "react-bootstrap/Dropdown";
import { DropdownContext } from "./Dropdown";
import { useContext } from "react";
interface DropdownToggleProps {
  children?: React.ReactElement;
  className?: string;
  id: string;
  label?: string;
  showArrow?: boolean;
  style?: React.CSSProperties;
}
export const DropdownToggle = ({
  id,
  style,
  className,
  label,
  children,
  showArrow,
}: DropdownToggleProps) => {
  const { value } = useContext(DropdownContext);
  const selectedLabel = children || value?.label || label;

  return (
    <DropdownBootstrap.Toggle
      id={id}
      className={`${showArrow ? "" : "noShowArrow"} ${className}`}
      style={style}
    >
      {selectedLabel}
    </DropdownBootstrap.Toggle>
  );
};
