import { WrapperTabs } from "./styles";

export const ComparablesTabs = () => {
  return (
    <div className="bg-light p-4">
      <WrapperTabs>
        <div className="title-tabs pb-2">Comparables usados:</div>
        <div className="d-flex justify-content-between">
          <div className="filters">Ver (# comps)</div>
          <div className="filters">Ordenar por (filtros)</div>
        </div>
      </WrapperTabs>
    </div>
  );
};
