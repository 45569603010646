// hooks.
import { useGetPublicationPriceInfo } from "@store/apiSlices/pricing/pricing.hooks";

// components.
import { formatPrice } from "@utils";
import { CardUtilityMX } from "./components/CardUtilityMX";
import { CardTooltipMessage } from "./components/CardTooltipMessage";
import { InfoTagHover } from "@shared";

// assets.
import { IconSuccess } from "@assets";

// styles.
import {
  CardItem,
  Title,
  WrapperCardItems,
} from "@shared/DashboardComponentsSummary";

export const PriceInfoCardMX = () => {
  const {
    getPublicationPriceInfoQuery: { data, isLoading },
  } = useGetPublicationPriceInfo();

  const cardStyle = "rounded-3 card-price-info p-3 d-flex flex-column gap-2";
  const isLoadingData = isLoading && "Cargando...";
  const isHabiIsHabi = data?.isHabiIsHabi ? "Sí" : "No";

  const isRevenueStandardCurrent = data?.isCurrentRevenueStandard;

  const category = data?.categoryPriceName || "No tiene";

  const witchCategoryUse = isRevenueStandardCurrent
    ? category
    : data?.revenueStandardCopy || "No tiene";

  const warningAskPriceCommittee =
    (data?.askPriceCommittee || 0) < (data?.minPublicationPrice || 0);

  return (
    <div className="d-flex flex-column gap-3">
      <Title>Aprobación de publicación</Title>
      <WrapperCardItems className="price-info-grid">
        <CardItem classNameData={`default-shadow ${cardStyle}`}>
          <small className="title-card">Ask price comité</small>
          <div className="d-flex align-items-center justify-content-between">
            <div
              className={`subtitle-card fw-bold ${
                warningAskPriceCommittee && "warning"
              }`}
            >
              {isLoadingData}
              {data?.askPriceCommittee && formatPrice(data.askPriceCommittee)}
            </div>
            <CardTooltipMessage
              warningFlag={warningAskPriceCommittee}
              message="Menor al precio mínimo original"
            />
          </div>
        </CardItem>
        <CardItem classNameData={`default-shadow ${cardStyle}`}>
          <small className="title-card">Total remo</small>
          <div className="subtitle-card fw-bold">
            {isLoadingData}
            {data?.totalRemo && formatPrice(data.totalRemo)}
          </div>
        </CardItem>
        <CardItem classNameData={`default-shadow ${cardStyle}`}>
          <small className="title-card">Mejora remo</small>
          <div className="subtitle-card fw-bold">
            {isLoadingData}
            {data?.improvementsPrice && formatPrice(data.improvementsPrice)}
          </div>
        </CardItem>
        <CardItem classNameData={`default-shadow ${cardStyle}`}>
          <small className="title-card">Pintura remo</small>
          <div className="subtitle-card fw-bold">
            {isLoadingData}
            {data?.paintingPrice && formatPrice(data.paintingPrice)}
          </div>
        </CardItem>
        <CardItem classNameData={`default-shadow ${cardStyle}`}>
          <small className="title-card">Reparación remo</small>
          <div className="subtitle-card fw-bold">
            {isLoadingData}
            {data?.repairsPrice && formatPrice(data.repairsPrice)}
          </div>
        </CardItem>
        <CardItem classNameData={`default-shadow ${cardStyle}`}>
          <small className="title-card">Aumento BO</small>
          <div className="subtitle-card fw-bold">
            {isLoadingData}
            {data?.boIncrementPrice && formatPrice(data.boIncrementPrice)}
          </div>
        </CardItem>
        <CardItem classNameData={`default-shadow ${cardStyle}`}>
          <small className="title-card">Habi es Habi</small>
          <div className="subtitle-card fw-bold">{isHabiIsHabi}</div>
        </CardItem>
        <CardItem classNameData={`default-shadow ${cardStyle}`}>
          <small className="title-card">Categoría</small>
          <div className="d-flex justify-content-between">
            <div className="subtitle-card fw-bold">{witchCategoryUse}</div>
            <div className="d-flex justify-content-between">
              <CardTooltipMessage
                warningFlag={data?.isHabiIsHabi}
                message="Es Habi es Habi"
              />
              {isRevenueStandardCurrent && (
                <InfoTagHover
                  style={{ left: "-20px", top: "16px" }}
                  icon={<IconSuccess />}
                >
                  Vigente
                </InfoTagHover>
              )}
            </div>
          </div>
        </CardItem>
        <CardUtilityMX />
      </WrapperCardItems>
    </div>
  );
};
