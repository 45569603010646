// libraries.
import { Button, Modal } from "react-bootstrap";
import { WrapperArchivedModal } from "./styles";
import { modalOptions, useModalOptions } from "@store/slices/modals";
import { useFormContext } from "react-hook-form";
import { IFormCreateComment } from "../ModalCreateComment/ModalCreateComment";
import { useComments } from "@store/apiSlices/feedbackLoop/comments/comments.hook";
import {
  CommentStatus,
  IComment,
} from "@store/apiSlices/feedbackLoop/comments/comments.service";
import { useEffect } from "react";

export const ModalArchived = () => {
  const { watch, handleSubmit, reset } = useFormContext<IFormCreateComment>();

  const { isModalOpen, closeModal, closeAllModals } = useModalOptions();

  const showModalArchived = isModalOpen(
    modalOptions.modalInventoryPageArchivedComment,
  );

  const cancelArchived = () => {
    closeModal(modalOptions.modalInventoryPageArchivedComment);
  };

  const commentId = watch("commentId");
  const { editComment, mutateUpdateCommentQuery } = useComments();

  const onSubmit = (data: IFormCreateComment) => {
    const serviceData: IComment = {
      commentCategory: data.commentCategory,
      commentExperience: data.commentExperience,
      commentId: commentId,
      commentStatus: CommentStatus.ARCHIVED,
      commentText: data.commentText,
      commentType: data.commentType,
      email: data.email,
      hashList: data.hashList,
      nid: data.nid,
      radius: data.radius,
      user: data.user,
    };
    editComment(serviceData);
    reset();
  };

  useEffect(() => {
    if (mutateUpdateCommentQuery.isSuccess) {
      closeAllModals();
    }
  }, [mutateUpdateCommentQuery.isSuccess]);

  return (
    <Modal show={showModalArchived} onHide={cancelArchived} centered>
      <WrapperArchivedModal>
        <Modal.Header>
          <Modal.Title className="second-title px-3">
            Archivar comentario para Aprobologos
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="simple-text px-4">
          <span>
            ¿Estás seguro de archivar el comentario para los Aprobologos?
          </span>
        </Modal.Body>
        <Modal.Footer className="modal-footer buttons-text px-4">
          <Button
            onClick={cancelArchived}
            className="btn-secundary-habi rounded-pill px-4"
            aria-label="Cancelar"
          >
            Cancelar
          </Button>
          <Button
            onClick={handleSubmit(onSubmit)}
            className="btn-habi rounded-pill px-4"
          >
            Archivar
          </Button>
        </Modal.Footer>
      </WrapperArchivedModal>
    </Modal>
  );
};
