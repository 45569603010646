import { useUserProfile } from "@shared/GoogleLoginButton/user-slice/useUserProfile.hook";
import { PublicationPriceSummaryCO } from "./PublicationPriceSummaryCO/PublicationPriceSummaryCO";
import { PublicationPriceSummaryMX } from "./PublicationPriceSummMX/PublicationPriceSummaryMX";

interface PriceInfosByCountryComponent {
  component: React.ReactNode;
  country: string;
}

export const PriceInfosByCountry: PriceInfosByCountryComponent[] = [
  {
    component: <PublicationPriceSummaryMX />,
    country: "MX",
  },
  { component: <PublicationPriceSummaryCO />, country: "CO" },
];

export const PublicationPriceSummaryByCountry = () => {
  const { user } = useUserProfile();
  const priceInfoComponent = PriceInfosByCountry.find(
    (filter) => filter.country === user.country,
  );
  return priceInfoComponent && priceInfoComponent.component;
};
