// libraries
import { toast } from "react-toastify";
import { useNid } from "@pages/HomePage";
import { pathHomeReleaseNotes } from "@pages/HomePage/routes/routes.types";
import { pathHome } from "@routes/routes.types";
import { useUserProfile } from "@shared/GoogleLoginButton/user-slice/useUserProfile.hook";
import { useGetLeadDetailInfo } from "@store/apiSlices/leadDetail/leadDetail.hooks";
import { useGetPublicationPriceInfo } from "@store/apiSlices/pricing/pricing.hooks";
import { useSaveApprovalPublicationPriceMutation } from "@store/apiSlices/statusPricing/saveApprovalPublicationPrice.endpoints";
import { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import { usePriceUtility } from "@store/apiSlices/utilityService/priceUtility.hook";
import { useDebounce } from "@uidotdev/usehooks";

export const SavePricingButtonCO = () => {
  const navigate = useNavigate();
  const { watch } = useFormContext();

  const pricePublicationInput = watch("publicationPrice");
  const minPublicationPriceInput = watch("minPublicationPrice");
  const utilityOption = watch("utilityOption");

  const deferredMinPublicationPriceValue = useDebounce(
    minPublicationPriceInput,
    1000,
  );

  const {
    getMinimumPriceUtilityQuery: { status },
    utilityInitial,
    isUtilityInitialNull,
  } = usePriceUtility({
    price: deferredMinPublicationPriceValue,
  });

  const { user } = useUserProfile();

  const { getLeadDetailInfoQuery } = useGetLeadDetailInfo();
  const dealId = getLeadDetailInfoQuery.data?.deal_id + "";

  const {
    getPublicationPriceInfoQuery: { data: publicationPriceInfo },
  } = useGetPublicationPriceInfo();

  const { businessOpportunity } = useNid();
  const businessOpportunityValue = businessOpportunity?.value;

  const [
    handlePostStatusPricingCO,
    { isSuccess, isError, isLoading: isLoadingSavePriceCO },
  ] = useSaveApprovalPublicationPriceMutation();

  const savePricingCO = () => {
    if (
      !publicationPriceInfo ||
      !user.country ||
      !dealId ||
      !businessOpportunityValue
    )
      return;

    handlePostStatusPricingCO({
      agent: user.email,
      country: user.country,
      current_category: publicationPriceInfo.revenueStandard,
      discard_reason_variation: publicationPriceInfo.discardReasonVariation,
      id: dealId,
      initial_utility: utilityInitial || 0,
      minimum_publication_price: Number(minPublicationPriceInput),
      price_type: businessOpportunityValue,
      publication_price: Number(pricePublicationInput),
      purchase_price: publicationPriceInfo.purchasePrice,
      status: "completed",
      utility_option: utilityOption,
      variation_price: publicationPriceInfo.variationPrice,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      navigate(`${pathHome}/${pathHomeReleaseNotes}`);
      toast.success("Precios guardados correctamente");
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      toast.error("Error al guardar los precios");
    }
  }, [isError]);

  useEffect(() => {
    if (isLoadingSavePriceCO) {
      toast.loading("Guardando precios...", {
        toastId: "loadingSavePrice",
      });
    } else {
      toast.dismiss("loadingSavePrice");
    }
  }, [isLoadingSavePriceCO]);

  if (status !== "fulfilled") {
    return (
      <Button
        onClick={savePricingCO}
        className="w-100 rounded-5 p-3"
        disabled={true}
      >
        Validando utilidad...
      </Button>
    );
  }

  return (
    <Button
      onClick={savePricingCO}
      className="w-100 rounded-5 p-3"
      disabled={isUtilityInitialNull}
    >
      {isUtilityInitialNull ? "Utilidad inicial no valida" : "Guardar precios"}
    </Button>
  );
};
