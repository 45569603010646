import { ImgProps } from "@assets/interfaces";
import heroImage from "./hero.jpg";
import pinImage from "./pin.png";
import thumbUpImage from "./thumb-up.png";

export const ImageHero = ({ style, className, width, height }: ImgProps) => (
  <img
    src={heroImage}
    alt="hero"
    className={className}
    style={style}
    width={width}
    height={height}
  />
);

export const ImagePin = ({ style, className, width, height }: ImgProps) => (
  <img
    src={pinImage}
    alt="pin"
    className={className}
    style={style}
    width={width}
    height={height}
  />
);

export const ImageThumbUp = ({ style, className, width, height }: ImgProps) => (
  <img
    src={thumbUpImage}
    alt="thumb-up"
    className={className}
    style={style}
    width={width}
    height={height}
  />
);
