import { useEffect } from "react";
import { useAuthMutation } from "./auth";
import { useUserProfile } from "@shared/GoogleLoginButton/user-slice/useUserProfile.hook";
import { extractCountryCodeFromEmail } from "@utils";

export const useAuthMutationQuery = () => {
  const { user, countries, updateUserProfile } = useUserProfile();
  const [authMutation, { data }] = useAuthMutation();

  const countriesSelect = data?.countriesSelect || [];

  useEffect(() => {
    if (user?.googleId) {
      authMutation({ userId: user.googleId });
    }
  }, [user?.googleId]);

  useEffect(() => {
    if (user?.email && countries?.length === 0 && countriesSelect.length > 0) {
      const countriesLen = countriesSelect.length;

      let countryCode;
      if (countriesLen === 1) {
        countryCode = countriesSelect[0].value;
      } else {
        countryCode = extractCountryCodeFromEmail(user.email);
      }
      updateUserProfile({
        ...user,
        countries: countriesSelect,
        country: countryCode,
      });
    }
  }, [user?.email, countriesSelect]);

  return { data };
};
