export const checkOptions = [
  { label: "Seleccionar todas", value: "selectAll" },
  { label: "Descargas", value: "download" },
  { label: "Clicks", value: "click" },
  { label: "Ofertas", value: "offer" },
  { label: "Visitas", value: "visit" },
  { label: "Sin alertas", value: "no_alert" },
];

export const bucketCheck = [
  { label: "Seleccionar todos", value: "allSelected" },
  { label: "PreVenta", value: "pre_sale" },
  { label: "0 a 30 días", value: "0-30" },
  { label: "31 a 60 días", value: "31-60" },
  { label: "61 a 90 días", value: "61-90" },
  { label: "91 a 120 días", value: "91-120" },
  { label: "121 a 150 días", value: "121-150" },
  { label: "151 a 180 días", value: "151-180" },
  { label: "181 a 210 días", value: "181-210" },
  { label: ">210", value: "more-210" },
];
