// styles.
import {
  CardItem,
  Title,
  WrapperCardItems,
} from "@shared/DashboardComponentsSummary";

// hook.
import { useGetInventoryPropertyInfo } from "@store/apiSlices/inventoryPropertyInfo/inventoryPropertyInfo.hook";

export const PropertyStatistics = () => {
  const {
    getInventoryPropertyInfoQuery: { data },
  } = useGetInventoryPropertyInfo();
  return (
    <>
      <Title>Estadísticas del inmueble</Title>
      <WrapperCardItems className="price-info-grid">
        <CardItem classNameData="default-shadow rounded-3 card-price-info p-3 d-flex flex-column gap-2">
          <small className="title-card">Clicks</small>
          <div className="d-flex justify-content-between">
            <div className="subtitle-card">{data?.click_num}</div>
            {/* esto está comentado porque aún no se puede implementar
            
            <div>
              <IconUptrend />
              <small className="statistics-percentage">{`${data.clickPercentage}%`}</small>
            </div> */}
          </div>
        </CardItem>
        <CardItem classNameData="default-shadow rounded-3 card-price-info p-3 d-flex flex-column gap-2">
          <small className="title-card">Descargas</small>
          <div className="d-flex justify-content-between">
            <div className="subtitle-card">{data?.download_num}</div>
            {/*esto está comentado porque aún no se puede implementar
            
            <div>
              <IconDowntrend />
              <small className="statistics-percentage">{`${data.downloadsPercentage}%`}</small>
            </div> */}
          </div>
        </CardItem>
        <CardItem classNameData="default-shadow rounded-3 card-price-info p-3 d-flex flex-column gap-2">
          <small className="title-card">Visitas</small>
          <div className="d-flex justify-content-between">
            <div className="subtitle-card">{data?.visit_num}</div>
            {/* esto está comentado porque aún no se puede implementar <div>
              <IconDowntrend />
              <small className="statistics-percentage">{`${data.visitsPercentage}%`}</small>
            </div> */}
          </div>
        </CardItem>
        <CardItem classNameData="default-shadow rounded-3 card-price-info p-3 d-flex flex-column gap-2">
          <small className="title-card">Ofertas</small>
          <div className="d-flex justify-content-between">
            <div className="subtitle-card">{data?.offer_num}</div>
            {/*esto está comentado porque aún no se puede implementar
             <div>
              <IconUptrend />
              <small className="statistics-percentage">{`${data.offersPercentage}%`}</small>
            </div> */}
          </div>
        </CardItem>
      </WrapperCardItems>
    </>
  );
};
