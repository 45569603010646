// libraries.
import { Table } from "react-bootstrap";

// hooks.
import { UsedComparables } from "@store/apiSlices/historyLeadInfo/historyLeadInfo.endpoints";
import { useGetHistoryLeadInfo } from "@store/apiSlices/historyLeadInfo/historyLeadInfo.hooks";

// components.
import { SourceBadgeTags } from "@shared/SourceBadges/SourceBadges";
import { NormalizedM2Price } from "../NormalizedPrice/NormalizedM2Price";
import { NormalizedClientPrice } from "../NormalizedPrice/NormalizedClient";

// styles.
import { WrapperTable } from "./styles";

// utils.
import { tableHeaders } from "../../utils";
import { formatPrice } from "@utils/formatPrice.utils";

interface TableMXProps {
  comparablesList: UsedComparables[];
}

export const TableMX = ({ comparablesList }: TableMXProps) => {
  const {
    getHistoryLeadInfoQuery: { data },
  } = useGetHistoryLeadInfo();

  const tableList = comparablesList?.map((item) => {
    const isOutlier = data?.outliers?.includes(item.comparable_id) ?? false;
    if (isOutlier) {
      return {
        ...item,
        isOutlier,
      };
    }
    return item;
  });

  const tableBody = () => {
    return (
      <tbody>
        {tableList?.map((comparable, rowIndex) => (
          <tr key={rowIndex}>
            <td
              className={comparable.isOutlier ? "outlier-numb" : "sticky-numb"}
            >
              {rowIndex + 1}
            </td>
            <td className={comparable.isOutlier ? "outlier-id" : "id"}>
              {comparable?.comparable_id}
            </td>
            <td>{comparable?.nid || "null"}</td>
            <td>{comparable?.date_create || "null"}</td>
            <td>{comparable?.appraisal_date || "null"}</td>
            <td>
              {
                <SourceBadgeTags color={comparable?.source_id}>
                  {comparable?.source_id}
                </SourceBadgeTags>
              }
            </td>
            <td>{formatPrice(comparable?.last_ask_price)}</td>
            <td>
              {
                <NormalizedClientPrice
                  comparableId={comparable.comparable_id}
                />
              }
            </td>
            <td>
              {<NormalizedM2Price comparableId={comparable.comparable_id} />}
            </td>
            <td>{comparable?.ground_area || "null"}</td>
            <td>{comparable?.area || "null"}</td>
            <td>{comparable?.room_num || "null"}</td>
            <td>{comparable?.banos || "null"}</td>
            <td>{comparable?.garage || "null"}</td>
            <td>{comparable?.elevator || "null"}</td>
            <td>{comparable?.years_old || "null"}</td>
            <td>{comparable?.floor_num || "null"}</td>
            <td>{comparable?.address || "null"}</td>
            <td>{comparable?.url || "null"}</td>
            <td>{comparable?.property_type || "null"}</td>
            <td>{comparable?.gray_work || "null"}</td>
            <td>{"description"}</td>
            <td>{"contact"}</td>
            <td>{comparable?.phone || "null"}</td>
          </tr>
        ))}
      </tbody>
    );
  };
  return (
    <WrapperTable>
      <Table responsive hover style={{ width: "2700px" }}>
        {tableHeaders()}
        {tableBody()}
      </Table>
    </WrapperTable>
  );
};
