import {
  electricPurple300,
  electricPurple800,
  robotoRegular,
  scarpaGray500,
  tomatoCoral500,
  warning,
} from "@habitech/shared";
import styled from "styled-components";

export const CardManager = styled.div`
  font-family: ${robotoRegular};
  .nid-and-info {
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
  }
  .date {
    font-size: 11px;
    font-weight: 400px;
    line-height: 18px;
    color: ${scarpaGray500};
  }
  .card-price {
    color: ${electricPurple800};
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
  .complex-name {
    color: ${scarpaGray500};
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
  }
  .address {
    color: ${scarpaGray500};
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
  }

  .badge-inventory {
    background-color: ${warning};
  }
  .badge-bucket {
    background-color: ${tomatoCoral500};
  }
  .badge-pre-sale {
    background-color: ${electricPurple300};
  }
`;

export const WrapperInventoryCards = styled.div`
  max-height: calc(100vh - 197px);
  overflow-y: auto;
  scroll-behavior: smooth;
  scrollbar-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0);
  scrollbar-width: thin;
`;
