// libraries.
import { electricPurple800, electricPurple900 } from "@habitech/shared";
import { commentCategoriesOptions } from "@pages/InventoryPage/modules/FeedbackLook/utils/comment-categories";
import { Form } from "react-bootstrap";
import { useFormContext } from "react-hook-form";

export const CommentCategoryInput = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div className="w-100">
      <div className="d-flex justify-content-between">
        <Form.Label className="text-white simple-text">Categoría *</Form.Label>
        {errors.commentCategory && (
          <small>
            <p className="text-danger mb-1 fw-bold simple-text">
              {errors.commentCategory.message as string}
            </p>
          </small>
        )}
      </div>

      <Form.Select
        data-testid="commentCategory"
        {...register("commentCategory", {
          required: "Campo requerido",
        })}
        aria-label="Selecciona una categoría"
        defaultValue=""
        className="text-white p-3 simple-text"
        style={{
          backgroundColor: electricPurple900,
          borderColor: electricPurple800,
        }}
      >
        <option value="">Selecciona</option>
        {commentCategoriesOptions.map((option) => (
          <option key={option.id} value={option.id}>
            {option.label}
          </option>
        ))}
      </Form.Select>
    </div>
  );
};
