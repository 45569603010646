// libraries.
import { Col } from "react-bootstrap";

// components.
import { PriceInfoCardMX } from "./components/PriceInfoCardMX/PriceInfoCardMX";
import { PercentileCalculatorMX } from "./components/CalculatorMX/PercentileCalculatorMX";

export const PriceCardsInfoMX = () => {
  return (
    <div>
      <div
        className="d-flex flex-column gap-4"
        id="approval-cards-mx"
        data-testid="approval-cardsMX"
      >
        <PriceInfoCardMX />
        <Col lg={7}>
          <PercentileCalculatorMX />
        </Col>
      </div>
    </div>
  );
};
