// libraries.
import { Styles } from "@interfaces/styles";

interface EmbeddedBoardProps extends Styles {
  height?: string;
  url: string;
  width?: string;
}

/**
 *  This component allows embedding dashboards by receiving the dashboard URL as a prop.
 * @returns <EmbeddedBoard />
 */
export const EmbeddedBoard = ({
  url,
  className,
  style,
}: EmbeddedBoardProps) => {
  return <iframe className={className} src={url} style={style} />;
};
