export const roundPrice = (price: number) => {
  let tempPrice = price % 1;
  if (tempPrice > 0.5) {
    tempPrice = Math.trunc(price) + 1;
  } else {
    tempPrice = Math.trunc(price);
  }

  return tempPrice;
};

export const formatPrice = (
  price: number,
  language = "es-MX",
  currency = "MXN",
) => {
  const roundedPrice = roundPrice(price);
  if (!roundedPrice) return "";
  const newPrice = new window.Intl.NumberFormat(language, {
    currency,
    minimumFractionDigits: 0,
    style: "currency",
  }).format(roundedPrice);

  return newPrice;
};
