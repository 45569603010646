// libraries.
import {
  ImmerReducer,
  createReducerFunction,
  createActionCreators,
} from "immer-reducer";
import { ReactNode, createContext, useContext, useReducer } from "react";

export interface PercentilesValuesFromCalculator {
  priceABSMin?: number;
  priceABSPublication?: number;
  priceM2Publication?: number;
  priceMinM2?: number;
}

class SettingsType {
  currentRevenueCategory?: string;
  percentileCalculatorValues?: PercentilesValuesFromCalculator;
  utility?: number;
  variationOfPublicationPrice?: number;
}

export const initialState: SettingsType = {
  currentRevenueCategory: "",
  percentileCalculatorValues: {
    priceABSMin: 0,
    priceABSPublication: 0,
    priceM2Publication: 0,
    priceMinM2: 0,
  },
  utility: 0,
  variationOfPublicationPrice: 0,
};

class PriceSummaryReducer extends ImmerReducer<typeof initialState> {
  resetPercentileValues() {
    this.draftState = initialState;
  }
  setCurrentRevenueCategory(payload: string) {
    this.draftState.currentRevenueCategory = payload;
  }
  setPercentileCalculatorValues(payload: PercentilesValuesFromCalculator) {
    this.draftState.percentileCalculatorValues = payload;
  }
  setUtility(payload: number) {
    this.draftState.utility = payload;
  }
  setVariationOfPublicationPrice(payload: number) {
    this.draftState.variationOfPublicationPrice = payload;
  }
}

const PriceSummaryContext = createContext(initialState);
const PriceSummaryContextProvider = PriceSummaryContext.Provider;

export const PriceSummaryReducerFunction =
  createReducerFunction(PriceSummaryReducer);
export const PriceSummaryActions = createActionCreators(PriceSummaryReducer);

export const PriceSummaryDispatchContext = createContext({});

function PriceSummaryProvider(props: { children: ReactNode }) {
  const { children } = props;
  const [state, dispatch] = useReducer(
    PriceSummaryReducerFunction,
    initialState,
  );
  return (
    <PriceSummaryContextProvider value={{ ...state }}>
      <PriceSummaryDispatchContext.Provider value={dispatch}>
        {children}
      </PriceSummaryDispatchContext.Provider>
    </PriceSummaryContextProvider>
  );
}

function usePriceSummaryState() {
  const context = useContext(PriceSummaryContext);
  if (context === undefined) {
    throw new Error(
      "usePriceSummaryState must be used within a PriceSummaryProvider",
    );
  }
  return context;
}

function usePriceSummaryDispatch() {
  const context = useContext(PriceSummaryDispatchContext);
  if (context === undefined) {
    throw new Error(
      "usePriceSummaryDispatch must be used within a PriceSummaryProvider",
    );
  }
  return context as React.Dispatch<unknown>;
}

function usePriceSummaryContext() {
  return [usePriceSummaryState(), usePriceSummaryDispatch()] as [
    typeof initialState,
    React.Dispatch<unknown>,
  ];
}

export {
  PriceSummaryProvider,
  usePriceSummaryState,
  usePriceSummaryDispatch,
  usePriceSummaryContext,
};
