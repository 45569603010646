// components
import { DiagnosisCards } from "./components/DiagnosisCards";
import { PropertyStatistics } from "./components/PropertyStatistics";
import { PropertyUnit } from "./components/PropertyUnit/PropertyUnit";
import { InventoryPercentileCalculator } from "../../shared/InventoryPercentileCalculator";

export const DiagnosisCardsMX = () => {
  return (
    <div>
      <div
        className="d-flex flex-column gap-4 "
        id="diagnosis-cars-mx"
        data-testid="diagnosis-cardsMX"
      >
        <DiagnosisCards />
        <PropertyUnit />
        <PropertyStatistics />
        <InventoryPercentileCalculator />
      </div>
    </div>
  );
};
