import {
  electricPurple25,
  electricPurple300,
  robotoRegular,
  scarpaGray25,
  scarpaGray50,
  white,
} from "@habitech/shared";
import styled from "styled-components";

// Container para el grid
export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  background: ${white};
  border-radius: 8px;
  font-family: ${robotoRegular};

  .row1 {
    border-radius: 8px 0px 0px 0px;
    font-size: 14px;
  }

  .row2 {
    border-radius: 0px 8px 0px 0px;
    font-size: 14px;
    padding: 16px 0;
  }

  .col1 {
    border-radius: 8px 0px 0px 0px;
    font-size: 14px;
    padding: 16px 5px;
  }

  .col2 {
    border-radius: 0px 0px 0px 8px;
    font-size: 14px;
    padding: 16px 10px;
  }

  .active-number {
    color: ${electricPurple300};
    background-color: ${electricPurple25};
  }
`;

export const GridItem = styled.div`
  padding: 16px;
  text-align: center;
  background: ${white};
  border-radius: 4px;
  border: 1px solid ${scarpaGray50};
`;

export const GridHeader = styled(GridItem)`
  background: ${scarpaGray25};
  font-weight: bold;
  border: 1px solid ${scarpaGray50};
`;
