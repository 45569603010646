// libraries.
import { useDebounce } from "@uidotdev/usehooks";
import { useFormContext } from "react-hook-form";

// hooks.
import { useGetInventoryPropertyInfo } from "@store/apiSlices/inventoryPropertyInfo/inventoryPropertyInfo.hook";

// Shared
import { InfoTagHover } from "@shared";

// styles.
import { CardItem } from "@shared/DashboardComponentsSummary";
import { usePriceUtility } from "@store/apiSlices/utilityService/priceUtility.hook";
import { useEffect } from "react";
import { CardDropdownCategories } from "@shared/DropdownCategories";

export const UtilityCardsMX = () => {
  const { utilityInitial: utilityDefault } = useGetInventoryPropertyInfo();

  const {
    watch,
    formState: { dirtyFields },
    setValue,
  } = useFormContext();

  const userTouchMinPublicationPriceMX = dirtyFields.minPublicationPrice;
  const minPublicationPriceInputMX = watch("minPublicationPrice");

  const deferredMinPublicationPriceValueMX = useDebounce(
    minPublicationPriceInputMX,
    1000,
  );

  const {
    getMinimumPriceUtilityQuery: { isFetching, isError },
    utilityCurrent: currentUtilityMX,
    utilityInitial: initialUtilityMX,
    selectedCategoryFromUser,
  } = usePriceUtility({
    price: deferredMinPublicationPriceValueMX,
  });

  const initialUtilityMXToShow =
    !userTouchMinPublicationPriceMX && !selectedCategoryFromUser
      ? utilityDefault
      : initialUtilityMX;

  useEffect(() => {
    setValue("utilityInitial", initialUtilityMXToShow);
    setValue("utilityCurrent", currentUtilityMX);
  }, [initialUtilityMXToShow, currentUtilityMX]);

  const isFetchingData = isFetching ? "Cargando..." : null;
  const isErrorData = isError ? "Error API" : null;

  return (
    <>
      {initialUtilityMX === null && (
        <CardDropdownCategories title={"Categoría - UE inicial"} />
      )}
      <CardItem classNameData="default-shadow rounded-3 card-price-info p-3 d-flex flex-column gap-2">
        <small className="title-card">UE inicial</small>
        <div className="d-flex justify-content-between">
          <div className="subtitle-card">
            {isFetchingData || isErrorData || `${initialUtilityMXToShow}%`}
          </div>
          {!isFetching && !isError && (
            <InfoTagHover style={{ left: "-20px", top: "26px" }}>
              Cambia de acuerdo al precio de publicación
            </InfoTagHover>
          )}
        </div>
      </CardItem>
      <CardItem classNameData="default-shadow rounded-3 card-price-info p-3 d-flex flex-column gap-2">
        <small className="title-card">UE actual</small>
        <div className="d-flex justify-content-between">
          <div className="subtitle-card">
            {isFetchingData || isErrorData || `${currentUtilityMX}%`}
          </div>
          {!isFetching && !isError && (
            <InfoTagHover style={{ left: "-20px", top: "26px" }}>
              Cambia de acuerdo al precio, comisión o días publicados
            </InfoTagHover>
          )}
        </div>
      </CardItem>
    </>
  );
};
