import { HomePage, LoginPage, PublicationPricePage } from "@pages";
import {
  pathHome,
  pathInventory,
  pathLogin,
  pathPublicationPrice,
} from "./routes.types";
import { routesPublicationPrice } from "@pages/PublicationPricePage/routes/routes";
import { InventoryPortalRoute } from "@interfaces";
import { routesHome } from "@pages/HomePage/routes/routes";
import { InventoryPage } from "@pages/InventoryPage";
import { routesInventory } from "@pages/InventoryPage/routes/routes";

export const routes: InventoryPortalRoute[] = [
  {
    children: routesPublicationPrice,
    element: <PublicationPricePage />,
    id: "publication-price",
    path: pathPublicationPrice,
  },
  {
    element: <LoginPage />,
    id: "login",
    path: pathLogin,
  },
  {
    children: routesHome,
    element: <HomePage />,
    id: "home",
    path: pathHome,
  },
  {
    children: routesInventory,
    element: <InventoryPage />,
    id: "inventory",
    path: pathInventory,
  },
];
