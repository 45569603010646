import {
  IconArea,
  IconBath,
  IconElevator,
  IconGarage,
  IconRoom,
} from "@assets";

// Hooks.
import { useUserProfile } from "@shared/GoogleLoginButton/user-slice/useUserProfile.hook";

interface PropertyFeatureProps {
  nid?: string;
}

interface AreaProps extends PropertyFeatureProps {
  area: number | undefined;
}

interface RoomsProps extends PropertyFeatureProps {
  rooms: number | undefined;
}

interface BathroomsProps extends PropertyFeatureProps {
  bathrooms: number | undefined;
  restrooms?: number;
}

interface GarageProps extends PropertyFeatureProps {
  garages: number | undefined;
}

interface ElevatorProps extends PropertyFeatureProps {
  elevators: number | undefined;
}

export const Area = ({ area }: AreaProps) => (
  <div className="d-flex align-items-center gap-1">
    <IconArea />
    <div className="d-flex gap-1">
      Área: <span className="value-property">{`${area}m²`}</span>
    </div>
  </div>
);

export const Rooms = ({ rooms }: RoomsProps) => (
  <div className="d-flex align-items-center gap-1">
    <IconRoom />
    <div className="d-flex gap-1">
      Habitaciones: <span className="value-property">{rooms}</span>
    </div>
  </div>
);

export const Bathrooms = ({ bathrooms, restrooms }: BathroomsProps) => {
  const { user: country } = useUserProfile();

  return (
    <div className="d-flex align-items-center gap-1">
      <IconBath />
      <div className="d-flex align-items-center gap-1">
        Baños: <span className="value-property">{bathrooms}</span>
        {country?.country === "MX" && (
          <>
            {restrooms !== 0 && "½:"}{" "}
            <span className="value-property">
              {restrooms !== 0 && restrooms}
            </span>
          </>
        )}
      </div>
    </div>
  );
};

export const Garage = ({ garages }: GarageProps) => (
  <div className="d-flex align-items-center gap-1">
    <IconGarage />
    <div className="d-flex gap-1">
      Garajes: <span className="value-property">{garages}</span>
    </div>
  </div>
);

export const Elevator = ({ elevators }: ElevatorProps) => (
  <div className="d-flex align-items-center gap-1">
    <IconElevator />
    <div className="d-flex gap-1">
      Elevadores: <span className="value-property">{elevators}</span>
    </div>
  </div>
);
