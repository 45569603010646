// libraries.
import {
  electricPurple50,
  electricPurple800,
  robotoRegular,
} from "@habitech/shared";

// components.
import { CardItem } from "@shared/DashboardComponentsSummary";
import { DropdownCategories } from "./DropdownCategories";

export const CardDropdownCategories = ({ title }: { title?: string }) => {
  return (
    <CardItem
      classNameData={`default-shadow rounded-3 card-price-info p-3 d-flex flex-column gap-1`}
      style={{ background: electricPurple50 }}
    >
      <small
        className="title-card"
        style={{ color: electricPurple800, fontFamily: robotoRegular }}
      >
        {title ? title : "Categoría"}
      </small>
      <DropdownCategories />
    </CardItem>
  );
};
