import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { apiInventoryPortal } from "../apiSlices";
import { ModelError } from "../interfaces/interfaces";
import { FeedbackRemoTransformed } from "./utils";

interface FeedbackRemoParams {
  country?: string;
  nid: number;
}

interface FeedbackRemo {
  activity_type: string;
  apartment_status: string;
  attendant: string;
  comment: string;
  contractor_date: string;
  creator: string;
  entry_date: string;
  initial_date: string;
  last_update: string;
  priority: string;
  rating: string;
  reason: string;
  created_date: string;
  request_stage: string;
  status: string;
  total: number;
}

interface FeedbackRemoResponse {
  body: FeedbackRemo[];
  code: number;
  success: boolean;
}

export const feedbackRemo = apiInventoryPortal.injectEndpoints({
  endpoints: (builder) => ({
    getFeedbackRemo: builder.query({
      query: ({ country, nid }: FeedbackRemoParams) => {
        return `/feedback_remo?country=${country}&nid=${nid}`;
      },
      transformErrorResponse: (error: FetchBaseQueryError) => {
        const modelError: ModelError = {
          errorStatus: error.status,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          messageFromServer: (error.data as any)?.message,
          serviceErrorName: "getFeedbackRemo",
        };
        return modelError;
      },
      transformResponse: (response: FeedbackRemoResponse) => {
        const feedbackRemo: FeedbackRemoTransformed[] = response.body.map(
          (feedback) => {
            return {
              activityType: feedback.activity_type,
              apartmentStatus: feedback.apartment_status,
              attendant: feedback.attendant,
              comment: feedback.comment,
              contractorDate: feedback.contractor_date,
              creator: feedback.creator,
              entryDate: feedback.entry_date,
              initialDate: feedback.initial_date,
              lastUpdate: feedback.last_update,
              priority: feedback.priority,
              rating: feedback.rating,
              reason: feedback.reason,
              requestCreated: feedback.created_date,
              requestStage: feedback.request_stage,
              status: feedback.status,
              totalPrice: feedback.total,
            };
          },
        );
        return { feedbackRemo };
      },
    }),
  }),
});

export const { useGetFeedbackRemoQuery } = feedbackRemo;
