// libraries.
import { scarpaGray50 } from "@habitech/shared";
import styled from "styled-components";

export const WrapperArchivedModal = styled.div`
  .modal-footer {
    background-color: ${scarpaGray50};
  }

  .second-title {
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
  }

  .buttons-text {
    font-family: "Roboto";
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
`;
