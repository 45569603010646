// hooks.
import { useUserProfile } from "@shared/GoogleLoginButton/user-slice/useUserProfile.hook";
import { useGetInventoryByNidQuery } from "./leadInventory.endpoint";

export const useLeadInventory = ({ nid }: { nid?: number }) => {
  const { country } = useUserProfile();
  const nidSelected = nid ? nid.toString() : "";
  const shouldFetch = Boolean(nidSelected && country);

  const leadInventoryQuery = useGetInventoryByNidQuery(
    {
      country,
      nid: nidSelected,
    },
    { skip: !shouldFetch },
  );

  return { leadInventoryQuery };
};
