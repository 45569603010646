// libraries.
import { useDispatch, useSelector } from "react-redux";

// store.
import { RootState } from "@store";
import {
  setClickedTapType,
  setViewCommentsType,
  ViewCommentsType,
  ClickedTapType,
} from "./pageModuleFeedbackLook.slice";

export const usePageModuleFeedbackLook = () => {
  const dispatch = useDispatch();

  const viewCommentsType = useSelector(
    (state: RootState) => state.pageModuleFeedbackLookReducer.viewCommentsType,
  );

  const setViewToTableComments = () =>
    dispatch(setViewCommentsType(ViewCommentsType.TABLE_COMMENTS));
  const setViewToCardComments = () =>
    dispatch(setViewCommentsType(ViewCommentsType.CARD_COMMENTS));

  const clickedTapType = useSelector(
    (state: RootState) => state.pageModuleFeedbackLookReducer.clickedTapType,
  );

  const setNonArchivedCommentsTap = () =>
    dispatch(setClickedTapType(ClickedTapType.NON_ARCHIVED_COMMENTS));
  const setArchivedCommentsTap = () =>
    dispatch(setClickedTapType(ClickedTapType.ARCHIVED_COMMENTS));

  return {
    clickedTapType,
    setArchivedCommentsTap,
    setNonArchivedCommentsTap,
    setViewToCardComments,
    setViewToTableComments,
    viewCommentsType,
  };
};
