// slices.
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { apiInventoryPortal } from "../apiSlices";
import { ModelError } from "../interfaces/interfaces";

interface RolesParams {
  country?: string;
  email: string;
}

interface RolesResponse {
  body: { role: string };
}

export const roles = apiInventoryPortal.injectEndpoints({
  endpoints: (builder) => ({
    getRoles: builder.query({
      query: ({ country, email }: RolesParams) => {
        return `/get_role_agent?country=${country}&email=${email}`;
      },
      transformErrorResponse: (error: FetchBaseQueryError) => {
        const modelError: ModelError = {
          errorStatus: error.status,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          messageFromServer: (error.data as any)?.message,
          serviceErrorName: "getRoles",
        };
        return modelError;
      },
      transformResponse: (response: RolesResponse) => {
        const role = response.body.role;
        return { role };
      },
    }),
  }),
});

export const { useGetRolesQuery } = roles;
