import { useUserProfile } from "@shared/GoogleLoginButton/user-slice/useUserProfile.hook";
import { SidebarButton } from "@shared/Sidebar";

export const UserInfo = () => {
  const { user } = useUserProfile();
  return (
    <SidebarButton hoverText={user.name || "user"}>
      <img
        src={user.imageUrl}
        alt={`user ${user.name}`}
        width={24}
        height={24}
        className="rounded-circle"
      />
    </SidebarButton>
  );
};
