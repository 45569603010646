// components
import { SavePricingButton } from "./components/SavePricingButton";
import { ApprovalPriceInputs } from "./components/PriceInputs/ApprovalPriceInputs";

// styles.
import {
  TitleSummary,
  WrapperSummary,
} from "@shared/DashboardComponentsSummary";
import { IncreaseApprovalPrice } from "./components/IncreaseApprovalPrice/IncreaseApprovalPrice";
import { ValidateTope } from "../../shared/ValidateTope";

export const PriceApprovalSummaryMX = () => {
  return (
    <div className="d-flex flex-column h-100 justify-content-between">
      <WrapperSummary>
        <TitleSummary
          title="Resumen del ejercicio"
          subtitle="Rectifica los valores"
        />
        <ApprovalPriceInputs />
        <IncreaseApprovalPrice />

        <div className="d-flex justify-content-end">
          <ValidateTope watchPriceName="originalMinimumPrice" />
        </div>
      </WrapperSummary>
      <div className="d-flex flex-column gap-4">
        <div className="line" />
        <textarea
          className="w-100 rounded-3 p-2"
          placeholder="Agregar comentario"
          style={{ height: "150px" }}
        />
        <SavePricingButton />
      </div>
    </div>
  );
};
