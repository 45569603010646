// libraries.
import { useParams } from "react-router-dom";

// hooks.
import { useUserProfile } from "@shared/GoogleLoginButton/user-slice/useUserProfile.hook";
import { useNid } from "@pages";
import { useGetHistoryLeadInfoQuery } from "./historyLeadInfo.endpoints";

export const useGetHistoryLeadInfo = () => {
  const paths = useParams();
  const { nid } = useNid();
  const { user: country } = useUserProfile();

  const nidSelected = paths.nid ? paths.nid : nid;

  const getHistoryLeadInfoQuery = useGetHistoryLeadInfoQuery({
    country: country.country,
    nid: nidSelected,
  });

  return { getHistoryLeadInfoQuery };
};
