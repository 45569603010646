// libraries.
import ReactDOM from "react-dom/client";
import { StrictMode } from "react";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";

// routes.
import { Router } from "@routes/Router";

// Store.
import { store, persistor } from "@store";

// styles.
import "bootstrap/dist/css/bootstrap.min.css";
import GlobalStyle from "./styles";
import "react-toastify/dist/ReactToastify.css";

// configs
import "./config/sentry";

const App = () => {
  return (
    <>
      <ToastContainer />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <GlobalStyle />
          <Router />
        </PersistGate>
      </Provider>
    </>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);
