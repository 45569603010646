// libraries.
import { Table } from "react-bootstrap";

// styles.
import { WrapperFeedbackTable } from "../components/TableRemo/styles";

interface TableRowsProps {
  headers: string[];
  rows: Array<{ [key: string]: string | number | React.ReactNode }>;
  style?: React.CSSProperties;
  widths?: { [header: string]: string };
}

export const TableRows = ({ headers, rows, widths, style }: TableRowsProps) => {
  return (
    <WrapperFeedbackTable>
      <Table bordered hover responsive className="rounded-5 mb-0" style={style}>
        <thead>
          <tr>
            {headers.map((header) => (
              <th style={{ width: widths?.[header] }} key={header}>
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, rowIndex) => (
            <tr key={`row-${rowIndex + 1}`}>
              {headers.map((_, cellIndex) => (
                <td key={`cell-${cellIndex + 1}`} className="align-middle">
                  {Object.values(row)[cellIndex]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </WrapperFeedbackTable>
  );
};
