// libraries.
import { Modal } from "react-bootstrap";
import { useFormContext } from "react-hook-form";

// utils.
import { modalOptions, useModalOptions } from "@store/slices/modals";

// assets.
import { IconClose } from "@assets";
import { electricPurple900 } from "@habitech/shared";

export const TitleCreateComment = () => {
  const { closeModal } = useModalOptions();

  const { watch, reset } = useFormContext();

  const commentId = watch("commentId");
  const hasCommentId =
    commentId !== undefined && commentId !== null && commentId !== 0;

  return (
    <Modal.Header className="text-white border-bottom-0">
      <div className="w-100">
        <div className="d-flex justify-content-between">
          {hasCommentId ? (
            <Modal.Title className="titles">Editar comentario</Modal.Title>
          ) : (
            <Modal.Title className="titles">Nuevo comentario</Modal.Title>
          )}
          <div
            data-testid="close-modal"
            onClick={() => {
              closeModal(modalOptions.modalInventoryPageCreateComment);
              reset();
            }}
          >
            <IconClose fill={electricPurple900} />
          </div>
        </div>
        {hasCommentId ? (
          <p className="simple-text">
            Puedes editar los campos y guardar los cambios
          </p>
        ) : (
          <p className="simple-text">
            Llena los campos y agrega un nuevo comentario
          </p>
        )}
      </div>
    </Modal.Header>
  );
};
