// libraries.
import React from "react";

// styles.
import { SpaceButtons } from "./styles";
import { useSidebarContext } from "../Sidebar/Sidebar";

interface SidebarButtonProps {
  alt?: string;
  children: React.ReactNode;
  hoverText: string;
  icon?: string;
  onClick?: () => void;
  selected?: boolean;
}

export const SidebarButton = ({
  children,
  onClick,
  selected = false,
  hoverText,
}: SidebarButtonProps) => {
  const {
    state: { isHoveredSidebar },
  } = useSidebarContext();

  return (
    <SpaceButtons
      onClick={onClick}
      aria-hidden
      className={`p-2 ${selected ? "selected" : ""}`}
    >
      <div className="d-flex align-items-center gap-2 text-white fw-bold hover-button">
        {children}
        {isHoveredSidebar && (
          <div style={{ whiteSpace: "nowrap" }}>{hoverText}</div>
        )}
      </div>
    </SpaceButtons>
  );
};
