import { PolygonF } from "@react-google-maps/api";
import { Hexagon } from "@store/apiSlices/feedbackLoop/hexagons/commentHexagon.endpoint";
import { optionsSelectedPolygon } from "../../ModalZone/components/Hexagons";
import { useGetHexagons } from "@store/apiSlices/feedbackLoop/hexagons/commentHexagon.hook";
import { useEffect, useState } from "react";

export const HexagonsZone = ({ hashList }: { hashList?: string[] }) => {
  const { hexagons } = useGetHexagons();
  const [hexagonsSelected, setHexagonsSelected] = useState<Hexagon[] | []>([]);

  useEffect(() => {
    if (!hashList) return;
    if (hexagons.length > 0) {
      const hexagonsToDisplay = hexagons.map((hexagon) => {
        const isSelected = hashList.includes(hexagon.hash);
        return { ...hexagon, selected: isSelected };
      });
      setHexagonsSelected(hexagonsToDisplay);
    }
  }, [hexagons]);

  if (!hexagonsSelected.length) return null;

  return (
    <>
      {hexagonsSelected.map((hexagon: Hexagon) => {
        if (!hexagon.selected) return null;
        return (
          <PolygonF
            key={hexagon.hash}
            path={hexagon.path}
            options={optionsSelectedPolygon}
          />
        );
      })}
    </>
  );
};
