// libraries.
import { useForm, FormProvider } from "react-hook-form";
import { Col } from "react-bootstrap";

// components.
import { PriceInfoCO } from "./PriceInfoCO/PriceInfoCO";
import { PriceSummaryCO } from "./PriceSummaryCO/PriceSummaryCO";

interface IPriceSummaryForms {
  isLoading: boolean;
  minPublicationPrice: number;
  publicationPrice: number;
  utilityInitial: number;
}

export const PublicationPriceSummaryCO = () => {
  const methods = useForm<IPriceSummaryForms>({
    defaultValues: {
      isLoading: false,
      minPublicationPrice: 0,
      publicationPrice: 0,
      utilityInitial: 0,
    },
  });

  return (
    <FormProvider {...methods}>
      <form className="d-flex">
        <Col md={9} className="h-100 p-4 price-info  d-flex flex-column gap-4">
          <div className="title"> Aprobación de publicación</div>
          <PriceInfoCO />
        </Col>
        <Col md={3} className="h-100 p-4 price-summary text-white">
          <PriceSummaryCO />
        </Col>
      </form>
    </FormProvider>
  );
};
