import { useUserProfile } from "@shared/GoogleLoginButton/user-slice/useUserProfile.hook";
import { Hexagon, useGetHexagonsQuery } from "./commentHexagon.endpoint";
import { useGetLeadDetailInfo } from "@store/apiSlices/leadDetail/leadDetail.hooks";
import { useEffect } from "react";
import { toast } from "react-toastify";

export const useGetHexagons = () => {
  const { country } = useUserProfile();
  const { latitude, longitude } = useGetLeadDetailInfo();

  const getHexagonsQuery = useGetHexagonsQuery({
    country: country,
    latitude: latitude,
    longitude: longitude,
  });

  const hexagons: Hexagon[] = getHexagonsQuery.data?.hexagons || [];

  useEffect(() => {
    if (getHexagonsQuery.isLoading) {
      toast.loading("Cargando hexágonos...", {
        autoClose: false,
        toastId: "getHexagonsLoading",
      });
    } else if (getHexagonsQuery.error) {
      toast.error("Error al cargar los hexágonos");
      toast.dismiss("getHexagonsLoading");
    } else {
      toast.dismiss("getHexagonsLoading");
    }
  }, [getHexagonsQuery.isLoading, getHexagonsQuery.error]);

  return { hexagons };
};
