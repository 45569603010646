interface Country {
  countryCode: string;
  name: string;
}

export const countries: Country[] = [
  {
    countryCode: "MX",
    name: "México",
  },
  {
    countryCode: "CO",
    name: "Colombia",
  },
];

export const countryCodes = countries.map((country) => country.countryCode);

export const extractCountryCodeFromEmail = (email: string) => {
  const match = email.match(/\.\w+$/);
  const countryCode = match ? match[0].substring(1) : null;

  if (!countryCode) {
    throw new Error("Invalid email");
  }

  return countryCode.toUpperCase();
};
