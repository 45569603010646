import { CardItem } from "@shared/DashboardComponentsSummary";
import { useGetInventoryPropertyInfo } from "@store/apiSlices/inventoryPropertyInfo/inventoryPropertyInfo.hook";

export const CardGrossMargin = () => {
  const { grossMargin } = useGetInventoryPropertyInfo();

  const formatGrossMargin = grossMargin ? (grossMargin * 100).toFixed(1) : 0;

  return (
    <CardItem classNameData="default-shadow rounded-3 card-price-info p-3 d-flex flex-column gap-2">
      <small className="title-card">Margen Bruto</small>
      <div className="subtitle-card">{formatGrossMargin} %</div>
    </CardItem>
  );
};
