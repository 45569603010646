// libraries.
import { Modal, Form } from "react-bootstrap";

// components.
import { CommentCategoryInput } from "./components/CommentCategoryInput";
import { CommentText } from "./components/CommentText";
import { CommentExperienceInput } from "./components/CommentExperienceInput";
import { CreateZone } from "./components/CreateZone";

export const FormCreateComment = () => {
  return (
    <Modal.Body className="p-0">
      <Form.Group
        className="d-flex flex-column gap-2 mb-3 px-1"
        controlId="exampleForm.ControlTextarea1"
      >
        <div className="d-flex justify-content-between gap-1">
          <CommentExperienceInput />
          <CommentCategoryInput />
        </div>
        <CommentText />
        <CreateZone />
      </Form.Group>
    </Modal.Body>
  );
};
