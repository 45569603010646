// libraries.
import styled from "styled-components";
import { electricPurple900, robotoRegular, white } from "@habitech/shared";

export const WrapperSelect = styled.div`
  font-size: 12px;
  .select-leader {
    height: 37px;
    max-width: 175px;
    color: ${white};
    background-color: ${electricPurple900};
    border: none;
    font-size: 12px;
    font-family: ${robotoRegular};

    background-image: url("data:image/svg+xml;utf8,<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'> <path fill-rule='evenodd' clip-rule='evenodd' d='M12.3802 5.63353C12.7671 6.0277 12.7611 6.66083 12.367 7.04768L9.40088 9.95871C8.62298 10.7222 7.37698 10.7222 6.59907 9.95871L3.633 7.04768C3.23884 6.66083 3.23291 6.0277 3.61976 5.63353C4.00661 5.23937 4.63974 5.23344 5.03391 5.62029L7.99998 8.53131L10.9661 5.62029C11.3602 5.23344 11.9934 5.23937 12.3802 5.63353Z' fill='white'/> </svg>");
  }
`;
