import { IconArrowLeft, IconArrowRight } from "@assets";
import { electricPurple500 } from "@habitech/shared";

interface ButtonScrollProps {
  onScrollLeft: () => void;
  onScrollRight: () => void;
}

export const ButtonScroll = ({
  onScrollLeft,
  onScrollRight,
}: ButtonScrollProps) => {
  return (
    <>
      <div
        className="d-flex align-items-center rounded-circle pointer"
        onClick={onScrollLeft}
        data-testid="left-button"
      >
        <IconArrowLeft fill={electricPurple500} />
      </div>
      <div
        className="d-flex rounded-circle pointer"
        onClick={onScrollRight}
        data-testid="right-button"
      >
        <IconArrowRight fill={electricPurple500} />
      </div>
    </>
  );
};
