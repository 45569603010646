// libraries.
import { Card, Container } from "react-bootstrap";

// styles
import { robotoRegular, scarpaGray50 } from "@habitech/shared";

export const EmptyTickets = () => {
  return (
    <Container fluid className=" d-flex justify-content-center h-100">
      <Card
        className="d-flex justify-content-center py-5 border-0"
        style={{
          boxShadow: `0px 0px 16px 0px ${scarpaGray50}`,
          flexGrow: 1,
          position: "relative",
          width: "calc(100% - 90px)",
        }}
      >
        <div className="text-center">
          <div
            data-testid="TablesRemo"
            className="fw-bold mb-2"
            style={{ fontFamily: robotoRegular }}
          >
            No hay tickets en el momento
          </div>
        </div>
      </Card>
    </Container>
  );
};
