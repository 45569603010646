import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { apiInventoryPortal } from "../apiSlices";
import { ModelError } from "../interfaces/interfaces";

interface NotificationsInfoParams {
  agent: string;
  country?: string;
  filters: string;
}

interface NotificationsInfo {
  id: number;
  type: string;
  nid: number;
  agent: string;
  category: string;
  created_at: string;
  comment: string;
  label: string;
  residential: string;
  status: string;
}

export interface NotificationsInfoTransformed {
  agent: string;
  category: string;
  comment: string;
  complexName: string;
  creationDate: string;
  nid: number;
  selected: boolean;
  ticketLabel: string;
  ticketId: number;
  ticketStatus: string;
  ticketType: string;
}

interface NotificationsInfoResponse {
  body: NotificationsInfo[];
  code: number;
  success: boolean;
}

export const notificationsInfo = apiInventoryPortal.injectEndpoints({
  endpoints: (builder) => ({
    getNotificationsInfo: builder.query({
      query: ({ agent, country, filters }: NotificationsInfoParams) => {
        return `/get_notifications?country=${country}&agent=${agent}&filters=${filters}`;
      },

      transformErrorResponse: (error: FetchBaseQueryError) => {
        const modelError: ModelError = {
          errorStatus: error.status,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          messageFromServer: (error.data as any)?.message,
          serviceErrorName: "getNotificationsInfo",
        };
        return modelError;
      },

      transformResponse: (
        response: NotificationsInfoResponse,
      ): NotificationsInfoTransformed[] => {
        const { body } = response;
        const listNotificationsInfo: NotificationsInfoTransformed[] = body.map(
          (item) => ({
            agent: item.agent,
            category: item.category,
            comment: item.comment,
            complexName: item.residential,
            nid: item.nid,
            selected: false,
            creationDate: item.created_at,
            ticketId: item.id,
            ticketLabel: item.label,
            ticketStatus: item.status,
            ticketType: item.type,
          }),
        );

        return listNotificationsInfo;
      },
    }),
  }),
});

export const { useGetNotificationsInfoQuery } = notificationsInfo;
