// utils.
import { PublicationPrice } from "./components/PublicationPrice";
import { PercentageInPrices } from "./components/PercentageInPrices";
import { MinPublicationPrice } from "./components/MinPublicationPrice";

export const PublicationPriceInputsCO = () => {
  return (
    <>
      <div className="d-flex gap-1 flex-column mt-2">
        <div className="d-flex flex-column gap-2">
          <div className="d-flex flex-column gap-2 mb-3">
            <PublicationPrice />
          </div>
          <div className="d-flex flex-column gap-2 mb-1">
            <MinPublicationPrice />
          </div>
        </div>
      </div>
      <PercentageInPrices />
    </>
  );
};
