// useSidebar.ts
import { useReducer, useCallback } from "react";
import { sidebarReducer, INITIAL_STATE_SIDEBAR } from "./sidebar.reducer";
import {
  doSidebarReset,
  doSidebarSelectedButton,
  doSidebarIsHovered,
} from "./sidebar.actions";

export function useSidebar() {
  const [state, dispatch] = useReducer(sidebarReducer, INITIAL_STATE_SIDEBAR);

  const reset = useCallback(() => dispatch(doSidebarReset()), [dispatch]);
  const setSelectedButton = useCallback(
    (value: string) => dispatch(doSidebarSelectedButton(value)),
    [dispatch],
  );

  const setIsHovered = (value: boolean) => dispatch(doSidebarIsHovered(value));

  return {
    reset,
    setIsHovered,
    setSelectedButton,
    state,
  };
}
