import { useUserProfile } from "@shared/GoogleLoginButton/user-slice/useUserProfile.hook";
import { useGetRolesQuery } from "./role";

export const useRoleByUser = () => {
  const { user } = useUserProfile();

  const shouldFetchRoles = user.country && user.email;

  const getRolesQuery = useGetRolesQuery(
    { country: user.country, email: user.email },
    { skip: !shouldFetchRoles },
  );

  const userRole = getRolesQuery.data?.role;

  return { getRolesQuery, userRole };
};
