// libraries.
import { Button } from "react-bootstrap";

// hooks.
import { modalOptions, useModalOptions } from "@store/slices/modals";
import { useFormContext } from "react-hook-form";

export const SaveZone = () => {
  const { clearErrors, setValue } = useFormContext();

  const { closeModal } = useModalOptions();

  const closeZoneModal = () => {
    closeModal(modalOptions.modalInventoryPageMapZone);
  };

  const cancelZoneModal = () => {
    clearErrors();
    setValue("hashList", []);
    setValue("commentType", "nid");
    setValue("radius", 0);
    closeModal(modalOptions.modalInventoryPageMapZone);
  };

  const createZone = () => {
    setValue("commentType", "zone");
    closeZoneModal();
  };

  return (
    <div className="d-flex gap-2">
      <Button
        onClick={cancelZoneModal}
        className="button-cancel"
        aria-label="Cancelar"
      >
        Eliminar selección
      </Button>
      <Button onClick={createZone} aria-label="Guardar">
        Guardar
      </Button>
    </div>
  );
};
