// hooks.
import { useFeedbackActions } from "@store/apiSlices/feedbackRemo/feedbackActions.hook";

// components
import { TableRows } from "../../../shared/TableRows";
import { TableLoading } from "@pages/InventoryPage/modules/FeedbackLook/components/TableFeedbackLook/components/TableStatus/components/TableLoading";
import { EmptyTickets } from "../../../shared/EmptyTickets";

// utils.
import { actionLabelStyles, formatDateTime } from "../../TableRemo/utils";
import { formatPrice } from "@utils";
import { headersActions, widths } from "../../../shared/utils";

export const RowsActionsRemo = () => {
  const {
    getFeedbackActionsQuery: { data, isLoading },
    itemsFeedbackActionsLen,
  } = useFeedbackActions();

  if (isLoading) {
    return <TableLoading />;
  }

  if (itemsFeedbackActionsLen === 0) {
    return <EmptyTickets />;
  }

  const actionsRows =
    data?.feedbackActions.map((item, i) => ({
      index: i + 1,
      createdDate: formatDateTime(item.createdDate),
      manager: (
        <>
          <div className="pb-2">{item.manager}</div>
          <small
            className="fw-bold py-1 px-2 rounded simple-text"
            style={actionLabelStyles(item.labelType)}
          >
            {item.labelType}
          </small>
        </>
      ),
      commercialHolding: item.commercialHolding,
      actionableType: (
        <div>
          <strong>{item.actionableType}</strong>
          <div>Costo: {formatPrice(item.diffPrice) || 0}</div>
        </div>
      ),
      comment: item.comment,
    })) || [];

  return (
    <TableRows headers={headersActions} rows={actionsRows} widths={widths} />
  );
};
