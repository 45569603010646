// libraries.
import styled from "styled-components";
import {
  electricPurple500,
  electricPurple600,
  monserratSemiBold,
  robotoRegular,
} from "@habitech/shared";

export const WrapperZoneModal = styled.div`
  .modal-header {
    padding: 16px 24px;
  }
  .modal-title {
    font-size: 16px !important;
    font-family: ${monserratSemiBold};
    font-weight: 800;
  }
  .description {
    font-size: 14px !important;
    font-family: ${robotoRegular};
    color: #6d6970;
    font-weight: 400;
  }
  button {
    font-size: 14px;
    width: 120px;
    background-color: ${electricPurple500};
    border: none;
    &:hover {
      background-color: rgb(99, 1, 204);
    }
  }
  .btn-close {
    width: 15px;
    background-color: #ffff;
    &:hover {
      background-color: #ffff;
    }
  }
  .button-cancel {
    font-size: 14px;
    font-weight: bold !important;
    width: 160px;
    background-color: rgb(255, 255, 255);
    color: rgb(124, 1, 255);
    border: 2px solid rgb(255, 255, 255);
    &:hover {
      background-color: rgb(214, 243, 255);
      border: 2px solid rgb(214, 243, 255);
    }
  }
  .footer {
    background: #f3f3f3;
  }
  .radius-mt {
    font-size: 14px;
    right: 15px;
    font-family: ${robotoRegular};
  }
  .gm-style-mtc button {
    color: ${electricPurple600} !important;
    font-size: 14px !important;
    font-weight: 500 !important;
  }
  button[title="Show street map"] {
    border-radius: 6px !important;
    font-weight: 400 !important;
    height: 37px !important;
    &:hover {
      background-color: rgb(214, 243, 255) !important;
      border: 2px solid rgb(214, 243, 255) !important;
    }
  }
  button[title="Show satellite imagery"] {
    border-radius: 6px !important;
    font-weight: 400 !important;
    height: 37px !important;
    &:hover {
      background-color: rgb(214, 243, 255) !important;
      border: 2px solid rgb(214, 243, 255) !important;
    }
  }
  button[title="Zoom in"] {
    img {
      filter: invert(20%) sepia(100%) saturate(6200%) hue-rotate(272deg)
        brightness(73%) contrast(134%);
    }
  }
  button[title="Tilt map"] {
    img {
      filter: invert(20%) sepia(100%) saturate(6200%) hue-rotate(272deg)
        brightness(73%) contrast(134%);
    }
  }
  button[title="Zoom out"] {
    img {
      filter: invert(20%) sepia(100%) saturate(6200%) hue-rotate(272deg)
        brightness(73%) contrast(134%);
    }
  }
  button[title="Toggle fullscreen view"] {
    border-radius: 6px !important;
    img {
      filter: invert(20%) sepia(100%) saturate(6200%) hue-rotate(272deg)
        brightness(73%) contrast(134%);
    }
  }
  .gm-style-mtc-bbw {
    gap: 8px !important;
  }
  .gm-control-active {
    border-radius: 8px !important;
    box-shadow:
      0px 0px 1px rgba(48, 49, 51, 0.05),
      0px 1px 3px rgba(48, 49, 51, 0.1) !important;
  }
  .gmnoprint:first-child {
    div {
      border-radius: 8px !important;
    }
  }
`;
