// libraries.

import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface FileTypeData {
  agent?: string;
  comment?: string;
  initial_utility?: number;
  min_publication_price?: number;
  nid?: string | number;
  publication_price?: number;
}

interface BulkUploadState {
  dataError: FileTypeData[];
  dataSuccess: FileTypeData[];
  fileName: string;
  hasFile: boolean;
  nidsNotUpdated: FileTypeData[];
}

export const initialStateBulkUpload: BulkUploadState = {
  dataError: [],
  dataSuccess: [],
  fileName: "",
  hasFile: false,
  nidsNotUpdated: [],
};

const moduleHomeBulkUploadSlice = createSlice({
  initialState: initialStateBulkUpload,
  name: "bulk_upload",
  reducers: {
    clearBulkUploadState: () => initialStateBulkUpload,
    setFileData: (state, action: PayloadAction<unknown>) => {
      const { errors, successes } = validateAndTransformData(
        action.payload as FileTypeData[],
      );

      state.dataError = errors;
      state.dataSuccess = successes;
      state.hasFile = action.payload ? true : false;
      state.fileName = action.payload ? state.fileName : "";
    },
    setNidsNotUpdated: (state, action: PayloadAction<string[]>) => {
      const nidsNotUpdated = state.dataSuccess.filter((item) =>
        action.payload.includes(item.nid?.toString() || ""),
      );

      state.nidsNotUpdated = nidsNotUpdated;
    },
  },
});

export const { clearBulkUploadState, setFileData, setNidsNotUpdated } =
  moduleHomeBulkUploadSlice.actions;

export default moduleHomeBulkUploadSlice.reducer;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isNumber = (value: any) => {
  return typeof value === "number";
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isString = (value: any) => {
  return typeof value === "string";
};

const validateItemData = (item: FileTypeData) => {
  let hasError = false;

  const transformedObject: FileTypeData = {
    comment: "",
    initial_utility: 0,
    min_publication_price: 0,
    nid: "",
    publication_price: 0,
  };

  const nidIsValid = isNumber(item.nid) || isString(item.nid);

  if (nidIsValid) {
    transformedObject.nid = String(item.nid);
  } else {
    hasError = true;
  }

  const publicationPriceIsValid = isNumber(item.publication_price);

  if (publicationPriceIsValid) {
    transformedObject.publication_price = item.publication_price;
  } else {
    hasError = true;
  }

  const minPublicationPriceIsValid = isNumber(item.min_publication_price);
  if (minPublicationPriceIsValid) {
    transformedObject.min_publication_price = item.min_publication_price;
  } else {
    hasError = true;
  }

  const commentIsValid = isString(item.comment);
  if (commentIsValid) {
    transformedObject.comment = item.comment;
  } else {
    hasError = true;
  }

  const initialUtilityIsValid = isNumber(item.initial_utility);
  if (initialUtilityIsValid) {
    transformedObject.initial_utility = item.initial_utility;
  } else {
    hasError = true;
  }

  return { hasError, transformedObject };
};

const validateAndTransformData = (data: Array<FileTypeData>) => {
  const errors: Array<FileTypeData> = [];
  const successes: Array<FileTypeData> = [];

  if (!data || data.length === 0) {
    return { errors, successes };
  }

  data.forEach((item) => {
    const { hasError, transformedObject } = validateItemData(item);

    if (hasError) {
      errors.push({ ...item });
    } else {
      successes.push(transformedObject);
    }
  });

  return { errors, successes };
};
