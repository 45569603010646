// libraries.
import { electricPurple900 } from "@habitech/shared";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

// hooks.
import { useGetInventoryPropertyInfo } from "@store/apiSlices/inventoryPropertyInfo/inventoryPropertyInfo.hook";
import {
  CommissionRange,
  increaseCommissionPercentage,
  isCommissionWithinPercentageRange,
} from "@utils/priceUtils.ts/isCommissionWithinPercentageRange";

export const CommissionPercentageInput = () => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  const {
    getInventoryPropertyInfoQuery: { data },
  } = useGetInventoryPropertyInfo();

  const defaultValue = Number(data?.commission) || 0;
  const limitCommission = defaultValue + increaseCommissionPercentage;

  useEffect(() => {
    setValue("commissionPercentage", defaultValue);
  }, [defaultValue]);

  const validatePercentageCommission = (value: number) => {
    const commissionRange = isCommissionWithinPercentageRange(
      value,
      defaultValue,
      increaseCommissionPercentage,
    );
    if (commissionRange === CommissionRange.UP) {
      return `Comisión máxima ${limitCommission}%`;
    }
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <div>Porcentaje de comisión</div>
        <div className="d-flex">
          <input
            data-testid="commissionPercentageInput"
            {...register("commissionPercentage", {
              min: 0,
              validate: (value) => validatePercentageCommission(value),
              valueAsNumber: true,
            })}
            disabled
            step="any"
            type="number"
            className="ps-2 pe-1"
            style={{
              borderRadius: "8px 0 0 8px",
              width: "148px",
            }}
          />
          <span
            className="p-2 "
            style={{
              backgroundColor: electricPurple900,
              borderRadius: "0 8px 8px 0",
              width: "27px",
            }}
          >
            %
          </span>
        </div>
      </div>
      {errors.commissionPercentage && (
        <small className="d-flex justify-content-end text-danger fw-bold">
          {String(errors.commissionPercentage.message)}
        </small>
      )}
    </>
  );
};
