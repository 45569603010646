import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum modalOptions {
  // inventory modal options.
  "modalInventoryPageCreateComment" = "modalInventoryPageCreateComment",
  "modalInventoryPageMapZone" = "modalInventoryPageMapZone",
  "modalInventoryPageArchivedComment" = "modalInventoryPageArchivedComment",
  "modalInventoryPageGetZone" = "modalInventoryPageGetZone",
  "modalInventoryPageNotifications" = "modalInventoryPageNotifications",
}

interface ModalOptionsWithArgs<TArgs> {
  args: TArgs;
  modalOptions: modalOptions | modalOptions[];
}

interface SettingsType<TArgs> {
  args?: TArgs;
  modalOptions: modalOptions[];
}

const initialStateModals: SettingsType<unknown> = {
  args: null,
  modalOptions: [],
};

export const modalOptionsSlice = createSlice({
  initialState: initialStateModals,
  name: "modalOptions",
  reducers: {
    closeModalOptions(state) {
      state.modalOptions = [];
      state.args = null;
    },
    closeThisModal(state, action: PayloadAction<modalOptions>) {
      state.modalOptions = state.modalOptions.filter(
        (modal) => modal !== action.payload,
      );
      state.args = null;
    },
    setModalOptions<TArgs>(
      state: SettingsType<TArgs>,
      action: PayloadAction<ModalOptionsWithArgs<TArgs>>,
    ): void {
      const modalOptions: modalOptions[] = Array.isArray(
        action.payload.modalOptions,
      )
        ? action.payload.modalOptions
        : [action.payload.modalOptions];

      state.modalOptions = modalOptions;
      state.args = action.payload.args;
    },
  },
});

export const { closeModalOptions, closeThisModal, setModalOptions } =
  modalOptionsSlice.actions;

export default modalOptionsSlice.reducer;
