// libraries.
import { Table } from "react-bootstrap";

// components.
import { TableHeaders } from "../../TableHeaders";
import { TableItem } from "../../TableItem";

// hooks.
import { useComments } from "@store/apiSlices/feedbackLoop/comments/comments.hook";

export const CommentsTable = () => {
  const { commentsNoArchived } = useComments();

  return (
    <Table striped bordered hover responsive className="rounded-5 mb-0">
      <TableHeaders />
      <tbody>
        {commentsNoArchived?.map((propsComment, i) => (
          <TableItem
            key={propsComment.commentId}
            {...propsComment}
            number={i + 1}
          />
        ))}
      </tbody>
    </Table>
  );
};

export const ArchivedCommentsTable = () => {
  const { commentsArchived } = useComments();

  return (
    <Table striped bordered hover responsive className="rounded-5 mb-0">
      <TableHeaders />
      <tbody>
        {commentsArchived?.map((propsComment, i) => (
          <TableItem
            key={propsComment.commentId}
            {...propsComment}
            number={i + 1}
          />
        ))}
      </tbody>
    </Table>
  );
};
