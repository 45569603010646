// libraries.
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

// slices.
import { apiPortalPricing } from "../apiSlices";

// interfaces.
import { ModelError } from "../interfaces/interfaces";

export interface SaveApprovalPublicationPriceModelMX {
  agent: string;
  country: string;
  current_category: string;
  id: string;
  initial_utility: number;
  minimum_publication_price: number;
  minimum_publication_price_discount: number;
  price_type: string;
  publication_price: number;
  publication_price_discount: number;
  purchase_price: number;
  status: string;
}
export interface SaveApprovalPublicationPriceModelCO {
  agent: string;
  country: string;
  current_category: string;
  discard_reason_variation?: string;
  id: string;
  initial_utility: number;
  minimum_publication_price: number;
  price_type: string;
  publication_price: number;
  purchase_price: number;
  status: string;
  utility_option?: string;
  variation_price?: number;
}

interface SaveApprovalPublicationPriceModelResponse {
  message: string;
  status: string;
  success: boolean;
}

export const saveApprovalPublicationPrice = apiPortalPricing.injectEndpoints({
  endpoints: (builder) => ({
    saveApprovalPublicationPrice: builder.mutation({
      query: (
        newData:
          | SaveApprovalPublicationPriceModelMX
          | SaveApprovalPublicationPriceModelCO,
      ) => ({
        body: newData,
        method: "POST",
        url: "/post_status_pricing",
      }),
      transformErrorResponse: (error: FetchBaseQueryError): ModelError => {
        const modelError: ModelError = {
          errorStatus: error.status,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          messageFromServer: (error.data as any)?.message,
          serviceErrorName: "post_status_pricing",
        };
        return modelError;
      },
      transformResponse: (
        response: SaveApprovalPublicationPriceModelResponse,
      ): SaveApprovalPublicationPriceModelResponse => {
        return response;
      },
    }),
  }),
});

export const { useSaveApprovalPublicationPriceMutation } =
  saveApprovalPublicationPrice;
