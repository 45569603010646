// Endpoints.
import { LeadDetailInfo } from "@store/apiSlices/leadDetail/leadDetail.endpoints";

// Components.
import { Area, Rooms, Bathrooms, Garage, Elevator } from "./PropertyFeatures";

// Styles.
import { WrapperPropertyLayout } from "./styles";

type PropertyLayoutProps = {
  data: LeadDetailInfo | undefined;
};
export const PropertyLayout = ({ data }: PropertyLayoutProps) => {
  return (
    <WrapperPropertyLayout>
      <h4 className="fs-6 mb-3">Acerca del inmueble</h4>
      <div className="d-flex justify-content-between gap-1">
        <Area area={data?.property?.area} />
        <Rooms rooms={data?.property?.rooms} />
        <Bathrooms
          bathrooms={data?.property?.baths}
          restrooms={data?.property?.half_baths}
        />
        <Garage garages={data?.property?.garage} />
        <Elevator elevators={data?.property?.elevator} />
      </div>
    </WrapperPropertyLayout>
  );
};
