interface PreSaleProps {
  preSale: number;
}

export const PreSaleTag = ({ preSale }: PreSaleProps) => {
  const isPreSale = preSale === 1;

  return isPreSale ? (
    <div className="badge pill badge-pre-sale mt-1">PreVenta</div>
  ) : null;
};
