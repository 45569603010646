import { useGetInventoryPropertyInfo } from "@store/apiSlices/inventoryPropertyInfo/inventoryPropertyInfo.hook";

export const DefaultCommission = () => {
  const {
    getInventoryPropertyInfoQuery: { data },
  } = useGetInventoryPropertyInfo();

  return (
    <div className="d-flex justify-content-end gap-3">
      <div>Comisión</div>
      <div>{data?.commission} %</div>
    </div>
  );
};
