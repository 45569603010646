// components
import {
  SidebarProvider,
  useSidebar,
  Sidebar,
  SidebarButtonHome,
} from "@shared/Sidebar";
import { UserInfo } from "@pages/HomePage/components/SidebarHome/components/UserInfo";
import { ButtonDiagnosis } from "./components/ButtonDiagnosis";
import { ButtonFeedbackLoop } from "./components/ButtonFeedbackLoop";
import { ButtonFeedbackRemo } from "./components/ButtonFeedbackRemo";

export const SidebarInventory = () => {
  const sidebar = useSidebar();

  return (
    <SidebarProvider value={sidebar}>
      <Sidebar className="position-fixed z-3">
        <div
          className="d-flex flex-column h-100 "
          data-testid="sidebar-inventory"
        >
          <div className="d-flex flex-column gap-3 h-100 justify-content-between">
            <div className="d-flex flex-column gap-3">
              <SidebarButtonHome />
              <ButtonDiagnosis />
              <ButtonFeedbackRemo />
              <ButtonFeedbackLoop />
            </div>
            <UserInfo />
          </div>
        </div>
      </Sidebar>
    </SidebarProvider>
  );
};
