import DropdownBootstrap from "react-bootstrap/Dropdown";

interface DropdownMenuProps {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}
export const DropdownMenu = ({
  className,
  style,
  children,
}: DropdownMenuProps) => {
  return (
    <DropdownBootstrap.Menu className={className} style={style}>
      {children}
    </DropdownBootstrap.Menu>
  );
};
