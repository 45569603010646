// components.
import { InfoTagHover } from "@shared";
import { CardPriceInfo } from "../PriceInfoCO";

// hooks.
import { useGetPublicationPriceInfo } from "@store/apiSlices/pricing/pricing.hooks";
import { IconSuccess } from "@assets";

interface CardCurrentProps {
  classStyles?: string;
}

export const CardCurrentRevenueCategory = ({
  classStyles,
}: CardCurrentProps) => {
  const {
    getPublicationPriceInfoQuery: { data },
  } = useGetPublicationPriceInfo();

  const isRevenueStandardCurrent = data?.isCurrentRevenueStandard === true;

  return (
    <CardPriceInfo classNameData={classStyles}>
      <small className="title-card">Categoría actual</small>
      <div className="d-flex subtitle-card fw-bold justify-content-between">
        {data?.revenueStandard || "No tiene"}
        {isRevenueStandardCurrent && (
          <InfoTagHover
            style={{ left: "-20px", top: "16px" }}
            icon={<IconSuccess />}
          >
            Vigente
          </InfoTagHover>
        )}
      </div>
    </CardPriceInfo>
  );
};
