// Libraries
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const apiInventoryPortal = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.ENV_INVENTORY_PORTAL_API_URL as string,
    prepareHeaders: (headers: Headers) => {
      headers.set(
        "x-api-key",
        process.env.ENV_INVENTORY_PORTAL_API_KEY as string,
      );
      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),
  endpoints: () => ({}),
  reducerPath: "apiInventoryPortal",
});

export const apiAuth = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.ENV_COGNITO_URL as string,
    prepareHeaders: (headers: Headers) => {
      headers.set("x-api-key", process.env.ENV_AUTH_API_KEY as string);
      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),
  endpoints: () => ({}),
  reducerPath: "apiAuth",
});

export const apiPortalPricing = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.ENV_PORTAL_PRICING_API,
    prepareHeaders: (headers) => {
      headers.set(
        "x-api-key",
        process.env.ENV_PORTAL_PRICING_API_KEY as string,
      );
      headers.set("Content-Type", "application/json");
      headers.set("Env-Origin", process.env.ENV_ORIGIN as string);
      return headers;
    },
  }),
  endpoints: () => ({}),
});
