// libraries.
import { useEffect, useState } from "react";

// hooks.
import { useModuleInventoryList } from "../../../inventoryList-slice/inventory.hook";

// components.
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
} from "@shared/Inputs/Dropdown";
import { CheckboxItem } from "@shared/Inputs/Dropdown/components/CheckboxItem";

// utils.
import { bucketCheck } from "../../../utils";

// styles.
import { WrapperInventoryFilters } from "./styles";

export const BucketFilter = () => {
  const { updateBucketFilter } = useModuleInventoryList();
  const initialSelectedOptions = bucketCheck.map((option) => option.value);
  const [selectedFilters, setSelectedFilters] = useState<string[]>(
    initialSelectedOptions,
  );

  const handleCheckboxChange = (value: string) => {
    setSelectedFilters((prevSelectedOpts) => {
      const isAllSelected = prevSelectedOpts.includes("allSelected");
      const isSelected = prevSelectedOpts.includes(value);

      if (value === "allSelected") {
        return isAllSelected ? [] : bucketCheck.map((option) => option.value);
      }
      if (isSelected && prevSelectedOpts.length === bucketCheck.length) {
        return prevSelectedOpts.filter(
          (option) => option !== value && option !== "allSelected",
        );
      }

      const updatedOptions = isSelected
        ? prevSelectedOpts.filter((option) => option !== value)
        : [...prevSelectedOpts, value];

      const optionsSelected = bucketCheck
        .filter((option) => option.value !== "allSelected")
        .every((option) => updatedOptions.includes(option.value));

      return optionsSelected
        ? [...updatedOptions, "allSelected"]
        : updatedOptions;
    });
  };

  useEffect(() => {
    const hasSelectAll = selectedFilters.includes("allSelected");
    if (hasSelectAll) {
      const updatedOptions = selectedFilters.filter(
        (option) => option !== "allSelected",
      );
      updateBucketFilter(updatedOptions.join(","));
    } else {
      updateBucketFilter(selectedFilters.join(","));
    }
  }, [selectedFilters, updateBucketFilter]);

  const defaultOption = {
    label: "Bucket",
    value: "bucketFilter",
  };

  return (
    <WrapperInventoryFilters>
      <Dropdown value={defaultOption}>
        <DropdownToggle
          className="filter-toggle"
          id="dropdownBucketFilter"
          showArrow
          style={{
            background: "white",
            border: "1px solid #949494",
            color: "black",
          }}
        />
        {selectedFilters.length > 0 && (
          <span className="counter">{selectedFilters.length}</span>
        )}
        <DropdownMenu>
          {bucketCheck.map((option) => (
            <CheckboxItem
              className="filter-check"
              key={option.value}
              id={option.label}
              label={option.label}
              checked={selectedFilters.includes(option.value)}
              onChange={() => handleCheckboxChange(option.value)}
            />
          ))}
        </DropdownMenu>
      </Dropdown>
    </WrapperInventoryFilters>
  );
};
