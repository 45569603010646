// libraries.
import styled from "styled-components";
import {
  electricPurple500,
  electricPurple700,
  electricPurple800,
  electricPurple900,
  scarpaGray25,
  monserratSemiBold,
  robotoRegular,
} from "@habitech/shared";

export const WrapperPublicationPriceSummary = styled.div`
  .price-summary {
    background-color: ${electricPurple800};
  }

  .price-info {
    background-color: ${scarpaGray25};
  }

  .line {
    height: 1px;
    background-color: ${electricPurple700};
  }

  input {
    background-color: ${electricPurple900};
    border: none;
    color: white;
  }

  textarea {
    background-color: ${electricPurple900};
    border: none;
    color: white;
    font-size: 14px;
    font-family: ${robotoRegular};
  }

  button {
    background-color: ${electricPurple500};
    border: none;
    &:hover {
      background-color: rgb(99, 1, 204);
    }
  }

  .title {
    font-family: ${monserratSemiBold};
    font-size: 16px;
    font-weight: 800;
  }
`;

export const PercentileCard = styled.div`
  grid-column: span 3; // Esto hará que el contenedor ocupe tres columnas
  gap: 1rem;
  .title {
    font-family: ${monserratSemiBold};
    font-size: 16px;
    font-weight: 800;
  }
  small {
    font-family: ${robotoRegular};
  }
`;
