import { Container } from "react-bootstrap";

export const NotFoundPage = () => {
  return (
    <Container
      className="d-flex align-items-center justify-content-center flex-column"
      style={{ height: "100vh" }}
    >
      <h1>404 - Página no encontrada</h1>
      <p>Lo sentimos, la página que buscas no existe.</p>
    </Container>
  );
};
