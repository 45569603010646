import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: process.env.ENV_SENTRY_DSN,
  environment: process.env.ENV_ORIGIN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 0.1,
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.2,
});
