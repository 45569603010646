import { useUserProfile } from "@shared/GoogleLoginButton/user-slice/useUserProfile.hook";
import { useGetLeadDetailInfo } from "@store/apiSlices/leadDetail/leadDetail.hooks";

interface FilterOption {
  id: string;
  label: string;
  selected: boolean;
}

export const getLabelUsedFilters = (
  propertyFilter: FilterOption[] | undefined,
): string => {
  const items: string[] = [];
  let label = "";

  propertyFilter?.forEach((item: FilterOption) => {
    if (item.selected && item.id !== "seleccionar_todos") {
      const name = item.label;
      items.push(name);
      label += `${name}, `;
    }
  });

  if (label) {
    const labelWithoutLastCharacter = label.slice(0, -2);
    if (items.length > 1) {
      label = `${labelWithoutLastCharacter.slice(0, 23)}... +${items.length}`;
    } else {
      label = labelWithoutLastCharacter;
    }
  }
  return label;
};

interface PropertyFilter {
  area_max?: number;
  area_min?: number;
}

export const getAreaRange = (
  propertyFilter: PropertyFilter | undefined,
): string | undefined => {
  const { user } = useUserProfile();
  const {
    getLeadDetailInfoQuery: { data },
  } = useGetLeadDetailInfo();
  if (user?.country === "CO") {
    if (
      data?.property?.area &&
      propertyFilter?.area_min &&
      data?.property?.area - propertyFilter?.area_min === 3
    ) {
      return "+/- 3";
    }
    if (
      data?.property?.area &&
      propertyFilter?.area_min &&
      data?.property?.area - propertyFilter?.area_min === 7
    ) {
      return "+/- 7";
    }
  }

  if (
    data?.property?.area &&
    propertyFilter?.area_min &&
    data?.property?.area - propertyFilter?.area_min === 5
  ) {
    return "+/- 5";
  }
  if (
    data?.property?.area &&
    propertyFilter?.area_min &&
    data?.property?.area - propertyFilter?.area_min === 10
  ) {
    return "+/- 10";
  }
  if (
    data?.property?.area &&
    propertyFilter?.area_min &&
    data?.property?.area - propertyFilter?.area_min === 20
  ) {
    return "+/- 20";
  }
  return undefined;
};

interface PropertyFilter {
  years_old_min?: number;
}

export const getAntiquityRange = (
  propertyFilter: PropertyFilter | undefined,
): string => {
  const {
    getLeadDetailInfoQuery: { data },
  } = useGetLeadDetailInfo();
  const antiquityFromLeadDetail = data?.property?.antiquity;
  if (antiquityFromLeadDetail && propertyFilter?.years_old_min) {
    const res = antiquityFromLeadDetail - propertyFilter.years_old_min;
    if (res === 3) {
      return "+/-3";
    }
    if (res === 5) {
      return "+/-5";
    }
    if (res === 7) {
      return "+/-7";
    }
    if (res === 10) {
      return "+/-10";
    }
    return `+/-${res}`;
  }
  return "Pendiente";
};
