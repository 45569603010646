// utils
import { formatPrice } from "@utils";

interface PropsTotalPrices {
  priceMin: number;
  pricePublished: number;
}

export function roundedByThousands(numero: number): number {
  return Math.round(numero / 1000) * 1000;
}

export const TotalPrices = ({ priceMin, pricePublished }: PropsTotalPrices) => {
  const formatPriceMin = formatPrice(priceMin);
  const formatPricePublished = formatPrice(pricePublished);

  return (
    <div className="d-flex justify-content-between align-items-center ">
      <div className="fw-bold">Precios finales</div>
      <div className="d-flex flex-column gap-2">
        <div className="d-flex justify-content-end">
          Publicado: {formatPricePublished}
        </div>
        <div className="d-flex justify-content-end">
          Mínimo: {formatPriceMin}
        </div>
      </div>
    </div>
  );
};
