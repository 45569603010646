// libraries
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useFormContext } from "react-hook-form";

// hooks.
import { useNid } from "@pages/HomePage";
import { useUserProfile } from "@shared/GoogleLoginButton/user-slice/useUserProfile.hook";
import { useGetLeadDetailInfo } from "@store/apiSlices/leadDetail/leadDetail.hooks";
import { useGetPublicationPriceInfo } from "@store/apiSlices/pricing/pricing.hooks";
import { useSaveApprovalPublicationPriceMutation } from "@store/apiSlices/statusPricing/saveApprovalPublicationPrice.endpoints";
import { useIncreaseApprovalPrice } from "@store/apiSlices/increaseApprovalPrice/increaseApprovalPrice.hook";

// paths.
import { pathHome } from "@routes/routes.types";
import { pathHomeReleaseNotes } from "@pages/HomePage/routes/routes.types";
import { useEffect } from "react";
import { usePriceUtility } from "@store/apiSlices/utilityService/priceUtility.hook";
import { useDebounce } from "@uidotdev/usehooks";

export const SavePricingButton = () => {
  const navigate = useNavigate();
  const { watch } = useFormContext();

  const originalMinimumPrice = watch("originalMinimumPrice");
  const finalMinPublicationPrice = watch("finalMinPublicationPrice");
  const deferredOriginalValue = useDebounce(finalMinPublicationPrice, 1000);

  const {
    getMinimumPriceUtilityQuery: { status },
    utilityInitial,
    isUtilityInitialNull,
  } = usePriceUtility({
    price: deferredOriginalValue,
  });

  const { user } = useUserProfile();

  const { getLeadDetailInfoQuery } = useGetLeadDetailInfo();
  const dealId = getLeadDetailInfoQuery.data?.deal_id + "";

  const { businessOpportunity } = useNid();
  const businessOpportunityValue = businessOpportunity?.value;

  const {
    getPublicationPriceInfoQuery: { data },
  } = useGetPublicationPriceInfo();

  const deferredMinimum = useDebounce(originalMinimumPrice, 1000);

  const {
    increaseApprovalPriceQuery: { data: increasePrices },
  } = useIncreaseApprovalPrice({
    priceMinimumOriginal: deferredMinimum,
  });

  const pricePublication = Number(increasePrices?.publicationPrice);
  const priceMinPublication = Number(increasePrices?.minimumPublicationPrice);

  const [
    handlePostStatusPricing,
    { isSuccess, isError, isLoading: isLoadingSavePrice },
  ] = useSaveApprovalPublicationPriceMutation();

  const savePricing = () => {
    if (!data || !user.country || !dealId || !businessOpportunityValue) return;

    handlePostStatusPricing({
      agent: user.email,
      country: user.country,
      current_category: data.revenueStandard,
      id: dealId,
      initial_utility: utilityInitial || 0,
      minimum_publication_price: priceMinPublication,
      price_type: businessOpportunityValue,
      publication_price: pricePublication,
      purchase_price: data.purchasePrice,
      status: "completed",
    });
  };

  useEffect(() => {
    if (isSuccess) {
      navigate(`${pathHome}/${pathHomeReleaseNotes}`);
      toast.success("Precios guardados correctamente");
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      toast.error("Error al guardar el precio");
    }
  }, [isError]);

  useEffect(() => {
    if (isLoadingSavePrice) {
      toast.loading("Guardando precios...", {
        toastId: "loadingPublicationPriceInfo",
      });
    } else {
      toast.dismiss("loadingPublicationPriceInfo");
    }
  }, [isLoadingSavePrice]);

  if (status === "uninitialized") {
    return (
      <Button
        onClick={savePricing}
        className="w-100 rounded-5 p-3"
        disabled={true}
      >
        Guardar
      </Button>
    );
  }

  if (status !== "fulfilled") {
    return (
      <Button
        onClick={savePricing}
        className="w-100 rounded-5 p-3"
        disabled={true}
      >
        Validando utilidad...
      </Button>
    );
  }

  return (
    <Button
      onClick={savePricing}
      className="w-100 rounded-5 p-3"
      disabled={isUtilityInitialNull}
    >
      {isUtilityInitialNull ? "Utilidad esperada no valida" : "Guardar"}
    </Button>
  );
};
