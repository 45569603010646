import { SidebarAction } from "./sidebar.actions";
import { SIDEBAR_BUTTON_HOME, sidebarActionsTypes } from "./sidebar.types";

export interface SidebarState {
  isHoveredSidebar: boolean;
  selectedSidebarButton: string;
}

export const INITIAL_STATE_SIDEBAR: SidebarState = {
  isHoveredSidebar: false,
  selectedSidebarButton: SIDEBAR_BUTTON_HOME,
};

export const sidebarReducer = (
  state: SidebarState,
  action: SidebarAction,
): SidebarState => {
  switch (action.type) {
    case sidebarActionsTypes.SIDEBAR_SELECTED_BUTTON:
      return { ...state, selectedSidebarButton: action.payload };
    case sidebarActionsTypes.SIDEBAR_IS_HOVERED:
      return { ...state, isHoveredSidebar: action.payload };
    case sidebarActionsTypes.SIDEBAR_RESET:
      return INITIAL_STATE_SIDEBAR;
    default:
      return state;
  }
};
