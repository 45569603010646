// libraries.
import { useEffect, useState } from "react";

export interface OptionValue {
  label: string;
  value: string;
}

export interface OnChangeArgs {
  value?: OptionValue;
}

interface UseSelectValueArgs {
  onChange?: (args: OnChangeArgs) => void;
  value?: OptionValue;
}

export const useSelectValue = ({ value, onChange }: UseSelectValueArgs) => {
  const [selectedValue, setSelectedValue] = useState(value);

  const handleChange = (value: OptionValue) => {
    setSelectedValue(value);
    onChange && onChange({ value });
  };

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  return { handleChange, selectedValue };
};
