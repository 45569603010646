// libraries.
import { Form } from "react-bootstrap";
import { useFormContext } from "react-hook-form";

// utils.
import { percentageToApplyInRelatedPricesCO } from "@shared/DashboardComponentsSummary/components/utils";
import { WrapperInputCheck } from "./styles";

export const PercentageInPrices = () => {
  const { register } = useFormContext();

  return (
    <WrapperInputCheck>
      <div className="d-flex justify-content-end">
        <Form.Check
          {...register("applyPercentageToPrices")}
          type="checkbox"
          label={`Calculo automático al ${percentageToApplyInRelatedPricesCO}%`}
          className="py-2"
          data-testid="applyPercentageToPrices"
        />
      </div>
    </WrapperInputCheck>
  );
};
