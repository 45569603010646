// libraries
import { robotoRegular, scarpaGray500 } from "@habitech/shared";
import styled from "styled-components";

export const WrapperTable = styled.div`
  font-size: 13px;
  font-family: ${robotoRegular};
  th {
    font-weight: 900;
  }
  td {
    color: ${scarpaGray500};
  }
`;
