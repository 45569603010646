// hooks.
import { useComments } from "@store/apiSlices/feedbackLoop/comments/comments.hook";

// styles.
import { WrapperFeedbackTabs } from "@pages/InventoryPage/modules/FeedbackRemo/components/styles";

interface PropsTitleCommentsTabs {
  active: boolean;
  title: string | JSX.Element;
}

export const TitleCommentsTabs = ({
  active,
  title,
}: PropsTitleCommentsTabs) => {
  return <div className={`${active && "fw-bold"}`}>{title}</div>;
};

export const TitleComments = ({ active }: { active: boolean }) => {
  const { commentsNoArchived } = useComments();

  return (
    <WrapperFeedbackTabs>
      <TitleCommentsTabs
        active={active}
        title={
          <div className="d-flex gap-1 align-items-center">
            <div className="tab-remo">Comentarios</div>
            <span>({commentsNoArchived?.length})</span>
          </div>
        }
      />
    </WrapperFeedbackTabs>
  );
};

export const TitleArchivedComments = ({ active }: { active: boolean }) => {
  const { commentsArchived } = useComments();

  return (
    <WrapperFeedbackTabs>
      <TitleCommentsTabs
        active={active}
        title={
          <div className="d-flex gap-1 align-items-center">
            <div className="tab-actions">Archivados</div>
            <span>({commentsArchived?.length})</span>
          </div>
        }
      />
    </WrapperFeedbackTabs>
  );
};
