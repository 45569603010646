// libraries.
import { Card } from "react-bootstrap";
import {
  electricPurple800,
  robotoRegular,
  scarpaGray500,
} from "@habitech/shared";

// components
import { UserImage } from "./UserImage";
import { BadgeCommentType } from "./BadgeCommentType";
import { BadgeCommentCategory } from "./BadgeCommentCategory";
import { BadgeCommentExperience } from "./BadgeCommentExperience";

// styles.
import { Styles } from "@interfaces/styles";

interface Props extends Styles {
  commentCategory?: string;
  commentExperience?: string;
  commentText?: string;
  commentType?: string;
  date?: string;
  imageUser?: string;
  user?: string;
}

export const CardComment = ({
  user,
  date,
  className,
  commentExperience,
  commentCategory,
  commentText,
  commentType,
  style,
}: Props) => {
  const selectDate = date || new Date().toLocaleDateString();
  const text = commentText || "";
  const finalStyle = style || {
    backgroundColor: electricPurple800,
    border: "none",
  };

  return (
    <Card className={className} style={finalStyle}>
      <Card.Body className="d-flex flex-column gap-2 p-0">
        <div className="d-flex flex-column gap-2">
          <div className="d-flex justify-content-between mb-2">
            <div className="simple-text">
              <UserImage nameUser={user} />
            </div>
            <small
              className="d-flex align-items-center simple-text"
              style={{
                color: `${scarpaGray500}`,
                fontFamily: "Roboto",
              }}
            >
              {selectDate}
            </small>
          </div>
          <small
            style={{
              color: `${scarpaGray500}`,
            }}
          >
            <BadgeCommentCategory commentCategory={commentCategory} />
          </small>
          <div className="d-flex justify-content-between mb-2">
            <div className="d-flex gap-2 text-white">
              <BadgeCommentType commentType={commentType} />
              <BadgeCommentExperience experience={commentExperience} />
            </div>
          </div>
          <small
            style={{
              display: "block",
              fontFamily: `${robotoRegular}`,
              maxHeight: "88px",
              overflowY: "auto",
            }}
          >
            {text}
          </small>
        </div>
      </Card.Body>
    </Card>
  );
};
