// libraries.
import { IconAgent, IconCalendar, IconMoney } from "@assets";
import { Card } from "react-bootstrap";

// utils.
import { getCurrentDealBusiness } from "../../utils";
import { formatPrice } from "@utils/formatPrice.utils";

// hooks.
import { useGetLeadDetailInfo } from "@store/apiSlices/leadDetail/leadDetail.hooks";

// styles.
import { Title, WrapperHistoryRecord, WrapperTitle } from "./styles";

export const LeadRecordCard = () => {
  const {
    getLeadDetailInfoQuery: { data },
  } = useGetLeadDetailInfo();

  const dealBusiness = getCurrentDealBusiness(
    data?.pricing_records[0]?.price_type,
  );
  if (data?.pricing_records?.length === 0) {
    return null;
  }
  return (
    <WrapperHistoryRecord className="p-4">
      <WrapperTitle>
        <Title>Histórico</Title>
      </WrapperTitle>
      <Card bg="light" style={{ width: "235px" }}>
        <Card.Header>
          <div className="title">{dealBusiness?.labelDealBusiness}</div>
          <div className="d-flex gap-2 align-items-center">
            <IconCalendar />
            <div>{data?.pricing_records[0]?.created_at}</div>
          </div>
          <div className="d-flex gap-2 align-items-center">
            <IconAgent />
            <div>{data?.pricing_records[0]?.agent_email.split("@")[0]}</div>
          </div>
        </Card.Header>
        <Card.Body>
          <div className="d-flex gap-2 align-items-center">
            <IconMoney />
            <div>
              {data?.pricing_records[0]?.price &&
                formatPrice(data?.pricing_records[0]?.price)}
            </div>
          </div>
        </Card.Body>
      </Card>
    </WrapperHistoryRecord>
  );
};
