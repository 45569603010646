// libraries.
import { useEffect } from "react";
import { Card } from "react-bootstrap";

// hooks.
import { useUserLeadDetail } from "../hooks/userLeadDetail.hook";
import { useGetHistoryLeadInfo } from "@store/apiSlices/historyLeadInfo/historyLeadInfo.hooks";
import { useLazyGetPercentileCalculatorQuery } from "@store/apiSlices/percentileCalculator/percentileCalculator.endpoints";

// components.
import { PercentileCard } from "@pages/PublicationPricePage/modules/PublicationPriceSummary/styles";
import { GridContainerCO } from "../../pages/PublicationPricePage/modules/PublicationPriceSummary/components/PublicationPriceSummaryCO/PriceInfoCO/styles";
import {
  GridHeader,
  GridItem,
} from "@pages/PublicationPricePage/modules/PublicationPriceSummary/components/PublicationPriceSummaryMX/PriceInfoMX/components/styles";

// interfaces
interface PropsPercentileCalculator {
  price: number;
  priceMin: number;
}

export const PercentileCalculator = ({
  price,
  priceMin,
}: PropsPercentileCalculator) => {
  const { country, area } = useUserLeadDetail();
  const {
    getHistoryLeadInfoQuery: { data: historyLeadInfo },
  } = useGetHistoryLeadInfo();

  const priceM2 = price / area || 0;
  const priceMinM2 = priceMin / area || 0;
  const comparablesInfoUsed = historyLeadInfo?.used_comparables?.map(
    ({ price_per_m2, last_ask_price }) => ({
      price_m2: price_per_m2,
      sale_value: last_ask_price,
    }),
  );

  const comparablesToJsonString = JSON.stringify(comparablesInfoUsed);
  const comparablesToEncodedString = encodeURIComponent(
    comparablesToJsonString,
  );

  const [mutatePercentileCalculator, { data: calculatorValues }] =
    useLazyGetPercentileCalculatorQuery();

  useEffect(() => {
    mutatePercentileCalculator({
      comparables: comparablesToEncodedString,
      country: country,
      minPublicationPriceAbs: priceMin,
      minPublicationPriceM2: priceMinM2,
      publicationPriceAbs: price,
      publicationPriceM2: priceM2,
    });
  }, [price, comparablesToEncodedString, priceMin]);

  return (
    <PercentileCard>
      <Card>
        <div className="p-3 border-bottom">
          <div className="title">Calculadora de Percentiles</div>
        </div>
        <GridContainerCO
          className="p-3 fw-bold"
          data-testId="grid-container-CO"
        >
          <div id="just-for-create-a-space" />
          <GridHeader className="text-muted row1">Precio mínimo</GridHeader>
          <GridHeader className="text-muted row2">
            Precio publicación
          </GridHeader>
          <GridHeader className="text-muted col1">
            Sobre precios absolutos
          </GridHeader>
          <GridItem data-testId="priceABSMin-CO">
            {calculatorValues?.minPublicationPrice.abs}
          </GridItem>
          <GridItem data-testId="priceABSPublication-CO">
            {calculatorValues?.publicationPrice.abs}
          </GridItem>
          <GridHeader className="text-muted col2">
            Sobre valores de m²
          </GridHeader>
          <GridItem data-testId="priceMinM2-CO">
            {calculatorValues?.minPublicationPrice.m2}
          </GridItem>
          <GridItem
            className="active-number"
            data-testId="priceM2Publication-CO"
          >
            {calculatorValues?.publicationPrice.m2}
          </GridItem>
        </GridContainerCO>
        <small className="px-3 pb-3 text-muted" data-testId="caption-CO">
          Se actualiza de acuerdo a los valores ingresados en precio de
          publicación y precio mínimo publicado
        </small>
      </Card>
    </PercentileCard>
  );
};
