// Slices
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { apiInventoryPortal } from "../apiSlices";
import { ModelError } from "../interfaces/interfaces";

interface Source {
  id: number;
  label: string;
}

interface City {
  id: number;
  label: string;
}

interface Agent {
  email: string;
  id: string;
  name: string;
}

interface Location {
  address: string;
  address_lote?: string;
  area_metropolitan_id?: number;
  big_zone?: string;
  big_zone_id?: number;
  city: City;
  coordinates?: number[];
  delegation: string;
  exterior_number?: string;
  house_complex?: string;
  lote_id?: number;
  median_zone?: string;
  median_zone_id?: number;
  zip_code?: string;
}

interface Price {
  last_price: number;
  price: number;
  price_m2: number;
}

interface Property {
  antiquity: number;
  area: number | undefined;
  baths: number;
  elevator: number;
  floor: number;
  garage: number;
  gray_work: number;
  half_baths: number;
  land: number;
  price: Price;
  property_id: number;
  property_type: string;
  property_type_id: number;
  rooms: number;
}

interface PricingRecords {
  agent_email: string;
  agent_name: string;
  created_at: string;
  price: number;
  price_type: string;
}

interface UtilsDate {
  create_date_description: string;
  date: string;
}

interface LogicDoors {
  id: string;
  label: string;
  value: string;
}

export interface LeadDetailInfo {
  agent: Agent;
  bucket: string;
  deal_id: number;
  hubspot_link: string;
  location: Location;
  logic_doors: LogicDoors[];
  nid: number;
  pricing_records: PricingRecords[];
  property: Property;
  publication_price: number;
  purchase_price: number;
  revenue_standard: string;
  source: Source;
  utils_date: UtilsDate;
}

export const leadDetail = apiInventoryPortal.injectEndpoints({
  endpoints: (builder) => ({
    getLeadDetailInfo: builder.query({
      query: ({ country, nid }: { country?: string; nid?: string }) => {
        return `/get_lead_detail?country=${country}&nid=${nid}`;
      },
      transformErrorResponse: (error: FetchBaseQueryError) => {
        const modelError: ModelError = {
          errorStatus: error.status,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          messageFromServer: (error.data as any)?.message,
          serviceErrorName: "getLeadDetailInfo",
        };
        return modelError;
      },
      transformResponse: (response: LeadDetailInfo) => {
        const leadDetailInfo: LeadDetailInfo = {
          agent: {
            email: response.agent.email,
            id: response.agent.id,
            name: response.agent.name,
          },
          bucket: response.bucket,
          deal_id: response.deal_id,
          hubspot_link: response.hubspot_link,
          location: {
            address: response.location.address,
            address_lote: response.location.address_lote || "",
            area_metropolitan_id: response.location.area_metropolitan_id || 0,
            big_zone: response.location.big_zone || "",
            big_zone_id: response.location.big_zone_id || 0,
            city: {
              id: response.location.city.id,
              label: response.location.city.label,
            },
            coordinates: response.location.coordinates || [0, 0],
            delegation: response.location.delegation,
            exterior_number: response.location.exterior_number || "",
            house_complex: response.location.house_complex || "",
            lote_id: response.location.lote_id || 0,
            median_zone: response.location.median_zone || "",
            median_zone_id: response.location.median_zone_id || 0,
            zip_code: response.location.zip_code || "",
          },
          logic_doors: response.logic_doors.map((doorsInfo) => ({
            id: doorsInfo.id || "",
            label: doorsInfo.label || "",
            value: doorsInfo.value || "",
          })),
          nid: response.nid,
          pricing_records: response.pricing_records.map((recordsInfo) => ({
            agent_email: recordsInfo.agent_email || "",
            agent_name: recordsInfo.agent_name || "",
            created_at: recordsInfo.created_at || "",
            price: recordsInfo.price || 0,
            price_type: recordsInfo.price_type || "",
          })),
          property: {
            antiquity: response.property.antiquity || 0,
            area: response.property.area || 0,
            baths: response.property.baths || 0,
            elevator: response.property.elevator || 0,
            floor: response.property.floor || 0,
            garage: response.property.garage || 0,
            gray_work: response.property.gray_work || 0,
            half_baths: response.property.half_baths || 0,
            land: response.property.land || 0,
            price: {
              last_price: response.property.price.last_price || 0,
              price: response.property.price.price || 0,
              price_m2: response.property.price.price_m2 || 0,
            },
            property_id: response.property.property_id || 0,
            property_type: response.property.property_type,
            property_type_id: response.property.baths || 0,
            rooms: response.property.rooms || 0,
          },
          publication_price: response.publication_price || 0,
          purchase_price: response.purchase_price || 0,
          revenue_standard: response.revenue_standard,
          source: { id: response.source.id, label: response.source.label },
          utils_date: {
            create_date_description:
              response.utils_date.create_date_description || "",
            date: response.utils_date.date || "",
          },
        };
        return leadDetailInfo;
      },
    }),
  }),
});

export const { useGetLeadDetailInfoQuery } = leadDetail;
