// libraries.
import {
  robotoRegular,
  scarpaGray50,
  scarpaGray500,
  scarpaGray800,
} from "@habitech/shared";
import styled from "styled-components";

export const ContainerFilters = styled.div`
  gap: 6px;
  display: flex;
  flex-direction: column;
  p {
    font-family: ${robotoRegular};
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: ${scarpaGray800};
  }
  .antiquity {
    display: flex;
    justify-content: space-between;
  }
  .price-filter {
    padding: 10px 16px;
    width: 108px;
    height: 40px;
    outline: none;
    background-color: ${scarpaGray50};
    border: 1px solid rgb(211, 210, 212);
    border-radius: 8px;
    cursor: not-allowed;
    color: ${scarpaGray500};
    font-family: ${robotoRegular};
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
  }
  .null-filter {
    display: flex;
    justify-content: space-between;
    font-family: ${robotoRegular};
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 18px;
  }
`;
