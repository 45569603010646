// components.
import { useGetHistoryLeadInfo } from "@store/apiSlices/historyLeadInfo/historyLeadInfo.hooks";
import { CalculationsTableCO } from "./components/CalculationsTableCO/CalculationsTableCO";
import { CalculationsTableMX } from "./components/CalculationsTableMX/CalculationsTableMX";

// hooks.
import { useUserProfile } from "@shared/GoogleLoginButton/user-slice/useUserProfile.hook";
import { useEffect } from "react";
import { toast } from "react-toastify";

interface TableItemByCountry {
  component: React.ReactNode;
  country: string;
}

export const TablesByCountry: TableItemByCountry[] = [
  {
    component: <CalculationsTableMX />,
    country: "MX",
  },
  {
    component: <CalculationsTableCO />,
    country: "CO",
  },
];

export const ComparablesTableByCountry = () => {
  const { user } = useUserProfile();
  const {
    getHistoryLeadInfoQuery: { isLoading },
  } = useGetHistoryLeadInfo();

  const calculationsTable = TablesByCountry.find(
    (filter) => filter.country === user?.country,
  );

  useEffect(() => {
    if (isLoading) {
      toast.loading("Cargando cálculos...", {
        toastId: "loadingCalculationsTable",
      });
    } else {
      toast.dismiss("loadingCalculationsTable");
    }
  }, [isLoading]);

  return calculationsTable && calculationsTable.component;
};
