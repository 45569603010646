import { useUserProfile } from "@shared/GoogleLoginButton/user-slice/useUserProfile.hook";

export const getBadgeColor = (color: string | undefined) => {
  const { user } = useUserProfile();
  const SourceBadges = [
    {
      id: "TypeformMX",
      value: "lowConfidence",
    },
    {
      id: "Web Scraping",
      value: "midConfidence",
    },
    {
      id: "avaluos",
      value: "highConfidence",
    },
    {
      id: "WEB",
      value: user?.country === "MX" ? "lowConfidence" : "midConfidence",
    },
    {
      id: "Okol landing",
      value: "noConfidence",
    },
    {
      id: "Okol leadsform",
      value: "noConfidence",
    },
    {
      id: "Okol",
      value: "midConfidence",
    },
    {
      id: "Ventana",
      value: "highConfidence",
    },
    {
      id: "Broker",
      value: "noConfidence",
    },
    {
      id: "Marketing",
      value: "midConfidence",
    },
    {
      id: "Estudio Inmueble",
      value: "lowConfidence",
    },
    {
      id: "ventana-scraping",
      value: user?.country === "MX" ? "lowConfidence" : "highConfidence",
    },
    {
      id: "CRM",
      value: "highConfidence",
    },
    {
      id: "Centro de Experiencia",
      value: "lowConfidence",
    },
    {
      id: "centro experiencia bogota",
      value: "lowConfidence",
    },
    {
      id: "Habimetro pcom",
      value: "lowConfidence",
    },
    {
      id: "Formulario Inversionistas",
      value: "lowConfidence",
    },
    {
      id: "Propiedades",
      value: "noConfidence",
    },
    {
      id: "propiedades",
      value: "noConfidence",
    },
    {
      id: "linea telefonica cali",
      value: "lowConfidence",
    },
    {
      id: "linea telefonica medellin",
      value: "midConfidence",
    },
    {
      id: "habi constructora",
      value: "noConfidence",
    },
    {
      id: "comercial",
      value: "lowConfidence",
    },
    {
      id: "Google Ads Forms",
      value: "noConfidence",
    },
    {
      id: "Facebook Forms",
      value: "noConfidence",
    },
    {
      id: "Form-habi",
      value: "noConfidence",
    },
    {
      id: "TikTok-Forms",
      value: "noConfidence",
    },
    {
      id: "Trade-mkt",
      value: "noConfidence",
    },
    {
      id: "Form-mkt",
      value: "noConfidence",
    },
    {
      id: "lead_forms",
      value: "noConfidence",
    },
    {
      id: "+48",
      value: "pink",
    },
    {
      id: "+24",
      value: "goldenYellow",
    },
    {
      id: "+8",
      value: "blue",
    },
  ];

  const badge = SourceBadges.find((badge) => badge.id === color);

  if (badge) {
    switch (badge.value) {
      case "highConfidence":
        return "success";
      case "midConfidence":
        return "primary";
      case "lowConfidence":
        return "warning";
      case "noConfidence":
        return "secondary";
      default:
        return "secondary";
    }
  }
};
