// components.
import { AnalyticsBrokers } from "./components/AnalyticsBrokers";
import { RowsBrokers } from "./components/RowsBrokers";

export const TableBrokers = () => {
  return (
    <div
      style={{
        height: "calc(100vh - 165px)",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <AnalyticsBrokers />
      <RowsBrokers />
    </div>
  );
};
