// slices.
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { apiInventoryPortal } from "../apiSlices";
import { ModelError } from "../interfaces/interfaces";

interface PriceCountdownParams {
  country?: string;
  nid?: string;
}

interface PriceCountdownResponse {
  body: Array<{
    remaining_days: number;
    allow_to_price: number;
  }>;
  code: number;
  success: boolean;
}

export const priceCountdown = apiInventoryPortal.injectEndpoints({
  endpoints: (builder) => ({
    getPriceCountdown: builder.query({
      query: ({ country, nid }: PriceCountdownParams) => {
        return `/get_final_countdown?country=${country}&nid=${nid}`;
      },
      transformErrorResponse: (error: FetchBaseQueryError) => {
        const modelError: ModelError = {
          errorStatus: error.status,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          messageFromServer: (error.data as any)?.message,
          serviceErrorName: "getPriceCountdown",
        };
        return modelError;
      },
      transformResponse: (response: PriceCountdownResponse) => {
        const { remaining_days, allow_to_price } = response.body[0];
        return {
          finalCountdown: remaining_days,
          isAbleToPrice: allow_to_price,
        };
      },
    }),
  }),
});

export const { useGetPriceCountdownQuery } = priceCountdown;
