// libraries.
import { ListGroup } from "react-bootstrap";
import { electricPurple600 } from "@habitech/shared";

// hooks.
import { useGetHistoryLeadInfo } from "@store/apiSlices/historyLeadInfo/historyLeadInfo.hooks";

// styles.
import { WrapperInfoContainer } from "../CalculationsInfoCO/styles";

// utils.
import { formatPrice } from "@utils/formatPrice.utils";

export const CalculationsInfoMX = () => {
  const {
    getHistoryLeadInfoQuery: { data },
  } = useGetHistoryLeadInfo();
  return (
    <WrapperInfoContainer>
      <ListGroup horizontal className="list-mx">
        <ListGroup.Item>
          <div data-id="calculation-properties">
            <span>Mediana</span>
            <br />
            <strong>
              {data?.median_price_m2 && formatPrice(data?.median_price_m2)}
            </strong>
          </div>
        </ListGroup.Item>
        <ListGroup.Item>
          <div data-id="calculation-properties">
            <span>Coeficiente</span>
            <br />
            <strong>{`${data?.coefficient_variation.toFixed(2)} %`}</strong>
          </div>
        </ListGroup.Item>
        <ListGroup.Item>
          <div data-id="calculation-properties">
            <span>Área</span>
            <br />
            <strong>{`${data?.m2} mt²`}</strong>
          </div>
        </ListGroup.Item>
        <ListGroup.Item>
          <div data-id="calculation-properties">
            <span>Percentil</span>
            <br />
            <strong>{data?.percentil}</strong>
          </div>
        </ListGroup.Item>
        <ListGroup.Item>
          <div data-id="calculation-properties">
            <span>Valor de compra</span>
            <br />
            <strong>
              {data?.discount_price && formatPrice(data?.discount_price)}
            </strong>
          </div>
        </ListGroup.Item>
        <ListGroup.Item>
          <div data-id="calculation-properties">
            <span>Valor comercial</span>
            <br />
            <strong style={{ color: `${electricPurple600}` }}>
              {data?.starting_price && formatPrice(data?.starting_price)}
            </strong>
          </div>
        </ListGroup.Item>
      </ListGroup>
    </WrapperInfoContainer>
  );
};
