export const TableLoading = () => {
  return (
    <div
      className=" d-flex justify-content-center align-items-center my-5"
      style={{
        height: "calc(100vh - 235px)",
        overflowY: "auto",
      }}
    >
      <div className="loading-spinner" />
    </div>
  );
};
