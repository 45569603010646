const variationOptions = [
  {
    id: "change_of_comparables",
    label: "Cambio de Comparables",
  },
  {
    id: "change_of_methodology",
    label: "Cambio de Metodología",
  },
  { id: "rare_case", label: "Caso raro" },
];

export const getLabelVariation = (id: string) => {
  const variationOption = variationOptions.find((option) => option.id === id);
  return variationOption?.label;
};

export const actualWarning = {
  Basic: { max: 5.5, min: -Infinity },
  Bone: { max: 5.5, min: -Infinity },
  Prime: { max: 5.5, min: -Infinity },
  Regular: { max: 5.5, min: -Infinity },
  Subprime: { max: 5.5, min: -Infinity },
};

export const utilityOptions = [
  { label: "Error precio de compra", value: "purchase_price_error" },
  { label: "Error precio de comité", value: "committee_price_error" },
  { label: "Cambio de mercado", value: "market_shift" },
  { label: "Valores remo", value: "rowing_values" },
  { label: "Caso extraño", value: "strange_case" },
  { label: "Cumple según categoría", value: "meets_according_to_category" },
  {
    label: "Descuento de compra bajo para la zona",
    value: "low_purchase_discount_for_the_area",
  },
  { label: "HESH eleva precio", value: "hesh_raises_price" },
  { label: "Aumento BO-Pricing", value: "increase_bo_pricing" },
  { label: "Sin utilidad desde compra", value: "no_profit_since_purchase" },
  { label: "Acuerdo con IM", value: "agreement_with_im" },
  { label: "Ajustado por históricos", value: "adjusted_by_historical" },
  {
    label: "Cambio de percentil Vence fecha",
    value: "percentile_change_due_date",
  },
];
