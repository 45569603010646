// hooks.
import { useUserLeadDetail } from "@shared/hooks/userLeadDetail.hook";
import { useGetListInventoryQuery } from "./listInventory.endpoint";
import { useModuleInventoryList } from "@pages/HomePage/modules/Inventory/inventoryList-slice/inventory.hook";
import { UserRoles } from "@shared/WrapperByUserRole/WrapperByUserRole";
import { useRoleByUser } from "../roles/role.hook";

export const useListInventory = () => {
  const { userRole } = useRoleByUser();
  const { country, email } = useUserLeadDetail();
  const { alertOptions, bucketFilter } = useModuleInventoryList();

  const shouldFetch = !country && !email;

  const leaderRole = userRole === UserRoles.LEADER;
  const emailParam = leaderRole ? "" : email;

  const getListInventory = useGetListInventoryQuery(
    {
      alert: alertOptions,
      bucket: bucketFilter,
      country,
      email: emailParam,
    },
    { skip: shouldFetch },
  );

  const listInventoryData = getListInventory.data || [];
  const loadingData = getListInventory.isLoading;
  const inventoryLen = getListInventory.data?.length || 0;

  return {
    getListInventory,
    inventoryLen,
    listInventoryData,
    loadingData,
  };
};
