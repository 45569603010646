import { useUserLeadDetail } from "@shared/hooks/userLeadDetail.hook";
import { useGetMinimumPriceUtilityQuery } from "./priceUtility.endpoints";
import { useGetPublicationPriceInfo } from "../pricing/pricing.hooks";
import { useDropdownCategory } from "@shared/DropdownCategories/dropdownCategory-slice/dropdownCategory.hook";

export const findCategoryToUse = ({
  categoryPriceName,
  isRevenueStandardInRange,
  selectedCategoryFromUser,
}: {
  categoryPriceName?: string;
  isRevenueStandardInRange?: boolean;
  selectedCategoryFromUser?: string;
}) => {
  if (selectedCategoryFromUser) {
    return selectedCategoryFromUser;
  }
  if (isRevenueStandardInRange) {
    return categoryPriceName;
  }
  return "";
};

export const usePriceUtility = ({ price }: { price: number }) => {
  const { nid, country } = useUserLeadDetail();
  const { getPublicationPriceInfoQuery } = useGetPublicationPriceInfo();
  const { selectedCategoryFromUser } = useDropdownCategory();

  const isRevenueStandardInRange =
    getPublicationPriceInfoQuery.data?.isCurrentRevenueStandard;
  const category = findCategoryToUse({
    categoryPriceName: getPublicationPriceInfoQuery.data?.categoryPriceName,
    isRevenueStandardInRange,
    selectedCategoryFromUser,
  });

  const shouldFetch = !!price && !!country && !!nid;

  const getMinimumPriceUtilityQuery = useGetMinimumPriceUtilityQuery(
    {
      category,
      country,
      nid,
      price: price,
    },
    {
      skip: !shouldFetch,
    },
  );

  const utilityInitial =
    getMinimumPriceUtilityQuery.data?.currentMinimumPriceUtility
      .initial_utility;
  const utilityCurrent =
    getMinimumPriceUtilityQuery.data?.currentMinimumPriceUtility
      .current_utility;

  const isUtilityInitialNull = utilityInitial === null;

  return {
    getMinimumPriceUtilityQuery,
    isUtilityInitialNull,
    selectedCategoryFromUser,
    utilityCurrent,
    utilityInitial,
  };
};
