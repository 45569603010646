export const commentCategoriesOptions = [
  {
    id: "cleaning",
    label: "Limpieza",
  },
  {
    id: "security",
    label: "Seguridad",
  },
  {
    id: "noise",
    label: "Ruido",
  },
  {
    id: "other",
    label: "Otro",
  },
];

export const findCommentCategoryLabel = (id?: string) => {
  return commentCategoriesOptions.find((category) => category.id === id)?.label;
};
