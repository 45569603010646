// Slices
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { apiInventoryPortal } from "../apiSlices";
import { ModelError } from "../interfaces/interfaces";

interface PublicationPriceInfo {
  approvalCategory: string;
  askPriceCommittee: number;
  askPricePublication: number;
  boIncrementPrice: number;
  categoryPriceName: string;
  discardReasonVariation: string;
  habiIsHabiPrice: number;
  improvementsPrice: number;
  isCurrentRevenueStandard: boolean;
  isHabiIsHabi: boolean;
  lastCommitteeComment: string;
  lastVariation: number;
  minPublicationPrice: number;
  originalMinimumPrice: number;
  paintingPrice: number;
  percentileCommittee: number;
  percentileHesh: number;
  percentilePublication: number;
  priceUtility: number;
  publicationPrice: number;
  purchasePrice: number;
  remodelingBudget: number;
  repairsPrice: number;
  revenueStandard: string;
  revenueStandardCopy: string;
  totalRemo: number;
  utilityPercentage: number;
  variationPrice: number;
}

export const pricing = apiInventoryPortal.injectEndpoints({
  endpoints: (builder) => ({
    getPublicationPriceInfo: builder.query({
      query: ({ country, nid }: { country?: string; nid?: string }) => {
        return `/get_publication_price_info?country=${country}&nid=${nid}`;
      },
      transformErrorResponse: (error: FetchBaseQueryError) => {
        const modelError: ModelError = {
          errorStatus: error.status,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          messageFromServer: (error.data as any)?.message,
          serviceErrorName: "getPublicationPriceInfo",
        };
        return modelError;
      },
      transformResponse: (response: {
        approve_category: string;
        ask_price_committee: number;
        ask_price_publication: number;
        bo_increment: number;
        category: string;
        comment_last_committee: string;
        discard_reason_variation: string;
        flag_price_committee: number;
        improvements: number;
        is_current_revenue_standard: boolean;
        last_variation: number;
        minimum_publication_price: number;
        original_minimum_price_api: number;
        paint: number;
        percentil_committee: number;
        percentil_hesh: number;
        percentil_publication: number;
        price_hesh: number;
        price_utility: number;
        publication_price: number;
        purchase_price: number;
        remodeling_budget: number;
        repairs: number;
        revenue_standard: string;
        total_remo: number;
        utility: number;
        variation_price: number;
      }) => {
        const regularCategory =
          response.revenue_standard === "No-Low Experience"
            ? "Regular"
            : response.revenue_standard;
        const publicationPriceInfo: PublicationPriceInfo = {
          approvalCategory: response.approve_category,
          askPriceCommittee: response.ask_price_committee,
          askPricePublication: response.ask_price_publication,
          boIncrementPrice: response.bo_increment,
          categoryPriceName: response.category,
          discardReasonVariation: response.discard_reason_variation || "",
          habiIsHabiPrice: response.price_hesh,
          improvementsPrice: response.improvements,
          isCurrentRevenueStandard: response.is_current_revenue_standard,
          isHabiIsHabi: !!response.flag_price_committee,
          lastCommitteeComment: response.comment_last_committee,
          lastVariation: response.last_variation || 0,
          minPublicationPrice: response.minimum_publication_price,
          originalMinimumPrice: response.original_minimum_price_api,
          paintingPrice: response.paint,
          percentileCommittee: response.percentil_committee,
          percentileHesh: response.percentil_hesh,
          percentilePublication: response.percentil_publication,
          priceUtility: response.price_utility || 0,
          publicationPrice: response.publication_price,
          purchasePrice: response.purchase_price,
          remodelingBudget: response.remodeling_budget,
          repairsPrice: response.repairs,
          revenueStandard: regularCategory,
          revenueStandardCopy: response.revenue_standard,
          totalRemo: response.total_remo,
          utilityPercentage: response.utility || 0,
          variationPrice: response.variation_price,
        };
        return publicationPriceInfo;
      },
    }),
  }),
});

export const { useGetPublicationPriceInfoQuery } = pricing;
