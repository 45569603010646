// Libraries.
import styled from "styled-components";
import { scarpaGray200 } from "@habitech/shared";

export const GreyLine = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${scarpaGray200};
`;

export const WrapperLoader = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
