// components.
import { CalculationsTabsMX } from "./CalculationsTabs";

// styles.
import { WrapperCalculation } from "./styles";

export const CalculationsTableMX = () => {
  return (
    <WrapperCalculation>
      <div className="w-100 py-4">
        <CalculationsTabsMX />
      </div>
    </WrapperCalculation>
  );
};
