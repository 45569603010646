import { robotoRegular, scarpaGray500 } from "@habitech/shared";
import styled from "styled-components";

export const WrapperInfoContainer = styled.div`
  .list {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    font-size: 13px;
    font-family: ${robotoRegular};
  }
  .list-mx {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    font-size: 13px;
    font-family: ${robotoRegular};
  }
  span {
    color: ${scarpaGray500};
    font-family: ${robotoRegular};
    font-weight: 500;
  }
`;
