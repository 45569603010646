// shared components.
import { Title } from "@pages/HomePage/components/NavBarUser/ViewLeadDetail/components/LeadRecord/styles";
import { WrapperCardItems } from "@shared/DashboardComponentsSummary";
import { Card } from "react-bootstrap";

// mock.
import { useGetInventoryPropertyInfo } from "@store/apiSlices/inventoryPropertyInfo/inventoryPropertyInfo.hook";

export const PropertyStatistics = () => {
  const {
    getInventoryPropertyInfoQuery: { data },
  } = useGetInventoryPropertyInfo();

  const clicks = data?.click_num || 0;
  const downloads = data?.download_num || 0;
  const visits = data?.visit_num || 0;
  const offers = data?.offer_num || 0;

  return (
    <>
      <Title>Estadísticas del inmueble</Title>
      <WrapperCardItems className="price-info-grid">
        <Card className="default-shadow rounded-3 card-price-info p-3 d-flex flex-column gap-2">
          <small className="title-card">Clicks</small>
          <div className="subtitle-card">{clicks}</div>
        </Card>
        <Card className="default-shadow rounded-3 card-price-info p-3 d-flex flex-column gap-2">
          <small className="title-card">Descargas</small>
          <div className="subtitle-card">{downloads}</div>
        </Card>
        <Card className="default-shadow rounded-3 card-price-info p-3 d-flex flex-column gap-2">
          <small className="title-card">Visitas</small>
          <div className="subtitle-card">{visits}</div>
        </Card>
        <Card className="default-shadow rounded-3 card-price-info p-3 d-flex flex-column gap-2">
          <small className="title-card">Ofertas</small>
          <div className="subtitle-card">{offers}</div>
        </Card>
      </WrapperCardItems>
    </>
  );
};
