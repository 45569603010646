import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@store/index";
import { setCategory } from "./dropdownCategory.slice";

export const useDropdownCategory = () => {
  const dispatch = useDispatch();

  const { selectedCategoryFromUser } = useSelector(
    (state: RootState) => state.sharedDropdownCategoryReducer,
  );

  const setCategoryFromUser = (category: string) => {
    dispatch(setCategory(category));
  };

  const clearCategoryFromUser = () => {
    dispatch(setCategory(""));
  };

  return {
    clearCategoryFromUser,
    selectedCategoryFromUser,
    setCategoryFromUser,
  };
};
