import { robotoRegular } from "@habitech/shared";
import styled from "styled-components";

export const WrapperButton = styled.div`
  cursor: not-allowed;
  .btn:disabled {
    background-color: rgb(210, 210, 210);
    border-color: 2px solid rgb(210, 210, 210);
    color: rgb(255, 255, 255);
    opacity: unset;
    cursor: not-allowed;
  }
  .btn:active {
    outline: none;
    background: transparent;
  }
  .error {
    font-size: 12px;
  }
`;

export const WrapperComment = styled.div`
  font-size: 15px;
  font-family: ${robotoRegular};
`;
