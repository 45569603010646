// libraries.
import { electricPurple600 } from "@habitech/shared";
import { CardItem } from "@shared/DashboardComponentsSummary";
import { useFeedbackBrokers } from "@store/apiSlices/feedbackRemo/feedbackBrokers.hooks";
import { Col, Container, Row } from "react-bootstrap";

// styles.
import { WrapperAnalyticTitle } from "../../styles";

const WrapperCardAnalytics = ({
  percentage,
  title,
}: {
  percentage: string;
  title: string;
}) => {
  return (
    <Col lg={3} className="my-3">
      <CardItem>
        <WrapperAnalyticTitle>
          <div className="text-muted">{title}</div>
          <div
            className="fw-bold percentage"
            style={{ color: electricPurple600 }}
          >
            {percentage}
          </div>
        </WrapperAnalyticTitle>
      </CardItem>
    </Col>
  );
};

export const AnalyticsBrokers = () => {
  const { brokersMetrics } = useFeedbackBrokers();

  const complexAccess = brokersMetrics?.complexAccess || "0%";
  const propertyAccess = brokersMetrics?.propertyAccess || "0%";
  const propertyInfo = brokersMetrics?.propertyInfo || "0%";
  const propertyStatus = brokersMetrics?.propertyStatus || "0%";
  return (
    <Container fluid>
      <Row>
        <WrapperCardAnalytics
          title="Acceso al inmueble"
          percentage={propertyAccess}
        />
        <WrapperCardAnalytics
          title="Acceso al conjunto"
          percentage={complexAccess}
        />
        <WrapperCardAnalytics
          title="Estado del inmueble"
          percentage={propertyStatus}
        />
        <WrapperCardAnalytics
          title="Información del inmueble"
          percentage={propertyInfo}
        />
      </Row>
    </Container>
  );
};
