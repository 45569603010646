import { electricPurple000, robotoRegular } from "@habitech/shared";
import styled from "styled-components";

export const WrapperNorm = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 30px;
  top: 80px;
  gap: 8px;
  font-family: ${robotoRegular};
  font-weight: 800;
  font-size: 12px;
`;

export const WrapperDownloadButton = styled.div`
  cursor: pointer;
  background: ${electricPurple000};
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
`;
