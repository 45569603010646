import {
  electricPurple300,
  electricPurple400,
  electricPurple50,
} from "@habitech/shared";

export const BadgeCommentType = ({ commentType }: { commentType?: string }) => {
  const type = commentType || "Modo";
  const typeIsZone = type === "zone";

  const text = type === "nid" ? "NID" : type;
  return (
    <>
      {!typeIsZone && (
        <small
          className="fw-bold py-1 px-2 rounded simple-text"
          style={{ background: electricPurple50, color: electricPurple300 }}
        >
          {text}
        </small>
      )}
      {typeIsZone && (
        <small
          className="rounded fw-bold py-1 px-2 text-white simple-text"
          style={{ background: electricPurple400 }}
        >{`Zona`}</small>
      )}
    </>
  );
};
