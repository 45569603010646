import GoogleMap from "@shared/GoogleMap/GoogleMap";
import { LeadLocation } from "./components/LeadLocation";
import { useGetLeadDetailInfo } from "@store/apiSlices/leadDetail/leadDetail.hooks";
import { LeadPolygon } from "./components/LeadPolygon";
import { LeadComparables } from "./components/LeadComparables";
import { LeadConfidenceLevels } from "./components/LeadConfidenceLevels";

export const ComparablesMap = () => {
  const { getLeadDetailInfoQuery } = useGetLeadDetailInfo();

  const coordinates = getLeadDetailInfoQuery.data?.location.coordinates;

  if (!coordinates) {
    return null;
  }

  const position = {
    lat: coordinates?.[0],
    lng: coordinates?.[1],
  };

  return (
    <div className="h-100">
      <GoogleMap
        containerStyle={{
          height: "100%",
          width: "100%",
        }}
        defaultCenter={position}
        defaultZoom={16}
      >
        <LeadConfidenceLevels />
        <LeadLocation />
        <LeadPolygon />
        <LeadComparables />
      </GoogleMap>
    </div>
  );
};
