import {
  electricPurple300,
  scarpaGray500,
  skyBlue500,
  tomatoCoral500,
  turquoise700,
} from "@habitech/shared";

const calculateValuesForExperience = (experience?: string) => {
  if (experience === "positive") {
    return { color: turquoise700, text: "Positiva" };
  } else if (experience === "negative") {
    return { color: tomatoCoral500, text: "Negativa" };
  } else if (experience === "analytic") {
    return { color: skyBlue500, text: "Análisis" };
  } else if (experience === "visit") {
    return { color: electricPurple300, text: "Visita" };
  } else {
    return { color: scarpaGray500, text: "Experiencia" };
  }
};

export const BadgeCommentExperience = ({
  experience,
}: {
  experience?: string;
}) => {
  const experienceValues = calculateValuesForExperience(experience);
  return (
    <small
      className="rounded fw-bold py-1 px-2 simple-text text-white"
      style={{ background: experienceValues.color }}
    >
      {experienceValues.text}
    </small>
  );
};
