// hooks.
import { useComments } from "@store/apiSlices/feedbackLoop/comments/comments.hook";

// components.
import { TableEmpty } from "./components/TableEmpty";
import { TableLoading } from "./components/TableLoading";
import { useEffect } from "react";
import { toast } from "react-toastify";

export const TableStatus = () => {
  const { getCommentsQuery, comments } = useComments();

  const isLoadingComments = getCommentsQuery.isLoading;
  const isSuccessComments = getCommentsQuery.isSuccess;
  const errorComments = getCommentsQuery.error;

  useEffect(() => {
    if (isLoadingComments && comments.length) {
      toast.loading("Cargando comentarios", { toastId: "getCommentsLoading" });
    } else if (errorComments) {
      toast.error("Error al cargar comentarios");
      toast.dismiss("isLoadingComments");
    } else if (isSuccessComments && comments.length) {
      toast.dismiss();
      toast.success("Comentarios cargados correctamente");
    } else {
      toast.dismiss("getCommentsLoading");
    }
  }, [errorComments, isSuccessComments]);

  if (isLoadingComments && !comments.length) {
    return <TableLoading />;
  }

  return <TableEmpty />;
};
