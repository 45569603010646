// libraries.
import { Container, Navbar } from "react-bootstrap";

// components.
import { InventoryTitle } from "./components/InventoryTitle";
import { AlertsFilter } from "./components/AlertsFilter";
import { InventorySearch } from "./components/InventorySearch/InventorySearch";

// styles.
import { scarpaGray50, white } from "@habitech/shared";
import { BucketFilter } from "./components/BucketFilter";

export const InventoryBar = () => {
  return (
    <Navbar
      style={{
        background: white,
        boxShadow: "0px 0px 16px 0px rgba(51, 51, 51, 0.08)",
      }}
      className="d-flex flex-column p-0"
    >
      <Container
        fluid
        className="p-4 d-flex flex-column"
        style={{ background: scarpaGray50 }}
      >
        <InventoryTitle />
      </Container>
      <Container fluid className="p-4 d-flex flex-column">
        <div className="w-100 d-flex justify-content-between align-items-center">
          <InventorySearch />
          <div className="d-flex gap-2" data-testid="inventory-bar-id">
            <BucketFilter />
            <AlertsFilter />
          </div>
        </div>
      </Container>
    </Navbar>
  );
};
