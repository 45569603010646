export enum CommissionRange {
  UP = "up",
  IN_RANGE = "inRange",
}

export const increaseCommissionPercentage = 0.5;

/**
 * Check if the commission is within the percentage range
 * @param commission current commission, is the commission input
 * @param defaultCommission default commission, is the original commission
 * @param increasePercentage percentage to increase
 * @returns 'up' if price is above the percentage range, undefined if price is within the percentage range
 */
export const isCommissionWithinPercentageRange = (
  commission: number,
  defaultCommission: number,
  increasePercentage: number,
) => {
  const limit = defaultCommission + increasePercentage;

  if (commission > limit) {
    return CommissionRange.UP;
  } else {
    return CommissionRange.IN_RANGE;
  }
};
