// Libraries.
import styled from "styled-components";
import {
  scarpaGray500,
  scarpaGray800,
  robotoRegular,
  monserratRegular,
} from "@habitech/shared";

export const BodyWrapper = styled.div`
  .first-subtitle {
    font-family: ${robotoRegular};
    font-weight: 400;
    font-size: 12px;
    color: ${scarpaGray500};
  }
  .second-subtitle {
    font-family: ${monserratRegular};
    font-weight: 600;
    font-size: 24px;
    color: ${scarpaGray800};
  }
`;
