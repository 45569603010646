// Libraries.
import styled from "styled-components";
import { scarpaGray200, scarpaGray800 } from "@habitech/shared";

export const InputSearch = styled.input`
  width: 100%;
  height: 40px;
  border: 1px solid ${scarpaGray200};
  font-weight: 400;
  font-size: 16px;
  color: ${scarpaGray800};
  &:focus-visible {
    outline: none;
  }
`;
