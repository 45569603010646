// components.
import { CalculationsInfoMX } from "./CalculationsInfoMX/CalculationsInfoMX";
import { CalculationsInfoCO } from "./CalculationsInfoCO/CalculationsInfoCO";

// hooks.
import { useUserProfile } from "@shared/GoogleLoginButton/user-slice/useUserProfile.hook";

interface CalculationsByCountry {
  component: React.ReactNode;
  country: string;
}

export const InfoByCountry: CalculationsByCountry[] = [
  {
    component: <CalculationsInfoMX />,
    country: "MX",
  },
  {
    component: <CalculationsInfoCO />,
    country: "CO",
  },
];

export const CalculationsInfoByCountry = () => {
  const { user } = useUserProfile();

  const calculationsInfo = InfoByCountry.find(
    (filter) => filter.country === user?.country,
  );

  return calculationsInfo && calculationsInfo.component;
};
