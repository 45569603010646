// libraries
import { Modal } from "react-bootstrap";
import { scarpaGray300, scarpaGray50 } from "@habitech/shared";

// slices.
import { modalOptions, useModalOptions } from "@store/slices/modals";

// components.
import { NotificationsDropdown } from "./NotificationsDropdown";

// assets.
import { IconClose } from "@assets";

export const NotificationsHeader = () => {
  const { closeModal } = useModalOptions();

  return (
    <Modal.Header
      className="pb-2 px-4 pt-4"
      style={{ background: scarpaGray50 }}
    >
      <div className="w-100 d-flex flex-column gap-3">
        <div className="d-flex align-items-center justify-content-between w-100">
          <Modal.Title className="titles">Notificaciones</Modal.Title>
          <div
            data-testid="close-modal"
            onClick={() => {
              closeModal(modalOptions.modalInventoryPageNotifications);
            }}
          >
            <IconClose fill={scarpaGray300} />
          </div>
        </div>
        <NotificationsDropdown />
      </div>
    </Modal.Header>
  );
};
