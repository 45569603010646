// libraries.
import { IconWarning } from "@assets";
import { electricPurple700, robotoRegular, white } from "@habitech/shared";
import styled from "styled-components";

export const WrapperCopy = styled.div`
  background-color: ${electricPurple700};
  border-radius: 8px;
  font-size: 12px;
  font-family: ${robotoRegular};
`;

export const CopyToDisablePrice = () => {
  return (
    <WrapperCopy className="d-flex flex-column gap-1  p-4">
      <div className="d-flex gap-1 fw-bold align-content-center">
        <IconWarning fill={white} />
        <div className="d-flex align-items-center">
          Inmueble en estado Pre-Publicado
        </div>
      </div>
      <div>
        No es posible cambiar el precio ya que esta Pre-Publicado en el portal
        de Brokers
      </div>
    </WrapperCopy>
  );
};
