// components.
import { RowsRemo } from "./components/RowsRemo";

export const TableRemo = () => {
  return (
    <div
      style={{
        height: "calc(100vh - 166px)",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <RowsRemo />
    </div>
  );
};
