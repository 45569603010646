// hooks.
import { useUserProfile } from "@shared/GoogleLoginButton/user-slice/useUserProfile.hook";
import { useGetLeadDetailInfo } from "../leadDetail/leadDetail.hooks";
import { useGetFeedbackBrokersQuery } from "./feedbackBrokers.endpoints";

export const useFeedbackBrokers = () => {
  const { country } = useUserProfile();
  const { nidSelected } = useGetLeadDetailInfo();

  const getFeedbackBrokersQuery = useGetFeedbackBrokersQuery({
    country: country,
    nid: Number(nidSelected),
  });

  const itemsFeedbackBrokersLen =
    getFeedbackBrokersQuery.data?.brokerTicket.length || 0;

  const brokersMetrics = getFeedbackBrokersQuery.data?.categoryMetrics;
  const brokersTickets = getFeedbackBrokersQuery.data?.brokerTicket;
  const isLoadingBrokers = getFeedbackBrokersQuery.isLoading;

  return {
    brokersMetrics,
    brokersTickets,
    isLoadingBrokers,
    itemsFeedbackBrokersLen,
  };
};
