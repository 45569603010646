import {
  pathPublicationPriceCalculations,
  pathPublicationPriceMap,
  pathPublicationPriceSummary,
} from "./routes.types";
import { PublicationPriceMap } from "../modules/PublicationPriceMap/PublicationPriceMap";
import { PublicationPriceCalculations } from "../modules/PublicationPriceCalculations/PublicationPriceCalculations";
import { PublicationPriceSummary } from "../modules/PublicationPriceSummary/PublicationPriceSummary";
import { InventoryPortalRoute } from "@interfaces";

export const routesPublicationPrice: InventoryPortalRoute[] = [
  {
    element: <PublicationPriceMap />,
    id: "publicationPriceMap",
    path: `${pathPublicationPriceMap}/:nid`,
  },
  {
    element: <PublicationPriceCalculations />,
    id: "publicationPriceCalculation",
    path: `${pathPublicationPriceCalculations}/:nid`,
  },
  {
    element: <PublicationPriceSummary />,
    id: "PublicationPriceSummary",
    path: `${pathPublicationPriceSummary}/:nid`,
  },
];
