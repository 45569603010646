import { useUserProfile } from "@shared/GoogleLoginButton/user-slice/useUserProfile.hook";
import { useGetLeadDetailInfo } from "../leadDetail/leadDetail.hooks";
import { useGetFeedbackRemoQuery } from "./feedbackRemo.endpoints";

export const useFeedbackRemo = () => {
  const { country } = useUserProfile();
  const { nidSelected } = useGetLeadDetailInfo();

  const getFeedbackRemoQuery = useGetFeedbackRemoQuery({
    country: country,
    nid: Number(nidSelected),
  });

  const itemsFeedbackRemoLen =
    getFeedbackRemoQuery.data?.feedbackRemo.length || 0;

  return { getFeedbackRemoQuery, itemsFeedbackRemoLen };
};
