// libraries.
import { Form } from "react-bootstrap";
import { useFormContext } from "react-hook-form";

// components.
import { CardPriceInfo } from "../PriceInfoCO";
import { utilityOptions } from "./utils";

export const CardUtilityOptions = () => {
  const { register } = useFormContext();

  return (
    <CardPriceInfo>
      <small className="title-card">Opciones utilidad</small>
      <Form.Select
        aria-label="utility options"
        data-testid="utilityOptions"
        {...register("utilityOption")}
      >
        <option value="">Seleccionar</option>
        {utilityOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Form.Select>
    </CardPriceInfo>
  );
};
