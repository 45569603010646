import {
  electricPurple000,
  electricPurple500,
  monserratSemiBold,
} from "@habitech/shared";
import styled from "styled-components";

export const WrapperFilters = styled.div`
  background: ${electricPurple000};
  flex-direction: column;
  display: flex;
  .filters-title {
    font-family: ${monserratSemiBold};
    font-weight: 600;
    font-size: 16px;
  }
  .Styles__WrapperSegmentedList-sc-zh2btw-1 {
    width: 100%;
  }

  .bg-habi {
    background: ${electricPurple500};
  }
`;
