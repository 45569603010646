// libraries.
import {
  electricPurple50,
  electricPurple500,
  robotoRegular,
  scarpaGray500,
  white,
  warning,
  electricPurple300,
  electricPurple25,
} from "@habitech/shared";
import styled from "styled-components";

export const WrapperPublicationPriceInfo = styled.div`
  &.price-info-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 1rem;
  }

  .card-price-info {
    border: 1px solid ${electricPurple50};
    background: ${white};
    box-shadow: 0px 0px 16px 0px rgba(124, 1, 255, 0.08);
    .title-card {
      color: ${scarpaGray500};
    }

    .subtitle-card {
      color: ${electricPurple500};
      font-size: 16px;
      font-family: ${robotoRegular};
    }

    .warning {
      color: ${warning};
      font-weight: 800;
      font-family: ${robotoRegular};
      font-size: 16px;
    }
  }
`;

export const GridContainerCO = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  background: ${white};
  border-radius: 8px;
  font-family: ${robotoRegular};

  .row1 {
    border-radius: 8px 0px 0px 0px;
    font-size: 14px;
  }

  .row2 {
    border-radius: 0px 8px 0px 0px;
    font-size: 14px;
    padding: 16px 0;
  }

  .col1 {
    border-radius: 8px 0px 0px 0px;
    font-size: 14px;
    padding: 16px 5px;
  }

  .col2 {
    border-radius: 0px 0px 0px 8px;
    font-size: 14px;
    padding: 16px 10px;
  }

  .active-number {
    color: ${electricPurple300};
    background-color: ${electricPurple25};
  }
`;
