// utils.
import { normalizationFlags } from "./utils";
import { formatPrice } from "@utils/formatPrice.utils";

// styles.
import { WrapperColorPrice } from "./styles";

interface NormalizedProps {
  comparableId: number;
}

export const NormalizedM2Price = ({ comparableId }: NormalizedProps) => {
  const { colorIndicatorPriceM2, priceList, usedComps } = normalizationFlags({
    comparableId,
  });
  return (
    <WrapperColorPrice pricecolor={colorIndicatorPriceM2}>
      {priceList[0]
        ? formatPrice(priceList[0]?.normalized_price_m2)
        : formatPrice(usedComps[0]?.price_per_m2 || 0)}
    </WrapperColorPrice>
  );
};
