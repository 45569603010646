// libraries.
import { useFormContext } from "react-hook-form";

// components.
import { CardComment } from "../../../shared/CardComment";
import { useUserProfile } from "@shared/GoogleLoginButton/user-slice/useUserProfile.hook";
import { useEffect } from "react";

export const CommentPreview = () => {
  const { name, imageUrl, user } = useUserProfile();

  const { watch, setValue } = useFormContext();
  const commentExperience = watch("commentExperience");
  const commentText = watch("commentText");
  const commentType = watch("commentType");

  useEffect(() => {
    if (name) {
      setValue("user", name);
      // el usuario siempre debe loguearse
      setValue("email", user.email);
    }
  }, [name, setValue]);

  return (
    <div>
      <CardComment
        user={name}
        imageUser={imageUrl}
        commentExperience={commentExperience}
        commentText={commentText}
        commentType={commentType}
        className="text-white"
      />
    </div>
  );
};
