//libraries.
import { Modal } from "react-bootstrap";
import { CardNotification } from "./CardNotification";

// hooks.
import { useNotificationsList } from "@store/apiSlices/notificationsInfo/notifications.hooks";

// components.
import { EmptyNotifications } from "./EmptyNotifications";
import { LoadingNotifications } from "./LoadingNotifications";

export const NotificationsList = () => {
  const { isLoading, notificationsLen } = useNotificationsList();

  if (notificationsLen === 0) return <EmptyNotifications />;

  if (isLoading) return <LoadingNotifications />;

  return (
    <Modal.Body
      className="p-0"
      style={{ flex: 1, height: "100%", overflow: "scroll" }}
    >
      <CardNotification />
    </Modal.Body>
  );
};
