// libraries.
import { useForm } from "react-hook-form";
import { useDebounce } from "@uidotdev/usehooks";
import { InputGroup, Form } from "react-bootstrap";
import { electricPurple600, white } from "@habitech/shared";

// hooks.

// assets.
import { IconSearch } from "@assets";
import { LeadInventoryCard } from "./components/LeadInventoryCard";

export const InventorySearch = () => {
  const { register, watch } = useForm();

  const nidValue = watch("nid");
  const deferredNidValue = useDebounce(nidValue, 1000);

  return (
    <div>
      <InputGroup>
        <Form.Control
          id="nid"
          placeholder="Busca por NID"
          aria-label="Busca por NID"
          aria-describedby="buscar"
          {...register("nid")}
          style={{ width: "200px" }}
          data-testid="input-nid-inventory-search"
        />
        <InputGroup.Text
          id="nid"
          style={{ backgroundColor: electricPurple600 }}
          className="py-2 px-3 d-flex justify-content-center align-items-center rounded-end"
        >
          <IconSearch fill={white} />
        </InputGroup.Text>
      </InputGroup>
      <LeadInventoryCard nid={deferredNidValue} />
    </div>
  );
};
