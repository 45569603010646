// libraries.
import { Button, Modal } from "react-bootstrap";

// types.
import { useLeadMutationAux } from "@store/apiSlices/statusPricing/statusPricing.hook";
import { useNid } from "../../../SearchByNid/nid-slice/useNid.hook";
import { useGetStatusPricingByAgent } from "@store/apiSlices/statusPricing/statusPricingByAgent.hook";
import { WrapperHasOtherLeadInProgress, WrapperProgress } from "./styles";
export const StartPricing = () => {
  const { businessOpportunity, businessOpportunityIsConfirmed, setNidValue } =
    useNid();
  const {
    getStatusPricingByAgentQuery: { isLoading },
    leadAgentStatus,
  } = useGetStatusPricingByAgent();

  const {
    leadCanStartPricing,
    leadIsInProgressByOtherUser,
    leadIsInProgressByCurrentUser,
    handleContinueLead,
    handleLeadAbortedMutation,
    handleLeadInProgressMutation,
    isLoadingAbortLead,
    isLoadingLeadInProgress,
    status,
  } = useLeadMutationAux();

  const agentHasOtherLeadInProgress =
    leadAgentStatus.isLeadInProgress && leadAgentStatus.isTheSameNid === false;

  const continueLead =
    leadAgentStatus.isTheSameNid &&
    leadAgentStatus.isLeadInProgress &&
    leadIsInProgressByCurrentUser;
  const leadInProgressByOtherUser = status === "Agent has a deal in progress";

  const disableButton =
    isLoadingLeadInProgress ||
    !businessOpportunity?.value ||
    !businessOpportunityIsConfirmed;

  if (isLoading) {
    return (
      <Modal.Footer className="p-4">
        <div>Cargando acciones...</div>
      </Modal.Footer>
    );
  }

  if (agentHasOtherLeadInProgress) {
    return (
      <Modal.Footer
        className="p-4 pointer"
        onClick={() => setNidValue(`${leadAgentStatus?.nid}`)}
      >
        <WrapperHasOtherLeadInProgress>
          <div>
            Tienes que <span className="fw-bold">finalizar</span> o{" "}
            <span className="fw-bold">abandonar</span> el proceso en curso:{" "}
            <span className="fw-bold other-nid">NID {leadAgentStatus.nid}</span>
          </div>
        </WrapperHasOtherLeadInProgress>
      </Modal.Footer>
    );
  }

  if (leadInProgressByOtherUser || leadIsInProgressByOtherUser) {
    return (
      <WrapperProgress>
        <Modal.Footer className="p-4">
          <div className="progress-nid">Lead en progreso por otro agente</div>
        </Modal.Footer>
      </WrapperProgress>
    );
  }

  return (
    <Modal.Footer className="p-4">
      {continueLead && (
        <>
          <Button
            variant="danger"
            onClick={handleLeadAbortedMutation}
            disabled={isLoadingAbortLead}
            className="rounded-pill "
          >
            {isLoadingAbortLead ? "Cargando..." : "Abandonar"}
          </Button>
          <Button onClick={handleContinueLead} disabled={disableButton}>
            {isLoadingLeadInProgress ? "Cargando..." : "Continuar"}
          </Button>
        </>
      )}

      {leadCanStartPricing && (
        <Button onClick={handleLeadInProgressMutation} disabled={disableButton}>
          {isLoadingLeadInProgress ? "Cargando..." : "Comenzar"}
        </Button>
      )}
    </Modal.Footer>
  );
};
