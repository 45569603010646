// libraries
import { MouseEvent } from "react";
import { robotoRegular } from "@habitech/shared";

// slices.
import { IComment } from "@store/apiSlices/feedbackLoop/comments/comments.service";
import { modalOptions, useModalOptions } from "@store/slices/modals";

// shared.
import { UserImage } from "../../../shared/UserImage";
import { BadgeCommentType } from "../../../shared/BadgeCommentType";
import { BadgeCommentStatus } from "../../../shared/BadgeCommentStatus";
import { BadgeCommentCategory } from "../../../shared/BadgeCommentCategory";
import { BadgeCommentExperience } from "../../../shared/BadgeCommentExperience";

export const TableItem = ({
  number,
  commentId,
  user,
  dateUpdated,
  commentCategory,
  commentType,
  commentExperience,
  commentText,
  commentStatus,
}: IComment) => {
  const { openModal } = useModalOptions();

  const handleBadgeClick = (event: MouseEvent<HTMLTableCellElement>) => {
    if (commentType === "zone") {
      event.stopPropagation();
      openModal(modalOptions.modalInventoryPageGetZone, { commentId });
    }
  };

  return (
    <tr
      onClick={() =>
        openModal(modalOptions.modalInventoryPageCreateComment, { commentId })
      }
    >
      <td
        className="text-center fw-bold"
        style={{ fontFamily: `${robotoRegular}` }}
      >
        {number}
      </td>
      <td style={{ fontFamily: `${robotoRegular}` }}>{dateUpdated}</td>
      <td className="text-white pointer" onClick={handleBadgeClick}>
        <BadgeCommentType commentType={commentType} />
      </td>
      <td
        style={{
          fontFamily: `${robotoRegular}`,
          maxWidth: "400px",
          wordWrap: "break-word",
        }}
      >
        {commentText}
      </td>
      <td style={{ fontFamily: `${robotoRegular}` }}>
        <UserImage nameUser={user} />
      </td>
      <td>
        <BadgeCommentCategory commentCategory={commentCategory} />
      </td>
      <td className="text-white">
        <BadgeCommentExperience experience={commentExperience} />
      </td>
      <td className="text-center">
        <BadgeCommentStatus status={commentStatus} />
      </td>
    </tr>
  );
};
