// libraries.
import { useFormContext } from "react-hook-form";
import { useDebounce } from "@uidotdev/usehooks";

// components.
import { InfoTagHover } from "@shared/InfoTagHover/InfoTagHover";
import { CardItem } from "@shared/DashboardComponentsSummary";

// hooks.
import { useGetInventoryPropertyInfo } from "@store/apiSlices/inventoryPropertyInfo/inventoryPropertyInfo.hook";

// endpoints.
import { usePriceUtility } from "@store/apiSlices/utilityService/priceUtility.hook";
import { useEffect } from "react";
import { CardDropdownCategories } from "@shared/DropdownCategories";

export const UtilityCardsCO = () => {
  const {
    watch,
    setValue,
    formState: { dirtyFields },
  } = useFormContext();
  const { utilityInitial: utilityDefault } = useGetInventoryPropertyInfo();

  const userTouchMinPublicationPrice = dirtyFields.minPublicationPrice;
  const minPublicationPriceInput = watch("minPublicationPrice");

  const deferredMinPublicationPriceValue = useDebounce(
    minPublicationPriceInput,
    1000,
  );

  const {
    getMinimumPriceUtilityQuery: { isFetching, isError },
    utilityCurrent,
    utilityInitial,
    selectedCategoryFromUser,
  } = usePriceUtility({
    price: deferredMinPublicationPriceValue,
  });

  const initialUtilityToShow =
    !userTouchMinPublicationPrice && !selectedCategoryFromUser
      ? utilityDefault
      : utilityInitial;

  const isFetchingUtility = isFetching ? "Cargando..." : null;
  const isErrorUtility = isError ? "Error API" : null;

  useEffect(() => {
    setValue("utilityInitial", initialUtilityToShow);
    setValue("utilityCurrent", utilityCurrent);
  }, [initialUtilityToShow, utilityCurrent]);

  return (
    <>
      {utilityInitial === null && (
        <CardDropdownCategories title={"Categoría - UE inicial"} />
      )}
      <CardItem
        classNameData="default-shadow rounded-3 card-price-info p-3 d-flex flex-column gap-2"
        data-testid="initialUtility"
      >
        <small className="title-card">UE inicial</small>
        <div className="d-flex justify-content-between">
          <div className="subtitle-card">
            {isFetchingUtility || isErrorUtility || `${initialUtilityToShow}%`}
          </div>
          {!isFetching && !isError && (
            <InfoTagHover style={{ left: "-20px", top: "26px" }}>
              Cambia de acuerdo al precio mínimo de publicación
            </InfoTagHover>
          )}
        </div>
      </CardItem>
      <CardItem classNameData="default-shadow rounded-3 card-price-info p-3 d-flex flex-column gap-2">
        <small className="title-card">UE actual</small>
        <div className="d-flex justify-content-between">
          <div className="subtitle-card">
            {isFetchingUtility || isErrorUtility || `${utilityCurrent}%`}
          </div>
          {!isFetching && !isError && (
            <InfoTagHover style={{ left: "-20px", top: "26px" }}>
              Cambia de acuerdo al precio, comisión o días publicados
            </InfoTagHover>
          )}
        </div>
      </CardItem>
    </>
  );
};
